import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { FilterMatchMode } from 'primereact/api';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import '../../../assets/scss/_Result.scss';
import moment from 'moment';


const ClassConfiguration = () => {
    const [classList, setClassList] = useState([]);
    const [masterData, setMasterData] = useState([]);
    const [visible, setVisible] = useState(false);
    const [subjectVisible, setSubjectVisible] = useState(false);
    const [visibleSection, setVisibleSection] = useState(false);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Class: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [securityUserID, setSecurityUserID] = useState("");
    const [rows, setRows] = useState(10);
    const [first, setFirst] = useState(0);

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        GetClassList(loginData?._id);
    }, []);

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const onPageChange = (e) => {
        setFirst(e.first);
        setRows(e.rows);
    };

    const GetClassList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            Loader.show();
            await api.post(APIConstant.path.getClassList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setClassList(res?.data?.filter(x => x?.Status === 'Active')?.map((x) => ({
                        ...x,
                        Class: `${x?.ClassName}-${x?.SectionFullName.charAt(0)}-${x?.MediumName}-${x?.Board}`
                    })));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const actionBodyTemplate = (rows) => {
        return (
            <React.Fragment>
                <div className='p-d-flex p-ai-center'>
                    <Button icon="pi pi-cog" className="e-sction-btn p-m-1" onClick={() => {
                        setVisible(true);
                        setMasterData(rows)
                    }} data-pr-tooltip='Class Configuration' tooltip={"View"} tooltipOptions={{ className: 'bluegray-tooltip', position: 'top' }} />
                    {/* <Button icon="pi pi-refresh" className="e-sction-btn p-m-0 p-m-1" onClick={(e) => {
                        setVisibleSection(true);
                        setMasterData(rows)
                    }} data-pr-tooltip='Change Section' data-pr-position='top' tooltip={"View"} tooltipOptions={{ className: 'bluegray-tooltip', position: 'top' }} /> */}
                    <Button icon="pi pi-pencil" className="e-sction-btn p-m-1" onClick={() => {
                        setSubjectVisible(true);
                        setMasterData(rows)
                    }} data-pr-tooltip='Optional Subjects' tooltip={"View"} tooltipOptions={{ className: 'bluegray-tooltip', position: 'top' }} />

                </div>
            </React.Fragment>
        );
    };

    const onCancel = () => {
        setVisible(false);
        setVisibleSection(false);
        setSubjectVisible(false);
        GetClassList(securityUserID);
    }

    const actionBodyTemplateSrNo = (data, props) => {
        return props.rowIndex + 1;
    };

    const lastUpdatedBodyTemplate = (rows) => {
        return (
            <div>{moment(rows?.UpdatedOn).format("DD-MM-YYYY HH:mm")}</div>
        );
    };

    return (
        <div className='reault-main'>
            <div className='student-result'>
                <header>
                    {/* <img src="school-logo.png" alt="School Logo"> */}
                    <div className='school-logo'></div>
                    <div className='p-d-flex flex-column p-ai-center'>
                        <div className='school-name'>
                            <h1>savvy</h1>
                        </div>
                        <p className='school-h-text'>international school</p>
                        <p className='school-h-subtext'>GBSE affiliated (aff. No.9999999999)</p>
                    </div>
                    <div className='school-logo'></div>
                </header>

                <section class="report-info">

                    <div className='report-box'>
                        <p className='report-text'>report card</p>
                    </div>
                    <div className='class-box'>
                        <p className='class-text'>class : <span>i - a</span></p>
                    </div>
                    <div className='class-box'>
                        <p className='ay-text'>academic year : <span>2024-2025</span></p>
                    </div>
                    <div className='student-details'>
                        <div className='student-texts'>
                            <div className='text-box-par'>
                                <p>student's name :</p>
                                <p>father's name :</p>
                                <p>date of birth :</p>
                                <p>address :</p>
                            </div>
                            <div className='text-box-cha'>
                                <p>astha pancholi</p>
                                <p>ashwin pancholi</p>
                                <p>24-05-2017</p>
                                <p>plot no 222/10, ward 3 a adipur</p>
                            </div>
                        </div>
                        <div className='student-numbers'>
                            <div className='text-par'>
                                <p className=''>roll no</p>
                                <p className=''>g. r. no.</p>
                            </div>
                            <div className='text-cha'>
                                <p className=''>1</p>
                                <p className=''>3457</p>
                            </div>
                        </div>

                    </div>

                </section>


                <section class="result-table footer-table">
                    <table>
                        <thead>
                            <tr>
                                <th className='yello'>scholastic area</th>
                                <th className='light-peach' colspan="5">term 1 ( 100 marks )</th>
                                <th className='light-bluish' colspan="5">term 2 ( 100 marks )</th>
                                <th colspan="2" className='p-p-0 light-yello'>overall
                                    <tr>
                                        <th className='t1-t2 light-yello'>t1 + t2</th>
                                    </tr>
                                </th>

                            </tr>
                            <tr>
                                <th className='yello'>Subject</th>
                                <th className='light-peach'>per. test (10)</th>
                                <th className='light-peach'>nb (05)</th>
                                <th className='light-peach'>oral test (05)</th>
                                <th className='light-peach'>half year (80)</th>
                                <th className='light-peach'>total (100)</th>
                                <th className='light-bluish'>per. test (10)</th>
                                <th className='light-bluish'>nb (05)</th>
                                <th className='light-bluish'>oral test (05)</th>
                                <th className='light-bluish'>half year (80)</th>
                                <th className='light-bluish'>total (100)</th>
                                <th className='light-yello'>grand total (200)</th>
                                <th className='light-yello'>grade</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>english</td>
                                <td className='purple'>10</td>
                                <td className='purple'>5</td>
                                <td className='purple'>5</td>
                                <td className='purple'>80</td>
                                <td className='purple'>100</td>
                                <td className='blue'>10</td>
                                <td className='blue'>5</td>
                                <td className='blue'>5</td>
                                <td className='blue'>80</td>
                                <td className='blue'>100</td>
                                <td className='green'>200</td>
                                <td>a</td>
                            </tr>
                            <tr>
                                <td>hindi</td>
                                <td className='purple'>10</td>
                                <td className='purple'>5</td>
                                <td className='purple'>5</td>
                                <td className='purple'>80</td>
                                <td className='purple'>100</td>
                                <td className='blue'>10</td>
                                <td className='blue'>5</td>
                                <td className='blue'>5</td>
                                <td className='blue'>80</td>
                                <td className='blue'>100</td>
                                <td className='light-green'>34</td>
                                <td>b</td>
                            </tr>
                            <tr>
                                <td>gujarati</td>
                                <td className='purple'>10</td>
                                <td className='purple'>5</td>
                                <td className='purple'>5</td>
                                <td className='purple'>80</td>
                                <td className='purple'>100</td>
                                <td className='blue'>10</td>
                                <td className='blue'>5</td>
                                <td className='blue'>5</td>
                                <td className='blue'>80</td>
                                <td className='blue'>100</td>
                                <td className='light-blue'>116</td>
                                <td>c</td>
                            </tr>
                            <tr>
                                <td>maths</td>
                                <td className='purple'>3</td>
                                <td className='purple'>2</td>
                                <td className='purple'>2</td>
                                <td className='purple'>80</td>
                                <td className='purple'>87</td>
                                <td className='blue'>2</td>
                                <td className='blue'>2</td>
                                <td className='blue'>3</td>
                                <td className='blue'>5</td>
                                <td className='blue'>80</td>
                                <td className='light-orange'>95</td>
                                <td>d</td>
                            </tr>
                            <tr>
                                <td>evs</td>
                                <td className='purple'>10</td>
                                <td className='purple'>1</td>
                                <td className='purple'>3</td>
                                <td className='purple'>50</td>
                                <td className='purple'>62</td>
                                <td className='blue'>3</td>
                                <td className='blue'>4</td>
                                <td className='blue'>65</td>
                                <td className='blue'>45</td>
                                <td className='blue'>4</td>
                                <td className='red'>66</td>
                                <td>e</td>
                            </tr>
                            <tr>
                                <td>computer</td>
                                <td className='purple'>-</td>
                                <td className='purple'>-</td>
                                <td className='purple'>-</td>
                                <td className='purple'>-</td>
                                <td className='purple'>-</td>
                                <td className='blue'>-</td>
                                <td className='blue'>-</td>
                                <td className='blue'>-</td>
                                <td className='blue'>-</td>
                                <td className='blue'>-</td>
                                <td>-</td>
                                <td>b</td>
                            </tr>
                            <tr>
                                <td>gk</td>
                                <td className='purple'>-</td>
                                <td className='purple'>-</td>
                                <td className='purple'>-</td>
                                <td className='purple'>-</td>
                                <td className='purple'>-</td>
                                <td className='blue'>-</td>
                                <td className='blue'>-</td>
                                <td className='blue'>-</td>
                                <td className='blue'>-</td>
                                <td className='blue'>-</td>
                                <td>-</td>
                                <td>c</td>
                            </tr>
                            <tr>
                                <td>drawing</td>
                                <td className='purple'>-</td>
                                <td className='purple'>-</td>
                                <td className='purple'>-</td>
                                <td className='purple'>-</td>
                                <td className='purple'>-</td>
                                <td className='blue'>-</td>
                                <td className='blue'>-</td>
                                <td className='blue'>-</td>
                                <td className='blue'>-</td>
                                <td className='blue'>-</td>
                                <td>-</td>
                                <td>a</td>
                            </tr>
                            <tr className=''>
                                <td>4 point grading scale ( indicators )</td>
                                <td colspan="2">a ( 80 - 100 )</td>
                                <td colspan="2">b ( 65 - 79 )</td>
                                <td colspan="2">c ( 50 - 64 )</td>
                                <td colspan="2">d ( 35 - 49 )</td>
                                <td colspan="2">e below ( 35 )</td>
                                <td colspan="2">d ( 35 - 49 )</td>
                            </tr>


                        </tbody>
                    </table>
                    <div class="footer-row">
                        <div class="column-one">
                            <div>
                                <p className='border-btm'>4 point grading scale</p>
                                <p>( indicators )</p>
                            </div>
                        </div>
                        <div class="column-two flex-col">
                            <div className='green'>
                                <p className='border-btm'>a ( 80 - 100 )</p>
                                <p>a ( 160 - 120 )</p>
                            </div>
                            <div className='light-green'>
                                <p className=' border-btm'>b ( 65 - 79 )</p>
                                <p>b ( 130 - 159 )</p>
                            </div>
                        </div>
                        <div class="column-three flex-col">
                            <div className='light-blue'>
                                <p className=' border-btm'>c ( 50 - 64 )</p>
                                <p>c ( 100 - 129 )</p>
                            </div>
                            <div className='light-orange'>
                                <p className='border-btm'>d ( 35 - 49 )</p>
                                <p>d ( 70 - 99 )</p>
                            </div>
                        </div>
                        <div class="column-four">
                            <div className='red'>
                                <p className='border-btm'>e below ( 35 )</p>
                                <p>e below ( 69 )</p>
                            </div>

                        </div>
                    </div>
                </section>


                <div className='co-main-box'>
                    <div className='co-left'>
                        <div className='grad-points'>
                            <p>co scholastic ares</p>
                            <p>(3 point grading scale a,b,c)</p>
                        </div>
                        <div className='co-acti'>
                            <div className='text-par'>
                                <p className='p-text-center'>activities</p>
                                <p className='text'>love for prayer</p>
                                <p className='text'>cleanliness</p>
                                <p className='text'>participants in activities</p>
                            </div>
                            <div className='text-cha'>
                                <p className=''>t 1</p>
                                <p className='text'>b</p>
                                <p className='text'>b</p>
                                <p className='text'>a</p>
                            </div>
                            <div className='text-cha'>
                                <p className=''>t 2</p>
                                <p className='text'>a</p>
                                <p className='text'>a</p>
                                <p className='text'>b</p>
                            </div>
                        </div>
                    </div>
                    <div className='co-left'>
                        <div className='grad-points'>
                            <p>co scholastic ares</p>
                            <p>(3 point grading scale a,b,c)</p>
                        </div>
                        <div className='co-acti'>
                            <div className='text-par'>
                                <p className='p-text-center'>elements</p>
                                <p className='text'>school social services</p>
                                <p className='text'>polite, well mannered</p>
                                <p className='text'>discipline</p>
                            </div>
                            <div className='text-cha'>
                                <p className=''>t 1</p>
                                <p className='text'>a</p>
                                <p className='text'>a</p>
                                <p className='text'>a</p>
                            </div>
                            <div className='text-cha'>
                                <p className=''>t 2</p>
                                <p className='text'>a</p>
                                <p className='text'>a</p>
                                <p className='text'>a</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='overall-main'>
                    <p className='overall-text'>overall</p>
                    <div className='overall-cha'>
                        <div className='overall-numbers border-left'>
                            <div className='text-par'>
                                <p className=''>marks</p>
                            </div>
                            <div className='text-cha'>
                                <p className=''>611 / 1000</p>
                            </div>
                        </div>
                        <div className='overall-numbers'>
                            <div className='text-par'>
                                <p className=''>percentage</p>
                            </div>
                            <div className='text-cha'>
                                <p className=''>61.10</p>
                            </div>
                        </div>
                        <div className='overall-numbers'>
                            <div className='text-par'>
                                <p className=''>grade</p>
                            </div>
                            <div className='text-cha'>
                                <p className=''>b</p>
                            </div>
                        </div>
                        <div className='overall-numbers border-right'>
                            <div className='text-par'>
                                <p className=''>rank</p>
                            </div>
                            <div className='text-cha'>
                                <p className=''>16</p>
                            </div>
                        </div>
                    </div>
                    <div className='overall-cha'>
                        <div className='overall-numbers border-left p-m-0'>
                            <div className='text-par'>
                                <p className=''>attendance</p>
                            </div>
                            <div className='text-cha'>
                                <p className=''>197 / 219</p>
                            </div>
                        </div>
                        <div className='overall-numbers border-right p-m-0'>
                            <div className='text-par'>
                                <p className=''>remarks</p>
                            </div>
                            <div className='text-cha'>
                                <p className=''>satisfactory</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='sign-main'>
                    <div className='sign-cha'>
                        <p>3-may-2025</p>
                    </div>
                    <hr />
                    <div className='sign-cha'>
                        <p>class teacher</p>
                    </div>
                    <hr />
                    <div className='sign-cha'>
                        <p>principal</p>
                    </div>
                </div>


                {/* <section class="co-scholastic">
                <div>
                    <h3>Co-Scholastic Areas</h3>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th>Activities</th>
                            <th>Elements</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Sports</td>
                            <td>Football, Basketball</td>
                        </tr>
                        <tr>
                            <td>Arts</td>
                            <td>Painting, Drama</td>
                        </tr>
                    </tbody>
                </table>
            </section>

            <section class="overall-info">
                <div>
                    <h3>Overall Marks</h3>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th>Marks</th>
                            <th>Percentage</th>
                            <th>Grade</th>
                            <th>Rank</th>
                            <th>Attendance</th>
                            <th>Remarks</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>660</td>
                            <td>82.5%</td>
                            <td>A</td>
                            <td>1</td>
                            <td>90%</td>
                            <td>Excellent Performance</td>
                        </tr>
                    </tbody>
                </table>
            </section> */}
            </div>
            <p className='footer-text'>
                plot no. 144, ward -9/b, bharat nagar, gandhidham, gujarat, +91-9427274600, savvygim@gmail.com
            </p>
        </div>
    );
};

export default ClassConfiguration;
