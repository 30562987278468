import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import React, { useState, useEffect, useRef } from 'react';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import Toast from '../../Shared/Toast/Toast';
import { FloatLabel } from 'primereact/floatlabel';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import DeleteConfirmation from '../../Dialogs/DeleteConfirmation';
import UploadFile from '../../UploadFile/UploadFile';

const AddTeacherMasterDialog = (props) => {
  const [securityUserID, setSecurityUserID] = useState("");
  const fileUploadRef = useRef(null);
  const [teacherID, setTeacherID] = useState("");
  const [teacherName, setTeacherName] = useState('');
  const [teacherNameErrText, setTeacherNameErrText] = useState('');
  const [teacherNameErr, setTeacherNameErr] = useState(false);
  const [emailId, setEmailId] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [phoneNoErrText, setPhoneNoErrText] = useState('');
  const [phoneNoErr, setPhoneNoErr] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordErrText, setPasswordErrText] = useState('');
  const [passwordErr, setPasswordErr] = useState(false);
  const [isPasswordSeen, setIsPasswordSeen] = useState(false);
  const [accountTypeList, setAccountTypeList] = useState([]);
  const [userPlanList, setUserPlanList] = useState([]);
  const [userTypeList, setUserTypeList] = useState([]);
  const [emailErr, setEmailErr] = useState(false);
  const [emailErrText, setEmailErrText] = useState('');
  const [shiftList, setShiftList] = useState([]);
  const [selectedShiftId, setSelectedShiftId] = useState(null);
  const [selectedShiftIdErr, setSelectedShiftIdErr] = useState(false);
  const [selectedShiftIdErrText, setSelectedShiftIdErrText] = useState('');
  const [signPath, setSignPath] = useState('');
  const [previewSignPath, setPreviewSignPath] = useState('');
  const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        Loader.show();
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        await getTeacherGeneratedId(loginData?._id);
        generateRandomPassword(10);
        await GetShiftList(loginData?._id);
        await getAccountType();
        await GetUserRoleList();
        await GetUserPlanList(loginData?.AccountType, loginData?.UserType);
      } catch (error) {
        Loader.hide();
        console.error('Error in useEffect:', error);
      } finally {
        Loader.hide();
      }
    };
    fetchData();
  }, []);

  const generateRandomPassword = (length = 10) => {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+";
    let password = "";
    for (let i = 0; i < length; i++) {
      password += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    setPassword(password);
  };

  const GetShiftList = async (id) => {
    try {
      let data = {
        UserID: id,
      }
      await api.post(APIConstant.path.getShiftList, data).then(async response => {
        let res = response;
        if (res.success) {
          setShiftList(res.data.map(x => ({
            label: `${x?.ShiftName}(${formattedTime(x?.ShiftStart)} - ${formattedTime(x?.ShiftEnd)})`,
            value: x?._id
          })));
          Loader.hide();
        }
      }).catch(err => {
        Loader.hide();
        console.log(err);
      });
    } catch (err) { console.log(err); }
  }

  const formattedTime = (date) => {
    return new Date(date).toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    })
  };

  const getTeacherGeneratedId = async (id) => {
    try {
      let data = {
        type: "Teacher",
        UserID: id
      }
      await api.post(APIConstant.path.generateDynamicId, data).then(async response => {
        let res = response;
        if (res.success) {
          setTeacherID(res?.id);
          Loader.hide();
        }
      }).catch(err => {
        Loader.hide();
        console.log(err);
      });
    } catch (err) { console.log(err); }
  }

  const onCancel = () => {
    props.onCancel();
    setTeacherName('');
    setEmailId('');
    setTeacherID('');
    setPhoneNo('');
    setPassword('');
  }

  const validate = () => {
    let formIsValid = true;
    if (CommonConfig.isEmpty(teacherName)) {
      formIsValid = false;
      setTeacherNameErr(true);
      setTeacherNameErrText("Teacher Name is required");
    } else {
      setTeacherNameErr(false);
      setTeacherNameErrText("");
    }
    if (CommonConfig.isEmpty(emailId)) {
      formIsValid = false;
      setEmailErr(true);
      setEmailErrText("EmailId is required");
    } else {
      setEmailErr(false);
      setEmailErrText("");
    }
    if (CommonConfig.isEmpty(phoneNo)) {
      formIsValid = false;
      setPhoneNoErr(true);
      setPhoneNoErrText("PhoneNo is required");
    } else {
      setPhoneNoErr(false);
      setPhoneNoErrText("");
    }
    if (CommonConfig.isEmpty(password)) {
      formIsValid = false;
      setPasswordErr(true);
      setPasswordErrText("Password is required");
    } else {
      setPasswordErr(false);
      setPasswordErrText("");
    }
    if (CommonConfig.isEmpty(selectedShiftId)) {
      formIsValid = false;
      setSelectedShiftIdErr(true);
      setSelectedShiftIdErrText("Shift is required");
    } else {
      setSelectedShiftIdErr(false);
      setSelectedShiftIdErrText("");
    }
    return formIsValid;
  };

  const getAccountType = async () => {
    try {
      let data = {
        Type: "AccountType",
      };
      await api
        .post(APIConstant.path.GetStringMap, data)
        .then(async (response) => {
          let res = response;
          if (res.success) {
            setAccountTypeList(
              res.data.map((data) => ({ label: data.Name, value: data._id }))
            );
            Loader.hide();
          }
        })
        .catch((err) => {
          Loader.hide();
          console.log(err);
        });
    } catch (err) {
      console.log("err", err);
    }
  };

  const GetUserRoleList = async (value) => {
    let params = {
      AccountType: value,
    };
    try {
      await api
        .post(APIConstant.path.GetUserRole, params)
        .then(async (response) => {
          let res = response;
          if (res.success) {
            setUserTypeList(
              res.data.map((role) => ({
                label: role.RoleName,
                value: role._id,
              }))
            );
            Loader.hide();
          }
        })
        .catch((err) => {
          Loader.hide();
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const GetUserPlanList = async (AccountType, UserType) => {
    try {
      var params = {
        AccountType: AccountType,
        UserType: UserType,
      };
      await api
        .post(APIConstant.path.getUserPlanList, params)
        .then((res) => {
          if (res.success) {
            if (res.data) {
              setUserPlanList(
                res.data.map((plan) => ({
                  ...plan,
                  label: plan.PlanName,
                  value: plan._id,
                }))
              );
            }
            Loader.hide();
          }
        })
        .catch((err) => {
          Loader.hide();
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const AddTeacherMaster = async (e) => {
    if (validate(e)) {
      try {
        const formData = new FormData();
        formData.append("id", '');
        formData.append("Name", teacherName ?? '');
        formData.append("Email", emailId ?? '');
        formData.append("Phone", phoneNo ?? '');
        formData.append("Password", password ?? '');
        formData.append("PlanId", userPlanList[0]?.value ?? '');
        formData.append("UserType", userTypeList.find((x) => x.label === 'Teacher')?.value ?? '');
        formData.append("isMailVerified", 1);
        formData.append("isPhoneVerified", 1);
        formData.append("AccountType", accountTypeList.find((x) => x.label === "New Member")?.value ?? '');
        formData.append("CreatedBy", securityUserID);
        if (signPath && signPath.type) {
          formData.append("signPath", signPath);
        } else if (CommonConfig.isEmpty(signPath)) {
          formData.append("signPath", '');
        }
        Loader.show();
        await api.post(APIConstant.path.AddUpdateUser, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }).then(async response => {
          let res = response;
          if (res.success) {
            await AddPersonalDetail(res?.data?._id);
            Toast.success({ message: res.message });
            Loader.hide();
            onCancel();
          } else {
            Toast.error({ message: res.message });
            Loader.hide();
            // onCancel();
          }
        })
          .catch((err) => {
            Loader.hide();
            onCancel();
          });
      } catch (err) {
        console.log(err);
        Loader.hide();
        onCancel();
      }
    }
  };

  const AddPersonalDetail = async (userId) => {
    try {
      let data = {
        PersonalId: '',
        UserId: userId,
        ProfileId: teacherID,
        ShiftId: selectedShiftId,
        CreatedBy: securityUserID
      };
      Loader.show();
      await api.post(APIConstant.path.AddPersonalDetail, data).then(async response => {
        let res = response;
        if (res.success) {
          Loader.hide();
        } else {
          Toast.error({ message: res.message });
          Loader.hide();
        }
      })
        .catch((err) => {
          Loader.hide();
        });
    } catch (err) {
      console.log(err);
      Loader.hide();
    }
  };

  const handleChange = (e, type) => {
    const value = e.target.value;
    const alphanumericValue = value.replace(/[^a-zA-Z0-9\s]/g, '');
    const numericValue = value.replace(/[^0-9]/g, '');
    if (type === 'TeacherName') {
      setTeacherName(alphanumericValue);
    } else if (type === 'MobileNo') {
      setPhoneNo(numericValue);
    }
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    const maxLength = 50;
    if (value.length <= maxLength) {
      setEmailId(value);
    }
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(value)) {
      setEmailErr(true);
      setEmailErrText('Please enter a valid email address.');
    } else {
      setEmailErr(false);
      setEmailErrText('');
    }
  };

  const uploadHandler = (e) => {
    const selectedFile = e.files[0];
    if (selectedFile) {
      const validFormats = ['image/jpeg', 'image/png', 'image/jpg', 'image/svg+xml'];
      if (validFormats.includes(selectedFile.type)) {
        const img = new Image();
        img.onload = () => {
          // if (img.width === img.height) {
            setSignPath(selectedFile);
            const fileURL = URL.createObjectURL(selectedFile);
            setPreviewSignPath(fileURL);
        // } else {
        //     if (fileUploadRef.current) {
        //         fileUploadRef.current.clear();
        //     }
        //     Toast.error({ message: 'The image must have a 1:1 aspect ratio.' });
        // }
        };
        img.onerror = () => {
          if (fileUploadRef.current) {
            fileUploadRef.current.clear();
          }
          Toast.error({ message: 'Error loading the image.' });
        };

        const reader = new FileReader();
        reader.onload = (event) => {
          img.src = event.target.result;
        };
        reader.readAsDataURL(selectedFile);
      } else {
        if (fileUploadRef.current) {
          fileUploadRef.current.clear();
        }
        Toast.error({ message: 'Invalid file format. Please select a JPEG, SVG or PNG image.' });
      }
    }
  };

  const handleClose = () => {
    setIsDeleteConfirmation(false)
  };

  const renderFooter = () => (
    <div className="dialog-footer1 p-d-flex p-ai-center p-jc-end">
      <Button
        label="Cancel"
        className="primary-btn1"
        onClick={handleClose}
      />
      <Button
        label="Delete"
        className="primary-btn"
        onClick={() => {
          setSignPath('')
          setPreviewSignPath('');
          if (fileUploadRef.current) {
            fileUploadRef.current.clear();
          }
          handleClose()
        }}
      />
    </div>
  );
  const handleDelete = (e) => {
    setIsDeleteConfirmation(true)
  };
  return (
    <div>
      <div className='p-grid'>
        <div className="p-col-12 p-md-6 p-pt-2">
          <label htmlFor="clasName">Teacher ID </label>
          <InputText
            className="p-mt-2 p-w-100"
            id="teacherName"
            type="text"
            value={teacherID}
            disabled={true}
          />
        </div>
        <div className="p-col-12 p-md-6 p-pt-2">
          <div className="">
            <label htmlFor="teacherName">Teacher Name<span style={{ color: "red" }}>*</span></label>
            <FloatLabel>
              <InputText
                className="p-mt-2 p-w-100"
                id="teacherName"
                type="text"
                placeholder="Teacher Name"
                value={teacherName}
                maxLength={40}
                autoFocus={true}
                onChange={(e) => handleChange(e, 'TeacherName')}
              />
            </FloatLabel>
            <span className="error-msg" style={{ color: 'red' }}>
              {teacherNameErr ? teacherNameErrText : null}
            </span>
          </div>
        </div>
        <div className="p-col-12 p-md-6 p-pt-2">
          <div className="">
            <label htmlFor="emailId">EmailId<span style={{ color: "red" }}>*</span></label>
            <FloatLabel>
              <InputText
                className="p-mt-2 p-w-100"
                id="emailId"
                type="email"
                placeholder="EmailId"
                value={emailId}
                onChange={(e) => handleEmailChange(e)}
              />
            </FloatLabel>
            <span className="p-error block" style={{ color: 'red' }}>
              {emailErr ? emailErrText : null}
            </span>
          </div>
        </div>
        <div className="p-col-12 p-md-6 p-pt-2">
          <div className="">
            <label htmlFor="phoneNo">Phone No<span style={{ color: "red" }}>*</span></label>
            <FloatLabel>
              <InputText
                className="p-mt-2 p-w-100"
                id="phoneNo"
                maxLength={10}
                placeholder="PhoneNo"
                value={phoneNo}
                onChange={(e) => handleChange(e, 'MobileNo')}
              />
            </FloatLabel>
            <span className="error-msg" style={{ color: 'red' }}>
              {phoneNoErr ? phoneNoErrText : null}
            </span>
          </div>
        </div>
        <div className="p-col-12 p-md-6 p-pt-2">
          <div className='custom-inputs password-field'>
            <label htmlFor="password">Password<span style={{ color: "red" }}>*</span></label>
            <FloatLabel>
              <InputText
                id='password'
                type={isPasswordSeen ? 'text' : 'password'}
                placeholder='******'
                className='p-w-100'
                maxLength={20}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <i
                className={
                  isPasswordSeen
                    ? 'icon-view tailer-icon'
                    : 'icon-view-off tailer-icon'
                }
                style={{ bottom: '8px' }}
                onClick={() =>
                  setIsPasswordSeen(!isPasswordSeen)
                }
              ></i>
            </FloatLabel>
            <span className="error-msg" style={{ color: 'red' }}>
              {passwordErr ? passwordErrText : null}
            </span>
          </div>
        </div>
        <div className="p-col-12 p-md-6 p-pt-2">
          <div className="">
            <label htmlFor="shiftName">Shift Name<span style={{ color: "red" }}>*</span></label>
            <Dropdown className='p-w-100' id="shiftId" value={selectedShiftId} options={shiftList} onChange={(e) => setSelectedShiftId(e.value)} placeholder="Select Shift" />
            <span className="error-msg" style={{ color: 'red' }}>
              {selectedShiftIdErr ? selectedShiftIdErrText : null}
            </span>
          </div>
        </div>
        <div className="p-col-12 p-md-12 p-pt-2">
          <div className="">
            <UploadFile label='Sign' note='' preview={previewSignPath} original={signPath} uploadHandler={uploadHandler} uploadRef={fileUploadRef} handleDelete={handleDelete} />
          </div>
        </div>
      </div>
      <div className='dialog-footer1 p-d-flex p-ai-center p-jc-end'>
        <Button className='p-m-0 primary-btn1 p-m-1' onClick={(e) => onCancel()}> Cancel </Button>
        <Button className='p-m-0 primary-btn p-m-1 color-white' label="Submit" severity="secondary" raised onClick={(e) => AddTeacherMaster()} />
      </div>
      <Dialog
        visible={isDeleteConfirmation}
        onHide={handleClose}
        header={
          <div className="p-d-flex p-jc-between p-ai-center">
            <span>Delete Confirmation</span>
          </div>
        }
        footer={renderFooter()}
        draggable={false}
        dismissableMask
        style={{ width: '450px' }}
      >
        <DeleteConfirmation />
      </Dialog>
    </div>
  );
};

export default AddTeacherMasterDialog;
