
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import React, { useState, useEffect, useRef } from 'react';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import ExportResultPDF from '../../Export/ResultExportPDF';
import PreviewResult from '../../Export/ResultPreviewCard';
import { Dialog } from 'primereact/dialog';
import downloadjs from 'downloadjs';
import html2canvas from 'html2canvas';
import { useReactToPrint } from "react-to-print";
import JSZip from 'jszip';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

const GeneratorCard = (props) => {
    const [visible, setVisible] = useState(false);
    const [resultTemplate, setResultTemplate] = useState('');
    const [boardErrText, setBoardErrText] = useState('');
    const [mediumErrText, setMediumErrText] = useState('');
    const [boardErr, setBoardErr] = useState(false);
    const [mediumErr, setMediumErr] = useState(false);
    const [templateConfigurationData, setTemplateConfigurationData] = useState('');
    const pageSizeOptions = [
        { label: "A4 (210 × 297 mm)", value: "A4" },
        { label: "A3 (297 × 420 mm)", value: "A3" },
    ];
    const [pageSize, setPageSize] = useState('');
    const [resultTemplateList, setResultTemplateList] = useState([]);
    const [pdfData, setPDfData] = useState([]);
    const componentRef = useRef();
    const elementRef = useRef(null);
    const [currentIndex, setCurrentIndex] = useState(1);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === pdfData.length ? 1 : prevIndex + 1
        );
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 1 ? pdfData.length : prevIndex - 1
        );
    };

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        GetResultTemplateList(loginData?._id);
        let studentId = props?.studentsData.map(x => x?.id);
        GetStudentResultData(studentId, loginData?._id);
    }, []);


    const onViewCancel =() => {
        setVisible(false);
    }

    const printFn = useReactToPrint({
        contentRef: elementRef,
        documentTitle: "ResultPDF",
    });

    const GetStudentResultData = async (id, userId) => {
        try {
            let data = {
                StudentsId: id,
                UserID: userId
            }
            await api.post(APIConstant.path.GetStudentResultData, data).then(async response => {
                let res = response;
                if (res.success) {
                    setPDfData(res?.data); 
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const GetResultTemplateList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.GetResultTemplateConfigurationList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setResultTemplateList(res.data.map(x => ({
                        ...x,
                        label: x?.TitleName,
                        value: x?._id
                    })));
                    setResultTemplate(res.data.length ? res.data[0]?._id: '');
                    setTemplateConfigurationData(res.data.length ? res?.data[0] : {});
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const validate = () => {
        let formIsValid = true;
        if (CommonConfig.isEmpty(resultTemplate)) {
            formIsValid = false;
            setBoardErr(true);
            setBoardErrText("Result Template is required");
        } else {
            setBoardErr(false);
            setBoardErrText("");
        }

        if (CommonConfig.isEmpty(pageSize)) {
            formIsValid = false;
            setMediumErr(true);
            setMediumErrText("Page Size is required");
        } else {
            setMediumErr(false);
            setMediumErrText("");
        }
        return formIsValid;
    };

    const generatePDF = () => {
        if (validate()) {
            try {
                printFn();
            } catch (error) {
                console.log('Error in generate Pdf: ', error);
                Loader.hide();
            }
        }
    };

    const generateJPGs = async () => {
        const container = document.getElementById('pdf-result-content');
        const cards = container.querySelectorAll('[id^="result"]');
        if (!cards) return;

        const zip = new JSZip();
        const imgFolder = zip.folder("Results");

        const dpi = 300;
        const inchWidth = 4;
        const inchHeight = 6;
        const pixelWidth = inchWidth * dpi;
        const pixelHeight = inchHeight * dpi;

        for (let i = 0; i < cards.length; i++) {
            const card = cards[i];
            try {
                Loader.show();
                if (!document.body.contains(card)) {
                    console.error('Card not attached to the DOM:', card);
                    continue;
                }
                const cardClone = card.cloneNode(true);
                document.body.appendChild(cardClone);

                cardClone.style.width = `${pixelWidth}px`;
                cardClone.style.height = `${pixelHeight}px`;

                const canvas = await html2canvas(cardClone, {
                    scale: dpi / 96,
                    useCORS: true,
                    allowTaint: true,
                    width: pixelWidth,
                    height: pixelHeight,
                });
                cardClone.remove();

                const dataURL = canvas.toDataURL('image/jpeg', 0.9);
                const base64Data = dataURL.split(',')[1];
                Loader.hide();

                imgFolder.file(`Result-${i + 1}.jpg`, base64Data, { base64: true });
            } catch (error) {
                Loader.hide();
                console.error('Error generating JPEG:', error);
            }
        }

        zip.generateAsync({ type: "blob" }).then((content) => {
            const zipFileName = "Results.zip";
            downloadjs(content, zipFileName, "application/zip");
        }).catch((error) => {
            Loader.hide();
            console.error('Error creating ZIP file:', error);
        });
    };

    return (
        <div>
            <div className='p-grid'>
                <div className="p-col-12 p-md-12 p-pt-2">
                    <div className="p-w-100">
                        <label htmlFor="ResultTemplate" className="mr-2">Result Template</label>
                        <div className="custom-inputs">
                            <Dropdown className='p-mt-1 p-w-100' id="resultTemplate" value={resultTemplate} options={resultTemplateList} onChange={(e) => {
                                setResultTemplate(e.value);
                                let filteredList = resultTemplateList.filter(x => x.value === e.value)[0];
                                setTemplateConfigurationData(filteredList);
                            }} placeholder="Select Id Card Template" />
                        </div>
                        <span className="p-error block" style={{ color: 'red' }}>
                            {boardErr ? boardErrText : null}
                        </span>
                    </div>
                </div>
                <div className="p-col-12 p-md-12 p-pt-2">
                    <div className="p-w-100">
                        <label htmlFor="pageSize" className="mr-2">Page Size</label>
                        <div className="custom-inputs">
                            <Dropdown className="p-mt-1 p-w-100" id="pageSize" value={pageSize} options={pageSizeOptions} onChange={(e) => setPageSize(e.value)} placeholder="Select Page Size" />
                        </div>
                        <span className="p-error block" style={{ color: 'red' }}>
                            {mediumErr ? mediumErrText : null}
                        </span>
                    </div>
                </div>
            </div>
            <div className="dialog-footer1 p-d-flex p-ai-center p-jc-between p-px-5">
                <div className='p-d-flex p-ai-center'>
                    <Button icon="pi pi-file-pdf" rounded className="e-border-btn p-m-0" tooltip={"Generate PDF"} tooltipOptions={{ className: 'bluegray-tooltip text-13', position: 'top' }} onClick={() => generatePDF()}></Button>
                    <Button icon="pi pi-images" rounded className="e-h-btn p-my-0 p-ml-2" tooltip={"Generate JPG"} tooltipOptions={{ className: 'bluegray-tooltip text-13', position: 'top' }} onClick={() => generateJPGs()}></Button>
                </div>
                <div className='p-d-flex p-ai-center p-jc-end'>
                    <Button className='primary-btn1' onClick={() =>  props?.onCancel()}> Cancel </Button>
                    <Button className='primary-btn p-m-0' label="Preview" severity="secondary" raised onClick={() => setVisible(true)} />
                </div>
            </div>
            <div className='p-d-none'>
                <div id="pdf-result-content" className='resultContent' ref={elementRef}
                >
                    <ExportResultPDF ref={componentRef} {...props} fileName={'ResultPDF'}
                        templateConfiguration={templateConfigurationData}
                        pdfData={pdfData} />
                </div>
            </div>
            <Dialog
                header='View Result'
                visible={visible}
                onHide={() => onViewCancel()}
                draggable={false}
                position="center"
                className="custom-result-dialog"
            >
                <div className='slideshowContainer'>
                    <div className='custom-result-preview'>
                        <PreviewResult
                            {...props}
                            className="custom-result-preview"
                            fileName={'ResultPDF'}
                            templateConfiguration={templateConfigurationData}
                            pdfData={pdfData[currentIndex - 1]}
                        />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '0.3rem' }}>
                        <p>{`${currentIndex} / ${pdfData.length}`}</p>
                    </div>

                    <div className="slideshowControls">
                        <button className="control-btn prev" onClick={prevSlide}>
                            <i className="pi pi-chevron-left"></i>
                        </button>
                        <button className="control-btn next" onClick={nextSlide}>
                            <i className="pi pi-chevron-right"></i>
                        </button>
                    </div>
                </div>
                <div className="custom-id-dialog-footer">
                    <div className='p-d-flex p-ai-center'>
                        <Button icon="pi pi-file-pdf" rounded className="e-pdf-btn p-m-0" tooltip={"Generate PDF"} tooltipOptions={{ className: 'bluegray-tooltip text-13', position: 'top' }} onClick={() => generatePDF()}></Button>
                        <Button icon="pi pi-images" rounded className="e-pdf-btn p-my-0 p-ml-2" tooltip={"Generate JPG"} tooltipOptions={{ className: 'bluegray-tooltip text-13', position: 'top' }} onClick={() => generateJPGs()}></Button>
                    </div>
                    <Button className='footer-btn' onClick={() => onViewCancel()}> CANCEL </Button>
                </div>
            </Dialog >
        </div >
    );
};

export default GeneratorCard;
