import React, { useState, useEffect, useRef } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import { Dialog } from 'primereact/dialog';
import StudentListDialog from './StudentList';
import CommonConfig from '../../../utils/constant';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import Toast from '../../Shared/Toast/Toast';
import moment from 'moment';
import { InputText } from 'primereact/inputtext';
import { FloatLabel } from 'primereact/floatlabel';

const ClassConfig = (props) => {
    const studentListRef = useRef();
    const [securityUserID, setSecurityUserID] = useState("");
    const [classConfigurationId, setClassConfigurationId] = useState('');
    const [studentVisible, setStudentVisible] = useState(false);
    const [classDuration, setClassDuration] = useState({ teachingFrom: null, teachingTo: null, breaks: [{ breakFrom: null, breakTo: null }] });
    const [classDurationErr, setClassDurationErr] = useState({ teachingFrom: false, teachingTo: false, breakFrom: false, breakTo: false });
    const [classDurationErrText, setClassDurationErrText] = useState({ teachingFrom: '', teachingTo: '', breakFrom: '', breakTo: '' });
    const [selectedTeacher, setSelectedTeacher] = useState(null);
    const [classTeacherErr, setClassTeacherErr] = useState(false);
    const [classTeacherErrText, setClassTeacherErrText] = useState('');
    const [teachersList, setTeachersList] = useState([]);
    const [subjectTeachersList, setSubjectTeachersList] = useState([]);
    const [subjectList, setSubjectList] = useState([]);
    const [duration, setDuration] = useState(0);
    const [interval, setInterval] = useState(0);
    const [selectedAttendanceType, setSelectedAttendanceType] = useState([]);
    const [attendanceTypeList, setAttendanceTypeList] = useState([{
        label: 'Class Teacher', value: 'ClassTeacher'
    },
    {
        label: 'Subject Teacher', value: 'SubjectTeacher'
    },
    {
        label: 'First Lecture', value: 'FirstLecture'
    }
    ]);

    const handleTeacherChange = (rowData, teacherIndex, newValue) => {
        const updatedSubjects = subjectList.map((subject) => {
            if (subject?.SubjectId === rowData?.SubjectId) {
                const updatedTeachersList = [...subject?.TeachersList];
                updatedTeachersList[teacherIndex] = {
                    ...updatedTeachersList[teacherIndex],
                    id: newValue,
                };
                return { ...subject, TeachersList: updatedTeachersList };
            }
            return subject;
        });
        setSubjectList(updatedSubjects);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                Loader.show();
                const loginData = CommonConfig.loginData();
                setSecurityUserID(loginData?._id);
                await GetSubjectList(loginData?._id);
                await GetTeachersList(loginData?._id);
                await GetClassConfigurationDetail(loginData?._id);
                Loader.hide();
            } catch (error) {
                Loader.hide();
                console.error('Error in useEffect:', error);
            } finally {
                Loader.hide();
            }
        };
        fetchData();
    }, []);

    const GetTeachersList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.getUser, data).then(async response => {
                let res = response;
                if (res.success) {
                    const dataList = res.data.filter((x) => x.UserType === 'Teacher').map((y) => ({
                        ...y,
                        label: y.Name,
                        value: y._id
                    }));
                    setTeachersList(dataList);
                    setSubjectTeachersList(dataList)
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const GetSubjectList = async (id) => {
        try {
            let data = {
                ClassId: props?.configId,
                UserID: id
            }
            await api.post(APIConstant.path.getSubjectByClassId, data).then(async response => {
                let res = response;
                if (res.success) {
                    setSubjectList(res.data);
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const GetClassConfigurationDetail = async (id) => {
        try {
            let data = {
                ClassId: props?.configId,
            }
            Loader.show();
            await api.post(APIConstant.path.getClassConfigurationList, data).then(async response => {
                let res = response;
                if (res.success) {
                    let resData = res.data[0];
                    setClassConfigurationId(resData?._id);
                    const breakFromArray = resData?.BreakFrom ? resData.BreakFrom.split(",").map(date => new Date(date)) : [];
                    const breakToArray = resData?.BreakTo ? resData.BreakTo.split(",").map(date => new Date(date)) : [];

                    const breaks = breakFromArray.map((breakFrom, index) => ({
                        breakFrom,
                        breakTo: breakToArray[index] || null,
                    }));
                    setClassDuration({ ...classDuration, teachingFrom: resData?.TeachingFrom ? new Date(resData?.TeachingFrom) : null, teachingTo: resData?.TeachingTo ? new Date(resData?.TeachingTo) : null, breaks: breaks });
                    setSelectedTeacher(resData?.ClassTeacherId);
                    setDuration(resData?.Duration);
                    setInterval(resData?.Interval);
                    setSelectedAttendanceType(resData?.AttendanceType);
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) {
            Loader.hide();
            console.log(err);
        }
    }

    const onStudentCancel = () => {
        setStudentVisible(false);
        props.onCancel();
    }

    const validate = () => {
        let formIsValid = true;
        if (CommonConfig.isEmpty(classDuration?.teachingFrom)) {
            formIsValid = false;
            setClassDurationErr({ ...classDurationErr, teachingFrom: true });
            setClassDurationErrText({ ...classDurationErrText, teachingFrom: "Teaching From Time is required" });
        } else if (classDurationErr?.teachingFrom === true) {
            formIsValid = false;
            // setClassDurationErr({ ...classDurationErr, teachingFrom: true });
            // setClassDurationErrText({ ...classDurationErrText, teachingFrom: "Teaching From Time is required" });
        } else {
            setClassDurationErr({ ...classDurationErr, teachingFrom: false });
            setClassDurationErrText({ ...classDurationErrText, teachingFrom: "" });
        }
        if (CommonConfig.isEmpty(classDuration?.teachingTo)) {
            formIsValid = false;
            setClassDurationErr({ ...classDurationErr, teachingTo: true });
            setClassDurationErrText({ ...classDurationErrText, teachingTo: "Teaching To Time is required" });
        } else if (classDurationErr?.teachingTo === true) {
            formIsValid = false;
            // setClassDurationErr({ ...classDurationErr, teachingFrom: true });
            // setClassDurationErrText({ ...classDurationErrText, teachingFrom: "Teaching From Time is required" });
        } else {
            setClassDurationErr({ ...classDurationErr, teachingTo: false });
            setClassDurationErrText({ ...classDurationErrText, teachingTo: "" });
        }
        // if (CommonConfig.isEmpty(classDuration?.breakFrom)) {
        //     formIsValid = false;
        //     setClassDurationErr({ ...classDurationErr, breakFrom: true });
        //     setClassDurationErrText({ ...classDurationErrText, breakFrom: "Break From Time is required" });
        // } else {
        //     setClassDurationErr({ ...classDurationErr, breakFrom: false });
        //     setClassDurationErrText({ ...classDurationErrText, breakFrom: "" });
        // }
        // if (CommonConfig.isEmpty(classDuration?.breakTo)) {
        //     formIsValid = false;
        //     setClassDurationErr({ ...classDurationErr, breakTo: true });
        //     setClassDurationErrText({ ...classDurationErrText, breakTo: "Break To Time is required" });
        // } else {
        //     setClassDurationErr({ ...classDurationErr, breakTo: false });
        //     setClassDurationErrText({ ...classDurationErrText, breakTo: "" });
        // }
        if (CommonConfig.isEmpty(selectedTeacher)) {
            formIsValid = false;
            setClassTeacherErr(true);
            setClassTeacherErrText("Class Teacher is required");
        } else {
            setClassTeacherErr(false);
            setClassTeacherErrText("");
        }

        return formIsValid;
    };

    const AddClassConfiguration = async (e) => {
        if (validate(e)) {
            try {
                let breakFrom = classDuration.breaks
                    .map((breakItem) => moment(breakItem.breakFrom).format("YYYY-MM-DDTHH:mm:ss"))
                    .join(",");
                let breakTo = classDuration.breaks
                    .map((breakItem) => moment(breakItem.breakTo).format("YYYY-MM-DDTHH:mm:ss"))
                    .join(",");
                let data = {
                    id: classConfigurationId,
                    ClassId: props?.configId,
                    Duration: duration,
                    Interval: interval,
                    TeachingFrom: moment(classDuration?.teachingFrom),
                    TeachingTo: moment(classDuration?.teachingTo),
                    BreakFrom: breakFrom,
                    BreakTo: breakTo,
                    ClassTeacherId: selectedTeacher,
                    AttendanceType: selectedAttendanceType,
                    CreatedBy: securityUserID,
                    UpdatedBy: classConfigurationId ? securityUserID : '',
                };

                Loader.show();
                await api.post(APIConstant.path.AddUpdateClassConfiguration, data).then(async response => {
                    let res = response;
                    if (res.success) {
                        await EditSubjectMaster();
                        Toast.success({ message: res.message });
                        Loader.hide();
                        props.onCancel();
                    } else {
                        Toast.error({ message: res.message });
                        Loader.hide();
                        props.onCancel();
                    }
                })
                    .catch((err) => {
                        Loader.hide();
                        props.onCancel();
                    });
                    if (studentListRef.current) {
                        studentListRef.current.saveStudentRollNo();
                        studentListRef.current.clear();
                    }
            } catch (err) {
                console.log(err);
                Loader.hide();
                props.onCancel();
            }
        }
    };

    const EditSubjectMaster = async (e) => {
        try {
            Loader.show();
            subjectList.map(async x => {
                let data = {
                    id: x._id,
                    Teachers: x?.TeachersList ? x?.TeachersList.map(x => x?.id).length > 1 ? x?.TeachersList.map(x => x?.id).join(',') : x?.TeachersList.map(x => x?.id)[0] : '',
                };
                await api
                    .post(APIConstant.path.AddUpdateSubjectMaster, data)
                    .then((response) => {
                        let res = response;
                        if (res.success) {
                            Loader.hide();
                        } else {
                            Loader.hide();
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        Loader.hide();
                    });
            })
        } catch (err) {
            console.log(err);
            Loader.hide();
        }

    };

    const actionBodyTemplateSrNo = (data, props) => {
        return props.rowIndex + 1;
    };

    const handleFromChange = (value) => {
        const toTime = classDuration.teachingTo;
        const minTime = new Date();
        minTime.setHours(6, 0, 0, 0);
        if (value < minTime) {
            setClassDurationErr({
                ...classDurationErr,
                teachingFrom: true,
            });
            setClassDurationErrText({
                ...classDurationErrText,
                teachingFrom: "'From' time cannot be earlier than 6 AM.",
            });
        } else if (toTime && value > toTime) {
            setClassDurationErr({
                ...classDurationErr,
                teachingFrom: true,
            });
            setClassDurationErrText({
                ...classDurationErrText,
                teachingFrom: "'From' time cannot be later than 'To' time.",
            });
        } else if (toTime && toTime.getTime() === value.getTime()) {
            setClassDurationErr({
                ...classDurationErr,
                teachingFrom: true,
            });
            setClassDurationErrText({
                ...classDurationErrText,
                teachingFrom: "'From' time cannot be the same as 'To' time.",
            });
        } else {
            setClassDurationErr({ ...classDurationErr, teachingFrom: false });
            setClassDurationErrText({ ...classDurationErrText, teachingFrom: "" });
        }
        setClassDuration({ ...classDuration, teachingFrom: value });
    };

    const handleToChange = (value) => {
        const fromTime = classDuration.teachingFrom;
        const maxTime = new Date();
        maxTime.setHours(16, 0, 0, 0);
        if (value > maxTime) {
            setClassDurationErr({
                ...classDurationErr,
                teachingTo: true,
            });
            setClassDurationErrText({
                ...classDurationErrText,
                teachingTo: "'To' time cannot be later than 4 PM.",
            });
        } else if (fromTime && value < fromTime) {
            setClassDurationErr({
                ...classDurationErr,
                teachingTo: true,
            });
            setClassDurationErrText({
                ...classDurationErrText,
                teachingTo: "'To' time cannot be earlier than 'From' time.",
            });
        } else if (fromTime && fromTime.getTime() === value.getTime()) {
            setClassDurationErr({
                ...classDurationErr,
                teachingTo: true,
            });
            setClassDurationErrText({
                ...classDurationErrText,
                teachingTo: "'To' time cannot be the same as 'From' time.",
            });
        } else {
            setClassDurationErr({ ...classDurationErr, teachingTo: false });
            setClassDurationErrText({ ...classDurationErrText, teachingTo: "" });
        }
        setClassDuration({ ...classDuration, teachingTo: value });
    };

    const handleBreakChange = (index, key, value) => {
        const updatedBreaks = [...classDuration.breaks];
        updatedBreaks[index][key] = value;
        setClassDuration({ ...classDuration, breaks: updatedBreaks });
    };

    const addBreak = () => {
        setClassDuration({
            ...classDuration,
            breaks: [...classDuration.breaks, { breakFrom: null, breakTo: null }]
        });
    };

    const removeBreak = (index) => {
        const updatedBreaks = classDuration.breaks.filter((_, i) => i !== index);
        setClassDuration({ ...classDuration, breaks: updatedBreaks });
    };

    return <div>
        <div className="p-fluid p-grid">
            <div className="p-col-12 p-card p-p-3 p-mt-3">
                <h4 className='p-mb-1 p-mt-0'>Class Duration</h4>
                <hr className='p-m-0' />
                <div className="p-grid">
                    <div className='p-col-6 p-md-6'>
                        <p className="p-m-0">Teaching From</p>
                        <p className="p-m-0">
                            <Calendar value={classDuration.teachingFrom} onChange={(e) => handleFromChange(e.value)} hourFormat='12' timeOnly dateFormat='dd/mm/yy' />
                            <span className="error-msg" style={{ color: 'red' }}>
                                {classDurationErr?.teachingFrom ? classDurationErrText?.teachingFrom : null}
                            </span>
                        </p>
                    </div>
                    <div className='p-col-6 p-md-6'>
                        <p className="p-m-0">Teaching To</p>
                        <p className="p-m-0">
                            <Calendar value={classDuration.teachingTo} onChange={(e) => handleToChange(e.value)} hourFormat='12' timeOnly dateFormat='dd/mm/yy' />
                            <span className="error-msg" style={{ color: 'red' }}>
                                {classDurationErr?.teachingTo ? classDurationErrText?.teachingTo : null}
                            </span>
                        </p>
                    </div>
                </div>
                <div className='p-mt-3'>
                    <h4 className='p-mt-0 p-mb-1'>Breaks</h4>
                    <hr className='p-m-0' />
                    {classDuration.breaks.map((breakItem, index) => (
                        <div className="p-grid" key={index}>
                            <div className='p-col-6 p-md-6'>
                                <p className="p-m-0">Break From</p>
                                <p className="p-m-0">
                                    <Calendar
                                        value={breakItem.breakFrom}
                                        onChange={(e) => handleBreakChange(index, 'breakFrom', e.value)}
                                        hourFormat="12"
                                        timeOnly
                                        dateFormat="dd/mm/yy"
                                    />
                                    <span className="error-msg" style={{ color: 'red' }}>
                                        {classDurationErr?.[`breakFrom_${index}`] ? classDurationErrText?.[`breakFrom_${index}`] : null}
                                    </span>
                                </p>
                            </div>
                            <div className='p-col-6 p-md-6'>
                                <p className="p-m-0">Break To</p>
                                <p className="p-m-0">
                                    <Calendar
                                        value={breakItem.breakTo}
                                        onChange={(e) => handleBreakChange(index, 'breakTo', e.value)}
                                        hourFormat="12"
                                        timeOnly
                                        dateFormat="dd/mm/yy"
                                    />
                                    <span className="error-msg" style={{ color: 'red' }}>
                                        {classDurationErr?.[`breakTo_${index}`] ? classDurationErrText?.[`breakTo_${index}`] : null}
                                    </span>
                                </p>
                            </div>
                            <div className="p-d-flex p-ai-center p-jc-end p-w-100">
                                <Button
                                    label="Remove"
                                    className="p-button-danger p-m-0 p-button-text"
                                    onClick={() => removeBreak(index)}
                                />
                                {/* <Button label="Add Break" icon="pi pi-plus" className="p-button-success p-mt-3" onClick={addBreak} /> */}
                            </div>
                        </div>
                    ))}
                    <Button label="Add Break" icon="pi pi-plus" className="p-button-success p-mt-3" onClick={addBreak} />
                </div>
                <div className="card-header-left p-grid">
                    <div className='p-col-12 p-md-6'>
                        <label htmlFor="duration">Duration (minute)</label>
                        <div>
                            <FloatLabel>
                                <InputText
                                    className="p-mt-1 p-w-100"
                                    id="duration"
                                    type="number"
                                    placeholder="Duration"
                                    value={duration}
                                    autoFocus={true}
                                    onChange={(e) => setDuration(e.target.value)}
                                />
                            </FloatLabel>
                        </div>
                    </div>
                    <div className='p-col-12 p-md-6'>
                        <label htmlFor="interval">Interval (minute)</label>
                        <div>
                            <FloatLabel>
                                <InputText
                                    className="p-mt-1 p-w-100"
                                    id="interval"
                                    type="number"
                                    placeholder="Interval"
                                    value={interval}
                                    onChange={(e) => setInterval(e.target.value)}
                                />
                            </FloatLabel>
                        </div>
                    </div>
                </div>
                <div className='p-col-6 p-md-12'>
                    <h4 className='p-mt-0 p-mb-1'>Attendance Type</h4>
                    <hr className='p-m-0' />
                    <Dropdown className='p-mt-2' value={selectedAttendanceType} options={attendanceTypeList} onChange={(e) => setSelectedAttendanceType(e.value)} placeholder="Select Attendance Type" />
                </div>
                <div className='p-col-6 p-md-12'>
                    <h4 className='p-mt-0 p-mb-1'>Class Teacher</h4>
                    <hr className='p-m-0' />
                    <Dropdown className='p-mt-2' value={selectedTeacher} options={teachersList} onChange={(e) => setSelectedTeacher(e.value)} placeholder="Select Teacher" />
                </div>
            </div>

            <div className="p-col-12 p-card p-p-3 p-mt-4">
                <h4>Subject Teacher</h4>
                <hr className='p-m-0' />
                <DataTable className='p-mt-2' value={subjectList} paginator rows={10}>
                    <Column body={actionBodyTemplateSrNo} header="Sr No." />
                    <Column field="SubjectName" header="Subject Name" />
                    <Column field="SubjectId" header="Subject Code" />
                    <Column field="primaryTeacher" header="Primary Teacher" body={(rowData) => (
                        <Dropdown value={rowData?.TeachersList[0]?.id || null} options={rowData?.TeachersList} onChange={(e) => handleTeacherChange(rowData, 0, e.value)} showClear display="chip" />
                    )} />
                    <Column field="secondaryTeacher" header="Secondary Teacher" body={(rowData) => (
                        <Dropdown value={rowData?.TeachersList[1]?.id || null} options={rowData?.TeachersList} onChange={(e) => handleTeacherChange(rowData, 1, e.value)} showClear display="chip" />
                    )} />
                </DataTable>
            </div>
            <StudentListDialog ref={studentListRef} onCancel={onStudentCancel} studentsList={props?.studentList} />

            <div className="p-d-flex p-ai-center p-jc-end p-w-100">
                {/* <Button label="Next" icon="pi pi-arrow-right" className="p-button-info p-mr-2" onClick={() => setStudentVisible(true)} /> */}

                <Button label="Cancel" className="p-button-info p-mr-2" onClick={() => props.onCancel()} />
                <Button label="Save" icon="pi pi-save" className="p-button-primary" onClick={(e) => AddClassConfiguration(e)} />
            </div>

            <Dialog
                header="Students List"
                visible={studentVisible}
                onHide={() => onStudentCancel()}
                draggable={false}
                position="center"
                style={{ width: '90vw' }}
            >
                <StudentListDialog onCancel={onStudentCancel} studentsList={props?.studentList} />

            </Dialog>
        </div>
    </div >
}

export default ClassConfig;
