import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import StudentCard from './StudentCard';
import '../../../assets/css/teacherMaster.css';
import { useNavigate } from 'react-router-dom';
import AddStudentMasterDialog from './AddStudentMaster';
import { Dialog } from 'primereact/dialog';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Toast from '../../Shared/Toast/Toast';
import DeleteConfirmation from '../../Dialogs/DeleteConfirmation';
import { InputSwitch } from 'primereact/inputswitch';
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { FileUpload } from "primereact/fileupload";
import { Tooltip } from 'primereact/tooltip';
import { Tag } from 'primereact/tag';
import { apiBase } from '../../../utils/config';

const StudentMaster = () => {
    const navigate = useNavigate();
    const [securityUserID, setSecurityUserID] = useState("");
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [visible, setVisible] = useState(false);
    const [studentsList, setStudentsList] = useState([]);
    const [viewMode, setViewMode] = useState('card');
    const [rows, setRows] = useState(10);
    const [first, setFirst] = useState(0);
    const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        ClassName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Board: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Medium: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const [updatedStatus, setUpdatedStatus] = useState('');
    const [isStatusConfirmation, setIsStatusConfirmation] = useState(false);
    const fileUploadRef = useRef(null);
    const [isUploadVisible, setIsUploadVisible] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                Loader.show();
                const loginData = CommonConfig.loginData();
                setSecurityUserID(loginData?._id);
                await GetStudentsList(loginData?._id);
            } catch (error) {
                Loader.hide();
                console.error('Error in useEffect:', error);
            } finally {
                Loader.hide();
            }
        };
        fetchData();
    }, []);

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const onPageChange = (e) => {
        setFirst(e.first);
        setRows(e.rows);
    };

    const onCancel = async () => {
        setVisible(false);
        await GetStudentsList(securityUserID);
    }

    const toggleActiveStatus = (id, status) => {
        setDeleteId(id);
        setUpdatedStatus(status === true ? 'Active' : 'Inactive');
        setIsStatusConfirmation(true);
    };

    const GetStudentsList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.getStudentDetailList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setStudentsList(res.data.sort((a, b) => parseInt(a?.RollNo) - parseInt(b?.RollNo)));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const actionBodyTemplate = (rows) => {
        return (
            <React.Fragment>
                <div className='p-d-flex p-ai-center'>
                    {CommonConfig.isEmpty(rows?.Phone) ? <Button icon="pi pi-ban" className="e-sction-btn p-m-0 p-m-2" /> : null}
                    <Button icon="pi pi-pencil" className="e-sction-btn p-m-0" onClick={() => {
                        navigate('/viewEdit-student-master', { state: { userId: rows?._id, isEdit: true } })
                    }} />
                    <Button icon="pi pi-trash" className="e-sction-btn p-m-0 p-m-2" onClick={(e) => {
                        setIsDeleteConfirmation(true);
                        setDeleteId(rows._id);
                    }} />
                    <InputSwitch
                        checked={rows?.Status === 'Active' ? true : false}
                        onChange={(e) => {
                            toggleActiveStatus(rows._id, e.value);
                        }}
                        className="p-ml-2"
                    />
                </div>
            </React.Fragment>
        );
    };

    const actionBodyTemplateSrNo = (data, props) => {
        return props.rowIndex + 1;
    };

    const renderFooter = () => (
        <div className="dialog-footer1 p-d-flex p-ai-center p-jc-end">
            <Button
                label="Cancel"
                className="primary-btn1"
                onClick={handleClose}
            />
            <Button
                label="Delete"
                className="primary-btn"
                onClick={(e) => DeleteStudent()}
            />
        </div>
    );

    const handleClose = () => {
        setIsDeleteConfirmation(false)
    };

    const handleDelete = (id) => {
        setIsDeleteConfirmation(true);
        setDeleteId(id);
    };

    const renderStatusFooter = () => (
        <div className="dialog-footer1 p-d-flex p-ai-center p-jc-end">
            <Button
                label="Cancel"
                className="primary-btn1"
                onClick={handleCloseStatus}
            />
            <Button
                label="Update"
                className="primary-btn"
                onClick={(e) => UpdateStatus()}
            />
        </div>
    );

    const handleCloseStatus = () => {
        setIsStatusConfirmation(false)
    };

    const UpdateStatus = async () => {
        try {
            let data = {
                id: deleteId,
                Status: updatedStatus
            }
            await api.post(APIConstant.path.updateStudentStatus, data).then(async response => {
                let res = response;
                if (res.success) {
                    Loader.hide();
                    Toast.success({ message: 'Student status updated successfully' });
                    setDeleteId('');
                    setUpdatedStatus('');
                    await GetStudentsList(securityUserID);
                }
                handleCloseStatus();
            }).catch(err => {
                Loader.hide();
                setDeleteId('');
                setUpdatedStatus('');
                handleCloseStatus();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const DeleteStudent = async () => {
        try {
            let data = {
                id: deleteId
            }
            await api.post(APIConstant.path.deleteStudent, data).then(async response => {
                let res = response;
                if (res.success) {
                    Loader.hide();
                    Toast.success({ message: 'Student deleted successfully' });
                    setDeleteId('');
                    setUpdatedStatus('');
                    GetStudentsList(securityUserID);
                }
                handleClose();
            }).catch(err => {
                Loader.hide();
                setDeleteId('');
                setUpdatedStatus('');
                handleClose();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const handleDownloadSampleFile = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Sample Sheet For Students");

        worksheet.columns = [
            { header: "ISNOLOGIN", key: "ISNOLOGIN" },
            { header: "UID", key: "UID" },
            { header: "GR", key: "GR" },
            { header: "ROLL NO.", key: "ROLLNO" },
            { header: "HOUSE", key: "HOUSE" },
            { header: "NAME", key: "NAME" },
            { header: "FATHERNAME", key: "FATHERNAME" },
            { header: "SURNAME", key: "SURNAME" },
            { header: "MOTHERNAME", key: "MOTHERNAME" },
            { header: "GENDER", key: "GENDER" },
            { header: "CLASS", key: "CLASS" },
            { header: "SECTION", key: "SECTION" },
            { header: "MEDIUM", key: "MEDIUM" },
            { header: "BOARD", key: "BOARD" },
            { header: "AADHAR", key: "AADHAR" },
            { header: "ADDRESS", key: "ADDRESS" },
            { header: "CONTACTNO", key: "CONTACTNO" },
            { header: "EMAIL", key: "EMAIL" },
            { header: "PASSWORD", key: "PASSWORD" },
            { header: "CONTACTNO_2", key: "CONTACTNO_2" },
            { header: "BLOODGROUP", key: "BLOODGROUP" },
            { header: "DOB", key: "DOB" },
            { header: "IMAGE_NAME", key: "IMAGE_NAME" }
        ];

        worksheet.addRows([
            {
                ISNOLOGIN: false, UID: 'STD345', GR: 'ABC-12345', ROLLNO: '1', HOUSE: 'Red', NAME: 'Darshit', FATHERNAME: 'Jignesh',
                SURNAME: 'Bhagvat', MOTHERNAME: '', GENDER: 'male', CLASS: '1', SECTION: 'ALPHA', MEDIUM: 'English', BOARD: 'CBSE',
                AADHAR: '', ADDRESS: 'haskjdasda', CONTACTNO: '', EMAIL: '', PASSWORD: '', CONTACTNO_2: '', BLOODGROUP: 'B+', DOB: '25/07/2016',
                IMAGE_NAME: ''
            },
            {
                ISNOLOGIN: true, UID: 'STD234', GR: 'ABC-4567', ROLLNO: '1', HOUSE: 'Blue', NAME: 'Rakesh', FATHERNAME: 'Ravi',
                SURNAME: 'Shukla', MOTHERNAME: '', GENDER: 'male', CLASS: '2', SECTION: 'ALPHA', MEDIUM: 'Hindi', BOARD: 'NCRT',
                AADHAR: '', ADDRESS: 'haskjdasda', CONTACTNO: '25485562562', EMAIL: 'rakesh@gmail.com', PASSWORD: 'Welcome@123', CONTACTNO_2: '', BLOODGROUP: 'B+', DOB: '25/07/2016',
                IMAGE_NAME: ''
            },
            {
                ISNOLOGIN: true, UID: 'STD234', GR: 'ABC-4567', ROLLNO: '1', HOUSE: 'Green', NAME: 'Vikash', FATHERNAME: 'Raj',
                SURNAME: 'Shukla', MOTHERNAME: '', GENDER: 'male', CLASS: '1', SECTION: 'BETA', MEDIUM: 'English', BOARD: 'NCRT',
                AADHAR: '', ADDRESS: 'haskjdasda', CONTACTNO: '25485562562', EMAIL: 'rakesh@gmail.com', PASSWORD: 'Welcome@123', CONTACTNO_2: '', BLOODGROUP: 'B+', DOB: '25/07/2016',
                IMAGE_NAME: ''
            },
        ]);

        const boldColumns = ["ISNOLOGIN",
            "UID",
            "NAME",
            "FATHERNAME",
            "SURNAME",
            "MOTHERNAME",
            "GENDER",
            "CLASS",
            "SECTION",
            "MEDIUM",
            "BOARD",
            "ADDRESS",
            "CONTACTNO",
            "PASSWORD", "DOB"];
        worksheet.getRow(1).eachCell((cell, colNumber) => {
            if (boldColumns.includes(worksheet.getColumn(colNumber).key)) {
                cell.font = { bold: true };
            }
        });

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        saveAs(blob, "SampleFile.xlsx");
    };

    const handleUploadVisible = () => {
        setIsUploadVisible(false)
    };

    const headerTemplate = (options) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        // const value = totalSize / 10000;
        // const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                {chooseButton}
                {uploadButton}
                {cancelButton}
                {/* <div className="flex align-items-center gap-3 ml-auto">
                    <span>{formatedValue} / 1 MB</span>
                    <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
                </div> */}
            </div>
        );
    };

    const itemTemplate = (file, props) => {
        return (
            <div className="">
                <div className="p-d-flex p-ai-baseline p-jc-between">
                    <img alt={file.name} role="presentation" src={file.objectURL} width={200} height={100} />
                    <div className="flex flex-column text-left ml-3">
                        <p> {file.name} </p>
                        <small>{new Date().toLocaleDateString()}</small>
                    </div>
                </div>
                <Tag value={props.formatSize} severity="warning" className="e-uplod-box" />
                <div className='p-d-flex p-ai-center p-jc-center'>
                    <Button type="button" icon="pi pi-times" className="p-d-flex p-ai-center p-jc-center p-button-outlined p-button-rounded p-button-danger ml-auto p-m-0" onClick={() => onTemplateRemove(file, props.onRemove)} />
                </div>
            </div>
        );
    };

    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column">
                <i className="pi pi-image mt-3 p-5" style={{ fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
                <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">
                    Drag and Drop Image Here
                </span>
            </div>
        );
    };

    const onTemplateUpload = async (e) => {
        const uploadedFile = e.files[0];
        if (!uploadedFile) return;

        try {
            Loader.show();
            const loginData = CommonConfig.loginData();
            const formData = new FormData();
            formData.append("file", uploadedFile);
            formData.append("filename", uploadedFile?.name);
            formData.append("accountType", loginData?.AccountType);
            formData.append("userType", loginData?.UserType);
            formData.append("userId", securityUserID);
            await api.post(APIConstant.path.ImportStudents, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
                .then(async (response) => {
                    let res = response;
                    if (res.success) {
                        Loader.hide();
                        Toast.success({ message: res?.message });
                        if (res?.skippedFileUrl) {
                            var downloadUrl = `${apiBase}${res?.skippedFileUrl}`;
                            const link = document.createElement("a");
                            link.href = downloadUrl;
                            link.setAttribute("download", downloadUrl.split('/').pop());
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        }
                        handleUploadVisible();
                        await GetStudentsList(securityUserID);
                    } else {
                        Loader.hide();
                        Toast.error({ message: res.message });
                        if (res?.skippedFileUrl) {
                            var downloadUrl = `${apiBase}${res?.skippedFileUrl}`;
                            const link = document.createElement("a");
                            link.href = downloadUrl;
                            link.setAttribute("download", downloadUrl.split('/').pop());
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        }
                    }
                })
                .catch((err) => {
                    console.error(err);
                    Toast.error({ message: err?.message });
                    Loader.hide();
                });

        } catch (error) {
            Loader.hide();
            const errorMessage = error.response?.data?.message || "File upload failed.";
            Toast.error({ message: errorMessage });
        }
    };

    const onTemplateRemove = (file, callback) => {
        callback();
    };

    const chooseOptions = { icon: 'pi pi-fw pi-images', iconOnly: true, className: 'p-d-flex p-ai-center p-jc-center p-button-rounded p-button-outlined p-m-0' };
    const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'p-d-flex p-ai-center p-jc-center p-button-success p-button-rounded p-button-outlined p-m-0' };
    const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'p-d-flex p-ai-center p-jc-center p-button-danger p-button-rounded p-button-outlined p-m-0' };

    return (
        <div className='wayment-owner-dashboard e-weighment-dashoard'>
            <div className="heading-area p-d-flex p-ai-center p-jc-between">
                <div className="p-d-flex p-ai-baseline">
                    <i className="pi pi-users"></i>
                    <h2>Student Master</h2>
                </div>
                <div className="p-d-flex p-ai-center p-jc-between">
                    <div className="searchbar-area e-searchbar-area">
                        <i className="pi pi-search" />
                        <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Global Search" />
                    </div>
                    <Button icon="pi pi-plus" className="e-h-btn" onClick={() => setVisible(true)} />
                    <Button
                        icon={viewMode === 'card' ? 'pi pi-bars' : 'pi pi-th-large'}
                        className="e-h-btn p-ml-2"
                        onClick={() => setViewMode(viewMode === 'card' ? 'list' : 'card')}
                    />
                    <Button icon="pi pi-upload" onClick={(e) => setIsUploadVisible(true)} className="e-h-btn p-ml-2" />
                    <Button label="Sample File" icon="pi pi-download" onClick={handleDownloadSampleFile} className="e-border-btn p-ml-2" />
                </div>

            </div>
            <hr className="p-m-0" />
            <div className={viewMode === 'card' ? 'teacher-grid' : 'teacher-list'}>
                {viewMode === 'card' ? (
                    studentsList.filter(x => x?.Name?.toLowerCase()?.includes(globalFilterValue.toLowerCase()) || x?.Email?.includes(globalFilterValue) || x?.Phone?.includes(globalFilterValue)).map(student => (
                        <StudentCard key={student._id} student={student} handleDelete={handleDelete} toggleActiveStatus={toggleActiveStatus} />
                    ))
                ) : (
                    <DataTable
                        value={studentsList}
                        paginator
                        rows={rows}
                        first={first}
                        filters={filters}
                        filterDisplay="menu"
                        globalFilterFields={['StudentId', 'Name', 'Phone', 'Email']}
                        responsiveLayout="scroll"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        rowsPerPageOptions={[5, 10, 20]}
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        onPage={onPageChange}
                        className='p-mt-3'
                    >
                        <Column field="id" body={actionBodyTemplateSrNo} header="Sr No." />
                        <Column
                            field="StudentId"
                            header="Student Id"
                        />
                        <Column
                            field="Name"
                            header="Name"
                        />
                        <Column
                            field="Phone"
                            header="Phone"
                        />
                        <Column
                            field="Email"
                            header="Email"
                        />
                        <Column
                            field="RollNo"
                            header="Roll No"
                        />
                        <Column
                            field="ClassFullName"
                            header="Class Name"
                        />
                        <Column body={actionBodyTemplate} header="Action" />
                    </DataTable>
                )}
            </div>
            <Dialog
                header="Add Student Master"
                visible={visible}
                onHide={() => onCancel()}
                draggable={false}
                position="center"
                style={{ width: "60vw", height: "70vh" }}

            >
                <AddStudentMasterDialog onCancel={onCancel} />

            </Dialog>
            <Dialog
                visible={isDeleteConfirmation}
                onHide={handleClose}
                header={
                    <div className="p-d-flex p-jc-between p-ai-center">
                        <span>Delete Confirmation</span>
                    </div>
                }
                footer={renderFooter()}
                draggable={false}
                dismissableMask
                style={{ width: '450px' }}
            >
                <DeleteConfirmation />
            </Dialog>
            <Dialog
                visible={isStatusConfirmation}
                onHide={handleCloseStatus}
                header={
                    <div className="p-d-flex p-jc-between p-ai-center">
                        <span>Status Update</span>
                    </div>
                }
                footer={renderStatusFooter()}
                draggable={false}
                dismissableMask
                style={{ width: '450px' }}
            >
                <div>
                    <p>
                        Are you sure you want to update status?
                    </p>
                </div>
            </Dialog>
            <Dialog
                visible={isUploadVisible}
                onHide={handleUploadVisible}
                header="Upload Excel"
                draggable={false}
                dismissableMask
                style={{ width: '500px', minHeight: '480px', maxHeight: '480px', }}
            >
                <div>
                    <div className='p-d-fle p-ai-center p-jc-between p-w-100'>
                        <div>
                            <Tooltip className='p-m-0' target=".custom-choose-btn" content="Choose" position="bottom" />
                        </div>
                        <div>
                            <Tooltip className='p-m-0' target=".custom-upload-btn" content="Upload" position="bottom" />
                        </div>
                        <div>
                            <Tooltip className='p-m-0' target=".custom-cancel-btn" content="Clear" position="bottom" />
                        </div>
                        <FileUpload ref={fileUploadRef} name="file" accept=".xlsx" multiple={false} customUpload={true} uploadHandler={onTemplateUpload} headerTemplate={headerTemplate} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate} chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions} />
                    </div>
                </div>
            </Dialog>
        </div>
    );
}

export default StudentMaster;
