import React, { useState, useEffect } from 'react';
import Loader from "../../Shared/Loader/Loader";
import { Button } from "primereact/button";
import "primereact/resources/themes/lara-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import Toast from '../../Shared/Toast/Toast';
import APIConstant from "../../../utils/pathConstants";
import CommonConfig from "../../../utils/constant";
import api from "../../../utils/apiClient";
import { Checkbox } from 'primereact/checkbox';

const GeneralConfiguration = () => {
  const [isClassTeacher, setIsClassTeacher] = useState(true);
  const [isSubjectTeacher, setIsSubjectTeacher] = useState(false);
  const [securityUserID, setSecurityUserID] = useState("");
  const [configurationId, setConfigurationId] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        Loader.show();
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        await GetConfigurationData(loginData?._id);
        Loader.hide();
      } catch (error) {
        Loader.hide();
        console.error('Error in useEffect:', error);
      } finally {
        Loader.hide();
      }
    };
    fetchData();
  }, []);

  const GetConfigurationData = async (id) => {
    try {
      let data = {
        id: id,
      }
      await api.post(APIConstant.path.GetGeneralConfigurationDetail, data).then(async response => {
        let res = response;
        if (res.success) {
          setConfigurationId(res?.data?._id);
          setIsSubjectTeacher(res?.data?.IsSubjectTeacher);
          setIsClassTeacher(res?.data?.IsClassTeacher);
          Loader.hide();
        }
      }).catch(err => {
        Loader.hide();
        console.log(err);
      });
    } catch (err) { console.log(err); }
  }

  const handleSubmit = async (e) => {
    try {
      Loader.show();
      let data = {
        id: configurationId,
        IsClassTeacher: isClassTeacher,
        IsSubjectTeacher: isSubjectTeacher,
        CreatedBy: securityUserID,
      };
      await api
        .post(APIConstant.path.AddUpdateGeneralConfiguration, data)
        .then((response) => {
          let res = response;
          if (res.success) {
            Toast.success({ message: res.message });
            Loader.hide();
            GetConfigurationData(securityUserID);
          } else {
            Toast.error({ message: res.message });
            Loader.hide();
          }
        })
        .catch((err) => {
          console.log(err);
          Loader.hide();
        });
    } catch (err) {
      console.log(err);
      Loader.hide();
    }
  };

  return (
    <div className='wayment-owner-dashboard e-weighment-dashoard'>
      <div className="heading-area p-d-flex p-ai-center p-jc-between">
        <div className="p-d-flex p-ai-baseline">
          <i className="pi pi-cog"></i>
          <h2>General Configuration</h2>
        </div>
      </div>
      <hr className='p-m-0'/>
      <div className='p-d-flex flex-column p-jc-between e-class-time-table'>
        <div className="p-grid p-mt-4 p-px-2">
          <div className="p-col-12 p-md-4">
            <div className="">
              <Checkbox
                inputId="classTeacher"
                name="isClassTeacher"
                value="IsClassTeacher"
                onChange={(e) => {
                  setIsClassTeacher(e.checked);
                }}
                checked={isClassTeacher}
              />
              <label htmlFor="classTeacher" className="p-ml-2"> Is Class Teacher</label>
            </div>
          </div>
          <div className="p-col-12 p-md-4">
            <div className="">
              <Checkbox
                inputId="subjectTeacher"
                name="isSubjectTeacher"
                value="IsSubjectTeacher"
                onChange={(e) => {
                  setIsSubjectTeacher(e.checked);
                }}
                checked={isSubjectTeacher}
              />
              <label htmlFor="subjectTeacher" className="p-ml-2"> Is Subject Teacher</label>
            </div>
          </div>
        </div>
        <div className="p-d-flex p-ai-center p-jc-end">
          <Button label="Submit" onClick={handleSubmit} className="btn-text-action-primary p-m-0" />
        </div>
      </div>
    </div>
  );
};

export default GeneralConfiguration;
