import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import React, { useState, useEffect } from 'react';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import Toast from '../../Shared/Toast/Toast';
import { Dropdown } from 'primereact/dropdown';
import { FloatLabel } from 'primereact/floatlabel';
import { MultiSelect } from 'primereact/multiselect';
import { Checkbox } from 'primereact/checkbox';
import AddParentMasterDialog from '../ParentMaster/AddParentMaster';
import { Dialog } from 'primereact/dialog';

const AddStudentMasterDialog = (props) => {
  const [securityUserID, setSecurityUserID] = useState("");
  const [classList, setClassList] = useState('');
  const [studentID, setStudentID] = useState("");
  const [studentIDErrText, setStudentIDErrText] = useState('');
  const [studentIDErr, setStudentIDErr] = useState(false);
  const [studentName, setStudentName] = useState('');
  const [studentNameErrText, setStudentNameErrText] = useState('');
  const [studentNameErr, setStudentNameErr] = useState(false);
  const [surName, setSurName] = useState('');
  const [surNameErrText, setSurNameErrText] = useState('');
  const [surNameErr, setSurNameErr] = useState(false);
  const [fName, setFName] = useState('');
  const [fNameErrText, setFNameErrText] = useState('');
  const [fNameErr, setFNameErr] = useState(false);
  const [mName, setMName] = useState('');
  const [mNameErrText, setMNameErrText] = useState('');
  const [mNameErr, setMNameErr] = useState(false);
  const [className, setClassName] = useState('');
  const [classNameErrText, setClassNameErrText] = useState('');
  const [classNameErr, setClassNameErr] = useState(false);
  const [phoneNo, setPhoneNo] = useState('');
  const [phoneNoErrText, setPhoneNoErrText] = useState('');
  const [phoneNoErr, setPhoneNoErr] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordErrText, setPasswordErrText] = useState('');
  const [passwordErr, setPasswordErr] = useState(false);
  const [isPasswordSeen, setIsPasswordSeen] = useState(false);
  const [accountTypeList, setAccountTypeList] = useState([]);
  const [userPlanList, setUserPlanList] = useState([]);
  const [userTypeList, setUserTypeList] = useState([]);
  const [grNo, setGRNo] = useState('');
  const [grNoErrText, setGRNoErrText] = useState('');
  const [grNoErr, setGRNoErr] = useState(false);
  const [emailId, setEmailId] = useState('');
  const [emailIdErrText, setEmailIdErrText] = useState('');
  const [emailIdErr, setEmailIdErr] = useState(false);
  const [selectedGender, setSelectedGender] = useState(null);
  const [selectedGenderErr, setSelectedGenderErr] = useState(false);
  const [selectedGenderErrText, setSelectedGenderErrText] = useState('');
  const genderOptions = [{ label: 'Male', value: 'male' }, { label: 'Female', value: 'female' }];
  const [parents, setParents] = useState([]);
  const [parentsErrText, setParentsErrText] = useState('');
  const [parentsErr, setParentsErr] = useState(false);
  const [parentList, setParentList] = useState([]);
  const [isNoLogin, setIsNoLogin] = useState(false);
  const [visible, setVisible] = useState(false);
  const [studentStatusList, setStudentStatusList] = useState([]);
  const [houseList, setHouseList] = useState([]);
  const [house, setHouse] = useState('');


  useEffect(() => {
    const fetchData = async () => {
      try {
        Loader.show();
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        generateRandomPassword(10);
        await getAccountType(loginData?._id);
        await GetStudentStatusList(loginData?._id);
        await GetHouseList(loginData?._id);
        await GetUserRoleList(loginData?._id);
        await GetUserPlanList(loginData?.AccountType, loginData?.UserType);
        await GetClassList(loginData?._id);
        await GetParentList(loginData?._id);
      } catch (error) {
        Loader.hide();
        console.error('Error in useEffect:', error);
      } finally {
        Loader.hide();
      }
    };
    fetchData();
  }, []);

  const generateRandomPassword = (length = 10) => {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+";
    let password = "";
    for (let i = 0; i < length; i++) {
      password += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    setPassword(password);
  };

  const GetHouseList = async (id) => {
    try {
      let data = {
        UserID: id,
      }
      await api.post(APIConstant.path.getHouseList, data).then(async response => {
        let res = response;
        if (res.success) {
          setHouseList(res.data.map(x => ({
            ...x,
            label: x?.HouseName,
            value: x?._id
          })));
          Loader.hide();
        }
      }).catch(err => {
        Loader.hide();
        console.log(err);
      });
    } catch (err) { console.log(err); }
  }

  const onCancel = () => {
    props.onCancel();
    setStudentName('');
    setClassName('');
    setEmailId('');
    setStudentID('');
    setPhoneNo('');
    setPassword('');
    setSelectedGender('');
  }

  const onSectionCancel = async () => {
    setVisible(false);
    setParents([]);
    await GetParentList(securityUserID);
  }

  const GetStudentStatusList = async (id) => {
    try {
      let data = {
        UserID: id,
      }
      await api.post(APIConstant.path.getStudentStatusList, data).then(async response => {
        let res = response;
        if (res.success) {
          setStudentStatusList(res.data.map(x => ({
            ...x,
            label: x?.StatusName,
            value: x?._id
          })));
          Loader.hide();
        }
      }).catch(err => {
        Loader.hide();
        console.log(err);
      });
    } catch (err) { console.log(err); }
  }

  const validate = () => {
    let formIsValid = true;
    if (CommonConfig.isEmpty(studentName)) {
      formIsValid = false;
      setStudentNameErr(true);
      setStudentNameErrText("Student Name is required");
    } else {
      setStudentNameErr(false);
      setStudentNameErrText("");
    }
    if (CommonConfig.isEmpty(studentID)) {
      formIsValid = false;
      setStudentIDErr(true);
      setStudentIDErrText("Student UID is required");
    } else {
      setStudentIDErr(false);
      setStudentIDErrText("");
    }
    if (CommonConfig.isEmpty(className)) {
      formIsValid = false;
      setClassNameErr(true);
      setClassNameErrText("ClassName is required");
    } else {
      setClassNameErr(false);
      setClassNameErrText("");
    }
    if (CommonConfig.isEmpty(phoneNo) && !isNoLogin) {
      formIsValid = false;
      setPhoneNoErr(true);
      setPhoneNoErrText("PhoneNo is required");
    } else {
      setPhoneNoErr(false);
      setPhoneNoErrText("");
    }
    if (CommonConfig.isEmpty(emailId) && !isNoLogin) {
      formIsValid = false;
      setEmailIdErr(true);
      setEmailIdErrText("EmailId is required");
    } else {
      setEmailIdErr(false);
      setEmailIdErrText("");
    }
    if (CommonConfig.isEmpty(password) && !isNoLogin) {
      formIsValid = false;
      setPasswordErr(true);
      setPasswordErrText("Password is required");
    } else {
      setPasswordErr(false);
      setPasswordErrText("");
    }
    if (CommonConfig.isEmpty(selectedGender)) {
      formIsValid = false;
      setSelectedGenderErr(true);
      setSelectedGenderErrText("Gender is required");
    } else {
      setSelectedGenderErr(false);
      setSelectedGenderErrText("");
    }
    if (parents.length > 2) {
      formIsValid = false;
      setParentsErr(true);
      setParentsErrText('You can only select up to 2 parents.');

    } else {
      setParentsErr(false);
      setParentsErrText('');
    }
    if (parents.length === 0) {
      formIsValid = false;
      setParentsErr(true);
      setParentsErrText('Atleast 1 Parent must be selected.');
    } else {
      setParentsErr(false);
      setParentsErrText('');
    }

    if (parents === 2) {
      const selectedParents = parents?.map(id => parentList.find(parent => parent?.value === id));
      if (selectedParents[0]?.Gender === selectedParents[1]?.Gender) {
        formIsValid = false;
        setParentsErr(true);
        setParentsErrText('Parents must be of opposite genders.');
      } else {
        setParentsErr(false);
        setParentsErrText('');
      }
    }
    if (CommonConfig.isEmpty(fName)) {
      formIsValid = false;
      setFNameErr(true);
      setFNameErrText("Father name is required");
    } else {
      setFNameErr(false);
      setFNameErrText("");
    }
    if (CommonConfig.isEmpty(surName)) {
      formIsValid = false;
      setSurNameErr(true);
      setSurNameErrText("Surname is required");
    } else {
      setSurNameErr(false);
      setSurNameErrText("");
    }
    if (CommonConfig.isEmpty(mName)) {
      formIsValid = false;
      setMNameErr(true);
      setMNameErrText("Mother name is required");
    } else {
      setMNameErr(false);
      setMNameErrText("");
    }
    return formIsValid;
  };

  const getAccountType = async () => {
    try {
      let data = {
        Type: "AccountType",
      };
      await api
        .post(APIConstant.path.GetStringMap, data)
        .then(async (response) => {
          let res = response;
          if (res.success) {
            setAccountTypeList(
              res.data.map((data) => ({ label: data.Name, value: data._id }))
            );
            Loader.hide();
          }
        })
        .catch((err) => {
          Loader.hide();
          console.log(err);
        });
    } catch (err) {
      console.log("err", err);
    }
  };

  const GetClassList = async (id) => {
    try {
      let data = {
        UserID: id,
      }
      await api.post(APIConstant.path.getClassList, data).then(async response => {
        let res = response;
        if (res.success) {
          setClassList(res?.data?.filter(x => x?.Status === 'Active')?.map((x) => ({
            ...x,
            value: x?._id,
            label: `${x?.ClassName}-${x?.SectionFullName.charAt(0)}-${x?.MediumName}-${x?.Board}`
          })));

          Loader.hide();
        }
      }).catch(err => {
        Loader.hide();
        console.log(err);
      });
    } catch (err) { console.log(err); }
  }

  const GetParentList = async (id) => {
    try {
      let data = {
        UserID: id,
      }
      await api.post(APIConstant.path.getUser, data).then(async response => {
        let res = response;
        if (res.success) {
          setParentList(res.data.filter((x) => x.UserType === 'Parent').map(x => ({
            Gender: x?.Gender,
            label: x?.Name,
            value: x?._id
          })));
          Loader.hide();
        }
      }).catch(err => {
        Loader.hide();
        console.log(err);
      });
    } catch (err) { console.log(err); }
  }

  const GetUserRoleList = async (value) => {
    let params = {
      AccountType: value,
    };
    try {
      await api
        .post(APIConstant.path.GetUserRole, params)
        .then(async (response) => {
          let res = response;
          if (res.success) {
            setUserTypeList(
              res.data.map((role) => ({
                label: role.RoleName,
                value: role._id,
              }))
            );
            Loader.hide();
          }
        })
        .catch((err) => {
          Loader.hide();
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const GetUserPlanList = async (AccountType, UserType) => {
    try {
      var params = {
        AccountType: AccountType,
        UserType: UserType,
      };
      await api
        .post(APIConstant.path.getUserPlanList, params)
        .then((res) => {
          if (res.success) {
            if (res.data) {
              setUserPlanList(
                res.data.map((plan) => ({
                  ...plan,
                  label: plan.PlanName,
                  value: plan._id,
                }))
              );
            }
            Loader.hide();
          }
        })
        .catch((err) => {
          Loader.hide();
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const AddStudentMaster = async (e) => {
    if (validate(e)) {
      try {
        let data = {
          id: '',
          Name: `${studentName} ${fName} ${surName}`,
          FirstName: studentName,
          SurName: surName,
          FName: fName,
          MName: mName,
          Email: emailId ?? '',
          Password: password,
          UserType: userTypeList.find((x) => x.label === 'Student')?.value,
          Phone: phoneNo ?? '',
          PlanId: userPlanList[0]?.value,
          IsNoLogin: isNoLogin,
          isMailVerified: 1,
          isPhoneVerified: 1,
          AccountType: accountTypeList.find((x) => x.label === "New Member")?.value,
          PersonalId: '',
          UserId: '',
          ProfileId: studentID,
          Gender: selectedGender,
          TypeId: parents.length ? (parents.length > 1 ? parents.join(',') : parents[0]) : '',
          AdmissionId: '',
          GRNo: grNo,
          House: house,
          StudentStatus: studentStatusList.find((x) => x.label === 'Active')?.value,
          ClassNo: className,
          CreatedBy: securityUserID
        };

        Loader.show();
        await api.post(APIConstant.path.AddUpdateStudent, data).then(async response => {
          let res = response;
          if (res.success) {
            Toast.success({ message: res.message });
            Loader.hide();
            onCancel();
          } else {
            Toast.error({ message: res.message });
            Loader.hide();
          }
        })
          .catch((err) => {
            Toast.error({ message: err });
            Loader.hide();
          });
      } catch (err) {
        console.log(err);
        Loader.hide();
        onCancel();
      }
    }
  };

  const handleChange = (e, type) => {
    const value = e.target.value;
    const alphanumericValue = value.replace(/[^a-zA-Z0-9\s]/g, '');
    const numericValue = value.replace(/[^0-9]/g, '');
    if (type === 'StudentName') {
      setStudentName(alphanumericValue);
    } else if (type === 'MobileNo') {
      setPhoneNo(numericValue);
    } else if (type === 'GRNO') {
      setGRNo(alphanumericValue);
    } else if (type === 'StudentID') {
      setStudentID(alphanumericValue?.toUpperCase());
    } else if (type === 'FName') {
      setFName(alphanumericValue);
    } else if (type === 'SurName') {
      setSurName(alphanumericValue);
    } else if (type === 'MName') {
      setMName(alphanumericValue);
    }
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    const maxLength = 50;
    if (value.length <= maxLength) {
      setEmailId(value);
    }
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(value)) {
      setEmailIdErr(true);
      setEmailIdErrText('Please enter a valid email address.');
    } else {
      setEmailIdErr(false);
      setEmailIdErrText('');
    }
  };

  const handleParentChange = (e) => {
    if (e?.value?.length > 2) {
      // Toast.error({ message: `You can only select up to 2 parents.` });
      setParentsErr(true);
      setParentsErrText('You can only select up to 2 parents.');
      return;
    }
    setParentsErr(false);
    setParentsErrText('');
    if (e?.value?.length === 2) {
      const selectedParents = e?.value.map(id => parentList.find(parent => parent?.value === id));
      if (selectedParents[0]?.Gender === selectedParents[1]?.Gender) {
        // Toast.error({ message: "Parents must be of opposite genders." });
        setParentsErr(true);
        setParentsErrText('Parents must be of opposite genders.');
        return;
      }
      setParentsErr(false);
      setParentsErrText('');
    }

    setParents(e.value);
  };

  return (
    <div className="p-d-flex p-flex-column p-jc-between">
      <div className="p-d-flex p-ai-center">
        <Checkbox
          inputId="isNoLogin"
          checked={isNoLogin}
          onChange={(e) => setIsNoLogin(e.checked)}
        />
        <label htmlFor="isNoLogin" style={{ marginLeft: '8px' }}>No Login Required </label>
      </div>
      <div className='p-grid p-mt-2'>
        <div className="p-col-12 p-md-6 p-pt-2">
          <div className="">
            <label htmlFor="clasName">Student UID<span style={{ color: "red" }}>*</span>
            </label>
            <InputText
              className="p-mt-2 p-w-100"
              id="studentName"
              type="text"
              value={studentID}
              maxLength={12}
              onChange={(e) => handleChange(e, 'StudentID')}
            // disabled={true}
            />
            <span className="error-msg" style={{ color: 'red' }}>
              {studentIDErr ? studentIDErrText : null}
            </span>
          </div>
        </div>
        <div className="p-col-12 p-md-6 p-pt-2 ">
          <div className="">
            <label htmlFor="grNo">GR No.</label>
            <FloatLabel>
              <InputText
                className="p-mt-2 p-w-100"
                id="grNo"
                type="text"
                placeholder="GR No"
                value={grNo}
                autoFocus={true}
                maxLength={10}
                onChange={(e) => handleChange(e, 'GRNO')}
              />
            </FloatLabel>
            <span className="error-msg" style={{ color: 'red' }}>
              {grNoErr ? grNoErrText : null}
            </span>
          </div>
        </div>
        <div className="p-col-12 p-md-6 p-pt-2">
          <div className="">
            <label htmlFor="studentName">First Name<span style={{ color: "red" }}>*</span>
            </label>
            <FloatLabel>
              <InputText
                className="p-mt-2 p-w-100"
                id="studentName"
                type="text"
                placeholder="Student Name"
                maxLength={20}
                value={studentName}
                onChange={(e) => handleChange(e, 'StudentName')}
              />
            </FloatLabel>
            <span className="error-msg" style={{ color: 'red' }}>
              {studentNameErr ? studentNameErrText : null}
            </span>
          </div>
        </div>
        <div className="p-col-12 p-md-6 p-pt-2">
          <div className="">
            <label htmlFor="surName">Surname<span style={{ color: "red" }}>*</span>
            </label>
            <FloatLabel>
              <InputText
                className="p-mt-2 p-w-100"
                id="surName"
                type="text"
                placeholder="Surname"
                maxLength={20}
                value={surName}
                onChange={(e) => handleChange(e, 'SurName')}
              />
            </FloatLabel>
            <span className="error-msg" style={{ color: 'red' }}>
              {surNameErr ? surNameErrText : null}
            </span>
          </div>
        </div>
        <div className="p-col-12 p-md-6 p-pt-2">
          <div className="">
            <label htmlFor="fatherName">Father Name<span style={{ color: "red" }}>*</span>
            </label>
            <FloatLabel>
              <InputText
                className="p-mt-2 p-w-100"
                id="fatherName"
                type="text"
                placeholder="Father Name"
                maxLength={20}
                value={fName}
                onChange={(e) => handleChange(e, 'FName')}
              />
            </FloatLabel>
            <span className="error-msg" style={{ color: 'red' }}>
              {fNameErr ? fNameErrText : null}
            </span>
          </div>
        </div>
        <div className="p-col-12 p-md-6 p-pt-2">
          <div className="">
            <label htmlFor="motherName">Mother Name<span style={{ color: "red" }}>*</span>
            </label>
            <FloatLabel>
              <InputText
                className="p-mt-2 p-w-100"
                id="motherName"
                type="text"
                placeholder="Mother Name"
                maxLength={20}
                value={mName}
                onChange={(e) => handleChange(e, 'MName')}
              />
            </FloatLabel>
            <span className="error-msg" style={{ color: 'red' }}>
              {mNameErr ? mNameErrText : null}
            </span>
          </div>
        </div>
        <div className="p-col-12 p-md-6 p-pt-2">
          <div className="">
            <label htmlFor="selectedClassName" className="mr-2">Class<span style={{ color: "red" }}>*</span>
            </label>
            <div className="custom-inputs">
              <Dropdown id="selectedClassName" className='p-w-100' value={className} options={classList}
                onChange={(e) => setClassName(e.value)} placeholder="Select Class" display="chip" />
              <span className="error-msg" style={{ color: 'red' }}>
                {classNameErr ? classNameErrText : null}
              </span>
            </div>
          </div>
        </div>
        <div className="p-col-12 p-md-6 p-pt-2">
          <div className="">
            <label htmlFor="house" className="mr-2">House</label>
            <div className="custom-inputs">
              <Dropdown id="house" className='p-w-100' value={house} options={houseList} onChange={(e) => setHouse(e.value)} placeholder="Select House" display="chip" />
            </div>
          </div>
        </div>
        <div className="p-col-12 p-md-6 p-pt-2">
          <div className="">
            <label htmlFor="gender" className="mr-2">Gender<span style={{ color: "red" }}>*</span>
            </label>
            <div className="custom-inputs">
              <Dropdown id="gender" className='p-w-100' value={selectedGender} options={genderOptions}
                onChange={(e) => setSelectedGender(e.value)} placeholder="Select Gender" display="chip" />
              <span className="error-msg" style={{ color: 'red' }}>
                {selectedGenderErr ? selectedGenderErrText : null}
              </span>
            </div>
          </div>
        </div>
        <div className="p-col-12  p-md-6 p-pt-2">
          <div className='p-w-100'>
            <label htmlFor="parents" className="mr-2">Parents<span style={{ color: "red" }}>*</span>
            </label>
            <div className='p-d-flex p-ai-baseline'>
              <div className="custom-inputs p-w-100">
                <MultiSelect
                  id="parents"
                  value={parents}
                  options={parentList}
                  onChange={(e) => handleParentChange(e)}
                  placeholder="Select Parents"
                  className="p-w-100"
                  filter
                  display="chip"
                />
                <span className="error-msg" style={{ color: 'red' }}>
                  {parentsErr ? parentsErrText : null}
                </span>
              </div>
              <Button
                className="e-btn white p-ml-2 p-mt-0 p-mb-0"
                icon="pi pi-plus text-white"
                onClick={(e) => setVisible(true)}
              />
            </div>
          </div>
        </div>
        {/* <div className="p-col-12 p-md-6 p-pt-2">
          <div className="">
            <Button
              className="e-btn white p-ml-2  "
              icon="pi pi-plus text-white"
              onClick={(e) => setVisible(true)}
            />
          </div>
        </div> */}
        <div className="p-col-12 p-md-6 p-pt-2">
          <div className="">
            <label htmlFor="selectedGender">EmailId{!isNoLogin && <span style={{ color: "red" }}>*</span>}</label>
            <FloatLabel>
              <InputText
                className="p-mt-2 p-w-100"
                id="emailId"
                type="email"
                placeholder="EmailId"
                value={emailId}
                autoFocus={true}
                disabled={isNoLogin}
                onChange={(e) => handleEmailChange(e)}
              />
            </FloatLabel>
            <span className="error-msg" style={{ color: 'red' }}>
              {emailIdErr ? emailIdErrText : null}
            </span>
          </div>
        </div>
        <div className="p-col-12 p-md-6 p-pt-2">
          <div className="">
            <label htmlFor="phoneNo">Phone No{!isNoLogin && <span style={{ color: "red" }}>*</span>}</label>
            <FloatLabel>
              <InputText
                className="p-mt-2 p-w-100"
                id="phoneNo"
                placeholder="PhoneNo"
                maxLength={10}
                disabled={isNoLogin}
                value={phoneNo}
                onChange={(e) => handleChange(e, 'MobileNo')}
              />
            </FloatLabel>
            <span className="error-msg" style={{ color: 'red' }}>
              {phoneNoErr ? phoneNoErrText : null}
            </span>
          </div>
        </div>
        <div className="p-col-12 p-md-6 p-pt-2">
          <div className='custom-inputs password-field'>
            <label htmlFor="password">Password{!isNoLogin && <span style={{ color: "red" }}>*</span>}</label>
            <FloatLabel>
              <InputText
                id='password'
                type={isPasswordSeen ? 'text' : 'password'}
                placeholder='******'
                className='p-w-100 p-mt-2'
                maxLength={20}
                disabled={isNoLogin}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <i
                className={
                  isPasswordSeen
                    ? 'icon-view tailer-icon'
                    : 'icon-view-off tailer-icon'
                }
                onClick={() =>
                  setIsPasswordSeen(!isPasswordSeen)
                }
              ></i>
            </FloatLabel>
            <span className="error-msg" style={{ color: 'red' }}>
              {passwordErr ? passwordErrText : null}
            </span>
          </div>
        </div>

      </div>
      <div className='dialog-footer1 p-d-flex p-ai-center p-jc-end'>
        <Button className='p-m-0 primary-btn1 p-m-1' onClick={(e) => onCancel()}> Cancel </Button>
        <Button className='p-m-0 primary-btn p-m-1 color-white' label="Submit" severity="secondary" raised onClick={(e) => AddStudentMaster()} />
      </div>
      <Dialog
        header="Add Parent Master"
        className='dialog-popup'
        visible={visible}
        onHide={() => onSectionCancel()}
        draggable={false}
        position="center"
        style={{ width: "50vw"}}
      >
        <AddParentMasterDialog onCancel={onSectionCancel} />
      </Dialog>
    </div>
  );
};

export default AddStudentMasterDialog;
