import React, { useState, useEffect } from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import '../../../assets/css/teacherTimeTable.css';
import Loader from "../../Shared/Loader/Loader";
import Toast from '../../Shared/Toast/Toast';
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { useNavigate } from 'react-router-dom';

const TeacherTimeTableCard = (props) => {
    const [classList, setClassList] = useState([]);
    const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const [periodsList, setPeriodsList] = useState([]);
    const [isExpanded, setIsExpanded] = useState(false);
    const [editTableData, setEditTableData] = useState([]);
    const [timetableData, setTimetableData] = useState([]);
    const navigate = useNavigate();
    const [subjectList, setSubjectList] = useState([]);

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        GetClassList(loginData?._id);
        GetSubjectList(loginData?._id);
        GetTimeTableData(props?.TeacherId);
    }, []);

    const GetClassList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            Loader.show();
            await api.post(APIConstant.path.getClassList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setClassList(res?.data?.filter(x => x?.Status === 'Active')?.map((x) => ({
                        value: x?._id,
                        label: `${x?.ClassName}-${x?.SectionFullName.charAt(0)}-${x?.MediumName}-${x?.Board}`
                    })));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const GetTimeTableData = async (teacherId) => {
        try {
            let data = {
                TeacherId: teacherId
            }
            const response = await api.post(APIConstant.path.GetTimeTableByTeacher, data);
            if (response.success && response.data.length > 0) {
                let periodData = response.data[0]?.Periods?.map((x, idx) => {
                    return {Time: x?.Time, isBreak: x?.SubjectId === 'Free' ? true: false}
                });
                setPeriodsList(periodData);
                let timeTableData = response.data.map(row => {
                    const formattedRow = { Day: row?.Day, id: row?.id};
                    row?.Periods.forEach((period, index) => {
                        formattedRow[`Period${index + 1}`] = period?.SubjectId === 'Free' ? period?.SubjectId : period?.SubjectName;
                        formattedRow[`Class${index + 1}`] = period?.ClassId === 'Free' ? period?.ClassId : period?.ClassName;
                    });
                    return formattedRow;
                });
                let timeTableEditData = response.data.map(row => {
                    const formattedRow = { Day: row?.Day, id: row?.id };
                    row?.Periods.forEach((period, index) => {
                        formattedRow[`Period${index + 1}`] = period?.SubjectId;
                        formattedRow[`Class${index + 1}`] = period?.ClassId;
                        formattedRow[`isFree${index + 1}`] = period?.ClassId === 'Free' ? true : false;
                    });
                    return formattedRow;
                });
                const sortedData = timeTableData.sort((a, b) => {
                    return days.indexOf(a?.Day) - days.indexOf(b?.Day);
                });
                const EditsortedData = timeTableEditData.sort((a, b) => {
                    return days.indexOf(a?.Day) - days.indexOf(b?.Day);
                });

                setTimetableData(sortedData);
                setEditTableData(EditsortedData);
            } else {
                setPeriodsList([]);
                setTimetableData([]);
                setEditTableData([]);
            }
        } catch (error) {
            console.error("Error fetching timetable:", error);
        }
    };

    const toggleExpand = () => {
        setIsExpanded((prev) => !prev);
    };

    const GetSubjectList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.getSubjectList, data).then(async response => {
                let res = response;
                if (res.success) {
                    let subjectData = res?.data?.filter(y => y?.Teachers.includes(props?.TeacherId))?.map(x => ({
                        label: x?.SubjectName,
                        value: x._id
                    }));
                    setSubjectList(subjectData);
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const printTimeTable = () => {
        try {
            Loader.show();
            const doc = new jsPDF();
            doc.setFontSize(16);
            doc.text(`Time Table for Teacher: ${props?.TeacherName}`, 10, 10);

            const columns = [
                { header: "Days/Periods", dataKey: "Day" },
                ...periodsList.map((period, index) => ({
                    header: period?.Time,
                    dataKey: `Period${index + 1}`,
                })),
            ];

            const rows = timetableData.map((row) => {
                const formattedRow = { Day: row.Day };
                periodsList.forEach((_, index) => {
                    formattedRow[`Period${index + 1}`] = row[`Period${index + 1}`] === 'Free' ? row[`Class${index + 1}`] : `${row[`Period${index + 1}`] ?? 'Free'} - ${row[`Class${index + 1}`] ?? 'Free'}`;
                });
                return formattedRow;
            });

            autoTable(doc, {
                columns: columns,
                body: rows,
                startY: 20,
                theme: "grid",
            });
            Loader.hide();
            doc.save(`Teacher_Time_Table_${props?.TeacherName || "Unknown"}.pdf`);
        } catch (error) {
            console.log('Error ');
            Loader.hide();
        }
    };

    return (
        <div className='time-table'>
            <Card className="card-actions p-mb-3" title={`Teacher : ${props?.TeacherName}`}>
                <hr className='p-m-0' />
                <div className="card-header">
                    <div className="card-header-right">
                        <Button icon="pi pi-pencil" className="e-btn" onClick={() => navigate('/edit-teacher-time-table', { state: { tableData: editTableData, periodData: periodsList, subjectList: subjectList, classList: classList, TeacherId: props?.TeacherId } })} />
                        <Button icon="pi pi-print" className="e-sction-btn" onClick={() => printTimeTable()} />
                        <Button
                            icon={`pi ${isExpanded ? 'pi-chevron-up' : 'pi-chevron-down'}`}
                            className="e-btn"
                            onClick={toggleExpand}
                        />
                    </div>
                </div>
                {isExpanded && (
                    <div className="card-content">
                        <DataTable value={timetableData} responsiveLayout="scroll" className="p-datatable-gridlines">
                            <Column field="Day" header="Days/Periods" />
                            {periodsList?.map((period, index) => (
                                <Column
                                    key={index}
                                    field={`Period${index + 1}`}
                                    header={period?.Time}
                                    body={(rowData) =>
                                        rowData[`Class${index + 1}`] === 'Free' ? (
                                            rowData[`Period${index + 1}`]
                                        ) : (
                                            `${rowData[`Class${index + 1}`] ?? 'Free'} - ${rowData[`Period${index + 1}`] ?? 'Free'}`

                                        )
                                    }
                                />
                            ))}
                        </DataTable>
                    </div>
                )}
            </Card>
        </div>

    );
};

export default TeacherTimeTableCard;
