//=============PATH=================//
import { apiBase } from './config';
const SERVER_PATH = apiBase;
export default class APIConstant {
  static path = {

    // Auth API

    login: `${SERVER_PATH}/authentication/loginUser`,
    logout: `${SERVER_PATH}/authentication/logoutUser`,
    GoogleMapApiKey: 'AIzaSyAww6TxPSzQbg74ljJahxvuLHmRfICHbf4',

    // Dashboard API
    getDahboardData: `${SERVER_PATH}/dashboard/getDahboardData`,
    getAdminDashboardUser: `${SERVER_PATH}/dashboard/getAdminDashboardUser`,
    getAdminDashboardUserList: `${SERVER_PATH}/dashboard/getAdminDashboardUserList`,

    //Sidebar API
    getUserMenu: `${SERVER_PATH}/menu/getMenuItems`,
    getUserType: `${SERVER_PATH}/useraccess/getUserType`,
    getMenuMaster: `${SERVER_PATH}/useraccess/getMenuMaster`,
    addUpdateUserType: `${SERVER_PATH}/useraccess/addUpdateUserType`,
    getUserTypeByID: `${SERVER_PATH}/useraccess/getUserTypeByID`,
    getUserWiseAccessList: `${SERVER_PATH}/userAccess/getUserAccessList`,


    // Add plan 
    getUserPlanList: `${SERVER_PATH}/userPlan/getUserPlanList`,

    //  user 
    AddUpdateUser: `${SERVER_PATH}/authentication/addUsers`,
    getUser: `${SERVER_PATH}/authentication/getUsers`,
    getUserById: `${SERVER_PATH}/authentication/getUserById`,
    updateUserStatus: `${SERVER_PATH}/authentication/updateUserStatus`,
    AddUpdateStudent: `${SERVER_PATH}/authentication/addUpdateStudent`,


    GetStringMap: `${SERVER_PATH}/stringMap/getStringMapList`,
    GetUserRole: `${SERVER_PATH}/userRole/getUserRoleList`,
    getExamTypeList: `${SERVER_PATH}/examTypeMaster/getExamTypeDetailList`,

    //Class Master
    AddUpdateClassMaster: `${SERVER_PATH}/classMaster/addClassDetail`,
    getClassList: `${SERVER_PATH}/classMaster/getClassDetailList`,
    updateClassDetailStatus: `${SERVER_PATH}/classMaster/updateClassDetailStatus`,
    deleteClassDetail: `${SERVER_PATH}/classMaster/deleteClassDetail`,
    GetStudentIdCardData: `${SERVER_PATH}/classMaster/getStudentIdCardData`,
    GetStudentResultData: `${SERVER_PATH}/classMaster/getStudentResultData`,

    //Class No Master
    AddUpdateClassNoMaster: `${SERVER_PATH}/classNoMaster/addClassNoDetail`,
    getClassNoList: `${SERVER_PATH}/classNoMaster/getClassNoDetailList`,
    updateClassNoDetailStatus: `${SERVER_PATH}/classNoMaster/updateClassNoDetailStatus`,
    deleteClassNoDetail: `${SERVER_PATH}/classNoMaster/deleteClassNoDetail`,

    //Section Master
    AddUpdateSectionMaster: `${SERVER_PATH}/sectionMaster/addSectionDetail`,
    getSectionList: `${SERVER_PATH}/sectionMaster/getSectionDetailList`,
    updateSectionDetailStatus: `${SERVER_PATH}/sectionMaster/updateSectionDetailStatus`,
    deleteSectionDetail: `${SERVER_PATH}/sectionMaster/deleteSectionDetail`,

    //Teacher Master
    AddPersonalDetail: `${SERVER_PATH}/personalDetail/addPersonalDetail`,
    GetPersonalDetailById: `${SERVER_PATH}/personalDetail/getPersonalDetailById`,
    AddQualificationDetail: `${SERVER_PATH}/qualificationDetail/addQualificationDetail`,
    GetQualificationDetailById: `${SERVER_PATH}/qualificationDetail/getQualificationDetailById`,

    // Student Master
    AddAdmissionDetail: `${SERVER_PATH}/admissionDetail/addAdmissionDetail`,
    GetAdmissionDetailById: `${SERVER_PATH}/admissionDetail/getAdmissionDetailById`,
    AddPreviousSchoolDetail: `${SERVER_PATH}/previousSchoolDetail/addPreviousSchoolDetail`,
    GetPreviousSchoolDetailById: `${SERVER_PATH}/previousSchoolDetail/getPreviousSchoolDetailById`,
    getStudentStatusList: `${SERVER_PATH}/studentStatusMaster/getStudentStatusList`,

    //House Master
    AddUpdateHouseMaster: `${SERVER_PATH}/houseMaster/addHouseDetail`,
    getHouseList: `${SERVER_PATH}/houseMaster/getHouseDetailList`,
    updateHouseDetailStatus: `${SERVER_PATH}/houseMaster/updateHouseDetailStatus`,
    deleteHouseDetail: `${SERVER_PATH}/houseMaster/deleteHouseDetail`,

    //Class Configuration
    AddUpdateClassConfiguration: `${SERVER_PATH}/classConfiguration/addClassConfiguration`,
    getClassConfigurationList: `${SERVER_PATH}/classConfiguration/getClassConfigurationList`,
    getStudentDetailList: `${SERVER_PATH}/classConfiguration/getStudentDetailList`,
    getStudentDropDownList: `${SERVER_PATH}/classConfiguration/getStudentDropDownList`,
    updateClassConfigurationStatus: `${SERVER_PATH}/classConfiguration/updateClassConfigurationStatus`,
    getParentDetailList: `${SERVER_PATH}/classConfiguration/getParentDetailList`,
    getTeacherDetailList: `${SERVER_PATH}/classConfiguration/getTeacherDetailList`,
    getParentDropDownList: `${SERVER_PATH}/classConfiguration/getParentDropDownList`,
    updateParentStatus: `${SERVER_PATH}/classConfiguration/updateParentStatus`,
    deleteParent: `${SERVER_PATH}/classConfiguration/deleteParent`,
    updateStudentStatus: `${SERVER_PATH}/classConfiguration/updateStudentStatus`,
    deleteStudent: `${SERVER_PATH}/classConfiguration/deleteStudent`,
    updateTeacherStatus: `${SERVER_PATH}/classConfiguration/updateTeacherStatus`,
    deleteTeacher: `${SERVER_PATH}/classConfiguration/deleteTeacher`,
    getChildreenDropDownList: `${SERVER_PATH}/classConfiguration/getChildreenDropDownList`,
    ImportStudents: `${SERVER_PATH}/classConfiguration/importStudents`,

    //Board Master
    AddUpdateBoardMaster: `${SERVER_PATH}/boardMaster/addBoardDetail`,
    getBoardList: `${SERVER_PATH}/boardMaster/getBoardDetailList`,

    //Id Card Configuration
    AddUpdateIdCardConfig: `${SERVER_PATH}/idCardConfiguration/addIdCardConfiguration`,
    GetIdCardConfigurationList: `${SERVER_PATH}/idCardConfiguration/getIdCardConfigurationList`,
    UpdateIdCardConfigurationStatus: `${SERVER_PATH}/idCardConfiguration/updateIdCardConfigurationStatus`,
    DeleteIdCardConfiguration: `${SERVER_PATH}/idCardConfiguration/deleteIdCardConfiguration`,

    //School Detail
    AddSchoolDetail: `${SERVER_PATH}/schoolDetail/addSchoolDetail`,
    GetSchoolDetailById: `${SERVER_PATH}/schoolDetail/getSchoolDetailById`,

    //Subject Master
    AddUpdateSubjectMaster: `${SERVER_PATH}/subjectMaster/addSubjectDetail`,
    getSubjectList: `${SERVER_PATH}/subjectMaster/getSubjectDetailList`,
    getSubjectByClassId: `${SERVER_PATH}/subjectMaster/getSubjectByClassId`,
    updateSubjectDetailStatus: `${SERVER_PATH}/subjectMaster/updateSubjectDetailStatus`,
    deleteSubjectDetail: `${SERVER_PATH}/subjectMaster/deleteSubjectDetail`,
    generateDynamicId: `${SERVER_PATH}/subjectMaster/generateDynamicId`,
    getLanguageList: `${SERVER_PATH}/languageMaster/getLanguageDetailList`,
    getBloodList: `${SERVER_PATH}/bloodMaster/getBloodDetailList`,
    getCasteList: `${SERVER_PATH}/casteMaster/getCasteDetailList`,
    getReligionList: `${SERVER_PATH}/religionMaster/getReligionDetailList`,

    addUpdateProfileConfiguration: `${SERVER_PATH}/profileConfiguration/addUpdateProfileConfiguration`,
    getProfileConfigurationList: `${SERVER_PATH}/profileConfiguration/getProfileConfigurationList`,

    //Academic Calander
    AddUpdateAcademicCalander: `${SERVER_PATH}/academicCalander/addAcademicCalanderConfiguration`,
    getAcademicCalanderTemplateList: `${SERVER_PATH}/academicCalander/getAcademicCalanderConfigurationList`,

    //Time Table
    AddUpdateTimeTableEntry: `${SERVER_PATH}/timeTable/addUpdateTimeTableEntry`,
    GetTimeTableByClass: `${SERVER_PATH}/timeTable/getTimeTableByClass`,
    AddUpdateTeacherTimeTableEntry: `${SERVER_PATH}/timeTable/addUpdateTeacherTimeTableEntry`,
    GetTimeTableByTeacher: `${SERVER_PATH}/timeTable/getTimeTableByTeacher`,

    // Shift Master
    AddUpdateShiftMaster: `${SERVER_PATH}/shiftMaster/addShiftDetail`,
    getShiftList: `${SERVER_PATH}/shiftMaster/getShiftDetailList`,
    updateShiftDetailStatus: `${SERVER_PATH}/shiftMaster/updateShiftDetailStatus`,

    // Leave Reason Master
    AddUpdateLeaveReasonMaster: `${SERVER_PATH}/leaveReasonMaster/addLeaveReasonDetail`,
    getLeaveReasonList: `${SERVER_PATH}/leaveReasonMaster/getLeaveReasonDetailList`,
    updateLeaveReasonDetailStatus: `${SERVER_PATH}/leaveReasonMaster/updateLeaveReasonDetailStatus`,
    deleteLeaveReasonDetail: `${SERVER_PATH}/leaveReasonMaster/deleteLeaveReasonDetail`,

    // Fee Master
    AddUpdateFeeMaster: `${SERVER_PATH}/feeMaster/addFeeDetail`,
    getFeeList: `${SERVER_PATH}/feeMaster/getFeeDetailList`,
    updateFeeDetailStatus: `${SERVER_PATH}/feeMaster/updateFeeDetailStatus`,
    deleteFeeDetail: `${SERVER_PATH}/feeMaster/deleteFeeDetail`,

    // Attendance
    GetAttendanceListByClass: `${SERVER_PATH}/attendance/getAttendanceList`,
    AddAttendanceDetail: `${SERVER_PATH}/attendance/addAttendanceDetail`,
    GetAttendanceClassList: `${SERVER_PATH}/attendance/getAttendanceClassList`,
    GetClassWiseAttendanceList: `${SERVER_PATH}/attendance/getClassWiseAttendanceList`,
    GetStudentWiseAttendanceList: `${SERVER_PATH}/attendance/getStudentWiseAttendanceList`,

    //Term Master
    AddUpdateTermMaster: `${SERVER_PATH}/termMaster/addTermDetail`,
    getTermList: `${SERVER_PATH}/termMaster/getTermDetailList`,
    updateTermDetailStatus: `${SERVER_PATH}/termMaster/updateTermDetailStatus`,
    deleteTermDetail: `${SERVER_PATH}/termMaster/deleteTermDetail`,

    //Stop Master
    AddUpdateStopMaster: `${SERVER_PATH}/stopMaster/addStopDetail`,
    getStopList: `${SERVER_PATH}/stopMaster/getStopDetailList`,
    updateStopDetailStatus: `${SERVER_PATH}/stopMaster/updateStopDetailStatus`,
    deleteStopDetail: `${SERVER_PATH}/stopMaster/deleteStopDetail`,

    //Grade Master
    AddUpdateGradeMaster: `${SERVER_PATH}/gradeMaster/addGradeDetail`,
    getGradeList: `${SERVER_PATH}/gradeMaster/getGradeDetailList`,
    updateGradeDetailStatus: `${SERVER_PATH}/gradeMaster/updateGradeDetailStatus`,
    deleteGradeDetail: `${SERVER_PATH}/gradeMaster/deleteGradeDetail`,

    //Co-Scholastic Master
    AddUpdateCoScholasticMaster: `${SERVER_PATH}/coScholasticMaster/addCoScholasticDetail`,
    getCoScholasticList: `${SERVER_PATH}/coScholasticMaster/getCoScholasticDetailList`,
    updateCoScholasticDetailStatus: `${SERVER_PATH}/coScholasticMaster/updateCoScholasticDetailStatus`,

    //Result Template
    AddUpdateResultTemplate: `${SERVER_PATH}/resultTemplate/addResultTemplate`,
    GetResultTemplateList: `${SERVER_PATH}/resultTemplate/getResultTemplateList`,
    GetResultTemplateListByClassId: `${SERVER_PATH}/resultTemplate/getResultTemplateListByClassId`,

    //Type Master
    AddUpdateTypeMaster: `${SERVER_PATH}/typeMaster/addTypeDetail`,
    getTypeList: `${SERVER_PATH}/typeMaster/getTypeDetailList`,
    updateTypeDetailStatus: `${SERVER_PATH}/typeMaster/updateTypeDetailStatus`,
    deleteTypeDetail: `${SERVER_PATH}/typeMaster/deleteTypeDetail`,

    //CoScholastic Template
    AddUpdateCoScholasticTemplate: `${SERVER_PATH}/coScholasticTemplate/addCoScholasticTemplate`,
    GetCoScholasticTemplateList: `${SERVER_PATH}/coScholasticTemplate/getCoScholasticTemplateList`,
    UpdateCoScholasticTemplateStatus: `${SERVER_PATH}/coScholasticTemplate/updateCoScholasticTemplateStatus`,
    DeleteCoScholasticTemplate: `${SERVER_PATH}/coScholasticTemplate/deleteCoScholasticTemplate`,

    //CoScholastic Grade Template
    AddUpdateCoScholasticGradeTemplate: `${SERVER_PATH}/coScholasticGradeTemplate/addCoScholasticGradeTemplate`,
    GetCoScholasticGradeTemplateList: `${SERVER_PATH}/coScholasticGradeTemplate/getCoScholasticGradeTemplateList`,
    UpdateCoScholasticGradeTemplateStatus: `${SERVER_PATH}/coScholasticGradeTemplate/updateCoScholasticGradeTemplateStatus`,
    DeleteCoScholasticGradeTemplate: `${SERVER_PATH}/coScholasticGradeTemplate/deleteCoScholasticGradeTemplate`,
    GetCoScholasticGradesList: `${SERVER_PATH}/coScholasticGradeTemplate/getCoScholasticGradesList`,

    // Grade Entry
    AddUpdateGradingEntry: `${SERVER_PATH}/gradeEntry/addGradeEntryDetail`,
    GetGradeEntryList: `${SERVER_PATH}/gradeEntry/getGradeEntryDetailList`,

    // Room Master
    AddUpdateRoomMaster: `${SERVER_PATH}/roomMaster/addRoomDetail`,
    getRoomList: `${SERVER_PATH}/roomMaster/getRoomDetailList`,
    updateRoomDetailStatus: `${SERVER_PATH}/roomMaster/updateRoomDetailStatus`,
    deleteRoomDetail: `${SERVER_PATH}/roomMaster/deleteRoomDetail`,

    // Exam Schedule
    AddUpdateExamSchedule: `${SERVER_PATH}/examSchedule/addExamScheduleDetail`,
    GetExamScheduleList: `${SERVER_PATH}/examSchedule/getExamScheduleDetailList`,
    updateExamScheduleDetailStatus: `${SERVER_PATH}/examSchedule/updateExamScheduleDetailStatus`,
    DeleteExamScheduleDetail: `${SERVER_PATH}/examSchedule/deleteExamScheduleDetail`,
    GetExamScheduleMarksList: `${SERVER_PATH}/examSchedule/getExamScheduleMarksDetailList`,

    // Exam Marks
    AddUpdateExamMarks: `${SERVER_PATH}/examMarks/addExamMarksDetail`,
    GetExamMarksList: `${SERVER_PATH}/examMarks/getExamMarksDetailList`,

    //General Configuration
    AddUpdateGeneralConfiguration: `${SERVER_PATH}/general/addGeneralConfigurationDetail`,
    GetGeneralConfigurationDetail: `${SERVER_PATH}/general/getGeneralConfigurationDetail`,

    //Fee Structure
    AddUpdateFeeStructure: `${SERVER_PATH}/feeStructure/addFeeStructureDetail`,
    GetFeeStructureList: `${SERVER_PATH}/feeStructure/getFeeStructureDetailList`,
    DeleteFeeStructure: `${SERVER_PATH}/feeStructure/deleteFeeStructureDetail`,

    //Result Template Configuration
    AddUpdateResultConfiguration: `${SERVER_PATH}/resultConfiguration/addUpdateResultConfiguration`,
    GetResultTemplateConfigurationList: `${SERVER_PATH}/resultConfiguration/getResultConfigurationList`,
    DeleteResultConfiguration: `${SERVER_PATH}/resultConfiguration/deleteResultConfiguration`,
  };
}
