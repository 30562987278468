import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { FilterMatchMode } from 'primereact/api';
import { Dialog } from 'primereact/dialog';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import AddUpdateExamScheduleDialog from './AddUpdateExamSchedule';
import Toast from '../../Shared/Toast/Toast';
import DeleteConfirmation from '../../Dialogs/DeleteConfirmation';
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import userImage from '../../../assets/icons/OBJECTS.svg';
import { apiBase } from '../../../utils/config';


const ExamSchedule = () => {
    const [examScheduleList, setExamScheduleList] = useState([]);
    const [masterData, setMasterData] = useState([]);
    const [visible, setVisible] = useState(false);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        TitleName: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });

    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [securityUserID, setSecurityUserID] = useState("");
    const [rows, setRows] = useState(10);
    const [first, setFirst] = useState(0);
    const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);
    const [deleteId, setDeleteId] = useState('');

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        GetExamScheduleList(loginData?._id);
    }, []);

    const onPageChange = (e) => {
        setFirst(e.first);
        setRows(e.rows);
    };

    const GetExamScheduleList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.GetExamScheduleList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setExamScheduleList(res.data);
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const onCancel = async () => {
        setVisible(false);
        setMasterData([]);
        await GetExamScheduleList(securityUserID);
    }

    const actionBodyTemplateSrNo = (data, props) => {
        return props.rowIndex + 1;
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const renderFooter = () => (
        <div className="dialog-footer1 p-d-flex p-ai-center p-jc-end">
            <Button
                label="Cancel"
                className="primary-btn1"
                onClick={handleClose}
            />
            <Button
                label="Delete"
                className="primary-btn"
                onClick={(e) => DeleteItem()}
            />
        </div>
    );

    const handleClose = () => {
        setIsDeleteConfirmation(false)
    };

    const DeleteItem = async () => {
        try {
            let data = {
                id: deleteId,
            }
            await api.post(APIConstant.path.DeleteExamScheduleDetail, data).then(async response => {
                let res = response;
                if (res.success) {
                    Loader.hide();
                    Toast.success({ message: 'Exam schedule deleted successfully' });
                    GetExamScheduleList(securityUserID);
                }
                handleClose();
            }).catch(err => {
                Loader.hide();
                handleClose();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const actionBodyTemplate = (rows) => {
        return (
            <React.Fragment>
                <div className='p-d-flex p-ai-center'>
                    <Button icon="pi pi-pencil" className="e-sction-btn p-my-0 p-m-0" onClick={() => {
                        setMasterData(rows);
                        setVisible(true)
                    }} />
                    <Button icon="pi pi-trash" className="e-sction-btn p-my-0 p-mr-0 p-ml-2" onClick={(e) => {
                        setIsDeleteConfirmation(true);
                        setDeleteId(rows._id);
                    }} />
                    <Button icon="pi pi-print" className="e-btn p-mx-2" onClick={() => printTimeTable(rows)} />
                </div>
            </React.Fragment>

        );
    };

    const formatDate = (date) => {
        if (!date) return "";
        return new Date(date).toLocaleDateString('en-GB');
    };

    const formatTime = (date) => {
        if (!date) return "";
        return new Date(date).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
    };

    const loadImageAsBase64 = async (url, fallbackUrl) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.crossOrigin = "Anonymous";
            img.src = url;
            img.onload = function () {
                const canvas = document.createElement("canvas");
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);
                resolve(canvas.toDataURL("image/jpeg"));
            };
            img.onerror = async () => {
                console.warn(`Failed to load image from ${url}. Using fallback.`);
                if (fallbackUrl) {
                    resolve(await loadFallbackImage(fallbackUrl));
                } else {
                    resolve(null);
                }
            };
        });
    };

    const loadFallbackImage = async (fallbackUrl) => {
        if (fallbackUrl.endsWith(".svg")) {
            const response = await fetch(fallbackUrl);
            const svgText = await response.text();
            return `data:image/svg+xml;base64,${btoa(svgText)}`;
        } else {
            return await loadImageAsBase64(fallbackUrl, null);
        }
    };

    const printTimeTable = async (data) => {
        try {
            Loader.show();
            const doc = new jsPDF();
            let startY = 10;
            const schoolLogoUrl = data?.SchoolLogo ? `${apiBase}/UserProfilePic/${data?.SchoolLogo}` : null;

            const base64Image = await loadImageAsBase64(schoolLogoUrl, userImage);
            if (base64Image) {
                doc.addImage(base64Image, "JPEG", 160, 10, 40, 20);
            }

            doc.setFontSize(18);
            doc.text("Exam Schedule", 80, startY);
            startY += 10;

            doc.setFontSize(12);
            doc.text(`School Name:`, 10, startY);
            doc.text(`${data?.SchoolName || 'N/A'}`, 60, startY);
            startY += 8;

            doc.text(`School Phone:`, 10, startY);
            doc.text(`${data?.SchoolPhone || 'N/A'}`, 60, startY);
            startY += 8;

            doc.text(`School Email:`, 10, startY);
            doc.text(`${data?.SchoolEmail || 'N/A'}`, 60, startY);
            startY += 8;

            doc.text(`Class:`, 10, startY);
            doc.text(`${data?.ClassName || 'N/A'}`, 60, startY);
            startY += 8;

            doc.text(`Exam Type:`, 10, startY);
            doc.text(`${data?.ExamTypeName || 'N/A'}`, 60, startY);
            startY += 12; // Extra space before table

            const columns = [
                { header: "Subject Name", dataKey: "SubjectName" },
                { header: "Subject Code", dataKey: "SubjectId" },
                { header: "Date", dataKey: "StartDate" },
                { header: "Day", dataKey: "Day" },
                { header: "From Time", dataKey: "StartTime" },
                { header: "End Time", dataKey: "EndTime" },
                { header: "Duration", dataKey: "Duration" },
                { header: "Marks", dataKey: "Marks" },
            ];

            const rows = data?.SubjectList?.map((row) => {
                const startTime = row?.StartTime ? new Date(row?.StartTime) : null;
                const endTime = row?.EndTime ? new Date(row?.EndTime) : null;

                let duration = "";
                if (startTime && endTime) {
                    const diffMs = endTime - startTime;
                    const diffMinutes = Math.floor(diffMs / (1000 * 60));
                    const hours = Math.floor(diffMinutes / 60);
                    const minutes = diffMinutes % 60;
                    duration = `${hours}h ${minutes}m`;
                }

                return {
                    ...row,
                    StartDate: formatDate(row?.StartDate),
                    StartTime: formatTime(row?.StartTime),
                    EndTime: formatTime(row?.EndTime),
                    Day: row?.StartDate ? new Date(row?.StartDate).toLocaleDateString('en-US', { weekday: 'long' }) : '',
                    Duration: duration
                }
            });

            autoTable(doc, {
                columns: columns,
                body: rows,
                startY: startY,
                theme: "grid",
                headStyles: { fillColor: [41, 128, 185] },
                styles: { fontSize: 10, cellPadding: 2 },
            });

            Loader.hide();
            doc.save(`Exam_Schedule_${data?.ClassName || "Unknown"}.pdf`);
        } catch (error) {
            console.error('Error:', error);
            Loader.hide();
        }
    };

    return (
        <div className='wayment-owner-dashboard e-weighment-dashoard'>
            <div className="heading-area p-d-flex p-ai-center p-jc-between">
                <div className="p-d-flex p-ai-baseline">
                    <i className="pi pi-calendar-plus"></i>
                    <h2>Exam Schedule</h2>
                </div>
                <div className="p-d-flex p-ai-center p-jc-between">
                    <div className="searchbar-area e-searchbar-area">
                        <i className="pi pi-search" />
                        <InputText
                            value={globalFilterValue}
                            onChange={onGlobalFilterChange}
                            placeholder="Global Search"
                        />
                    </div>
                    <Button icon="pi pi-plus" className="e-h-btn" onClick={() => setVisible(true)} />
                </div>
            </div>
            <hr className="p-m-0" />
            <DataTable
                value={examScheduleList}
                paginator
                rows={rows}
                first={first}
                filters={filters}
                filterDisplay="menu"
                globalFilterFields={['TitleName']}
                responsiveLayout="scroll"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                rowsPerPageOptions={[5, 10, 20]}
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                onPage={onPageChange}
                className='p-mt-3 id-card-table'
            >
                <Column field="id" body={actionBodyTemplateSrNo} header="Sr No." />
                <Column
                    field="TitleName"
                    header="Exam Name"
                />
                <Column
                    field="ExamTypeName"
                    header="ExamType"
                />
                <Column
                    field="ClassName"
                    header="Class Name"
                />

                <Column body={actionBodyTemplate} header="Action" />
            </DataTable>
            <Dialog
                header='Exam Schedule'
                visible={visible}
                onHide={() => onCancel()}
                draggable={false}
                position="center"
                style={{ maxWidth: '86vw', height: "63.5%" }}
            >
                <AddUpdateExamScheduleDialog onCancel={onCancel} configId={masterData?._id} masterData={masterData} />
            </Dialog>
            <Dialog
                visible={isDeleteConfirmation}
                onHide={handleClose}
                className='p-py-0'
                header={
                    <div className="p-d-flex p-jc-between p-ai-center">
                        <span>Delete Confirmation</span>
                    </div>
                }
                footer={renderFooter()}
                draggable={false}
                dismissableMask
                style={{ width: '450px' }}
            >
                <DeleteConfirmation />
            </Dialog>
        </div>
    );
};

export default ExamSchedule;
