import React, { useState, useEffect } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { Paginator } from "primereact/paginator";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useNavigate } from "react-router-dom";
import api from "../../../utils/apiClient";
import Toast from '../../Shared/Toast/Toast';
import APIConstant from "../../../utils/pathConstants";
import CoScholasticGradeCard from "./CoScholasticGradingCard";

const CoScholasticGradeEntry = (props) => {
    const [students, setStudents] = useState([]);
    const navigate = useNavigate();
    const [currentStudentIndex, setCurrentStudentIndex] = useState(0);

    useEffect(() => {
        GetGradeMarksList();
    }, []);

    const GetGradeMarksList = async () => {
        try {
            let data = {
                ClassId: props?.location?.state?.classId,
                CoScholasticId: props?.location?.state?.coScholasticId
            };
            const response = await api.post(APIConstant.path.GetGradeEntryList, data);
            if (response.success) {
                setStudents(response.data);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleNext = () => {
        if (currentStudentIndex < students.length - 1) {
            setCurrentStudentIndex(currentStudentIndex + 1);
        }
    };

    const handlePrevious = () => {
        if (currentStudentIndex > 0) {
            setCurrentStudentIndex(currentStudentIndex - 1);
        }
    };

    const handleGradeUpdate = (studentId, index, term, gradeValue) => {
        var formatedData = students.map((student) =>
            student.StudentId === studentId
                ? {
                    ...student,
                    GradeDetails: student.GradeDetails.map((grade, gradeIndex) =>
                        gradeIndex === index
                            ? {
                                ...grade,
                                Details: grade.Details.map((detail) => ({
                                    ...detail,
                                    Terms: detail.Terms.map((t) =>
                                        t.TermId === term ? { ...t, Grade: gradeValue } : t
                                    ),
                                })),
                            }
                            : grade
                    ),
                }
                : student
        );
        setStudents(formatedData);
    };

    const AddGradeEntryData = async (isGraft) => {
        try {
            const updatedStudents = students?.map((student) => (
                { ...student, IsGraft: isGraft })
            );
            await api.post(APIConstant.path.AddUpdateGradingEntry, updatedStudents).then(async response => {
                let res = response;
                if (res.success) {
                    Toast.success({ message: res.message });
                    navigate('/co-scholastic-grading')
                }
            }).catch(err => {
                console.log(err);
            });
        } catch (err) { console.log(err); }
    };

    return (
        <div className="wayment-owner-dashboard e-weighment-dashboard">
            <div className="heading-area flex justify-between items-center">
                <div className="flex items-center">
                    <i className="pi pi-arrow-left" onClick={() => navigate('/co-scholastic-grading')}></i>
                    <h2 className="ml-2">{props?.location?.state?.className}</h2>
                </div>
            </div>
            <hr />

            <TabView>
                <TabPanel header="INDIVIDUAL">
                    <div>
                        <div className="flex justify-between items-center my-4">
                            {/* <span>ROLL NO.</span>
                            <InputText
                                value={students[currentStudentIndex]?.RollNo || ''}
                                readOnly
                                className="w-16 text-center"
                            /> */}
                            <Paginator
                                first={currentStudentIndex}
                                rows={1}
                                totalRecords={students.length}
                                onPageChange={(e) => setCurrentStudentIndex(e.first)}
                            />
                        </div>
                        {students.length > 0 && (
                            <CoScholasticGradeCard student={students[currentStudentIndex]} onGradeChange={handleGradeUpdate} />
                        )}
                        <div className="flex justify-between mt-4">
                            <Button
                                label="Previous"
                                className="p-button-secondary"
                                onClick={handlePrevious}
                                disabled={currentStudentIndex === 0}
                            />
                            <div className="flex justify-end gap-3 mt-4">
                                <Button label="Cancel" onClick={(e) => navigate('/co-scholastic-grading')} className="p-button-outlined p-button-secondary" />
                                <Button label="Save as Draft" className="p-button-secondary" onClick={(e) => AddGradeEntryData(true)} />
                                <Button label="Submit All" className="p-button-primary" onClick={(e) => AddGradeEntryData(false)} />
                            </div>
                            <Button
                                label="Next"
                                className="p-button-secondary"
                                onClick={handleNext}
                                disabled={currentStudentIndex === students.length - 1}
                            />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel header="COMPARE" />
            </TabView>
        </div>
    );
};

export default CoScholasticGradeEntry;
