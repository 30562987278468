import React, { useState, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { RadioButton } from "primereact/radiobutton";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { MultiSelect } from 'primereact/multiselect';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import { FloatLabel } from "primereact/floatlabel";
import Toast from '../../Shared/Toast/Toast';
import moment from 'moment';


const AddEditFeeStructure = (props) => {
    const [securityUserID, setSecurityUserID] = useState("");
    const [titleName, setTitleName] = useState("");
    const [titleNameErr, setTitleNameErr] = useState(false);
    const [titleNameErrText, setTitleNameErrText] = useState("");
    const [selectedQuota, setSelectedQuota] = useState([]);
    const [selectedClass, setSelectedClass] = useState([]);
    const [classNameErr, setClassNameErr] = useState(false);
    const [classNameErrText, setClassNameErrText] = useState("");
    const [feeData, setFeeData] = useState([]);
    const [feeCollectionMethod, setFeeCollectionMethod] = useState("one-time");
    const [startDateOneTime, setStartDateOneTime] = useState(null);
    const [endDateOneTime, setEndDateOneTime] = useState(null);
    const [startDateInstalment, setStartDateInstalment] = useState(null);
    const [endDateInstalment, setEndDateInstalment] = useState(null);
    const [fineTypeList, setFineTypeList] = useState([{ label: "Daily", value: 'Daily' }, { label: "Weekly", value: 'Weekly' }, { label: "Monthly", value: "Monthly" }]);
    const [quotaList, setQuotaList] = useState([]);
    const [feeList, setFeeList] = useState([]);
    const [classList, setClassList] = useState([]);
    const [fineDataOneTime, setFineDataOneTime] = useState([]);
    const [fineDataInstalment, setFineDataInstalment] = useState([]);
    const [installmentName, setInstallmentName] = useState('');
    const [installmentNameErr, setInstallmentNameErr] = useState(false);
    const [installmentNameErrText, setInstallmentNameErrText] = useState("");
    const [installmentNo, setInstallmentNo] = useState('');
    const [installmentMonth, setInstallmentMonth] = useState([]);
    const [installmentMonthErr, setInstallmentMonthErr] = useState(false);
    const [installmentMonthErrText, setInstallmentMonthErrText] = useState("");
    const [installmentMonthList, setInstallmentMonthList] = useState([]);

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        GetClassList(loginData?._id);
        GetCasteList(loginData?._id);
        GetFeeList(loginData?._id);
        const months = Array.from({ length: 12 }, (_, i) => ({
            label: new Date(2000, i, 1).toLocaleString('en-US', { month: 'long' }),
            value: i + 1
        }));
        setInstallmentMonthList(months);
        let bodyList = [
            { id: '', FeeType: '', AdmissionType: 'All', isEdit: true }
        ];
        let fineList = [
            { id: '', FineType: '', FineAmount: '' }
        ];
        if (props?.configId) {
            let feeListData = props?.masterData?.FeeData.length
                ? props?.masterData?.FeeData.map(x => {
                    let dynamicData = x?.DynamicColumn?.reduce((acc, y) => {
                        acc[y.Key] = y.value;
                        return acc;
                    }, {});

                    return {
                        ...x,
                        ...dynamicData
                    };
                })
                : [];

            setTitleName(props?.masterData?.TitleName);
            setSelectedClass(props?.masterData?.ClassId ? props?.masterData?.ClassId.split(',') : []);
            setSelectedQuota(props?.masterData?.QuotaId ? props?.masterData?.QuotaId.split(',') : []);
            setFeeData(props?.masterData?.FeeData.length ? feeListData : bodyList);
            if (props?.masterData?.FeeCollectionMode === 'one-time') {
                setFineDataOneTime(props?.masterData?.FineData.length ? props?.masterData?.FineData : fineList);
            } else {
                setFineDataInstalment(props?.masterData?.FineData.length ? props?.masterData?.FineData : fineList);
            }
            if (props?.masterData?.FeeCollectionMode === 'one-time') {
                setStartDateOneTime(props?.masterData?.StartDate ? new Date(props?.masterData?.StartDate) : null);
                setEndDateOneTime(props?.masterData?.EndDate ? new Date(props?.masterData?.EndDate) : null);
            } else {
                setStartDateInstalment(props?.masterData?.StartDate ? new Date(props?.masterData?.StartDate) : null);
                setEndDateInstalment(props?.masterData?.EndDate ? new Date(props?.masterData?.EndDate) : null);
            }
            setFeeCollectionMethod(props?.masterData?.FeeCollectionMode || 'one-time');
            setInstallmentMonth(props?.masterData?.InstallmentMonth ? props?.masterData?.InstallmentMonth.split(',') : []);
            setInstallmentName(props?.masterData?.InstallmentName);
            setInstallmentNo(props?.masterData?.InstallmentNo);
        } else {
            setFeeData(bodyList);
            if (props?.masterData?.FeeCollectionMode === 'one-time') {
                setFineDataOneTime(fineList);
            } else {
                setFineDataInstalment(fineList);

            }
        }
    }, []);

    const GetClassList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.getClassList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setClassList(
                        Array.from(
                            new Map(
                                res?.data?.filter(x => x?.Status === 'Active')?.map((x) => [
                                    `${x?.ClassName}-${x?.MediumName}-${x?.Board}`,
                                    {
                                        ...x,
                                        value: x?.ClassId,
                                        label: `${x?.ClassName}-${x?.MediumName}-${x?.Board}`
                                    }
                                ])
                            ).values()
                        )
                    );
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const GetCasteList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.getCasteList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setQuotaList(res?.data?.map(x => ({
                        label: x?.CasteName,
                        value: x?._id
                    })));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const GetFeeList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.getFeeList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setFeeList(res?.data?.map(x => ({
                        label: x?.FeeName,
                        value: x?._id
                    })));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const toggleEdit = (rowIndex) => {
        const updatedFee = feeData?.map((item, idx) =>
            idx === rowIndex ? { ...item, isEdit: !item.isEdit } : item
        );
        setFeeData(updatedFee);
    };

    const deleteDataRow = (index, type) => {
        if (type === 'Fee') {
            const updatedFee = feeData?.filter((_, i) => i !== index);
            setFeeData(updatedFee);
        } else if (type === 'Fine') {
            if (feeCollectionMethod === "one-time") {
                const updatedFine = fineDataOneTime?.filter((_, i) => i !== index);
                setFineDataOneTime(updatedFine);
            } else {
                const updatedFine = fineDataInstalment?.filter((_, i) => i !== index);
                setFineDataInstalment(updatedFine);
            }
        }
    };

    const srNoBodyTemplateSrNo = (data, props) => {
        return props.rowIndex + 1;
    };

    const addDataValue = (type) => {
        if (type === 'Fee') {
            let values = [{ id: '', FeeType: '', AdmissionType: 'All', isEdit: true }];
            setFeeData([...feeData, ...values]);
        } else if (type === 'Fine') {
            if (feeCollectionMethod === "one-time") {
                let values = [{ id: '', FineType: '', FineAmount: '' }];
                setFineDataOneTime([...fineDataOneTime, ...values]);
            } else {
                let values = [{ id: '', FineType: '', FineAmount: '' }];
                setFineDataInstalment([...fineDataInstalment, ...values]);
            }
        }
    };

    const actionBodyTemplate = (rows, { rowIndex }) => {
        return (
            <React.Fragment>
                <div className="p-d-flex p-jc-start">
                    {rows.isEdit ? (
                        <Button icon="pi pi-check" className="e-sction-btn p-m-0 p-mt-3" onClick={(e) => toggleEdit(rowIndex)} />
                    ) : (
                        <Button icon="pi pi-pencil" className="e-sction-btn p-m-0 p-mt-3" onClick={(e) => toggleEdit(rowIndex)} />
                    )}
                    {rowIndex === feeData.length - 1 &&
                        <Button
                            icon="pi pi-plus"
                            className="e-sction-btn p-mr-0 p-my-0 p-ml-2"
                            onClick={() => addDataValue('Fee')}
                        />}
                    {(rowIndex !== 0 || feeData.length > 1) &&
                        <Button icon="pi pi-trash" className="e-sction-btn p-my-0 p-mt-3 p-ml-2"
                            onClick={() => deleteDataRow(rowIndex, 'Fee')}
                        />
                    }
                </div>
            </React.Fragment>
        );
    };

    const handleChange = (rowIndex, value, type) => {
        const updatedFeeValues = [...feeData];
        var textValue = value;
        if (type !== 'FeeType') {
            textValue = value.replace(/[^0-9]/g, '');
        } else {
            let feeName = feeList?.find(q => q?.value === value)?.label;
            updatedFeeValues[rowIndex]['FeeTypeName'] = feeName;
        }
        updatedFeeValues[rowIndex][type] = textValue;
        setFeeData(updatedFeeValues);
    };

    const feeTypeBodyTemplate = (rows, { rowIndex }) => {
        return (
            <React.Fragment>
                <div className='p-d-flex p-ai-center'>
                    {rows?.isEdit ? (
                        <Dropdown
                            className="p-w-100 p-mt-1"
                            name='feeType'
                            inputId='feeType'
                            value={rows?.FeeType}
                            options={feeList}
                            onChange={(e) => handleChange(rowIndex, e.value, 'FeeType')}
                            placeholder="Select Fee Type"
                        />
                    ) : <div>{rows?.FeeTypeName} </div>}
                </div>
            </React.Fragment>
        );
    };

    const casteBodyTemplate = (rowData, type, { rowIndex }) => {
        return (
            <React.Fragment>
                <div className='p-d-flex p-ai-center'>
                    {rowData?.isEdit ? (
                        <InputText
                            value={rowData?.[type]}
                            maxLength={4}
                            onChange={(e) => handleChange(rowIndex, e.target.value, type)}
                            style={{ width: "60px", textAlign: "center" }}
                        />
                    ) : <div>{rowData?.[type]} </div>}
                </div>
            </React.Fragment>
        );
    };

    const handleFineChange = (rowIndex, value, type) => {
        if (feeCollectionMethod === "one-time") {
            const updatedFineValues = [...fineDataOneTime];
            let textValue = value;
            if (type === 'FineAmount') {
                textValue = value.replace(/[^0-9]/g, '');
            }
            updatedFineValues[rowIndex][type] = textValue;
            setFineDataOneTime(updatedFineValues);
        } else {
            const updatedFineValues = [...fineDataInstalment];
            let textValue = value;
            if (type === 'FineAmount') {
                textValue = value.replace(/[^0-9]/g, '');
            }
            updatedFineValues[rowIndex][type] = textValue;
            setFineDataInstalment(updatedFineValues);
        }
    };

    const onCancel = () => {
        props.onCancel();
        setTitleName('');
        setSelectedClass([]);
        setSelectedQuota([]);
        setFeeData([]);
        setFineDataOneTime([]);
        setFineDataInstalment([]);
        setStartDateOneTime(null);
        setEndDateOneTime(null);
        setStartDateInstalment(null);
        setEndDateInstalment(null);
    }

    const validate = () => {
        let formIsValid = true;
        if (CommonConfig.isEmpty(titleName)) {
            formIsValid = false;
            setTitleNameErr(true);
            setTitleNameErrText("Title Name is required");
        } else {
            setTitleNameErr(false);
            setTitleNameErrText("");
        }
        if (selectedClass.length === 0) {
            formIsValid = false;
            setClassNameErr(true);
            setClassNameErrText("Select atleast one class");
        } else {
            setClassNameErr(false);
            setClassNameErrText("");
        }
        if (feeCollectionMethod === 'installments') {
            if (CommonConfig.isEmpty(installmentName)) {
                formIsValid = false;
                setInstallmentNameErr(true);
                setInstallmentNameErrText("Installemnt Name is required");
            } else {
                setInstallmentNameErr(false);
                setInstallmentNameErrText("");
            }
            if (installmentMonth.length === 0) {
                formIsValid = false;
                setInstallmentMonthErr(true);
                setInstallmentMonthErrText("Select atleast one month");
            } else {
                setInstallmentMonthErr(false);
                setInstallmentMonthErrText("");
            }
        }
        return formIsValid;
    };

    const AddUpdateFeeStructure = async (e) => {
        if (validate(e)) {
            try {
                Loader.show();
                var feeListData = [];

                if (feeData) {
                    feeListData = feeData.map((x, idx) => {
                        return {
                            ...x,
                            DynamicColumn: Object.keys(x).filter(z => z !== 'AdmissionType' && z !== 'FeeType' && z !== 'FeeTypeName' && z !== 'isEdit' && z !== '_id').map(y => ({
                                Key: y,
                                value: x[y]
                            })),
                        }
                    })
                }
                let data = {
                    id: props?.configId,
                    TitleName: titleName,
                    ClassId: selectedClass.length > 1 ? selectedClass.join(',') : selectedClass[0],
                    QuotaId: selectedQuota.length > 1 ? selectedQuota.join(',') : selectedQuota[0],
                    FeeData: feeListData,
                    FeeCollectionMode: feeCollectionMethod,
                    StartDate: feeCollectionMethod === "one-time" ? (startDateOneTime ? moment(startDateOneTime).format("YYYY-MM-DD HH:MM") : null) : (startDateInstalment ? moment(startDateInstalment).format("YYYY-MM-DD HH:MM") : null),
                    EndDate: feeCollectionMethod === "one-time" ? (endDateOneTime ? moment(endDateOneTime).format("YYYY-MM-DD HH:MM") : null) : (endDateInstalment ? moment(endDateInstalment).format("YYYY-MM-DD HH:MM") : null),
                    InstallmentName: feeCollectionMethod === "one-time" ? '' : installmentName,
                    InstallmentNo: feeCollectionMethod === "one-time" ? '' : installmentNo,
                    InstallmentMonth: feeCollectionMethod === "one-time" ? '' : installmentMonth.length > 1 ? installmentMonth.join(',') : installmentMonth[0],
                    FineData: feeCollectionMethod === "one-time" ? fineDataOneTime : fineDataInstalment,
                    CreatedBy: securityUserID,
                };
                await api
                    .post(APIConstant.path.AddUpdateFeeStructure, data)
                    .then((response) => {
                        let res = response;
                        if (res.success) {
                            Toast.success({ message: res.message });
                            Loader.hide();
                            onCancel();
                        } else {
                            Toast.error({ message: res.message });
                            Loader.hide();
                            onCancel();
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        Loader.hide();
                        onCancel();
                    });
            } catch (err) {
                console.log(err);
                Toast.error({ message: err });
                Loader.hide();
                onCancel();
            }
        }
    };

    return (
        <div className="p-4 fee-management-container">
            <div className="p-grid">
                <div className="p-col-12 p-md-6">
                    <label htmlFor="titleName">Title Name<span style={{ color: "red" }}>*</span></label>
                    <FloatLabel>
                        <InputText
                            className='p-mt-1 p-w-100'
                            id="titleName"
                            maxLength={20}
                            value={titleName}
                            onChange={(e) => setTitleName(e.target.value)}
                        />
                    </FloatLabel>
                    <span className="error-msg" style={{ color: 'red' }}>
                        {titleNameErr ? titleNameErrText : null}
                    </span>
                </div>
                <div className="p-col-12 p-md-6">
                    <label htmlFor="className">Class<span style={{ color: "red" }}>*</span></label>
                    <MultiSelect
                        id="className"
                        value={selectedClass} options={classList}
                        onChange={(e) => setSelectedClass(e.value)}
                        placeholder="Select Class"
                        display="chip"
                        className='p-w-100 p-mt-1'
                        filter />
                    <span className="error-msg" style={{ color: 'red' }}>
                        {classNameErr ? classNameErrText : null}
                    </span>
                </div>
                <div className="p-col-12 p-md-7">
                    <label htmlFor="className">Do you want to add Quota?</label>
                    <div className="p-d-flex p-ai-center p-jc-between">
                        <MultiSelect
                            id="quota"
                            value={selectedQuota} options={quotaList}
                            onChange={(e) => setSelectedQuota(e.value)}
                            placeholder="Select Quota"
                            display="chip"
                            className='p-w-100'
                            filter />
                        <Button icon="pi pi-plus" className="e-btn p-my-0 p-ml-1" />
                    </div>
                </div>
            </div>

            <DataTable value={feeData} responsiveLayout="scroll" className="mt-4 fee-table">
                <Column body={srNoBodyTemplateSrNo} header="Sr No." />
                <Column body={feeTypeBodyTemplate} header="Fee Type" />
                <Column field="AdmissionType" header="Admission Type" />
                {selectedQuota?.map((quota) => (
                    <Column key={quota} body={(rowData, rowIndex) => casteBodyTemplate(rowData, (quotaList?.find(q => q.value === quota)?.label), rowIndex)} header={quotaList?.find(q => q.value === quota)?.label} />
                ))}
                <Column header="Action" body={actionBodyTemplate} />
            </DataTable>

            <div className="form-header p-mt-2">
                <div>
                    <span>Fee Collection Method*</span>
                    <div className="radio-group p-mt-2 p-ml-2">
                        <RadioButton
                            inputId="one-time"
                            name="feeCollection"
                            value="one-time"
                            onChange={(e) => setFeeCollectionMethod(e.value)}
                            checked={feeCollectionMethod === "one-time"}
                        />
                        <label htmlFor="one-time" className="radio-label">One time</label>
                        <div className="p-ml-5">
                            <RadioButton
                                inputId="installments"
                                name="feeCollection"
                                value="installments"
                                onChange={(e) => setFeeCollectionMethod(e.value)}
                                checked={feeCollectionMethod === "installments"}
                            />
                            <label htmlFor="installments" className="radio-label p-ml-2">Installments</label>
                        </div>
                    </div>
                </div>
            </div>
            {feeCollectionMethod === "one-time" ? (
                <>
                    <div className="p-grid">
                        <div className="p-col-6 p-md-6">
                            <label htmlFor="startDateTime">Start Date & Time</label>
                            <div>
                                <Calendar
                                    className='p-w-100 p-mt-0'
                                    id="startDateTime"
                                    onChange={(e) => setStartDateOneTime(e.value)} placeholder="DD/MM/YYYY - HH:MM" showTime
                                    value={startDateOneTime}
                                    hideOnDateTimeSelect
                                    hourFormat="12"
                                    dateFormat="dd/mm/yy"
                                />
                            </div>
                        </div>
                        <div className="p-col-6 p-md-6">
                            <label htmlFor="endDateTime">End Date & Time</label>
                            <div>
                                <Calendar
                                    className='p-w-100 p-mt-0'
                                    id="endDateTime"
                                    onChange={(e) => setEndDateOneTime(e.value)}
                                    placeholder="DD/MM/YYYY - HH:MM"
                                    showTime
                                    hideOnDateTimeSelect
                                    value={endDateOneTime}
                                    hourFormat="12"
                                    dateFormat="dd/mm/yy"
                                />
                            </div>
                        </div>
                    </div>
                </>) : (
                <>
                    <div className="p-grid">
                        <div className="p-col-6 p-md-6">
                            <label htmlFor="installmentName">Installment Name<span style={{ color: "red" }}>*</span></label>
                            <FloatLabel>
                                <InputText
                                    className='p-mt-1 p-w-100'
                                    id="installmentName"
                                    maxLength={20}
                                    value={installmentName}
                                    onChange={(e) => setInstallmentName(e.target.value)}
                                />
                            </FloatLabel>
                            <span className="error-msg" style={{ color: 'red' }}>
                                {installmentNameErr ? installmentNameErrText : null}
                            </span>
                        </div>
                        <div className="p-col-6 p-md-6">
                            <label htmlFor="installmentNo">Installment No.</label>
                            <FloatLabel>
                                <InputText
                                    className='p-mt-1 p-w-100'
                                    id="installmentNo"
                                    maxLength={3}
                                    value={installmentNo}
                                    onChange={(e) => setInstallmentNo(e.target.value)}
                                />
                            </FloatLabel>
                        </div>
                        <div className="p-col-6">
                            <label htmlFor="installmentMonth">Select Months<span style={{ color: "red" }}>*</span></label>
                            <MultiSelect
                                id="installmentMonth"
                                value={installmentMonth}
                                options={installmentMonthList}
                                onChange={(e) => setInstallmentMonth(e.value)}
                                placeholder="Select"
                                className='p-w-100'
                                filter
                            />
                            <span className="error-msg" style={{ color: 'red' }}>
                                {installmentMonthErr ? installmentMonthErrText : null}
                            </span>
                        </div>
                        <div className="p-col-6 p-md-6">
                            <label htmlFor="startDateTime">Start Date & Time</label>
                            <div>
                                <Calendar
                                    className='p-w-100 p-mt-0'
                                    id="startDateTime"
                                    onChange={(e) => setStartDateInstalment(e.value)}
                                    placeholder="DD/MM/YYYY" showTime
                                    value={startDateInstalment}
                                    hideOnDateTimeSelect
                                    hourFormat="12"
                                    dateFormat="dd/mm/yy"
                                />
                            </div>
                        </div>
                        <div className="p-col-6 p-md-6">
                            <label htmlFor="endDateTime">End Date & Time</label>
                            <div>
                                <Calendar
                                    className='p-w-100 p-mt-0'
                                    id="endDateTime"
                                    onChange={(e) => setEndDateInstalment(e.value)}
                                    placeholder="DD/MM/YYYY - HH:MM"
                                    showTime
                                    hideOnDateTimeSelect
                                    value={endDateInstalment}
                                    hourFormat="12"
                                    dateFormat="dd/mm/yy"
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}
            <div>
                <div className="p-d-flex p-ai-center">
                    <div className="fee-collection-container">
                        <span>Do you want to add Fine?</span>
                    </div>
                    <Button icon="pi pi-plus" className="e-border-btn p-mr-0 p-my-0 p-ml-2" onClick={e => addDataValue('Fine')} />
                </div>
                {(feeCollectionMethod === "one-time" ? fineDataOneTime : fineDataInstalment).map((data, idx) => (
                    <div key={idx} className="p-card p-mt-3">
                        <div className="p-grid p-ai-center p-p-3">
                            <div className="p-col-6">
                                <label htmlFor="fineType">Fine Type</label>
                                <Dropdown
                                    id="fineType"
                                    value={data?.FineType}
                                    options={fineTypeList}
                                    onChange={(e) => handleFineChange(idx, e.value, 'FineType')}
                                    placeholder="Daily/Weekly/Monthly"
                                    className='p-w-100'
                                    filter
                                />
                            </div>
                            <div className="p-col-6">
                                <label htmlFor="fineAmount">Fine Amount</label>
                                <InputText
                                    id="fineAmount"
                                    placeholder="Enter Fine Amount"
                                    maxLength={4}
                                    value={data?.FineAmount}
                                    onChange={(e) => handleFineChange(idx, e.target.value, 'FineAmount')}
                                    className='p-w-100'
                                />
                            </div>
                            {(idx !== 0 || (feeCollectionMethod === "one-time" ? fineDataOneTime : fineDataInstalment).length > 1) &&
                                <div className="p-col-12 p-d-flex p-ai-center p-jc-center p-m-0 p-w-100">
                                    <Button icon="pi pi-trash" className="p-d-flex p-ai-center p-jc-center p-m-0 p-button-rounded p-button-danger" onClick={() => deleteDataRow(idx, 'Fine')} />
                                </div>
                            }
                        </div>
                    </div>
                ))}
            </div>
            <div className="dialog-footer1 p-d-flex p-ai-center p-jc-end">
                <Button className='p-m-0 primary-btn1 p-m-1' label="Cancel" onClick={(e) => onCancel()} />
                <Button label="Save" icon="pi pi-check" className="p-m-0 btn-text-action-primary" onClick={(e) => AddUpdateFeeStructure()} />
            </div>

        </div>
    );
};

export default AddEditFeeStructure;
