import React, { useState, useEffect, useRef } from 'react';
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";
import { InputText } from "primereact/inputtext";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import moment from 'moment';
import placeHolder from '../../../assets/images/PlaceHolder.webp';
import Toast from '../../Shared/Toast/Toast';
import { apiBase } from '../../../utils/config';
import DeleteConfirmation from '../../Dialogs/DeleteConfirmation';
import { Dialog } from 'primereact/dialog';

const AddEditAcademicCalender = (props) => {
    const [securityUserID, setSecurityUserID] = useState("");
    const importFileUploadRef = useRef(null);

    const [formData, setFormData] = useState({
        academicYearName: "",
        academicYearNameErr: false,
        academicYearNameErrText: '',
        startDate: null,
        startDateErr: false,
        startDateErrText: '',
        endDate: null,
        endDateErr: false,
        endDateErrText: '',
        classesApplicable: [],
        importURL: "",
        importFile: null,
    });
    const [classList, setClassList] = useState([]);
    const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);
    const handleInputChange = (key, value) => {
        setFormData((prev) => ({ ...prev, [key]: value }));
    };

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        GetClassList(loginData?._id);
        if (props?.configId) {
            setFormData((prev) => ({ ...prev, academicYearName: props?.masterData?.TitleName, startDate: props?.masterData?.StartDate ? new Date(props?.masterData?.StartDate) : null, endDate: props?.masterData?.EndDate ? new Date(props?.masterData?.EndDate) : null, classesApplicable: props?.masterData?.ApplicableClass?.split(',') || [], importURL: props?.masterData?.ImportUrl, importFile: props?.masterData?.ImportFile ? `${apiBase}/Documents/${props?.masterData?.ImportFile}` : null }));
        }
    }, []);

    const handleClose = () => {
        setIsDeleteConfirmation(false)
    };

    const GetClassList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.getClassList, data).then(async response => {
                let res = response;
                if (res.success) {
                    let templateData = props?.templateFullData || [];
                    let configId = props?.configId;
                    let applicableClassIds = templateData
                        .filter((data) => data._id !== configId)
                        .flatMap((data) => data?.ApplicableClass?.split(',') || []);
                    let classData = res?.data?.filter((x) => !applicableClassIds?.includes(x?._id));
                    let filteredData = classData?.filter(x => x?.Status === 'Active')?.map((x) => ({
                        value: x?._id,
                        label: `${x?.ClassName}-${x?.SectionFullName.charAt(0)}-${x?.MediumName}-${x?.Board}`
                    }));
                    setClassList(filteredData);

                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const validate = () => {
        let formIsValid = true;
        if (CommonConfig.isEmpty(formData?.academicYearName)) {
            formIsValid = false;
            setFormData((prev) => ({ ...prev, academicYearNameErr: true, academicYearNameErrText: 'Name is required' }));
        } else {
            setFormData((prev) => ({ ...prev, academicYearNameErr: false, academicYearNameErrText: '' }));
        }
        if (CommonConfig.isEmpty(formData?.startDate)) {
            formIsValid = false;
            setFormData((prev) => ({ ...prev, startDateErr: true, startDateErrText: 'Start date is required' }));
        } else {
            setFormData((prev) => ({ ...prev, startDateErr: false, startDateErrText: '' }));
        }
        if (CommonConfig.isEmpty(formData?.endDate)) {
            formIsValid = false;
            setFormData((prev) => ({ ...prev, endDateErr: true, endDateErrText: 'End Date is required' }));
        } else {
            setFormData((prev) => ({ ...prev, endDateErr: false, endDateErrText: '' }));
        }
        return formIsValid;
    };

    const onCancel = () => {
        props.onCancel();
    }
    const AddUpdateTemplate = async (e) => {
        if (validate(e)) {
            try {

                const dataForm = new FormData();
                dataForm.append("id", props?.masterData?._id ?? '');
                dataForm.append("TitleName", formData?.academicYearName);
                dataForm.append("StartDate", formData?.startDate ? moment(formData?.startDate).format("YYYY-MM-DD") : '');
                dataForm.append("EndDate", formData?.endDate ? moment(formData?.endDate).format("YYYY-MM-DD") : '');
                dataForm.append("ApplicableClass", formData?.classesApplicable?.length > 1 ? formData?.classesApplicable?.join(',') : formData?.classesApplicable[0]);
                dataForm.append("ImportUrl", formData?.importURL);
                dataForm.append("CreatedBy", securityUserID);
                if (formData?.importFile && formData?.importFile.type) {
                    dataForm.append("ImportFile", formData?.importFile);
                }
                Loader.show();

                await api
                    .post(APIConstant.path.AddUpdateAcademicCalander, dataForm, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then((response) => {
                        let res = response;
                        if (res.success) {
                            Toast.success({ message: res.message });
                            Loader.hide();
                            onCancel();
                        } else {
                            Toast.error({ message: res.message });
                            Loader.hide();
                            onCancel();
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        Toast.error({ message: err?.data?.message });
                        Loader.hide();
                        onCancel();
                    });
            } catch (err) {
                console.log(err);
                Toast.error({ message: err });
                Loader.hide();
                onCancel();
            }
        } else {
            Toast.error({ message: "bfsdjkfdskj" });
        }
    };

    const handleFileUpload = (e) => {
        const selectedFile = e.files[0];
        if (selectedFile) {
            const validFormats = ['image/jpeg', 'image/png', 'image/jpg', 'image/svg'];
            if (validFormats.includes(selectedFile.type)) {
                const img = new Image();
                img.onload = () => {
                    setFormData((prev) => ({ ...prev, importFile: selectedFile }));
                    const fileURL = URL.createObjectURL(selectedFile);
                    setFormData((prev) => ({ ...prev, previewImportFile: fileURL }));
                };
                img.onerror = () => {
                    if (importFileUploadRef.current) {
                        importFileUploadRef.current.clear();
                    }
                    Toast.error({ message: 'Error loading the image.' });
                };

                const reader = new FileReader();
                reader.onload = (event) => {
                    img.src = event.target.result;
                };
                reader.readAsDataURL(selectedFile);
            } else {
                if (importFileUploadRef.current) {
                    importFileUploadRef.current.clear();
                }
                Toast.error({ message: 'Invalid file format. Please select a JPEG, SVG or PNG image.' });
            }
        }
    };

    const renderFooter = () => (
        <div className="dialog-footer1 p-d-flex p-ai-center p-jc-end">
            <Button
                label="Cancel"
                className="primary-btn1"
                onClick={handleClose}
            />
            <Button
                label="Delete"
                className="primary-btn"
                onClick={() => {
                    handleClose();
                    setFormData((prev) => ({ ...prev, previewImportFile: '', importFile: '' }));
                    if (importFileUploadRef.current) {
                        importFileUploadRef.current.clear();
                    }
                }}
            />
        </div>
    );

    const getDateOnly = (date) => {
        return date ? new Date(date.getFullYear(), date.getMonth(), date.getDate()) : null;
    };

    const handleStartChange = (value) => {
        const startDate = getDateOnly(value);
        const endDate = getDateOnly(formData.endDate);

        if (endDate && startDate > endDate) {
            setFormData((prev) => ({ ...prev, startDateErr: true, startDateErrText: "'Start' date cannot be later than 'End' date." }));
        } else if (endDate && startDate.getDate() === endDate.getDate()) {
            setFormData((prev) => ({ ...prev, startDateErr: true, startDateErrText: "'Start' date cannot be the same as 'End' date." }));
        } else {
            setFormData((prev) => ({ ...prev, startDateErr: false, startDateErrText: '' }));
        }
        setFormData((prev) => ({ ...prev, startDate: value }));
    };

    const handleEndChange = (value) => {
        const startDate = getDateOnly(formData?.startDate);
        const endDate = getDateOnly(value);

        if (startDate && endDate < startDate) {
            setFormData((prev) => ({ ...prev, endDateErr: true, endDateErrText: "'End' date cannot be earlier than 'Start' date." }));
        } else if (startDate && startDate.getDate() === endDate.getDate()) {
            setFormData((prev) => ({ ...prev, endDateErr: true, endDateErrText: "'End' date cannot be the same as 'Start' date." }));
        } else {
            setFormData((prev) => ({ ...prev, endDateErr: false, endDateErrText: '' }));
        }
        setFormData((prev) => ({ ...prev, endDate: value }));
    };


    return (
        <div className="p-d-flex p-flex-column p-jc-between">
            <div className='p-grid'>
                <div className="p-col-12 p-md-4 p-pt-2">
                    <div className="">
                        <label htmlFor="academicYearName">Academic Year Name</label>
                        <div className="custom-inputs">
                            <InputText
                                id="academicYearName"
                                value={formData?.academicYearName}
                                maxLength={15}
                                onChange={(e) => handleInputChange("academicYearName", e.target.value)}
                            />
                            <span className="error-msg" style={{ color: 'red' }}>
                                {formData?.academicYearNameErr ? formData?.academicYearNameErrText : null}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="p-col-12 p-md-4 p-pt-2">
                    <div className="">
                        <label htmlFor="startDate">Start Date</label>
                        <div className="custom-inputs">
                            <Calendar
                                className='p-w-100 p-mt-0'
                                id="startDate"
                                value={formData.startDate}
                                onChange={(e) => handleStartChange(e.value)}
                                hourFormat="12"
                                dateFormat="dd/mm/yy"
                            />
                            <span className="error-msg" style={{ color: 'red' }}>
                                {formData?.startDateErr ? formData?.startDateErrText : null}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="p-col-12 p-md-4 p-pt-2">
                    <div className="">
                        <label htmlFor="endDate">End Date</label>
                        <div className="custom-inputs">
                            <Calendar
                                className='p-w-100'
                                id="endDate"
                                value={formData.endDate}
                                onChange={(e) => handleEndChange(e.value)}
                                hourFormat="12"
                                dateFormat="dd/mm/yy"
                            />
                            <span className="error-msg" style={{ color: 'red' }}>
                                {formData?.endDateErr ? formData?.endDateErrText : null}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="p-col-12 p-md-4 p-pt-2">
                    <div className="">
                        <label htmlFor="classesApplicable">Classes Applicable</label>
                        <div className="custom-inputs">
                            <MultiSelect
                                id="classesApplicable"
                                value={formData?.classesApplicable}
                                options={classList}
                                onChange={(e) => handleInputChange("classesApplicable", e.value)}
                                placeholder="Select Classes"
                                display="chip"
                                className='p-w-100'
                                filter
                            />
                        </div>
                    </div>
                </div>
                <div className="p-col-12 p-md-4 p-pt-2">
                    <div className="">
                        <label htmlFor="importURL">Import URL</label>
                        <div className="custom-inputs p-w-100">
                            <InputText
                                className='p-w-100'
                                id="importURL"
                                value={formData.importURL}
                                onChange={(e) => handleInputChange("importURL", e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="p-col-12 p-md-4 p-pt-2">
                    <div>
                        <label htmlFor="importFile">Import File</label>
                        <div className="p-d-flex p-flex-column p-jc-around">
                            <img src={formData?.previewImportFile || formData?.importFile || placeHolder} alt="" className="upload-preview" style={{ width: '50px', height: '50px', borderRadius: '8px', objectFit: 'cover' }} />
                            <div className='p-d-flex p-ai-center user-profile-btn p-mt-3'>
                                {!formData?.previewImportFile && <FileUpload ref={importFileUploadRef} name='importFile' customUpload auto mode="basic" uploadHandler={handleFileUpload} className="small-file-upload" accept="image/*" />
                                }
                                {(!CommonConfig.isEmpty(formData?.importFile) || !CommonConfig.isEmpty(formData?.previewImportFile)) && (
                                    <div
                                        className="error-msg-alert p-ml-2"
                                        style={{
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            setIsDeleteConfirmation(true);
                                        }}
                                    >
                                        <i className="pi pi-trash" style={{ fontSize: "16px", color: "red" }}></i>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className='dialog-footer1 p-d-flex p-ai-center p-jc-end'>
                <Button className='p-m-0 primary-btn1 p-m-1' onClick={(e) => onCancel()}> Cancel </Button>
                <Button className='p-m-0 primary-btn' label="Submit" severity="secondary" raised onClick={(e) => AddUpdateTemplate()} />
            </div>
            <Dialog
                visible={isDeleteConfirmation}
                onHide={handleClose}
                header={
                    <div className="p-d-flex p-jc-between p-ai-center">
                        <span>Delete Confirmation</span>
                    </div>
                }
                footer={renderFooter()}
                draggable={false}
                dismissableMask
                style={{ width: '450px' }}
            >
                <DeleteConfirmation />
            </Dialog>
        </div>

    );
};

export default AddEditAcademicCalender;
