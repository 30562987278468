import { Card } from 'primereact/card';
import React, { useState, useEffect } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import api from "../../../utils/apiClient";
import APIConstant from "../../../utils/pathConstants";
import userImage from '../../../assets/images/UserImage.png';
import CommonConfig from '../../../utils/constant';

export default function CoScholasticGradeCard(props) {

    const gradeDetails = props?.student?.GradeDetails || [];
    const [selectedGrades, setSelectedGrades] = useState({});
    const [gradeList, setGradeList] = useState([]);

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        GetGradeList(loginData?.CreatedBy);
    }, []);

    const handleGradeChange = (e, index, term) => {
        props.onGradeChange(props.student.StudentId, index, term, e.value);

    };

    const uniqueTypes = [...new Set(gradeDetails.map((g) => g.Type))];

    const GetGradeList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.getGradeList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setGradeList(
                        res?.data
                            ?.filter((y) => y?.IsCoScholastic)
                            .flatMap((x) =>
                                x?.GradeTemplateValues?.map((valueItem) => ({
                                    value: valueItem?._id || x?._id,
                                    label: valueItem?.GradeName || x?.GradeTemplateName,
                                }))
                            )
                    );
                }
            }).catch(err => {
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    return (
        <div className="p-4">
            <hr className='p-m-0' />
            <div className="flex items-center gap-4 p-4 border-b mb-4">
                <img src={props?.student?.ProfilePic || userImage} alt="" className="upload-preview" style={{ width: '100px', height: '100px', borderRadius: '8px', objectFit: 'cover' }} />
                <div>
                    <h3 className="text-lg font-semibold">{props?.student?.StudentName || "Unknown Student"}</h3>
                    <p className="text-gray-500">Roll No: {props?.student?.RollNo || "N/A"}</p>
                </div>
            </div>

            {uniqueTypes.map((section) => {
                const filteredData = gradeDetails.filter((g) => g.Type === section);
                const uniqueTerms = [
                    ...new Set(filteredData.flatMap((g) => g.Details.flatMap((d) => d.Terms.map((t) => t.TermName)))),
                ];
console.log('fdfdsf: ', uniqueTerms);

                return (
                    <Card key={section} className="mb-4">
                        <h3 className="text-lg font-semibold border-b pb-2">{section}</h3>
                        <DataTable value={filteredData.flatMap((g) => g.Details)} responsiveLayout="scroll">
                            <Column header="Sr No." body={(rowData, { rowIndex }) => rowIndex + 1} />
                            <Column field="CoScholasticName" header="Name" />

                            {uniqueTerms.map((termName) => (
                                <Column
                                    key={termName}
                                    header={termName}
                                    body={(rowData, { rowIndex }) => {
                                        const term = rowData.Terms.find((t) => t.TermName === termName);
                                        return (
                                            <Dropdown
                                                value={term?.Grade}
                                                options={gradeList}
                                                onChange={(e) => handleGradeChange(e, rowIndex, term?.TermId)}
                                                placeholder="Select Grade"
                                            />
                                        );
                                    }}
                                />
                            ))}
                        </DataTable>
                    </Card>
                );
            })}
        </div>
    );
}
