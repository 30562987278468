import React, { useState, useEffect, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { RadioButton } from 'primereact/radiobutton';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { MultiSelect } from 'primereact/multiselect';
import { TabView, TabPanel } from 'primereact/tabview';
import '../../../assets/css/teacherMasterDetail.css';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import { InputTextarea } from 'primereact/inputtextarea';
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import Toast from '../../Shared/Toast/Toast';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { apiBase } from '../../../utils/config';
import { Dialog } from 'primereact/dialog';
import DeleteConfirmation from '../../Dialogs/DeleteConfirmation';
import UploadFile from '../../UploadFile/UploadFile';


const ViewEditStudentDetails = (props) => {
    const navigate = useNavigate();
    const fileUploadRef = useRef(null);
    const [securityUserID, setSecurityUserID] = useState("");
    const [personalDetailId, setPersonalDetailId] = useState('');
    const [fullName, setFullName] = useState("");
    const [fullNameErr, setFullNameErr] = useState(false);
    const [fullNameErrText, setFullNameErrText] = useState("");
    const [surName, setSurName] = useState('');
    const [surNameErr, setSurNameErr] = useState(false);
    const [surNameErrText, setSurNameErrText] = useState('');
    const [fName, setFName] = useState('');
    const [fNameErr, setFNameErr] = useState(false);
    const [fNameErrtext, setFNameErrText] = useState('');
    const [mName, setMName] = useState('');
    const [mNameErr, setMNameErr] = useState(false);
    const [mNameErrText, setMNameErrText] = useState('');
    const [studentId, setStudentId] = useState("");
    const [studentIdErr, setStudentIdErr] = useState(false);
    const [studentIdErrText, setStudentIdErrText] = useState('');
    const [mobileNumber, setMobileNumber] = useState("");
    const [mobileNumberErr, setMobileNumberErr] = useState(false);
    const [mobileNumberErrText, setMobileNumberErrText] = useState("");
    const [alternateMobileNumber, setAlternateMobileNumber] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [selectedGender, setSelectedGender] = useState(null);
    const [selectedBloodGroup, setSelectedBloodGroup] = useState(null);
    const [selectedNationality, setSelectedNationality] = useState(null);
    const [selectedReligion, setSelectedReligion] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [languages, setLanguages] = useState([]);
    const [aadharNo, setAadharNo] = useState('');
    const [emailIdErrText, setEmailIdErrText] = useState('');
    const [emailIdErr, setEmailIdErr] = useState(false);
    const [aadharOTP, setAadharOTP] = useState('');
    const [isAadharVerify, setIsAadharVerify] = useState(false);
    const [dob, setDob] = useState('');
    const [doa, setDoa] = useState('');
    const [age, setAge] = useState('');
    const [pinCode, setPinCode] = useState('');
    const [currentAddress, setCurrentAddress] = useState('');
    const [permanentAddress, setPermanentAddress] = useState('');
    const [oldPermanentAddress, setOldPermanentAddress] = useState('');
    const [quotaId, setQuotaId] = useState('');
    const [admissionType, setAdmissionType] = useState('');
    const [applicationNo, setApplicationNo] = useState('');
    const genderOptions = [{ label: 'Male', value: 'male' }, { label: 'Female', value: 'female' }];
    const nationalityOptions = [{ label: 'Indian', value: 'indian' }, { label: 'Other', value: 'other' }];
    const [section, setSection] = useState('Basic Details');
    const [academicSection, setAcademicSection] = useState('Current Details');
    const [admissionId, setAdmissionId] = useState('');
    const [previousSchoolId, setPreviousSchoolId] = useState('');
    const [languageList, setLanguageList] = useState([]);
    const [bloodList, setBloodList] = useState([]);
    const [religionList, setReligionList] = useState([]);
    const [casteList, setCasteList] = useState([]);
    const [parentsDetailList, setParentsDetailList] = useState([]);
    const [mediumList, setMediumList] = useState([]);
    const [isRandom, setIsRandom] = useState(false);
    const [profilePath, setProfilePath] = useState(null);
    const statusList = [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' }
    ];
    const [academicYear, setAcademicYear] = useState('');
    const [grNo, setGRNo] = useState('');
    const [classList, setClassList] = useState([]);
    const [boardList, setBoardList] = useState([]);
    const [sectionList, setSectionList] = useState([]);
    const [houseList, setHouseList] = useState([]);
    const [studentStatus, setStudentStatus] = useState('');
    const [classNo, setClassNo] = useState('');
    const [classNoErr, setClassNoErr] = useState(false);
    const [classNoErrText, setClassNoErrText] = useState('');
    const [classNoName, setClassNoName] = useState('');
    const [rollNo, setRollNo] = useState('');
    const [house, setHouse] = useState('');
    const [houseName, setHouseName] = useState('');
    const [houseColor, setHouseColor] = useState('');
    const [previousSchoolName, setPreviousSchoolName] = useState('');
    const [previousMedium, setPreviousMedium] = useState('');
    const [previousBoard, setPreviousBoard] = useState('');
    const [failedInAnyClass, setFailedInAnyClass] = useState('');
    const [lastClassNo, setLastClassNo] = useState('');
    const [previousRemark, setPreviousRemark] = useState('');
    const [reasonForLeaving, setReasonForLeaving] = useState('');
    const [transferNo, setTransferNo] = useState('');
    const [leavingNo, setLeavingNo] = useState('');
    const [studentStatusList, setStudentStatusList] = useState([]);
    const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);
    const [previewProfilePath, setPreviewProfilePath] = useState('');
    const [isNoLogin, setIsNoLogin] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                Loader.show();
                const loginData = CommonConfig.loginData();
                setSecurityUserID(loginData?._id);
                await GetStudentStatusList(loginData?._id);
                await GetClassList(loginData?._id);
                await GetBoardList(loginData?._id);
                await GetSectionList(loginData?._id);
                await GetHouseList(loginData?._id);
                await getUserDetailById();
                await GetLanguageList();
                await GetBloodList(loginData?._id);
                await GetCasteList(loginData?._id);
                await GetReligionList(loginData?._id);
                await getPersonDetail();
                await getAdmissionDetail();
                await getPreviousSchoolDetail();
            } catch (error) {
                Loader.hide();
                console.error('Error in useEffect:', error);
            } finally {
                Loader.hide();
            }
        };
        fetchData();
    }, []);

    const onReferesh = async () => {
        await getUserDetailById();
        await GetLanguageList(securityUserID);
        await GetBloodList(securityUserID);
        await GetCasteList(securityUserID);
        await GetReligionList(securityUserID);
        await GetClassList(securityUserID);
        await GetBoardList(securityUserID);
        await GetSectionList(securityUserID);
        await GetHouseList(securityUserID);
        await GetStudentStatusList(securityUserID);
        await getPersonDetail();
        await getAdmissionDetail();
        await getPreviousSchoolDetail();
    }

    const getUserDetailById = async () => {
        try {
            let data = {
                id: props?.location?.state?.userId,
            };
            Loader.show();
            await api
                .post(APIConstant.path.getUserById, data)
                .then(async (response) => {
                    let res = await response;
                    if (res.success) {
                        setFullName(res?.data?.FirstName || '');
                        setSurName(res?.data?.SurName || '');
                        setFName(res?.data?.FName || '');
                        setMName(res?.data?.MName || '');
                        setMobileNumber(res?.data?.Phone || '');
                        setEmailAddress(res?.data?.Email || '');
                        setIsNoLogin(res?.data?.IsNoLogin);
                        setProfilePath(res?.data?.ProfilePhotoUrl ? `${apiBase}/UserProfilePic/${res?.data?.ProfilePhotoUrl}` : null);
                    }
                    Loader.hide();
                })
                .catch((err) => {
                    Loader.hide();
                    console.log(err);
                });
        } catch (err) {
            Loader.hide();
            console.log(err);
        }
    };

    const GetLanguageList = async (id) => {
        try {
            await api.get(APIConstant.path.getLanguageList).then(async response => {
                let res = response;
                if (res.success) {
                    setLanguageList(res.data.map(x => ({
                        ...x,
                        label: x?.LanguageName,
                        value: x?._id
                    })));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const GetBloodList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.getBloodList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setBloodList(res.data.map(x => ({
                        label: x?.BloodName,
                        value: x?._id
                    })));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const GetCasteList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.getCasteList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setCasteList(res.data.map(x => ({
                        label: x?.CasteName,
                        value: x?._id
                    })));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const GetReligionList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.getReligionList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setReligionList(res.data.map(x => ({
                        label: x?.ReligionName,
                        value: x?._id
                    })));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const GetClassList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.getClassList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setClassList(res?.data?.filter(x => x?.Status === 'Active')?.map((x) => ({
                        ...x,
                        value: x?._id,
                        label: `${x?.ClassName}-${x?.SectionFullName.charAt(0)}-${x?.MediumName}-${x?.Board}`
                    })));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const GetStudentStatusList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.getStudentStatusList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setStudentStatusList(res.data.map(x => ({
                        ...x,
                        label: x?.StatusName,
                        value: x?._id
                    })));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const GetBoardList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.getBoardList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setBoardList(res.data.map(x => ({
                        ...x,
                        label: x?.BoardName,
                        value: x?._id
                    })));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const GetSectionList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.getSectionList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setSectionList(res.data.map(x => ({
                        ...x,
                        label: x?.SectionName,
                        value: x?._id
                    })));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const GetHouseList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.getHouseList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setHouseList(res.data.map(x => ({
                        ...x,
                        label: x?.HouseName,
                        value: x?._id
                    })));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const getPersonDetail = async (ID) => {
        try {
            let data = {
                id: props?.location?.state?.userId,
            };
            Loader.show();
            await api
                .post(APIConstant.path.GetPersonalDetailById, data)
                .then(async (response) => {
                    let res = await response;
                    if (res.success) {
                        setPersonalDetailId(res?.data?._id);
                        setStudentId(res?.data?.ProfileId || '');
                        setAadharNo(res?.data?.AadharNo || '');
                        setSelectedGender(res?.data?.Gender || '');
                        setDob(res?.data?.DOB ? new Date(res?.data?.DOB) : '');
                        setAge(res?.data?.Age || '');
                        setSelectedNationality(res?.data?.Nationality || '');
                        setSelectedBloodGroup(res?.data?.BloodGroup || '');
                        setSelectedReligion(res?.data?.Religion || '');
                        setSelectedCategory(res?.data?.Category || '');
                        setLanguages(res?.data?.Language ? res?.data?.Language.split(',') : []);
                        setAlternateMobileNumber(res?.data?.AlternateContact || '');
                        setCurrentAddress(res?.data?.CurrentAddress || '');
                        setPermanentAddress(res?.data?.PermanentAddress || '');
                        setPinCode(res?.data?.PinCode || '');
                        setParentsDetailList(res?.data?.ParentChildList || '');
                    }
                    Loader.hide();
                })
                .catch((err) => {
                    Loader.hide();
                    console.log(err);
                });
        } catch (err) {
            Loader.hide();
            console.log(err);
        }
    };

    const getAdmissionDetail = async (ID) => {
        try {
            let data = {
                id: props?.location?.state?.userId,
            };
            Loader.show();
            await api
                .post(APIConstant.path.GetAdmissionDetailById, data)
                .then(async (response) => {
                    let res = await response;
                    if (res.success) {
                        let resData = res?.data;
                        if (resData) {
                            setAdmissionId(resData._id);
                            setDoa(resData?.AdmissionDate ? new Date(resData?.AdmissionDate) : '');
                            setAdmissionType(resData?.AdmissionType);
                            setQuotaId(resData?.QuotaId);
                            setApplicationNo(resData?.ApplicationNo);
                            setAcademicYear(resData?.AcademicYear);
                            setGRNo(resData?.GRNo);
                            setStudentStatus(resData?.StudentStatus);
                            setClassNo(resData?.ClassNo);
                            setClassNoName(resData?.ClassNoName);
                            setRollNo(resData?.RollNo);
                            setHouse(resData?.House);
                            setHouseName(resData?.HouseName);
                            setHouseColor(resData?.HouseColor ? `#${resData?.HouseColor}` : '');
                        }
                    }
                    Loader.hide();
                })
                .catch((err) => {
                    Loader.hide();
                    console.log(err);
                });
        } catch (err) {
            Loader.hide();
            console.log(err);
        }
    };

    const getPreviousSchoolDetail = async (ID) => {
        try {
            let data = {
                id: props?.location?.state?.userId,
            };
            Loader.show();
            await api
                .post(APIConstant.path.GetPreviousSchoolDetailById, data)
                .then(async (response) => {
                    let res = await response;
                    if (res.success) {
                        let resData = res?.data;
                        if (resData.length) {
                            setPreviousSchoolId(resData[0]._id);
                            setPreviousSchoolName(resData[0]?.SchoolName);
                            setPreviousMedium(resData[0]?.Medium);
                            setPreviousBoard(resData[0]?.Board);
                            setFailedInAnyClass(resData[0]?.FailedInAny);
                            setLastClassNo(resData[0]?.LastClassStudied);
                            setPreviousRemark(resData[0]?.Remark);
                            setReasonForLeaving(resData[0]?.ReasonLeaving);
                            setTransferNo(resData[0]?.TransferCrtNo);
                            setLeavingNo(resData[0]?.LeavingCrtNo);
                        }
                    }
                    Loader.hide();
                })
                .catch((err) => {
                    Loader.hide();
                    console.log(err);
                });
        } catch (err) {
            Loader.hide();
            console.log(err);
        }
    };

    const validate = () => {
        let formIsValid = true;
        if (CommonConfig.isEmpty(fullName)) {
            formIsValid = false;
            setFullNameErr(true);
            setFullNameErrText("Student Name is required");
        } else {
            setFullNameErr(false);
            setFullNameErrText("");
        }
        if (CommonConfig.isEmpty(studentId)) {
            formIsValid = false;
            setStudentIdErr(true);
            setStudentIdErrText("Student UID is required");
        } else {
            setStudentIdErr(false);
            setStudentIdErrText("");
        }
        if (CommonConfig.isEmpty(mobileNumber) && !isNoLogin) {
            formIsValid = false;
            setMobileNumberErr(true);
            setMobileNumberErrText("PhoneNo is required");
        } else {
            setMobileNumberErr(false);
            setMobileNumberErrText("");
        }
        if (CommonConfig.isEmpty(emailAddress) && !isNoLogin) {
            formIsValid = false;
            setEmailIdErr(true);
            setEmailIdErrText("EmailId is required");
        } else {
            setEmailIdErr(false);
            setEmailIdErrText("");
        }
        if (CommonConfig.isEmpty(fName)) {
            formIsValid = false;
            setFNameErr(true);
            setFNameErrText("Father name is required");
        } else {
            setFNameErr(false);
            setFNameErrText("");
        }
        if (CommonConfig.isEmpty(surName)) {
            formIsValid = false;
            setSurNameErr(true);
            setSurNameErrText("Surname is required");
        } else {
            setSurNameErr(false);
            setSurNameErrText("");
        }
        if (CommonConfig.isEmpty(mName)) {
            formIsValid = false;
            setMNameErr(true);
            setMNameErrText("Mother name is required");
        } else {
            setMNameErr(false);
            setMNameErrText("");
        }
        if (CommonConfig.isEmpty(classNo)) {
            formIsValid = false;
            setClassNoErr(true);
            setClassNoErrText("Class Name is required");
        } else {
            setClassNoErr(false);
            setClassNoErrText("");
        }
        return formIsValid;
    };

    const saveDetails = async (e) => {
        if (validate(e)) {
            await AddEditUserDetail();
            await AddEditPersonalDetail();
            await AddEditAdmissionDetail();
            await AddEditPreviousSchoolDetail();
            await onReferesh();
            navigate('/student-master')
        }
    }

    const AddEditUserDetail = async () => {
        try {
            const formData = new FormData();
            formData.append("id", props?.location?.state?.userId);
            formData.append("Name", `${fullName} ${fName} ${surName}`);
            formData.append("FirstName", fullName || '');
            formData.append("SurName", surName || '');
            formData.append("FName", fName || '');
            formData.append("MName", mName || '');
            formData.append("Email", emailAddress || '');
            formData.append("Phone", mobileNumber || '');
            if (profilePath && profilePath.type) {
                formData.append("userProfilePic", profilePath);
            } else if (CommonConfig.isEmpty(profilePath)) {
                formData.append("userProfilePic", '');
            }
            Loader.show();
            await api.post(APIConstant.path.AddUpdateUser, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
                .then(async (response) => {
                    let res = response;
                    if (res.success) {
                        // Toast.success({ message: 'User details updated successfully' });
                    } else {
                        Toast.error({ message: res.message });
                    }
                    Loader.hide();
                })
                .catch((err) => {
                    console.error(err);
                    Toast.error({ message: 'Failed to update user details' });
                    Loader.hide();
                });
        } catch (err) {
            console.log(err);
            Toast.error({ message: 'An error occurred' });
            Loader.hide();
        }
    };

    const AddEditPersonalDetail = async (e) => {
        try {
            let data = {
                PersonalId: personalDetailId,
                UserId: props?.location?.state?.userId,
                AadharNo: aadharNo,
                Gender: selectedGender,
                DOB: dob ? moment(dob).format("YYYY-MM-DD") : '',
                Age: age,
                Nationality: selectedNationality,
                BloodGroup: selectedBloodGroup,
                Religion: selectedReligion,
                Category: selectedCategory,
                Language: languages.length > 1 ? languages.join(',') : languages[0],
                AlternateContact: alternateMobileNumber,
                PinCode: pinCode,
                CurrentAddress: currentAddress,
                PermanentAddress: permanentAddress,
                CreatedBy: securityUserID,
            };
            Loader.show();
            await api.post(APIConstant.path.AddPersonalDetail, data).then(async response => {
                let res = response;
                if (res.success) {
                    Toast.success({ message: 'Details Updated' });
                    Loader.hide();
                } else {
                    Toast.error({ message: res.message });
                    Loader.hide();
                }
            })
                .catch((err) => {
                    Loader.hide();
                });
        } catch (err) {
            console.log(err);
            Loader.hide();
        }
    };

    const AddEditAdmissionDetail = async (e) => {
        try {
            let data = {
                AdmissionId: admissionId,
                UserId: props?.location?.state?.userId,
                ParentsId: '',
                AdmissionDate: doa ? moment(doa).format("YYYY-MM-DD") : '',
                AdmissionType: admissionType,
                QuotaId: quotaId,
                ApplicationNo: applicationNo,
                AcademicYear: academicYear,
                GRNo: grNo,
                StudentStatus: studentStatus,
                ClassNo: classNo,
                RollNo: rollNo,
                House: house,
                CreatedBy: securityUserID
            };
            Loader.show();
            await api.post(APIConstant.path.AddAdmissionDetail, data).then(async response => {
                let res = response;
                if (res.success) {
                    // Toast.success({ message: 'Admission details updated' });
                    Loader.hide();
                } else {
                    Toast.error({ message: res.message });
                    Loader.hide();
                }
            })
                .catch((err) => {
                    Loader.hide();
                });
        } catch (err) {
            console.log(err);
            Loader.hide();
        }
    };

    const AddEditPreviousSchoolDetail = async (e) => {
        try {
            let data = {
                id: previousSchoolId,
                UserId: props?.location?.state?.userId,
                SchoolName: previousSchoolName,
                Medium: previousMedium,
                Board: previousBoard,
                FailedInAny: failedInAnyClass,
                LastClassStudied: lastClassNo,
                Remark: previousRemark,
                ReasonLeaving: reasonForLeaving,
                TransferCrtNo: transferNo,
                LeavingCrtNo: leavingNo,
                CreatedBy: securityUserID
            };
            Loader.show();
            await api.post(APIConstant.path.AddPreviousSchoolDetail, data).then(async response => {
                let res = response;
                if (res.success) {
                    // Toast.success({ message: 'Admission details updated' });
                    Loader.hide();
                } else {
                    Toast.error({ message: res.message });
                    Loader.hide();
                }
            })
                .catch((err) => {
                    Loader.hide();
                });
        } catch (err) {
            console.log(err);
            Loader.hide();
        }
    };

    const calculateAge = (dob) => {
        if (!dob) return '';
        const today = new Date();
        const birthDate = new Date(dob);

        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();

        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        return age;
    };

    const handleDobChange = (e) => {
        const selectedDob = e.value;
        setDob(selectedDob);
        setAge(calculateAge(selectedDob));
    };

    const handleChange = (e, type) => {
        const value = e.target.value;
        const alphanumericValue = value.replace(/[^a-zA-Z0-9\s]/g, '');
        const numericValue = value.replace(/[^0-9]/g, '');
        if (type === 'FullName') {
            setFullName(alphanumericValue);
        } else if (type === 'MobileNo') {
            setMobileNumber(numericValue);
        } else if (type === 'AlternateNo') {
            setAlternateMobileNumber(numericValue);
        } else if (type === 'AadharNo') {
            setAadharNo(numericValue);
        } else if (type === 'Pincode') {
            setPinCode(numericValue);
        } else if (type === 'GRNo') {
            setGRNo(numericValue);
        } else if (type === 'StudentId') {
            setStudentId(alphanumericValue?.toUpperCase());
        } else if (type === 'SurName') {
            setSurName(alphanumericValue);
        } else if (type === 'FName') {
            setFName(alphanumericValue);
        } else if (type === 'MName') {
            setMName(alphanumericValue);
        }
    };

    const handleCheckBox = (e, type) => {
        setIsRandom(e.checked);
        if (e.checked) {
            setOldPermanentAddress(permanentAddress);
            setPermanentAddress(currentAddress);
        } else {
            setPermanentAddress(oldPermanentAddress);
            setOldPermanentAddress('');
        }
    };

    const handleEmailChange = (e) => {
        const value = e.target.value;
        const maxLength = 50;
        if (value.length <= maxLength) {
            setEmailAddress(value);
        }
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailPattern.test(value)) {
            setEmailIdErr(true);
            setEmailIdErrText('Please enter a valid email address.');
        } else {
            setEmailIdErr(false);
            setEmailIdErrText('');
        }
    };

    const handleFileSelect = (e) => {
        const selectedFile = e.files[0];
        if (selectedFile) {
            const validFormats = ['image/jpeg', 'image/png', 'image/jpg', 'image/svg+xml'];
            if (validFormats.includes(selectedFile.type)) {
                const img = new Image();
                img.onload = () => {
                    if (img.width === img.height) {
                        setProfilePath(selectedFile);
                        const fileURL = URL.createObjectURL(selectedFile);
                        setPreviewProfilePath(fileURL);
                    } else {
                        if (fileUploadRef.current) {
                            fileUploadRef.current.clear();
                        }
                        Toast.error({ message: 'The image must have a 1:1 aspect ratio.' });
                    }
                };
                img.onerror = () => {
                    if (fileUploadRef.current) {
                        fileUploadRef.current.clear();
                    }
                    Toast.error({ message: 'Error loading the image.' });
                };

                const reader = new FileReader();
                reader.onload = (event) => {
                    img.src = event.target.result;
                };
                reader.readAsDataURL(selectedFile);
            } else {
                if (fileUploadRef.current) {
                    fileUploadRef.current.clear();
                }
                Toast.error({ message: 'Invalid file format. Please select a JPEG, SVG or PNG image.' });
            }
        }
    };

    const renderFooter = () => (
        <div className="dialog-footer1 p-d-flex p-ai-center p-jc-end">
            <Button
                label="Cancel"
                className="primary-btn1"
                onClick={handleClose}
            />
            <Button
                label="Delete"
                className="primary-btn"
                onClick={() => {
                    handleClose();
                    setProfilePath("")
                    setPreviewProfilePath('');
                    if (fileUploadRef.current) {
                        fileUploadRef.current.clear();
                    }
                }}
            />
        </div>
    );

    const handleClose = () => {
        setIsDeleteConfirmation(false)
    };
    const handleDelete = (e) => {
        setIsDeleteConfirmation(true)
    };
    return (
        <div className="wayment-owner-dashboard e-weighment-dashoard">
            <div className='e-profile-header'>
                <div className="e-profile-box">
                    <div className="e-profile-main p-d-flex p-flex-lg-row">
                        <UploadFile label='' note='' preview={previewProfilePath} original={profilePath} uploadHandler={handleFileSelect} uploadRef={fileUploadRef} handleDelete={handleDelete} />
                        <div className="e-profile-info">
                            <h2 className='p-text-justify p-mt-1'>{`${fullName} ${fName} ${surName}`}</h2>
                            <div className='p-d-flex p-ai-center'>
                                <div className='profile-text-box'><p className='profile-text'>Student UID - {studentId}</p></div>
                                <div className='profile-text-box p-mx-2'> <p className='profile-text'>GR No. - {grNo}</p></div>
                            </div>
                            <div className='p-d-flex p-ai-center'>
                                <div className="profile-transport-box">
                                    <span className='profile-t-text'>Transport Facility</span>
                                </div>
                                <div>
                                    <Button label="Using" className="e-border-btn" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-d-flex p-ai-center p-jc-between p-mt-3">
                <div className="p-d-flex flex-column p-ai-center">
                    <h4 className='p-mt-2 p-mb-0'>Class</h4>
                    <Button label={classNoName} className="e-border-btn p-m-0" />
                </div>
                <div className="p-d-flex flex-column">
                    <h4 className='p-mt-2 p-mb-0 p-text-center'>Roll No.</h4>
                    <Button label={rollNo} className="btn-text-action-secondary p-m-0" />
                </div>
                <div className="p-d-flex flex-column p-ai-center">
                    <h4 className='p-mt-2 p-mb-0'>House</h4>
                    <div class="btn-text-action-primary p-m-0" style={{ backgroundColor: houseColor }} >
                        <i class="fa fa-users"></i>
                        <span>{houseName}</span>
                    </div>

                </div>
            </div>
            <TabView className='p-mt-3'>
                <TabPanel header="Personal Details">
                    <div className="form-section p-mb-0">
                        <div className="p-grid">
                            <div className="p-field-radiobutton p-col-12 p-md-4">
                                <RadioButton inputId="basicDetails" name="section" value="Basic Details" onChange={(e) => setSection(e.value)} checked={section === 'Basic Details'} />
                                <label htmlFor="basicDetails">Basic Details</label>
                            </div>
                            <div className="p-field-radiobutton p-col-12 p-md-4">
                                <RadioButton inputId="birthPlaceDetails" name="section" value="Birth Place Details" onChange={(e) => setSection(e.value)} checked={section === 'Birth Place Details'} />
                                <label htmlFor="birthPlaceDetails">Birth Place Details</label>
                            </div>
                            <div className="p-field-radiobutton p-col-12 p-md-4">
                                <RadioButton inputId="parentDetails" name="section" value="Parent Details" onChange={(e) => setSection(e.value)} checked={section === 'Parent Details'} />
                                <label htmlFor="parentDetails">Parent Details</label>
                            </div>
                        </div>

                        <div className="p-fluid">
                            {section === 'Basic Details' && (
                                <div className='p-grid'>
                                    <div className="p-col-12 p-md-4">
                                        <label htmlFor="fullName">First Name</label>
                                        <InputText id="fullName" value={fullName} disabled={!props?.location?.state?.isEdit} onChange={(e) => handleChange(e, 'FullName')} />
                                        <span className="error-msg" style={{ color: 'red' }}>
              {fullNameErr ? fullNameErrText : null}
            </span>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <label htmlFor="surName">Surname</label>
                                        <InputText id="surName" value={surName} disabled={!props?.location?.state?.isEdit} onChange={(e) => handleChange(e, 'SurName')} />
                                        <span className="error-msg" style={{ color: 'red' }}>
              {surNameErr ? surNameErrText : null}
            </span>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <label htmlFor="fName">Father Name</label>
                                        <InputText id="fName" value={fName} disabled={!props?.location?.state?.isEdit} onChange={(e) => handleChange(e, 'FName')} />
                                        <span className="error-msg" style={{ color: 'red' }}>
              {fNameErr ? fNameErrtext : null}
            </span>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <label htmlFor="mName">Mother Name</label>
                                        <InputText id="mName" value={mName} disabled={!props?.location?.state?.isEdit} onChange={(e) => handleChange(e, 'MName')} />
                                        <span className="error-msg" style={{ color: 'red' }}>
              {mNameErr ? mNameErrText : null}
            </span>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <label htmlFor="aadhar">Aadhar</label>
                                        <div className="p-inputgroup p-d-flex p-ai-lg-baseline">
                                            <InputText className='p-w-100 p-h-100' id="aadhar" disabled={!props?.location?.state?.isEdit} value={aadharNo} maxLength={12} onChange={(e) => handleChange(e, 'AadharNo')} />
                                            <Button className='p-m-0' label="Verify" disabled={!props?.location?.state?.isEdit} />
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <label htmlFor="gender">Gender</label>
                                        <Dropdown id="gender" disabled={!props?.location?.state?.isEdit} value={selectedGender} options={genderOptions} onChange={(e) => setSelectedGender(e.value)} placeholder="Select Gender" />
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <label className='p-d-block' htmlFor="dob">Date of Birth</label>
                                        <Calendar className='p-w-100' id="dob" value={dob} onChange={handleDobChange} disabled={!props?.location?.state?.isEdit} dateFormat='dd/mm/yy' maxDate={new Date()} />
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <label htmlFor="age">Age</label>
                                        <InputText id="age" value={age} disabled />
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <label htmlFor="nationality">Nationality</label>
                                        <Dropdown id="nationality" value={selectedNationality} disabled={!props?.location?.state?.isEdit} options={nationalityOptions} onChange={(e) => setSelectedNationality(e.value)} placeholder="Select Nationality" />
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <label htmlFor="bloodGroup">Blood Group</label>
                                        <Dropdown id="bloodGroup" value={selectedBloodGroup} options={bloodList} disabled={!props?.location?.state?.isEdit} onChange={(e) => setSelectedBloodGroup(e.value)} placeholder="Select Blood Group" />
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <label htmlFor="religion">Religion</label>
                                        <Dropdown id="religion" value={selectedReligion} options={religionList} disabled={!props?.location?.state?.isEdit} onChange={(e) => setSelectedReligion(e.value)} placeholder="Select Religion" />
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <label htmlFor="category">Category</label>
                                        <Dropdown id="category" value={selectedCategory} options={casteList} disabled={!props?.location?.state?.isEdit} onChange={(e) => setSelectedCategory(e.value)} placeholder="Select Category" />
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <label htmlFor="languages">Languages Spoken</label>
                                        <MultiSelect id="languages" value={languages} options={languageList} disabled={!props?.location?.state?.isEdit} onChange={(e) => setLanguages(e.value)} placeholder="Select language" filter display='chip' />
                                    </div>
                                </div>
                            )}
                            {section === 'Birth Place Details' && (
                                <div className='p-grid'>
                                    <div className="p-col-12 p-md-4 p-field">
                                        <label htmlFor="pinCode">Pin Code</label>
                                        <InputText id="pinCode" value={pinCode} maxLength={6} disabled={!props?.location?.state?.isEdit} onChange={(e) => setPinCode(e.target.value)} />
                                    </div>
                                    <div className="p-col-12 p-md-8 p-field">
                                        <label htmlFor="address">Address</label>
                                        <InputTextarea id="address" value={currentAddress} maxLength={100} type='textArea' rows={3} cols={30} onChange={(e) => setCurrentAddress(e.target.value)} disabled={!props?.location?.state?.isEdit} />
                                    </div>
                                </div>
                            )}
                            {section === 'Parent Details' && (
                                <div>
                                    {parentsDetailList?.map((parent, index) => (
                                        <div key={index}>
                                            <div className='p-d-flex p-ai-center p-jc-between'>
                                                <div className="p-d-flex p-ai-center e-content-box">
                                                    <p className='e-title'>Full Name :</p>
                                                    <p className='p-pl-2 e-sub-title'>{parent?.name || 'N/A'}</p>
                                                </div>
                                                <div className="p-d-flex p-ai-center e-content-box">
                                                    <p className='e-title'>Email Address :</p>
                                                    <p className='p-pl-2 e-sub-title'>{parent?.email || 'N/A'}</p>
                                                </div>
                                                <div className="p-d-flex p-ai-center e-content-box">
                                                    <p className='e-title'>Contact Number :</p>
                                                    <p className='p-pl-2 e-sub-title'>{parent?.phone || 'N/A'}</p>
                                                </div>
                                            </div>

                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </TabPanel>
                <TabPanel header="Contact Details">
                    <div className="form-section p-mb-0">
                        <div className="p-grid">
                            <div className="p-col-12 p-md-4">
                                <label className='p-d-block' htmlFor="email">Email Address</label>
                                <InputText className='p-w-100' id="email" value={emailAddress} disabled={!props?.location?.state?.isEdit || isNoLogin} onChange={(e) => handleEmailChange(e)} />
                                <span className="p-error block" style={{ color: 'red' }}>
                                    {emailIdErr ? emailIdErrText : null}
                                </span>
                            </div>
                            <div className="p-col-12 p-md-4">
                                <label className='p-d-block' htmlFor="contactNo">Contact Number</label>
                                <div className="p-inputgroup">
                                    <InputText className='p-w-100' id="contactNo" maxLength={10} value={mobileNumber} disabled={!props?.location?.state?.isEdit || isNoLogin} onChange={(e) => handleChange(e, 'MobileNo')} />
                                    <span className="error-msg" style={{ color: 'red' }}>
              {mobileNumberErr ? mobileNumberErrText : null}
            </span>
                                </div>
                            </div>
                            <div className="p-col-12 p-md-4">
                                <label className='p-d-block' htmlFor="contactNo">Emergency Contact Number</label>
                                <div className="p-inputgroup">
                                    <InputText className='p-w-100' id="emrContactNo" maxLength={10} value={alternateMobileNumber} onChange={(e) => handleChange(e, 'AlternateNo')} disabled={!props?.location?.state?.isEdit || isNoLogin} />
                                </div>
                            </div>
                            <div className="p-col-12 p-md-4">
                                <label className='p-d-block' htmlFor="pinCode">Pin Code</label>
                                <InputText className='p-w-100' id="pinCode" maxLength={6} value={pinCode} disabled={!props?.location?.state?.isEdit} onChange={(e) => handleChange(e, 'Pincode')} />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <label className='p-d-block' htmlFor="currentAddress">Current Address</label>
                                <InputTextarea className='p-w-100' id="currentAddress" value={currentAddress} maxLength={100} type='textArea' rows={1} cols={30} onChange={(e) => setCurrentAddress(e.target.value)} disabled={!props?.location?.state?.isEdit} />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <div className="p-d-flex p-ai-center p-mt-4">
                                    <Checkbox
                                        inputId="randomCheckbox"
                                        checked={isRandom}
                                        onChange={(e) => handleCheckBox(e)}
                                        disabled={!props?.location?.state?.isEdit}
                                    />
                                    <label className='p-ml-2' htmlFor="randomCheckbox">Permanent address same as current</label>
                                </div>
                            </div>
                            <div className="p-col-12 p-md-4">
                                <label className='p-d-block' htmlFor="permanentAddress">Permanent Address</label>
                                <InputTextarea className='p-w-100' id="permanentAddress" value={permanentAddress} maxLength={100} rows={2} cols={30} onChange={(e) => setPermanentAddress(e.target.value)} disabled={!props?.location?.state?.isEdit} />
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel header="Admission Details">
                    <div className="form-section p-mb-0">
                        <div className="p-grid">
                            <div className="p-col-12 p-md-6">
                                <label htmlFor="doa">Admission Date</label>
                                <Calendar className='p-w-100' id="doa" value={doa} onChange={(e) => setDoa(e.value)} disabled={!props?.location?.state?.isEdit} dateFormat='dd/mm/yy' />
                            </div>
                            <div className="p-col-12 p-md-6">
                                <label className='p-d-block' htmlFor="admissionType">Admission Type</label>
                                <InputText className='p-w-100' id="admissionType" maxLength={30} value={admissionType} onChange={(e) => setAdmissionType(e.target.value)} disabled={!props?.location?.state?.isEdit} />
                            </div>
                            <div className="p-col-12 p-md-12">
                                <h4 className='p-m-0 p-pb-2'>Quota Details</h4>
                                <hr className='p-m-0' />
                            </div>
                            <div className="p-col-12 p-md-6">
                                <label className='p-d-block' htmlFor="quotaId">Quota</label>
                                <InputText className='p-w-100' id="quotaId" maxLength={50} value={quotaId} onChange={(e) => setQuotaId(e.target.value)} disabled={!props?.location?.state?.isEdit} />
                            </div>
                            <div className="p-col-12 p-md-6">
                                <label className='p-d-block' htmlFor="applicationNo">Application No</label>
                                <InputText className='p-w-100' id="applicationNo" maxLength={20} value={applicationNo} onChange={(e) => setApplicationNo(e.target.value)} disabled={!props?.location?.state?.isEdit} />
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel header="Academic Details">
                    <div className="form-section p-mb-0">
                        <div className="p-grid">
                            <div className="p-field-radiobutton p-col-12 p-md-4">
                                <RadioButton inputId="currentDetail" name="academicSection" value="Current Details" onChange={(e) => setAcademicSection(e.value)} checked={academicSection === 'Current Details'} />
                                <label htmlFor="currentDetail">Current Details</label>
                            </div>
                            <div className="p-field-radiobutton p-col-12 p-md-4">
                                <RadioButton inputId="previousDetails" name="academicSection" value="Previous Details" onChange={(e) => setAcademicSection(e.value)} checked={academicSection === 'Previous Details'} />
                                <label htmlFor="previousDetails">Previous Details</label>
                            </div>
                        </div>

                        <div className="p-fluid">
                            {academicSection === 'Current Details' && (
                                <div className='p-grid'>
                                    <div className="p-col-12 p-md-4">
                                        <label htmlFor="academicYear">Academic Year</label>
                                        <InputText id="academicYear" value={academicYear} disabled={!props?.location?.state?.isEdit} onChange={(e) => setAcademicYear(e.target.value)} />
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <label htmlFor="grNo">GR No.</label>
                                        <InputText id="grNo" value={grNo} maxLength={10} disabled={!props?.location?.state?.isEdit} onChange={(e) => handleChange(e, 'GRNo')} />
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <label htmlFor="studentId">Student UID</label>
                                        <InputText id="studentId" disabled={!props?.location?.state?.isEdit} maxLength={12} onChange={(e) => handleChange(e, 'StudentId')} value={studentId} />
                                        <span className="error-msg" style={{ color: 'red' }}>
                                            {studentIdErr ? studentIdErrText : null}
                                        </span>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <label htmlFor="studentStatus">Student Status</label>
                                        <Dropdown id="studentStatus" disabled={!props?.location?.state?.isEdit} value={studentStatus} options={studentStatusList} onChange={(e) => setStudentStatus(e.value)} placeholder="Select Status" />
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <label htmlFor="classNo">Class</label>
                                        <Dropdown id="classNo" disabled={!props?.location?.state?.isEdit} value={classNo} options={classList} onChange={(e) => setClassNo(e.value)} placeholder="Select Class" />
                                        <span className="error-msg" style={{ color: 'red' }}>
              {classNoErr ? classNoErrText : null}
            </span>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <label htmlFor="rollNo">Roll No.</label>
                                        <InputText id="rollNo" value={rollNo} maxLength={3} disabled={!props?.location?.state?.isEdit} onChange={(e) => setRollNo(e.target.value)} />
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <label htmlFor="house">House</label>
                                        <Dropdown id="house" disabled={!props?.location?.state?.isEdit} value={house} options={houseList} onChange={(e) => setHouse(e.value)} placeholder="Select House" />
                                    </div>
                                </div>
                            )}
                            {academicSection === 'Previous Details' && (
                                <div className='p-grid'>
                                    <div className="p-col-12 p-md-4">
                                        <label htmlFor="previousSchoolName">School Name</label>
                                        <InputText id="previousSchoolName" value={previousSchoolName} disabled={!props?.location?.state?.isEdit} onChange={(e) => setPreviousSchoolName(e.target.value)} />
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <label htmlFor="previousMedium">Medium</label>
                                        <Dropdown id="previousMedium" disabled={!props?.location?.state?.isEdit} value={previousMedium} options={languageList} onChange={(e) => setPreviousMedium(e.value)} placeholder="Select Medium" />
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <label htmlFor="previousBoard">Board</label>
                                        <Dropdown id="previousBoard" disabled={!props?.location?.state?.isEdit} value={previousBoard} options={boardList} onChange={(e) => setPreviousBoard(e.value)} placeholder="Select Board" />
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <label htmlFor="failedInAnyClass">Failed in Any Class?</label>
                                        <Dropdown id="failedInAnyClass" disabled={!props?.location?.state?.isEdit} value={failedInAnyClass} options={statusList} onChange={(e) => setFailedInAnyClass(e.value)} placeholder="Select" />
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <label htmlFor="lastClassNo">Last Class Studied</label>
                                        <InputText id="lastClassNo" value={lastClassNo} disabled={!props?.location?.state?.isEdit} onChange={(e) => setLastClassNo(e.target.value)} maxLength={20} />
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <label htmlFor="remark">Remark</label>
                                        <InputText id="remark" value={previousRemark} maxLength={100} disabled={!props?.location?.state?.isEdit} onChange={(e) => setPreviousRemark(e.target.value)} />
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <label htmlFor="reasonForLeaving">Reason for leaving School</label>
                                        <InputText id="reasonForLeaving" maxLength={100} value={reasonForLeaving} disabled={!props?.location?.state?.isEdit} onChange={(e) => setReasonForLeaving(e.target.value)} />
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <label htmlFor="transferNo">Transfer Certificate No</label>
                                        <InputText id="transferNo" value={transferNo} maxLength={100} disabled={!props?.location?.state?.isEdit} onChange={(e) => setTransferNo(e.target.value)} />
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <label htmlFor="leavingNo">Leaving Certificate No</label>
                                        <InputText id="leavingNo" value={leavingNo} maxLength={100} disabled={!props?.location?.state?.isEdit} onChange={(e) => setLeavingNo(e.target.value)} />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </TabPanel>
            </TabView>

            <div className="p-d-flex p-ai-center p-jc-end">
                <Button label="Back" className="e-border-btn p-mt-0 p-mb-0 p-mr-0" onClick={() => navigate('/student-master')} />
                {props?.location?.state?.isEdit ?
                    <Button label="Save" className='btn-text-action-primary p-button p-component p-ml-2 p-mr-2 p-my-0' icon="pi pi-check" onClick={() => saveDetails()} /> : null}
            </div>
            <Dialog
                visible={isDeleteConfirmation}
                onHide={handleClose}
                header={
                    <div className="p-d-flex p-jc-between p-ai-center">
                        <span>Delete Confirmation</span>
                    </div>
                }
                footer={renderFooter()}
                draggable={false}
                dismissableMask
                style={{ width: '450px' }}
            >
                <DeleteConfirmation />
            </Dialog>
        </div>
    );
}

export default ViewEditStudentDetails;
