
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import React, { useState, useEffect } from 'react';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import Toast from '../../Shared/Toast/Toast';
import { FloatLabel } from 'primereact/floatlabel';
import { ToggleButton } from 'primereact/togglebutton';

const AddEditSubjectMasterDialog = (props) => {
    const [subjectName, setSubjectName] = useState('');
    const [securityUserID, setSecurityUserID] = useState("");
    const [classes, setClasses] = useState([]);
    const [teachers, setTeachers] = useState([]);
    const [subjectNameErrText, setSubjectNameErrText] = useState('');
    const [classesErrText, setClassesErrText] = useState('');
    const [teachersErrText, setTeachersErrText] = useState('');
    const [subjectNameErr, setSubjectNameErr] = useState(false);
    const [classesErr, setClassesErr] = useState(false);
    const [teachersErr, setTeachersErr] = useState(false);
    const [teachersList, setTeachersList] = useState([]);
    const [classesList, setClassesList] = useState([]);
    const [subjectId, setSubjectId] = useState('');
    const [subjectIdErr, setSubjectIdErr] = useState(false);
    const [subjectIdErrText, setSubjectIdErrText] = useState('');
    const [isGrade, setIsGrade] = useState(false);
    const [isOptional, setIsOptional] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                Loader.show();
                const loginData = CommonConfig.loginData();
                setSecurityUserID(loginData?._id);
                await GetClassesList(loginData?._id);
                await GetTeachersList(loginData?._id);
                if (props.ID) {
                    setSubjectName(props?.masterData?.SubjectName);
                    setClasses(props?.masterData?.Classes.split(',') || []);
                    setTeachers(props?.masterData?.Teachers.split(',') || []);
                    setSubjectId(props?.masterData?.SubjectId);
                    setIsGrade(props?.masterData?.IsGrade);
                    setIsOptional(props?.masterData?.IsOptional)
                }
                Loader.hide();
            } catch (error) {
                Loader.hide();
                console.error('Error in useEffect:', error);
            }
        };
        fetchData();
    }, []);

    const onCancel = () => {
        props.onCancel();
    }

    const GetClassesList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.getClassList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setClassesList(res.data.map(x => ({
                        ...x,
                        label: `${x?.ClassName}-${x?.SectionFullName.charAt(0)}-${x?.MediumName}-${x?.Board}`,
                        value: x?._id
                    })));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const GetTeachersList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.getUser, data).then(async response => {
                let res = response;
                if (res.success) {
                    setTeachersList(res.data.filter((x) => x.UserType === 'Teacher').map((y) => ({
                        ...y,
                        label: y.Name,
                        value: y._id
                    })));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const validate = () => {
        let formIsValid = true;
        if (CommonConfig.isEmpty(subjectName)) {
            formIsValid = false;
            setSubjectNameErr(true);
            setSubjectNameErrText("Subject Name is required");
        } else {
            setSubjectNameErr(false);
            setSubjectNameErrText("");
        }
        if (classes.length === 0) {
            formIsValid = false;
            setClassesErr(true);
            setClassesErrText("Classes is required");
        } else {
            setClassesErr(false);
            setClassesErrText("");
        }

        if (teachers.length === 0) {
            formIsValid = false;
            setTeachersErr(true);
            setTeachersErrText("Teachers is required");
        } else {
            setTeachersErr(false);
            setTeachersErrText("");
        }
        if (CommonConfig.isEmpty(subjectId)) {
            formIsValid = false;
            setSubjectIdErr(true);
            setSubjectIdErrText("Subject Id is required");
        } else {
            setSubjectIdErr(false);
            setSubjectIdErrText("");
        }
        return formIsValid;
    };

    const AddEditSubjectMaster = async (e) => {
        if (validate(e)) {
            try {
                Loader.show();
                let data = {
                    id: props.ID,
                    SubjectName: subjectName,
                    SubjectId: subjectId,
                    Classes: classes.length > 1 ? classes.join(',') : classes[0],
                    Teachers: teachers.length > 1 ? teachers.join(',') : teachers[0],
                    IsGrade: isGrade,
                    IsOptional: isOptional,
                    CreatedBy: securityUserID,
                };

                await api
                    .post(APIConstant.path.AddUpdateSubjectMaster, data)
                    .then((response) => {
                        let res = response;
                        if (res.success) {
                            Toast.success({ message: res.message });
                            Loader.hide();
                            onCancel();
                        } else {
                            Toast.error({ message: res.message });
                            Loader.hide();
                            // onCancel();
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        Loader.hide();
                        onCancel();
                    });
            } catch (err) {
                console.log(err);
                Loader.hide();
                onCancel();
            }
        }
    };

    const handleChange = (e, type) => {
        const value = e.target.value;
        const alphanumericValue = value.replace(/[^a-zA-Z0-9\s]/g, '');
        if (type === 'SubjectName') {
            setSubjectName(alphanumericValue);
        } else if (type === 'SubjectId') {
            setSubjectId(alphanumericValue);
        }
    };

    return (
        <div className="p-d-flex p-flex-column p-jc-between">
            <div className='p-grid'>
                <div className="p-col-12 p-md-6 p-pt-2">
                    <div className="">
                        <label htmlFor="clasName">Subject Name<span style={{ color: "red" }}>*</span></label>
                        <FloatLabel>
                            <InputText
                                className="p-mt-2 p-w-100"
                                id="className"
                                type="text"
                                placeholder="Subject Name"
                                maxLength={20}
                                value={subjectName}
                                autoFocus={true}
                                onChange={(e) => handleChange(e, 'SubjectName')}
                            />
                        </FloatLabel>
                        <span className="error-msg" style={{ color: 'red' }}>
                            {subjectNameErr ? subjectNameErrText : null}
                        </span>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                    <div className="">
                        <label htmlFor="subjectId">Subject Id <span style={{ color: "red" }}>*</span></label>
                        <FloatLabel>
                            <InputText
                                className="p-mt-2 p-w-100"
                                id="subjectId"
                                type="text"
                                placeholder="Subject Id"
                                maxLength={10}
                                onChange={(e) => handleChange(e, 'SubjectId')}
                                value={subjectId}
                            />
                        </FloatLabel>
                        <span className="error-msg" style={{ color: 'red' }}>
                            {subjectIdErr ? subjectIdErrText : null}
                        </span>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                    <div className="">
                        <label htmlFor="Classes" className="mr-2">Classes<span style={{ color: "red" }}>*</span></label>
                        <div className="custom-inputs">
                            <MultiSelect className='p-w-100' id="classes" value={classes} options={classesList} onChange={(e) => setClasses(e.value)} placeholder="Select Classes" filter display='chip' />
                            <span className="p-error block" style={{ color: 'red' }}>
                                {classesErr ? classesErrText : null}
                            </span>
                        </div>

                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                    <div className="">
                        <div className="custom-inputs">
                            <label htmlFor="Teachers" className="mr-2">Teachers<span style={{ color: "red" }}>*</span></label>
                            <MultiSelect
                                className="p-w-100"
                                inputId="teachers"
                                placeholder="Select Teachers"
                                value={teachers}
                                onChange={(e) => setTeachers(e.value)}
                                options={teachersList}
                                optionLabel="label"
                                filter
                                display='chip'
                            />
                            <span className="p-error block" style={{ color: 'red' }}>
                                {teachersErr ? teachersErrText : null}
                            </span>
                        </div>

                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                    <div className="p-d-flex p-ai-center">
                        <div className="custom-inputs">
                            <p className='p-m-0'>Grade </p>
                            <ToggleButton
                                className='p-w-100'
                                onLabel="Yes"
                                offLabel="No"
                                onIcon="pi pi-check"
                                offIcon="pi pi-times"
                                checked={isGrade}
                                onChange={(e) => setIsGrade(e.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                    <div className="">
                        <div className="custom-inputs">
                            <p className='p-m-0'>Is Optional </p>
                            <ToggleButton
                                className='p-w-100'
                                onLabel="Yes"
                                offLabel="No"
                                onIcon="pi pi-check"
                                offIcon="pi pi-times"
                                checked={isOptional}
                                onChange={(e) => setIsOptional(e.value)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='dialog-footer1 p-d-flex p-ai-center p-jc-end'>
                <Button className='p-m-0 primary-btn1' onClick={() => onCancel()}> cancel </Button>
                <Button className='p-m-0 primary-btn p-m-1' label="Submit" severity="secondary" raised onClick={() => AddEditSubjectMaster()} />
            </div>
        </div>
    );
};

export default AddEditSubjectMasterDialog;
