import React, { useState, useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import CommonConfig from '../../../utils/constant';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import Toast from '../../Shared/Toast/Toast';
import moment from 'moment';
import { MultiSelect } from 'primereact/multiselect';

const SubjectConfig = (props) => {
    const [securityUserID, setSecurityUserID] = useState("");
    const [subjectList, setSubjectList] = useState([]);
    const [studentList, setStudentList] = useState([]);
    const [classConfigurationId, setClassConfigurationId] = useState('');

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        GetSubjectList(loginData?._id);
        let listData = props?.studentList ? props?.studentList?.map(x => ({
            ...x,
            label: x?.Name,
            value: x?.id
        })) : [];
        setStudentList(listData);
    }, []);

    const GetSubjectList = async (id) => {
        try {
            let data = {
                ClassId: props?.configId,
                UserID: id
            }
            await api.post(APIConstant.path.getSubjectByClassId, data).then(async response => {
                let res = response;
                if (res.success) {
                    let sortedData = res?.data?.filter(x => x?.IsOptional);
                    setSubjectList(sortedData);
                    await GetClassConfigurationDetail(sortedData);
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const GetClassConfigurationDetail = async (sortedSubjectList) => {
        try {
            let data = {
                ClassId: props?.configId,
            }
            Loader.show();
            await api.post(APIConstant.path.getClassConfigurationList, data).then(async response => {
                let res = response;
                if (res.success) {
                    let resData = res.data[0];
                    setClassConfigurationId(resData?._id);
                    var optionalList = resData?.OptionalSubject || [];
                    let sortedData = sortedSubjectList?.map(subject => {
                        const matched = optionalList.find(opt => opt?.SubjectId === subject?._id);
                        return {
                            ...subject,
                            StudentId: matched ? matched?.StudentId ? matched?.StudentId.split(',') : [] : [],
                        };
                    });
                    setSubjectList(sortedData);
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) {
            Loader.hide();
            console.log(err);
        }
    }

    const UpdateConfiguration = async (e) => {
        try {
            let updatedData = subjectList ? subjectList.map(x => ({
                SubjectId: x._id,
                StudentId: x?.StudentId ? x?.StudentId.length > 1 ? x?.StudentId.join(',') : x?.StudentId[0] : ''
            })) : [];
            let data = {
                id: classConfigurationId,
                ClassId: props?.configId,
                OptionalSubject: updatedData,
                UpdatedBy: classConfigurationId ? securityUserID : '',
            };

            Loader.show();
            await api.post(APIConstant.path.AddUpdateClassConfiguration, data).then(async response => {
                let res = response;
                if (res.success) {
                    Toast.success({ message: res.message });
                    Loader.hide();
                    props.onCancel();
                } else {
                    Toast.error({ message: res.message });
                    Loader.hide();
                    props.onCancel();
                }
            })
                .catch((err) => {
                    Loader.hide();
                    props.onCancel();
                });
        } catch (err) {
            console.log(err);
            Loader.hide();
            props.onCancel();
        }
    };


    const srNoBodyTemplateSrNo = (data, props) => {
        return props.rowIndex + 1;
    };

    const handleChange = (rowIndex, value) => {
        const updatedSubjectValues = [...subjectList];
        updatedSubjectValues[rowIndex]['StudentId'] = value;
        setSubjectList(updatedSubjectValues);
    };

    const studentBodyTemplate = (rowData, { rowIndex }) => {
        return (
            <React.Fragment>
                <div className='p-d-flex p-ai-center'>
                    <MultiSelect
                        value={rowData?.StudentId}
                        options={studentList}
                        onChange={(e) => handleChange(rowIndex, e.value)}
                        showClear display="chip"
                        id="studentName"
                        placeholder="Select Students..."
                        className='p-w-100'
                        filter />
                </div>
            </React.Fragment>
        );
    };

    return (
        <div className="p-4 fee-management-container">
            <div className="p-col-12 p-card p-p-3">
                <DataTable className='p-mt-2' value={subjectList} paginator rows={10}>
                    <Column body={srNoBodyTemplateSrNo} header="Sr No." />
                    <Column field="SubjectName" header="Subject Name" />
                    <Column field="SubjectId" header="Subject Code" />
                    <Column header="Students" body={studentBodyTemplate} />
                </DataTable>
            </div>
            <div className="dialog-footer1 p-d-flex p-ai-center p-jc-end">
                <Button className='p-m-0 primary-btn1 p-m-1' label="Cancel" onClick={() => props.onCancel()} />
                <Button label="Save" className="p-m-0 btn-text-action-primary" onClick={(e) => UpdateConfiguration(e)} /> 
            </div>
        </div>
    )
}

export default SubjectConfig;
