
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import React, { useState, useEffect } from 'react';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import Toast from '../../Shared/Toast/Toast';
import { FloatLabel } from 'primereact/floatlabel';

const AddEditRoomMasterDialog = (props) => {
    const [securityUserID, setSecurityUserID] = useState("");
    const [roomAddress, setRoomAddress] = useState(null);
    const [totalCapacity, setTotalCapacity] = useState(null);
    const [roomName, setRoomName] = useState('');
    const [roomNameErrText, setRoomNameErrText] = useState('');
    const [roomNameErr, setRoomNameErr] = useState(false);
    const [roomAddressErrText, setRoomAddressErrText] = useState('');
    const [roomAddressErr, setRoomAddressErr] = useState(false);
    const [totalCapacityErrText, setTotalCapacityErrText] = useState('');
    const [totalCapacityErr, setTotalCapacityErr] = useState(false);
    const [benchX2, setBenchX2] = useState('');
    const [benchX2ErrText, setBenchX2ErrText] = useState('');
    const [benchX2Err, setBenchX2Err] = useState(false);
    const [benchX3, setBenchX3] = useState('');
    const [benchX3ErrText, setBenchX3ErrText] = useState('');
    const [benchX3Err, setBenchX3Err] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                Loader.show();
                const loginData = CommonConfig.loginData();
                setSecurityUserID(loginData?._id);
                if (props.ID) {
                    setRoomName(props?.masterData?.RoomNo);
                    setRoomAddress(props?.masterData?.RoomAddress);
                    setTotalCapacity(props?.masterData?.TotalCapacity);
                    setBenchX2(props?.masterData?.BenchX2);
                    setBenchX3(props?.masterData?.BenchX3);
                }
                Loader.hide();
            } catch (error) {
                Loader.hide();
                console.error('Error in useEffect:', error);
            }
        };
        fetchData();
    }, []);

    const onCancel = () => {
        props.onCancel();
    }

    const validate = () => {
        let formIsValid = true;
        if (CommonConfig.isEmpty(roomName)) {
            formIsValid = false;
            setRoomNameErr(true);
            setRoomNameErrText("Room No is required");
        } else {
            setRoomNameErr(false);
            setRoomNameErrText("");
        }
        return formIsValid;
    };

    const AddEditRoomMaster = async (e) => {
        if (validate(e)) {
            try {
                Loader.show();
                let data = {
                    id: props.ID,
                    RoomNo: roomName,
                    RoomAddress: roomAddress,
                    TotalCapacity: totalCapacity,
                    BenchX2: benchX2,
                    BenchX3: benchX3,
                    CreatedBy: securityUserID,
                };
                await api
                    .post(APIConstant.path.AddUpdateRoomMaster, data)
                    .then((response) => {
                        let res = response;
                        if (res.success) {
                            Toast.success({ message: res.message });
                            Loader.hide();
                            onCancel();
                        } else {
                            Toast.error({ message: res.message });
                            Loader.hide();
                            // onCancel();
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        Loader.hide();
                        onCancel();
                    });
            } catch (err) {
                console.log(err);
                Loader.hide();
                onCancel();
            }
        }
    };

    const handleChange = (e, type) => {
        const value = e.target.value;
        const alphanumericValue = value.replace(/[^a-zA-Z0-9\s]/g, '');
        const numericValue = value.replace(/[^0-9]/g, '');
        if (type === 'RoomName') {
            setRoomName(alphanumericValue);
        } else if (type === 'RoomAddress') {
            setRoomAddress(alphanumericValue);
        } else if (type === 'BenchX2') {
            setBenchX2(numericValue);
        } else if (type === 'BenchX3') {
            setBenchX3(numericValue);
        } else if (type === 'TotalCapacity') {
            setTotalCapacity(numericValue);
        }
    };

    return (
        <div className="p-d-flex p-flex-column p-jc-between">
            <div className='p-grid'>
                <div className="p-col-12 p-md-6 p-pt-2">
                    <div className="">
                        <label htmlFor="roomName">Room No<span style={{ color: "red" }}>*</span></label>
                        <FloatLabel>
                            <InputText
                                className="p-mt-2 p-w-100"
                                id="roomName"
                                type="text"
                                placeholder="Room No"
                                maxLength={10}
                                value={roomName}
                                autoFocus={true}
                                onChange={(e) => handleChange(e, 'RoomName')}
                            />
                        </FloatLabel>
                        <span className="error-msg" style={{ color: 'red' }}>
                            {roomNameErr ? roomNameErrText : null}
                        </span>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                    <div className="">
                        <label htmlFor="roomAddress">Room Address </label>
                        <FloatLabel>
                            <InputText
                                className="p-mt-2 p-w-100"
                                id="roomAddress"
                                type="text"
                                placeholder="Room Address"
                                maxLength={20}
                                value={roomAddress}
                                onChange={(e) => handleChange(e, 'RoomAddress')}
                            />
                        </FloatLabel>
                        <span className="error-msg" style={{ color: 'red' }}>
                            {roomAddressErr ? roomAddressErrText : null}
                        </span>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                    <div className="">
                        <label htmlFor="totalCapacity">Total Capacity </label>
                        <FloatLabel>
                            <InputText
                                className="p-mt-2 p-w-100"
                                id="totalCapacity"
                                type="text"
                                placeholder="Total Capacity"
                                maxLength={3}
                                value={totalCapacity}
                                onChange={(e) => handleChange(e, 'TotalCapacity')}
                            />
                        </FloatLabel>
                        <span className="error-msg" style={{ color: 'red' }}>
                            {totalCapacityErr ? totalCapacityErrText : null}
                        </span>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                    <div className="">
                        <label htmlFor="benchX2">Bench x2 </label>
                        <FloatLabel>
                            <InputText
                                className="p-mt-2 p-w-100"
                                id="benchX2"
                                type="text"
                                placeholder="Bench X2"
                                maxLength={3}
                                value={benchX2}
                                onChange={(e) => handleChange(e, 'BenchX2')}
                            />
                        </FloatLabel>
                        <span className="error-msg" style={{ color: 'red' }}>
                            {benchX2Err ? benchX2ErrText : null}
                        </span>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                    <div className="">
                        <label htmlFor="benchX3">Bench X3 </label>
                        <FloatLabel>
                            <InputText
                                className="p-mt-2 p-w-100"
                                id="benchX3"
                                type="text"
                                placeholder="Bench X3"
                                maxLength={3}
                                value={benchX3}
                                onChange={(e) => handleChange(e, 'BenchX3')}
                            />
                        </FloatLabel>
                        <span className="error-msg" style={{ color: 'red' }}>
                            {benchX3Err ? benchX3ErrText : null}
                        </span>
                    </div>
                </div>
            </div>
            <div className='dialog-footer1 p-d-flex p-ai-center p-jc-end'>
                <Button className='p-m-0 primary-btn1' onClick={() => onCancel()}> cancel </Button>
                <Button className='p-m-0 primary-btn p-m-1' label="Submit" severity="secondary" raised onClick={() => AddEditRoomMaster()} />
            </div>
        </div>
    );
};

export default AddEditRoomMasterDialog;