import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { TabView, TabPanel } from 'primereact/tabview';
import Loader from "../Shared/Loader/Loader";
import APIConstant from "../../utils/pathConstants";
import api from "../../utils/apiClient";
import CommonConfig from '../../utils/constant';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import { useNavigate } from 'react-router-dom';
import Toast from '../Shared/Toast/Toast';
import moment from 'moment';
import { apiBase } from '../../utils/config';
import { MultiSelect } from 'primereact/multiselect';
import { Dialog } from 'primereact/dialog';
import DeleteConfirmation from '../Dialogs/DeleteConfirmation';
import UploadFile from '../UploadFile/UploadFile';


const SchoolProfile = () => {
    const navigate = useNavigate();
    const fileUploadRef = useRef(null);
    const [boardList, setBoardList] = useState([]);
    const [personalDetailId, setPersonalDetailId] = useState('');
    const [schoolDetailid, setSchoolDetailId] = useState('');
    const [profilePath, setProfilePath] = useState(null);
    const [board, setBoard] = useState([]);
    const [medium, setMedium] = useState([]);
    const [userName, setUserName] = useState('');
    const [schoolIndexNo, setSchoolIndexNo] = useState('');
    const [slogan, setSlogan] = useState('');
    const [schoolEstabalishedDate, setSchoolEstabalishedDate] = useState('');
    const [affiliationNo, setAffiliationNo] = useState('');
    const [affiliationFrom, setAffiliationFrom] = useState('');
    const [affiliationTo, setAffiliationTo] = useState('');
    const [panNo, setPanNo] = useState('');
    const [gstNo, setGSTNo] = useState('');
    const [securityUserID, setSecurityUserID] = useState("");
    const [mediumList, setMediumList] = useState([]);
    const [mobileNumber, setMobileNumber] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [emailErr, setEmailErr] = useState(false);
    const [emailErrText, setEmailErrText] = useState('');
    const [pinCode, setPinCode] = useState('');
    const [currentAddress, setCurrentAddress] = useState('');
    const [planName, setPlanName] = useState('');
    const [planFrom, setPlanFrom] = useState('');
    const [planTo, setPlanTo] = useState('');
    const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);
    const [previewProfilePath, setPreviewProfilePath] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                Loader.show();
                const loginData = CommonConfig.loginData();
                setSecurityUserID(loginData?._id);
                await GetLanguageList();
                await GetBoardList(loginData?._id);
                await GetUserDetail(loginData?._id);
                await getPersonDetail(loginData?._id);
                await getSchoolDetail(loginData?._id);
                Loader.hide();
            } catch (error) {
                Loader.hide();
                console.error('Error in useEffect:', error);
            } finally {
                Loader.hide();
            }
        }
        fetchData();
    }, []);

    const GetLanguageList = async () => {
        try {
            await api.get(APIConstant.path.getLanguageList).then(async response => {
                let res = response;
                if (res.success) {
                    setMediumList(res?.data?.map(x => ({
                        ...x,
                        label: x?.LanguageName,
                        value: x?._id
                    })));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const GetBoardList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.getBoardList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setBoardList(res.data.map(x => ({
                        ...x,
                        label: x?.BoardName,
                        value: x?._id
                    })));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const GetUserDetail = async (userid) => {
        try {
            let data = {
                id: userid,
            }
            await api.post(APIConstant.path.getUserById, data).then(async response => {
                let res = response;
                if (res.success) {
                    setUserName(res?.data?.Name);
                    setMobileNumber(res?.data?.Phone);
                    setEmailAddress(res?.data?.Email);
                    setPlanName(res?.data?.PlanId);
                    setPlanFrom(res?.data?.PlanStartDate ? new Date(res?.data?.PlanStartDate) : '');
                    setPlanTo(res?.data?.PlanEndDate ? new Date(res?.data?.PlanEndDate) : '');
                    setProfilePath(res?.data?.ProfilePhotoUrl ? `${apiBase}/UserProfilePic/${res?.data?.ProfilePhotoUrl}` : null)
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const getPersonDetail = async (ID) => {
        try {
            let data = {
                id: ID,
            };
            await api
                .post(APIConstant.path.GetPersonalDetailById, data)
                .then(async (response) => {
                    let res = await response;
                    if (res.success) {
                        Loader.hide();
                        setPersonalDetailId(res?.data?._id);
                        setCurrentAddress(res?.data?.CurrentAddress);
                        setPinCode(res?.data?.PinCode);
                    }
                })
                .catch((err) => {
                    Loader.hide();
                    console.log(err);
                });
        } catch (err) {
            console.log(err);
        }
    };

    const getSchoolDetail = async (ID) => {
        try {
            let data = {
                id: ID,
            };
            await api
                .post(APIConstant.path.GetSchoolDetailById, data)
                .then(async (response) => {
                    let res = await response;
                    if (res.success) {
                        Loader.hide();
                        setSchoolDetailId(res?.data[0]?._id);
                        setSchoolIndexNo(res?.data[0]?.IndexNo);
                        setSlogan(res?.data[0]?.Slogan);
                        setMedium(res?.data[0]?.Medium?.split(',') || []);
                        setBoard(res?.data[0]?.Board?.split(',') || []);
                        setSchoolEstabalishedDate(res?.data[0]?.SchoolEstablished ? new Date(res?.data[0]?.SchoolEstablished) : '');
                        setAffiliationFrom(res?.data[0]?.AffiliationFrom ? new Date(res?.data[0]?.AffiliationFrom) : '');
                        setAffiliationTo(res?.data[0]?.AffiliationTo ? new Date(res?.data[0]?.AffiliationTo) : '');
                        setAffiliationNo(res?.data[0]?.AffiliationNo);
                        setPanNo(res?.data[0]?.PANNo);
                        setGSTNo(res?.data[0].GSTNo);
                    }
                })
                .catch((err) => {
                    Loader.hide();
                    console.log(err);
                });
        } catch (err) {
            console.log(err);
        }
    };

    const handleSave = async () => {
        await AddEditPersonalDetail();
        await AddEditSchoolDetail();
        await AddEditUserDetail();
        onReferesh();
        handleBack();
    }

    const onReferesh = () => {
        GetUserDetail(securityUserID);
        getPersonDetail(securityUserID);
        getSchoolDetail(securityUserID);
    }

    const AddEditPersonalDetail = async () => {
        try {
            let data = {
                PersonalId: personalDetailId,
                UserId: securityUserID,
                PinCode: pinCode,
                CurrentAddress: currentAddress,
                CreatedBy: securityUserID,
            };
            Loader.show();
            await api.post(APIConstant.path.AddPersonalDetail, data).then(async response => {
                let res = response;
                if (res.success) {
                    Toast.success({ message: 'Details Updated' });
                    Loader.hide();
                } else {
                    Toast.error({ message: res.message });
                    Loader.hide();
                }
            })
                .catch(() => {
                    Loader.hide();
                });
        } catch (err) {
            console.log(err);
            Loader.hide();
        }
    };

    const AddEditSchoolDetail = async () => {
        try {
            let data = {
                id: schoolDetailid,
                UserId: securityUserID,
                PANNo: panNo,
                SchoolEstablished: schoolEstabalishedDate ? moment(schoolEstabalishedDate).format("YYYY-MM-DD") : '',
                AffiliationFrom: affiliationFrom ? moment(affiliationFrom).format("YYYY-MM-DD") : '',
                AffiliationTo: affiliationTo ? moment(affiliationTo).format("YYYY-MM-DD") : '',
                AffiliationNo: affiliationNo,
                Board: board.length > 1 ? board.join(',') : board[0],
                Medium: medium.length > 1 ? medium.join(',') : medium[0],
                Slogan: slogan,
                GSTNo: gstNo,
                IndexNo: schoolIndexNo,
                CreatedBy: securityUserID
            };
            Loader.show();
            await api.post(APIConstant.path.AddSchoolDetail, data).then(async response => {
                let res = response;
                if (res.success) {
                    // Toast.success({ message: 'School details updated' });
                    Loader.hide();
                } else {
                    Toast.error({ message: res.message });
                    Loader.hide();
                }
            })
                .catch(() => {
                    Loader.hide();
                });
        } catch (err) {
            console.log(err);
            Loader.hide();
        }
    };

    const handleFileSelect = (e) => {
        const selectedFile = e.files[0];
        if (selectedFile) {
            const validFormats = ['image/jpeg', 'image/png', 'image/jpg', 'image/svg'];
            if (validFormats.includes(selectedFile.type)) {
                const img = new Image();
                img.onload = () => {
                    if (img.width === img.height) {
                        setProfilePath(selectedFile);
                        const fileURL = URL.createObjectURL(selectedFile);
                        setPreviewProfilePath(fileURL);
                    } else {
                        if (fileUploadRef.current) {
                            fileUploadRef.current.clear();
                        }
                        Toast.error({ message: 'The image must have a 1:1 aspect ratio.' });
                    }
                };
                img.onerror = () => {
                    if (fileUploadRef.current) {
                        fileUploadRef.current.clear();
                    }
                    Toast.error({ message: 'Error loading the image.' });
                };

                const reader = new FileReader();
                reader.onload = (event) => {
                    img.src = event.target.result;
                };
                reader.readAsDataURL(selectedFile);
            } else {
                if (fileUploadRef.current) {
                    fileUploadRef.current.clear();
                }
                Toast.error({ message: 'Invalid file format. Please select a JPEG, SVG or PNG image.' });
            }
        }
    };

    const handleChange = (e, type) => {
        const value = e.target.value;
        const alphanumericValue = value.replace(/[^a-zA-Z0-9\s]/g, '');
        const numericValue = value.replace(/[^0-9]/g, '');
        if (type === 'PAN') {
            setPanNo(alphanumericValue.toUpperCase().trim());
        } else if (type === 'GST') {
            setGSTNo(alphanumericValue.toUpperCase());
        } else if (type === 'AffiliationNo') {
            setAffiliationNo(numericValue);
        } else if (type === 'IndexNo') {
            setSchoolIndexNo(numericValue);
        } else if (type === 'MobileNo') {
            setMobileNumber(numericValue);
        } else if (type === 'PinCode') {
            setPinCode(numericValue);
        }

    };

    const handleEmailChange = (e) => {
        const value = e.target.value;
        const maxLength = 50;
        if (value.length <= maxLength) {
            setEmailAddress(value);
        }
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailPattern.test(value)) {
            setEmailErr(true);
            setEmailErrText('Please enter a valid email address.');
            // Toast.error({ message: 'Please enter a valid email address.' });
        } else {
            setEmailErr(false);
            setEmailErrText('');
        }
    };

    const AddEditUserDetail = async () => {
        try {
            const formData = new FormData();
            formData.append("id", securityUserID);
            formData.append("Name", userName);
            formData.append("Email", emailAddress);
            formData.append("Phone", mobileNumber);
            formData.append("CreatedBy", securityUserID);
            if (profilePath && profilePath.type) {
                formData.append("userProfilePic", profilePath);
            } else if (CommonConfig.isEmpty(profilePath)) {
                formData.append("userProfilePic", '');
            }
            Loader.show();
            await api.post(APIConstant.path.AddUpdateUser, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
                .then(async (response) => {
                    let res = response;
                    if (res.success) {
                        // Toast.success({ message: 'User details updated successfully' });
                    } else {
                        Toast.error({ message: res.message });
                    }
                    Loader.hide();
                })
                .catch((err) => {
                    console.error(err);
                    // Toast.error({ message: 'Failed to update user details' });
                    Loader.hide();
                });
        } catch (err) {
            console.log(err);
            Toast.error({ message: 'An error occurred' });
            Loader.hide();
        }
    };

    const renderFooter = () => (
        <div className="dialog-footer1 p-d-flex p-ai-center p-jc-end">
            <Button
                label="Cancel"
                className="primary-btn1"
                onClick={handleClose}
            />
            <Button
                label="Delete"
                className="primary-btn"
                onClick={() => {
                    setProfilePath('')
                    setPreviewProfilePath('');
                    if (fileUploadRef.current) {
                        fileUploadRef.current.clear();
                    }
                    handleClose()
                }}
            />
        </div>
    );

    const handleClose = () => {
        setIsDeleteConfirmation(false)
    };

    const handleBack = () => {
        const loginData = CommonConfig.loginData();
        CommonConfig.getUserMenu(loginData, navigate);
    }

    const handleDelete = (e) => {
        setIsDeleteConfirmation(true)
    };

    return (
        <div className='wayment-owner-dashboard e-edit-profile-dashoard p-py-3'>

            <div className="heading-area p-d-flex p-ai-center p-jc-between">
                <div className="heading-area p-d-flex p-ai-baseline">
                    <i className="pi pi-home"></i>
                    <h2 className='p-m-0'>User Profile test</h2>
                </div>
            </div>

            <hr />
            <TabView>
                <TabPanel header="Basic details">
                    <div className="p-grid">
                        <div className="p-col-12 p-md-4">
                            <UploadFile label='' note='' preview={previewProfilePath} original={profilePath} uploadHandler={handleFileSelect} uploadRef={fileUploadRef} handleDelete={handleDelete} />
                        </div>
                        <div className="p-col-12 p-md-8">
                            <div className="p-grid">
                                <div className="p-col-12 p-md-6">
                                    <div className="p-field">
                                        <p className='p-m-0 p-pb-1'>School Name</p>
                                        <InputText className='p-w-100' value={userName} maxLength={40} onChange={(e) => setUserName(e.target.value)} />
                                    </div>
                                </div>
                                <div className="p-col-12 p-md-6">
                                    <div className="p-field">
                                        <p className='p-m-0 p-pb-1'>School Index No.</p>
                                        <InputText className='p-w-100' value={schoolIndexNo} maxLength={20} onChange={(e) => handleChange(e, 'IndexNo')} />
                                    </div>
                                </div>
                                <div className="p-col-12 p-md-6">
                                    <div className="p-field">
                                        <p className='p-m-0 p-pb-1'>Slogan</p>
                                        <InputText className='p-w-100' id='slogan' value={slogan} maxLength={20} onChange={(e) => setSlogan(e.target.value)} />
                                    </div>
                                </div>
                                <div className="p-col-12 p-md-6">
                                    <div className="p-field">
                                        <p className='p-m-0 p-pb-1'>Mediums</p>
                                        <MultiSelect className='p-w-100' id="medium" value={medium} options={mediumList}
                                            onChange={(e) => setMedium(e.value)} placeholder="Select Medium" filter display='chip' />
                                    </div>
                                </div>
                                <div className="p-col-12 p-md-6">
                                    <div className="p-field">
                                        <p className='p-m-0 p-pb-1'>School Established</p>
                                        <Calendar className='p-w-100 e-sction-btn' id="schoolEstabalishedDate" value={schoolEstabalishedDate} onChange={(e) => setSchoolEstabalishedDate(e.value)} showIcon dateFormat='dd/mm/yy' />
                                    </div>
                                </div>
                                <div className="p-col-12 p-md-6">
                                    <div className="p-field">
                                        <p className='p-m-0 p-pb-1'>Board</p>
                                        <MultiSelect className='p-w-100' id="board" value={board} options={boardList}
                                            onChange={(e) => setBoard(e.value)} placeholder="Select Board" filter display='chip' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='p-grid'>
                        <div className="p-col-12 p-md-4">
                            <div className="p-field">
                                <p className='p-m-0 p-pb-1'>Affiliation Number</p>
                                <InputText className='p-w-100' maxLength={10} value={affiliationNo} onChange={(e) => handleChange(e, 'AffiliationNo')} />
                            </div>
                        </div>
                        <div className="p-col-12 p-md-4">
                            <div className="p-field">
                                <p className='p-m-0 p-pb-1'>Affiliation From</p>
                                <Calendar className='p-w-100 e-sction-btn' id="affiliationFrom" value={affiliationFrom} onChange={(e) => setAffiliationFrom(e.value)} showIcon dateFormat='dd/mm/yy' />
                            </div>
                        </div>
                        <div className="p-col-12 p-md-4">
                            <div className="p-field">
                                <p className='p-m-0 p-pb-1'>Affiliation To</p>
                                <Calendar className='p-w-100 e-sction-btn' id="affiliationTo" value={affiliationTo} onChange={(e) => setAffiliationTo(e.value)} showIcon dateFormat='dd/mm/yy' />
                            </div>
                        </div>
                        <div className="p-col-12 p-md-4">
                            <div className="p-field">
                                <p className='p-m-0 p-pb-1'>PAN</p>
                                <InputText className='p-w-100' maxLength={10} value={panNo} onChange={(e) => handleChange(e, 'PAN')} />
                            </div>
                        </div>
                        <div className="p-col-12 p-md-4">
                            <div className="p-field">
                                <p className='p-m-0 p-pb-1'>GST No. (Optional)</p>
                                <InputText className='p-w-100' maxLength={15} value={gstNo} onChange={(e) => handleChange(e, 'GST')} />
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel header="Contact details">
                    <div className="form-section p-mb-0">
                        <div className="p-grid">
                            <div className="p-col-12 p-md-4">
                                <div className="p-field">
                                    <p className='p-m-0'>Email Address</p>
                                    <InputText className='p-w-100' id="fullName" value={emailAddress} onChange={(e) => handleEmailChange(e)} />
                                    <span className="p-error block" style={{ color: 'red' }}>
                                        {emailErr ? emailErrText : null}
                                    </span>
                                </div>
                            </div>
                            <div className="p-col-12 p-md-4">
                                <div className="p-field">
                                    <p className='p-m-0'>Contact Number</p>
                                    <InputText className='p-w-100' id="contactNo" maxLength={10} value={mobileNumber} onChange={(e) => handleChange(e, 'MobileNo')} />
                                </div>
                            </div>
                            <div className="p-col-12 p-md-4">
                                <div className="p-field">
                                    <p className='p-m-0'>Pin Code</p>
                                    <InputText className='p-w-100' id="pinCode" maxLength={6} value={pinCode} onChange={(e) => handleChange(e, 'PinCode')} />
                                </div>
                            </div>
                            <div className="p-col-12 p-md-12">
                                <div className="p-field">
                                    <p className='p-m-0'>Current Address</p>
                                    <InputTextarea className='p-w-100' id="currentAddress" value={currentAddress} type='textArea' rows={5} cols={30} onChange={(e) => setCurrentAddress(e.target.value)} />
                                </div>
                            </div>

                        </div>
                    </div>
                </TabPanel>
                <TabPanel header="Plan details">
                    <div className="form-section p-mb-0">
                        <div className="p-fluid">
                            <div className='p-grid'>
                                <div className="p-col-12 p-md-6">
                                    <div className="p-field">
                                        <p className='p-m-0 p-pb-1'>Plan Name</p>
                                        <InputText id="planName" value={planName} disabled />
                                    </div>
                                </div>
                            </div>
                            <div className='p-mt-1'>
                                <p className='plan-duration-text'>Plan Duration</p>
                                <hr />
                                <div className='p-grid'>
                                    <div className="p-col-12 p-md-6">
                                        <div className="p-field e-plan-duration">
                                            <p className='p-m-0 p-pb-1'>From</p>
                                            <Calendar className='p-d-flex p-ai-center p-m-0' id="planFrom" value={planFrom} showIcon disabled dateFormat='dd/mm/yy' />
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-6">
                                        <div className="p-field e-plan-duration">
                                            <p className='p-m-0 p-pb-1'>To</p>
                                            <Calendar className='p-d-flex p-ai-center p-m-0' id="planTo" value={planTo} showIcon disabled dateFormat='dd/mm/yy' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </TabPanel>
            </TabView>
            <div className="form-actions p-d-flex p-ai-center p-jc-end">
                <Button label="Back to Home" icon="pi pi-arrow-left" className="p-button-secondary p-m-0" onClick={() => handleBack()} />
                <Button label="Save" className="p-button-primary p-my-0 p-mr-0 p-ml-2" onClick={() => handleSave()} />
            </div>
            <Dialog
                visible={isDeleteConfirmation}
                onHide={handleClose}
                header={
                    <div className="p-d-flex p-jc-between p-ai-center">
                        <span>Delete Confirmation</span>
                    </div>
                }
                footer={renderFooter()}
                draggable={false}
                dismissableMask
                style={{ width: '450px' }}
            >
                <DeleteConfirmation />
            </Dialog>
        </div>

    );
};

export default SchoolProfile;
