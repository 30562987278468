import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { FloatLabel } from 'primereact/floatlabel';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primeicons/primeicons.css';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import { MultiSelect } from 'primereact/multiselect';
import { RadioButton } from 'primereact/radiobutton';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';

const TemplateValues = (props) => {
    const { onSave, editData, totalOfList, bestOfList } = props;
    const [securityUserID, setSecurityUserID] = useState("");
    const [examTypeList, setExamTypeList] = useState([]);
    const [examType, setExamType] = useState('Individual');
    const [examName, setExamName] = useState('');
    const [examNameErr, setExamNameErr] = useState(false);
    const [examNameErrText, setExamNameErrText] = useState('');
    const [shortName, setShortName] = useState('');
    const [totalMarks, setTotalMarks] = useState('');
    const [term, setTerm] = useState([]);
    const [totalValueList, setTotalValueList] = useState([]);
    const [totalValue, setTotalValue] = useState([]);
    const [bestOfValueList, setBestOfValueList] = useState([]);
    const [bestOfValue, setBestOfValue] = useState([]);
    const [termsList, setTermsList] = useState([]);
    const [templateValueId, setTemplateValueId] = useState('');
    const [isOverAllAllow, setIsOverAllAllow] = useState(false);

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        const totalOfData = [{
            Value: '',
            Weightage: ''
        }]
        setSecurityUserID(loginData?._id);
        GetTermsList(loginData?._id);
        GetExamTypeList();
        if (totalOfList) {
            const listData = totalOfList?.map(x => ({
                label: x?.Name,
                value: x?.Name
            }))
            setTotalValueList(listData);
        }
        if (bestOfList) {
            const listData = bestOfList?.map(x => ({
                label: x?.Name,
                value: x?.Name
            }))
            setBestOfValueList(listData);
        }
        if (editData) {
            setTemplateValueId(editData?._id);
            setExamType(editData?.ExamType);
            setExamName(editData?.Name);
            setIsOverAllAllow(editData?.IsOverAllAllow);
            setShortName(editData?.ShortName);
            setTotalMarks(editData?.Marks);
            setTerm(editData?.Term ? editData?.Term.split(',') : []);
            setBestOfValue(editData?.BestOfValue ? editData?.BestOfValue.split(',') : []);
            setTotalValue(editData?.TotalValue ? editData?.TotalValue : totalOfData);
        } else {
            setTotalValue(totalOfData);
        }
    }, [editData]);

    const GetExamTypeList = async () => {
        try {
            let data = {
                UserID: securityUserID,
            }
            await api.post(APIConstant.path.getExamTypeList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setExamTypeList(res.data);
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const GetTermsList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.getTermList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setTermsList(res?.data?.map(x => ({
                        label: x?.TermName,
                        value: x?._id,
                    })));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const handleChange = (e, type) => {
        const value = e.target.value;
        const alphanumericValue = value.replace(/[^a-zA-Z0-9\s!@#$%^&*]/g, '');
        const numericValue = value.replace(/[^0-9]/g, '');
        if (type === 'ExamName') {
            setExamName(alphanumericValue);
        } else if (type === 'ShortName') {
            setShortName(alphanumericValue);
        } else if (type === 'TotalMarks') {
            setTotalMarks(numericValue);
        }
    };

    const AddTemplateValue = () => {
        if (!CommonConfig.isEmpty(examName)) {
            const termNames = termsList
                .filter(x => term.includes(x?.value))
                .map(x => x.label);
            const totalOfName = totalValue ?
                totalValue?.map(x => x?.Value) : '';
            const weightage = totalValue ?
                totalValue?.map(x => x?.Weightage) : '';
            onSave({
                ExamType: examType,
                Name: examName,
                ShortName: shortName,
                Marks: totalMarks,
                Term: term ? term.join(',') : '',
                BestOfValue: bestOfValue ? bestOfValue.join(',') : '',
                TermName: termNames.join(', '),
                TotalValue: totalValue || [],
                TotalValueName: totalOfName.join(', '),
                Weightage: weightage.join(', '),
                IsOverAllAllow: isOverAllAllow
            });
        } else {
            setExamNameErr(true);
            setExamNameErrText('Exam is Required');
        }
    };

    const onCancel = () => {
        setExamName('');
        setExamType('');
        setTotalMarks('');
        setShortName('');
        setTerm('');
        setBestOfValue([]);
        setTotalValue([]);
        setTemplateValueId('');
        props.onCancel();
    };

    const totalValueBodyTemplate = (rowData, { rowIndex }) => {
        return (
            <div>
                <label htmlFor="totalValue">Total</label>
                <div className="custom-inputs">
                    <Dropdown
                        id={`Value-${rowIndex}`}
                        value={rowData?.Value}
                        options={totalValueList}
                        onChange={(e) => handleTemplateValuesChange(rowIndex, 'Value', e.value)}
                        placeholder="Select Total"
                        display="chip"
                        className='p-w-100'
                        filter
                    />
                </div>
            </div>
        )
    };

    const weightageBodyTemplate = (rowData, { rowIndex }) => {
        return (
            <div>
                <label htmlFor="Weightage">Weightage</label>
                <div className="custom-inputs">
                    <InputText
                        className="p-w-80"
                        id={`Weightage-${rowIndex}`}
                        type="text"
                        maxLength={3}
                        placeholder="Weightage"
                        value={rowData?.Weightage}
                        onChange={(e) => handleTemplateValuesChange(rowIndex, 'Weightage', e.target.value)}
                    />
                </div>
            </div>
        )
    };

    const actionsBodyTemplate = (rowData, { rowIndex }) => (
        <div className="p-mt-4 p-d-flex p-ai-center">
            {rowIndex === totalValue.length - 1 &&
                <Button
                    icon="pi pi-plus"
                    className="e-sction-btn p-m-0 p-mt-3"
                    onClick={() => addTemplateValue()}
                />}
            {(rowIndex !== 0 || totalValue.length > 1) &&
                <Button icon="pi pi-trash" className="e-sction-btn p-m-0 p-mt-3 p-ml-lg-1"
                    onClick={() => removeTemplateValue(rowIndex)}
                />
            }
        </div>
    );

    const addTemplateValue = () => {
        let values = [{
            'Value': '',
            'Weightage': ''
        }];
        setTotalValue([...totalValue, ...values]);
    };

    const removeTemplateValue = (index) => {
        const updatedTemplates = totalValue?.filter((_, i) => i !== index);
        setTotalValue(updatedTemplates);
    };

    const handleTemplateValuesChange = (index, key, value) => {
        const updatedTemplateValues = [...totalValue];
        if (key === 'Value') {
            updatedTemplateValues[index][key] = value;
        } else {
            const numericValue = value.replace(/[^0-9]/g, '');
            updatedTemplateValues[index][key] = numericValue;
        }
        setTotalValue(updatedTemplateValues);
    };

    return (
        <div>
            <div className='e-template-value-table'>
                <div className='p-grid'>
                    {examTypeList?.map((tab) => (
                        <div className="p-col-12 p-md-4">
                            <div className="">
                                <RadioButton
                                    inputId={tab?._id}
                                    name={tab?._id}
                                    value={tab?.ExamTypeName}
                                    checked={examType === tab?.ExamTypeName}
                                    onChange={(e) => setExamType(e.value)}
                                    disabled={CommonConfig.isEmpty(templateValueId) ? false : true}
                                />
                                <label htmlFor="Activity"> {tab?.ExamTypeName}</label>
                            </div>
                        </div>
                    ))}
                    {examType === 'Total' && <div className="p-col-12 p-md-6 p-pt-2">
                        <div className="">
                            <div className="custom-inputs">
                                <label htmlFor="isOverAllAllow" className='p-d-block'>Over All Allow</label>
                                <Checkbox
                                className='p-ml-2 p-mt-1'
                                    inputId="isOverAllAllow"
                                    checked={isOverAllAllow}
                                    onChange={(e) => setIsOverAllAllow(e.checked)}
                                />
                            </div>
                        </div>
                    </div>}
                    <div className="p-col-12 p-md-6 p-pt-2">
                        <label htmlFor="examName">ExamName<span style={{ color: "red" }}>*</span></label>
                        <div className="">
                            <FloatLabel>
                                <InputText
                                    className="p-pl-2 p-w-100"
                                    id="examName"
                                    maxLength={20}
                                    placeholder="ExamName"
                                    value={examName}
                                    autoFocus={true}
                                    onChange={(e) => handleChange(e, 'ExamName')}
                                />
                            </FloatLabel>
                            <span className="error-msg" style={{ color: 'red' }}>
                                {examNameErr ? examNameErrText : null}
                            </span>
                        </div>
                    </div>
                    <div className="p-col-12 p-md-6 p-pt-2">
                        <label htmlFor="shortName">Short Name</label>
                        <div className="">
                            <FloatLabel>
                                <InputText
                                    className="p-pl-2 p-w-100"
                                    id="shortName"
                                    maxLength={5}
                                    placeholder="Short Name"
                                    value={shortName}
                                    onChange={(e) => handleChange(e, 'ShortName')}
                                />
                            </FloatLabel>
                        </div>
                    </div>
                    <div className="p-col-12 p-md-6 p-pt-2">
                        <label htmlFor="totalMarks">Total Marks</label>
                        <div className="">
                            <FloatLabel>
                                <InputText
                                    className="p-pl-2 p-w-100"
                                    id="totalMarks"
                                    maxLength={3}
                                    placeholder="Total Marks"
                                    value={totalMarks}
                                    autoFocus={true}
                                    onChange={(e) => handleChange(e, 'TotalMarks')}
                                />
                            </FloatLabel>
                        </div>
                    </div>
                    {!isOverAllAllow && <div className="p-col-12 p-md-6 p-pt-2">
                        <div className="">
                            <label htmlFor="term">Terms</label>
                            <div className="custom-inputs">
                                <MultiSelect
                                    id="term"
                                    value={term}
                                    options={termsList}
                                    onChange={(e) => setTerm(e.value)}
                                    placeholder="Select Terms"
                                    display="chip"
                                    className='p-w-100'
                                    filter
                                />
                            </div>
                        </div>
                    </div>}
                    {examType === 'Total' &&
                        <div>
                            <DataTable value={totalValue} responsiveLayout="scroll">
                                <Column header="Total" body={totalValueBodyTemplate} />
                                <Column header="Weightage" body={weightageBodyTemplate} />
                                <Column header="Actions" body={actionsBodyTemplate} />
                            </DataTable>
                        </div>
                    }
                    {examType === 'Best Of' &&
                        <div className="p-col-12 p-md-12 p-pt-2">
                            <div className="">
                                <label htmlFor="bestOfValue">Best Of</label>
                                <div className="custom-inputs">
                                    <MultiSelect
                                        id="bestOfValue"
                                        value={bestOfValue}
                                        options={bestOfValueList}
                                        onChange={(e) => setBestOfValue(e.value)}
                                        placeholder="Select Best Of"
                                        display="chip"
                                        className='p-w-100'
                                        filter
                                    />
                                </div>
                            </div>
                        </div>
                    }
                </div>

            </div>
            <div className="dialog-footer1 p-d-flex p-ai-center p-jc-end">
                <Button className='p-m-0 primary-btn1 p-m-1' label="Cancel" onClick={(e) => onCancel()} />
                <Button className='p-m-0 primary-btn' label="Add" onClick={(e) => AddTemplateValue()} />
            </div>
        </div >
    );
};

export default TemplateValues;
