
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import React, { useState, useEffect, useRef } from 'react';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import Toast from '../../Shared/Toast/Toast';
import { FloatLabel } from 'primereact/floatlabel';
import { ColorPicker } from 'primereact/colorpicker';
import { Dialog } from 'primereact/dialog';
import DeleteConfirmation from '../../Dialogs/DeleteConfirmation';
import { apiBase } from '../../../utils/config';
import UploadFile from '../../UploadFile/UploadFile';

const AddHouseMasterDialog = (props) => {
    const [houseName, setHouseName] = useState('');
    const fileUploadRef = useRef(null);
    const [securityUserID, setSecurityUserID] = useState("");
    const [houseNameErrText, setHouseNameErrText] = useState('');
    const [houseNameErr, setHouseNameErr] = useState(false);
    const [color, setColor] = useState('8D0000');
    const [colorErr, setColorErr] = useState(false);
    const [colorErrText, setColorErrText] = useState('');
    const [backgroundImage, setBackgroundImage] = useState(null);
    const [previewProfilePath, setPreviewProfilePath] = useState('');
    const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        if (props.ID) {
            setHouseName(props?.masterData?.HouseName);
            setColor(props?.masterData?.ColorCode);
            setBackgroundImage(props?.masterData?.BgImage ? `${apiBase}/TemplateBackGround/${props?.masterData?.BgImage}` : null);
        }
    }, []);

    const onCancel = () => {
        props.onCancel();
        setHouseName('');
        setColor('');
    }

    const validate = () => {
        let formIsValid = true;
        if (CommonConfig.isEmpty(houseName)) {
            formIsValid = false;
            setHouseNameErr(true);
            setHouseNameErrText("House Name is required");
        } else {
            setHouseNameErr(false);
            setHouseNameErrText("");
        }
        if (CommonConfig.isEmpty(color)) {
            formIsValid = false;
            setColorErr(true);
            setColorErrText("Color is required");
        } else {
            setColorErr(false);
            setColorErrText("");
        }
        return formIsValid;
    };

    const AddHouseMaster = async (e) => {
        if (validate(e)) {
            try {
                Loader.show();
                const formData = new FormData();
                formData.append("id", props.ID ?? '');
                formData.append('HouseName', houseName);
                formData.append("ColorCode", color);
                formData.append("CreatedBy", securityUserID);
                if (backgroundImage && backgroundImage.type) {
                    formData.append("BackGroundImage", backgroundImage);
                } else if (CommonConfig.isEmpty(backgroundImage)) {
                    formData.append("BgImage", '');
                }
                await api
                    .post(APIConstant.path.AddUpdateHouseMaster, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }).then((response) => {
                        let res = response;
                        if (res.success) {
                            Toast.success({ message: res.message });
                            Loader.hide();
                            onCancel();
                        } else {
                            Toast.error({ message: res.message });
                            Loader.hide();
                            // onCancel();
                        }
                    })
                    .catch((err) => {
                        Toast.error({ message: err?.data?.message });
                        Loader.hide();
                        onCancel();
                    });
            } catch (err) {
                console.log(err);
                Loader.hide();
                onCancel();
            }
        }
    };

    const handleChange = (e, type) => {
        const value = e.target.value;
        const alphanumericValue = value.replace(/[^a-zA-Z0-9\s]/g, '');
        setHouseName(alphanumericValue);
    };

    const handleBackGroundImage = (e) => {
        const selectedFile = e.files[0];
        if (selectedFile) {
            const validFormats = ['image/jpeg', 'image/png', 'image/jpg', 'image/svg'];
            if (validFormats.includes(selectedFile.type)) {
                const img = new Image();
                img.onload = () => {
                    if (img.width === img.height) {
                        setBackgroundImage(selectedFile);
                        const fileURL = URL.createObjectURL(selectedFile);
                        setPreviewProfilePath(fileURL);
                    } else {
                        if (fileUploadRef.current) {
                            fileUploadRef.current.clear();
                        }
                        Toast.error({ message: 'The image must have a 1:1 aspect ratio.' });
                    }
                };
                img.onerror = () => {
                    if (fileUploadRef.current) {
                        fileUploadRef.current.clear();
                    }
                    Toast.error({ message: 'Error loading the image.' });
                };

                const reader = new FileReader();
                reader.onload = (event) => {
                    img.src = event.target.result;
                };
                reader.readAsDataURL(selectedFile);
            } else {
                if (fileUploadRef.current) {
                    fileUploadRef.current.clear();
                }
                Toast.error({ message: 'Invalid file format. Please select a JPEG, SVG or PNG image.' });
            }
        }
    };

    const renderFooter = () => (
        <div className="dialog-footer1 p-d-flex p-ai-center p-jc-end">
            <Button
                label="Cancel"
                className="primary-btn1"
                onClick={handleClose}
            />
            <Button
                label="Delete"
                className="primary-btn"
                onClick={() => {
                    handleClose();
                    setBackgroundImage("")
                    setPreviewProfilePath()
                    if (fileUploadRef.current) {
                        fileUploadRef.current.clear();
                    }
                }}
            />
        </div>
    );

    const handleClose = () => {
        setIsDeleteConfirmation(false)
    };

    const handleColorChange = (e) => {
        const newColor = e.value;
        setColor(newColor);
        setColorErr(false);
    };

    const handleInputChange = (e) => {
        let inputValue = e.target?.value?.trim();
        const alphanumericValue = inputValue.replace(/[^a-zA-Z0-9\s]/g, '');

        setColor(alphanumericValue);
        if (/^[0-9A-F]{6}$/.test(alphanumericValue)) {
            setColorErr(false);
        } else {
            setColorErr(true);
            setColorErrText("Enter a valid 6-character HEX color (e.g., A1B2C3).");
        }
    };

    const handleDelete = (e) => {
        setIsDeleteConfirmation(true)
    };

    return (
        <div className="p-d-flex p-flex-column p-jc-between">
            <div className='p-grid'>
                <div className="p-col-12 p-md-12 p-pt-2">
                    <div className="">
                        <label className='' htmlFor="houseName">House Name<span style={{ color: "red" }}>*</span></label>
                        <FloatLabel>
                            <InputText
                                className="p-mt-2 p-w-100"
                                id="houseName"
                                type="text"
                                maxLength={20}
                                placeholder="House Name"
                                value={houseName}
                                autoFocus={true}
                                onChange={(e) => handleChange(e)}
                            />
                        </FloatLabel>
                        <span className="error-msg" style={{ color: 'red' }}>
                            {houseNameErr ? houseNameErrText : null}
                        </span>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                    <div className="p-w-100">
                        <div className="custom-inputs">
                            <label htmlFor="ColorCode" className="mr-2">Color Code</label>
                            <ColorPicker
                                value={color.replace("#", "")}
                                onChange={handleColorChange}
                            />

                            <InputText
                                className="p-mt-2 p-w-80 p-ml-2"
                                value={color.toUpperCase()}
                                onChange={handleInputChange}
                                maxLength={8}
                            />
                        </div>
                        <span className="p-error block" style={{ color: 'red' }}>
                            {colorErr ? colorErrText : null}
                        </span>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                    <UploadFile label='Background Image' note='(Select 1:1 ratio image)' preview={previewProfilePath} original={backgroundImage} uploadHandler={handleBackGroundImage} uploadRef={fileUploadRef} handleDelete={handleDelete} />
                </div>

            </div>
            <div className='dialog-footer1 p-d-flex p-ai-center p-jc-end'>
                <Button className='p-m-0 primary-btn1 p-m-1' onClick={(e) => onCancel()}> Cancel </Button>
                <Button className='p-m-0 primary-btn' label="Submit" severity="secondary" raised onClick={(e) => AddHouseMaster()} />
            </div>

            <Dialog
                visible={isDeleteConfirmation}
                onHide={handleClose}
                header={
                    <div className="p-d-flex p-jc-between p-ai-center">
                        <span>Delete Confirmation</span>
                    </div>
                }
                footer={renderFooter()}
                draggable={false}
                dismissableMask
                style={{ width: '450px' }}
            >
                <DeleteConfirmation />
            </Dialog>
        </div>
    );
};

export default AddHouseMasterDialog;
