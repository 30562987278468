import React from 'react';
import { apiBase } from '../../utils/config';
import moment from 'moment';
import UserImage from '../../assets/icons/OBJECTS.svg';

const ExportIdCardPDF = React.forwardRef((props, ref) => {
    const { templateConfiguration, pdfData } = props;
    const fontFamily = templateConfiguration?.FontTheme;
    const pdfBlocks = [];

    const hexToHsl = (hex) => {
        hex = hex.replace(/^#/, '');
        let r = parseInt(hex.substring(0, 2), 16) / 255;
        let g = parseInt(hex.substring(2, 4), 16) / 255;
        let b = parseInt(hex.substring(4, 6), 16) / 255;
    
        let max = Math.max(r, g, b), min = Math.min(r, g, b);
        let h, s, l = (max + min) / 2;
    
        if (max === min) {
            h = s = 0;
        } else {
            let d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
            switch (max) {
                case r: h = (g - b) / d + (g < b ? 6 : 0); break;
                case g: h = (b - r) / d + 2; break;
                case b: h = (r - g) / d + 4; break;
            }
            h = Math.round(h * 60);
        }
        s = Math.round(s * 100);
        l = Math.round(l * 100);
        
        return { h, s, l };
    };
    
    const gradientStyle = (colorCode) => {
        const fallbackColor = '#649A80';
        const isValidHex = (code) => /^#?([0-9A-F]{3}|[0-9A-F]{6})$/i.test(code);
        const validColor = isValidHex(colorCode) ? (colorCode.startsWith('#') ? colorCode : `#${colorCode}`) : fallbackColor;
    
        const { h, s, l } = hexToHsl(validColor);
    
        return {
            background: templateConfiguration?.TemplateName?.includes('IdCard2') ? templateConfiguration?.StaticColor ? `#${templateConfiguration?.StaticColor}` : ' #e2e379' : `linear-gradient(0deg, hsla(${h}, ${s}%, ${l - 20}%, 1) 50%,#e2e379 72%)`,
            borderRadius: '4px',
            padding: '10px',
        };
    };    

    pdfData?.map((x, idx) => {
        pdfBlocks.push(
            <div style={{ flex: '0 1 calc(19.90% - 6px)', marginLeft: '0px', marginTop: '0px', width: '207px', height: '334px', position: 'relative', justifyContent: 'center', ...gradientStyle(x?.HouseColor) }} ref={ref} key={idx} id={`id-card-${idx}`}>
                <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', height: '256px', }}>
                    <div style={{ position: 'relative', backgroundColor: 'transparent', borderRadius: '6px', opacity: 1, marginTop: '0px' }}>
                        <div style={{ width: '100%', maxHeight: '256px', height: '53px', backgroundColor: '#e2e379', display: 'flex', marginTop: '0px' }}>
                            <img
                                src={templateConfiguration?.SchoolImage ? `${apiBase}/TemplateSign/${templateConfiguration?.SchoolImage}` : x?.SchoolPhotoUrl ? `${apiBase}/UserProfilePic/${x?.SchoolPhotoUrl}` : UserImage}
                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = UserImage;
                                }}
                            />
                        </div>
                        <div style={{ position: 'relative', backgroundColor: '#FFFFFF', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', borderRadius: '6px', opacity: '1', marginTop: '8px', height: 239}}>
                            <div style={{ backgroundColor: 'transparent', borderRadius: '50%', position: 'absolute', top: '14%', left: '1%', opacity: 0.12 }}>
                                <img style={{ width: '11.5rem', height: '11.5rem', borderRadius: '50%' }} src={templateConfiguration?.IsHouseWiseImage ? `${apiBase}/TemplateBackGround/${x?.HouseBgImage}` : templateConfiguration?.BackGroundImage ? `${apiBase}/TemplateBackGround/${templateConfiguration?.BackGroundImage}` : ''}
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        // e.target.src = UserImage;
                                    }} />
                            </div>
                            <div>
                                {templateConfiguration?.BodySection?.map((y, index) => {
                                    return (
                                        <div>
                                            {y?.key === 'AcademicYear' ?
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '0.3rem' }}>
                                                    <p style={{ margin: '0px', fontSize: '0.65rem', fontStyle: 'normal', fontWeight: 700, color: '#074E69' }}>{x?.AcademicYear ?? '-'}</p>
                                                </div> :
                                                y?.key === 'StudentProfilePic' ?
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '0.1rem' }}>
                                                        <div style={{
                                                            borderRadius: templateConfiguration?.ProfileStyle === 'round' ? '50%' : '6px',
                                                        }}
                                                        >
                                                            <img
                                                                src={x?.ProfilePhotoUrl ? `${apiBase}/UserProfilePic/${x?.ProfilePhotoUrl}` : UserImage}
                                                                style={{
                                                                    borderRadius: templateConfiguration?.ProfileStyle === 'round' ? '50%' : '6px', width: '45px', height: '45px', backgroundColor: '#FFFFFF', margin: '0.2rem auto 0.2rem auto', display: 'flex', alignItems: 'center', justifyContent: 'center',
                                                                }}
                                                                onError={(e) => {
                                                                    e.target.onerror = null;
                                                                    e.target.src = UserImage;
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    : y?.key === 'Class&Section' ?
                                                        <p style={{ margin: '0px', fontSize: '0.60rem', fontStyle: 'normal', fontWeight: 600, color: '#064D69', position: 'absolute', top: '18%', right: '6%', transform: `rotate(${templateConfiguration?.ClassAngle}deg)`, }}>{x?.ClassName}</p>
                                                        : y?.key === 'StudentName' ?
                                                            <div style={{
                                                                display: 'flex', alignItems: 'center', justifyContent: 'center', opacity: 0.9, height: '20px', marginBottom: '0.2rem', backgroundColor: templateConfiguration?.NameBGColor ? `#${templateConfiguration?.NameBGColor}` : x?.HouseColor ? `#${x.HouseColor}` : '#064D69', textAlign: 'center'
                                                            }}
                                                            >
                                                                <p style={{
                                                                    color: templateConfiguration?.NameColor ? `#${templateConfiguration?.NameColor}` : '#FFFFFF', margin: '0px', fontSize: '0.7rem', fontWeight: 600,
                                                                }}>{templateConfiguration?.NameType === 'FirstSurname' ? `${x?.FirstName ?? ''} ${x?.SurName ?? ''}` :templateConfiguration?.NameType === 'SurnameFirstName' ? `${x?.Surname ?? ''} ${x?.FirstName ?? ''}` : templateConfiguration?.NameType === 'FirstFatherNameSurname' ? `${x?.FirstName ?? ''} ${x?.FName ?? ''} ${x?.Surname ?? ''}` : templateConfiguration?.NameType === 'SurnameFirstFatherName' ? `${x?.Surname ?? ''} ${x?.FirstName ?? ''} ${x?.FName ?? ''}` : templateConfiguration?.NameType === 'FirstMotherNameSurname' ? `${x?.Firstame ?? ''} ${x?.MName ?? ''} ${x?.Surname ?? ''}` : templateConfiguration?.NameType === 'SurnameFirstMotherName' ? `${x?.Surname ?? ''} ${x?.FirstName ?? ''} ${x?.MName ?? ''}` : `${x?.FirstName ?? ''} ${x?.SurName ?? ''}`}</p>
                                                            </div>
                                                            : (y?.key === 'contactNo' || y?.key === 'schoolEmail') ?
                                                                null :
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'center',
                                                                        alignItems: 'baseline',
                                                                        marginBottom: '3px',
                                                                    }}
                                                                    key={index}
                                                                >
                                                                    <p style={{
                                                                        margin: 0,
                                                                        fontSize: '0.55rem',
                                                                        fontWeight: 700,
                                                                        color: '#064D69',
                                                                        textTransform: 'capitalize',
                                                                        maxWidth: '80px',
                                                                        flex: '1',
                                                                        textOverflow: 'ellipsis',
                                                                        whiteSpace: 'nowrap',
                                                                        overflow: 'hidden',
                                                                        marginLeft: '10px',

                                                                    }}
                                                                    >
                                                                        {y?.label}
                                                                    </p>
                                                                    <p style={{
                                                                        margin: 0,
                                                                        fontSize: y?.key === 'Address' ? '0.55rem' : '0.57rem',
                                                                        // maxHeight: y?.key === 'Address' ? '10px' : '0px',
                                                                        fontWeight: 400,
                                                                        color: '#064D69',
                                                                        textTransform: 'capitalize',
                                                                        // maxWidth: '150px',
                                                                        // display: 'flex',
                                                                        // alignItems: 'center',
                                                                        // flex: '1',
                                                                        // justifyContent: 'flex-start',
                                                                        // textOverflow: 'ellipsis',
                                                                        // whiteSpace: 'normal',
                                                                        // overflow: 'hidden',
                                                                        // wordWrap: 'break-word',
                                                                        // wordBreak: 'break-word',
                                                                        display: "-webkit-box",
                                                                        maxWidth: 78,
                                                                        width: 78,
                                                                        WebkitLineClamp: "3",
                                                                        WebkitBoxOrient: "vertical",
                                                                        overflow: "hidden"
                                                                    }}
                                                                    >
                                                                        {y?.key === 'DOB' ? (x?.[y?.key] ? moment(x?.[y?.key]).format("DD-MM-YYYY") : '') : x?.[y?.key]}
                                                                    </p>
                                                                </div>

                                            }
                                        </div>
                                    )
                                }
                                )}
                            </div>
                            <div style={{ display: 'flex', marginTop: 'auto', flexDirection: 'column', alignItems: 'end', justifyContent: 'end', paddingBottom: '0.25rem' }}>
                                <img src={templateConfiguration?.SignImage ? `${apiBase}/TemplateSign/${templateConfiguration?.SignImage}` : UserImage} style={{ width: '25px', height: '25px', objectFit: 'contain', marginRight: '10px' }} onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = UserImage;
                                }} />
                                <p className="p-w-md-auto p-mr-2" style={{ margin: '0px', fontSize: '0.5rem', fontStyle: 'normal', fontWeight: 700, color: '#064D69', textTransform: 'capitalize', maxWidth: '70px', width: '70', }}>{templateConfiguration?.AuthorityTitle}</p>
                            </div>
                            {/* </div> */}
                        </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <p style={{ fontSize: '0.50rem', fontStyle: 'normal', fontWeight: 500, color: '#FFFFFF', marginTop: '4px', marginBottom: '0px' }}>
                            {templateConfiguration?.EmailPhoneTextData ? templateConfiguration?.EmailPhoneTextData : templateConfiguration?.BodySection?.filter(y => y?.key === 'contactNo').length ? x?.SchoolPhone : ''},{templateConfiguration?.BodySection?.filter(y => y?.key === 'schoolEmail').length ? x?.SchoolEmail : ''}
                        </p>
                    </div>
                </div>
            </div>
        )
    });
    return pdfBlocks;
});

export default ExportIdCardPDF;
