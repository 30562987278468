import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { FilterMatchMode } from 'primereact/api';
import { Dialog } from 'primereact/dialog';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import ClassConfigurationDialog from './ClassConfiguration';
import SubjectConfigDialog from './SubjectConfig';
import SectionChangeDialog from './SectionChange';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';


const ClassConfiguration = () => {
    const [classList, setClassList] = useState([]);
    const [masterData, setMasterData] = useState([]);
    const [visible, setVisible] = useState(false);
    const [subjectVisible, setSubjectVisible] = useState(false);
    const [visibleSection, setVisibleSection] = useState(false);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Class: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [securityUserID, setSecurityUserID] = useState("");
    const [rows, setRows] = useState(10);
    const [first, setFirst] = useState(0);

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        GetClassList(loginData?._id);
    }, []);

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const onPageChange = (e) => {
        setFirst(e.first);
        setRows(e.rows);
    };

    const GetClassList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            Loader.show();
            await api.post(APIConstant.path.getClassList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setClassList(res.data.map((x) => ({
                        ...x,
                        Class: `${x?.ClassName}-${x?.SectionFullName.charAt(0)}-${x?.MediumName}-${x?.Board}`
                    })));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const actionBodyTemplate = (rows) => {
        return (
            <React.Fragment>
                <div className='p-d-flex p-ai-center p-jc-end'>
                    <Button icon="pi pi-cog" className="e-sction-btn p-m-1" onClick={() => {
                        setVisible(true);
                        setMasterData(rows)
                    }} data-pr-tooltip='Class Configuration' tooltip={"View"} tooltipOptions={{ className: 'bluegray-tooltip', position: 'top' }} />
                    {/* <Button icon="pi pi-refresh" className="e-sction-btn p-m-0 p-m-1" onClick={(e) => {
                        setVisibleSection(true);
                        setMasterData(rows)
                    }} data-pr-tooltip='Change Section' data-pr-position='top' tooltip={"View"} tooltipOptions={{ className: 'bluegray-tooltip', position: 'top' }} /> */}
                    <Button icon="pi pi-pencil" className="e-sction-btn p-m-1" onClick={() => {
                        setSubjectVisible(true);
                        setMasterData(rows)
                    }} data-pr-tooltip='Optional Subjects' tooltip={"View"} tooltipOptions={{ className: 'bluegray-tooltip', position: 'top' }} />
                    
                </div>
            </React.Fragment>
        );
    };

    const onCancel = () => {
        setVisible(false);
        setVisibleSection(false);
        setSubjectVisible(false);
        GetClassList(securityUserID);
    }

    const actionBodyTemplateSrNo = (data, props) => {
        return props.rowIndex + 1;
    };

    const lastUpdatedBodyTemplate = (rows) => {
        return (
            <div>{moment(rows?.UpdatedOn).format("DD-MM-YYYY HH:mm")}</div>
        );
    };

    return (
        <div className='wayment-owner-dashboard e-weighment-dashoard'>
            <div className="heading-area p-d-flex p-ai-center p-jc-between">
                <div className="p-d-flex p-ai-baseline">
                    <i className="pi pi-window-maximize"></i>
                    <h2>Class Configuration</h2>
                </div>
                <div className="p-d-flex p-ai-center p-jc-between">
                    <div className="searchbar-area e-searchbar-area">
                        <i className="pi pi-search" />
                        <InputText 
                            value={globalFilterValue}
                            onChange={onGlobalFilterChange}
                            placeholder="Global Search"
                        />
                    </div>
                </div>
            </div>
            <hr className='p-m-0' />
            <DataTable
                value={classList}
                paginator
                rows={rows}
                first={first}
                filters={filters}
                filterDisplay="menu"
                responsiveLayout="scroll"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                rowsPerPageOptions={[5, 10, 20]}
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                onPage={onPageChange}
                className='p-mt-3 id-card-table'
            >
                <Column field="id" body={actionBodyTemplateSrNo} header="Sr No." style={{ width: '15%'}} />
                <Column
                    field="Class"
                    header="Class"
                    style={{ width: '30%'}}
                />
                <Column
                    body={lastUpdatedBodyTemplate}
                    header="Last Updated On"
                    style={{ width: '30%'}}
                />
                <Column body={actionBodyTemplate} header="Action" headerStyle={{ display: 'flex', justifyContent: 'end' }}/>
            </DataTable>
            <Dialog
                header={"Class- " + masterData?.Class + " Config."}
                visible={visible}
                onHide={() => onCancel()}
                draggable={false}
                position="center"
                style={{ width: "70vw", height: "80vh" }}
            >
                <ClassConfigurationDialog onCancel={onCancel} configId={masterData?._id} studentList={masterData?.Users} />

            </Dialog>
            <Dialog
                header={"Optional Subject Config"}
                visible={subjectVisible}
                onHide={() => onCancel()}
                draggable={false}
                position="center"
                style={{ width: "60vw", height: '49%' }}
            >
                <SubjectConfigDialog onCancel={onCancel} configId={masterData?._id} studentList={masterData?.Users} />

            </Dialog>
            {/* <Dialog
                header='Change Section'
                visible={visibleSection}
                onHide={() => onCancel()}
                draggable={false}
                position="center"
            >
                <SectionChangeDialog onCancel={onCancel} ClassName={masterData?.Class} configId={masterData?._id} studentList={masterData?.Users} />

            </Dialog> */}
        </div>
    );
};

export default ClassConfiguration;
