import Login from './components/Login/Login.js';
import Dashboard from './components/Dashboard/Dashboard.js';
import Error404 from './components/Shared/Error404/Error404.js';
import ClassMaster from './components/Masters/ClassMaster/ClassMaster.js';
import SectionMaster from './components/Masters/SectionMaster/SectionMaster.js';
import TeacherMaster from './components/Masters/TeacherMaster/TeacherMaster.js';
import ViewEditTeacherDetails from './components/Masters/TeacherMaster/ViewEditTeacherMaster.js';
import StudentMaster from './components/Masters/StudentMaster/StudentMaster.js';
import ViewEditStudentDetails from './components/Masters/StudentMaster/ViewEditStudentMaster.js';
import HouseMaster from './components/Masters/HouseMaster/HouseMaster.js';
import ParentMaster from './components/Masters/ParentMaster/ParentMaster.js';
import ViewEditParentDetails from './components/Masters/ParentMaster/ViewEditParentMaster.js';
import ClassConfiguration from './components/Configuration/Class/Class.js';
import IdCardTemplateConfiguration from './components/Configuration/IdCardTemplate/IdCardTemplate.js';
import EditProfile from './components/Profile/EditProfile.js';
import IdCardMaster from './components/Generator/IdCard/IdCardMaster.js';
import SubjectMaster from './components/Masters/SubjectMaster/SubjectMaster.js';
import ProfileConfiguration from './components/Configuration/ProfileConfiguration/ProfileConfiguration.js';
import AcademicCalendarTemplate from './components/Configuration/AcademicCalendar/AcademicCalander.js';
import TimeTableConfiguration from './components/Configuration/TimeTable/TimeTable.js';
import EditClassTimeTable from './components/Configuration/TimeTable/EditClassTimeTable.js';
import EditTeacherTimeTable from './components/Configuration/TimeTable/EditTeacherTimeTable.js';
import ShiftMaster from './components/Masters/ShiftMaster/ShiftMaster.js';
import LeaveReasonMaster from './components/Masters/LeaveReasonMaster/LeaveReasonMaster.js';
import FeeMaster from './components/Masters/FeeMaster/FeeMaster.js';
import Attendance from './components/Attendance/ViewAttendance.js';
import Result from './components/Configuration/Result/Result.js';
import TakeAttendance from './components/Attendance/TakeAttendance.js';
import TermMaster from './components/Masters/TermMaster/TermMaster.js';
import StopMaster from './components/Masters/StopMaster/StopMaster.js';
import GradeMaster from './components/Masters/GradeMaster/GradeMaster.js';
import CoScholasticMaster from './components/Masters/Co-ScholasticMaster/Co-ScholasticMaster.js';
import ExamMaster from './components/Masters/ExamMaster/ExamMaster.js';
import TypeMaster from './components/Masters/TypeMaster/TypeMaster.js';
import CoScholasticTemplateConfiguration from './components/Configuration/CoScholasticTemplate/CoScholasticTemplate.js';
import CoScholasticGradeTemplateConfiguration from './components/Configuration/CoScholasticGradeTemplate/CoScholasticGradeTemplate.js';
import RoomMaster from './components/Masters/RoomMaster/RoomMaster.js';
import ClassNoMaster from './components/Masters/ClassNoMaster/ClassNoMaster.js';
import ExamSchedule from './components/Generator/ExamSchedule/ExamSchedule.js';
import GeneralConfiguration from './components/Configuration/General/GeneralConfiguration.js';
import Examination from './components/Grading/Examination/Examination.js';
import ExamMarksEntry from './components/Grading/Examination/ExamMarksEntry.js';
import FeeStructureConfiguration from './components/Configuration/FeeStructure/FeeStructure.js';
import ResultTemplateConfiguration from './components/Configuration/ResultTemplateConfiguration/ResultTemplateConfiguration.js';
import ResultGenerator from './components/Generator/ResultGenerator/ResultGenerator.js';
import CoScholasticGrading from './components/Grading/Result/CoScholasticGrading.js';
import CoScholasticGradeEntry from './components/Grading/Result/CoScholasticGradingEntry.js';

var dashRoutes = [
    {
        path: "/",
        name: "Login",
        component: Login,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | Login'
        },
        isPreventedRoute: true
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | Login'
        },
        isPreventedRoute: true
    },
    // {
    //     path: "/forgot-password",
    //     name: "ForgotPassword",
    //     component: ForgotPassword,
    //     icon: "",
    //     invisible: false,
    //     meta: {
    //         title: 'SCLERP | Forgot Password'
    //     },
    //     isPreventedRoute: true
    // },
    // {
    //     path: "/reset-password",
    //     name: "ResetPassword",
    //     component: ResetPassword,
    //     icon: "",
    //     invisible: false,
    //     meta: {
    //         title: 'SCLERP | Reset Password'
    //     },
    //     isPreventedRoute: true
    // },
    {
        path: "/404Error",
        name: "Error404",
        component: Error404,
        icon: "",
        invisible: false,
        meta: {
            title: 'Page not found'
        },
        isShared: true
    },

    /** Add all protected routing (requires session) here */
    {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | Dashboard'
        },
        isPreventedRoute: false
    },
    {
        path: "/edit-profile",
        name: "EditProfile",
        component: EditProfile,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | Edit Profile'
        },
        isPreventedRoute: false
    },
    {
        path: "/class-master",
        name: "ClassMaster",
        component: ClassMaster,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | ClassMaster'
        },
        isPreventedRoute: false
    },
    {
        path: "/section-master",
        name: "SectionMaster",
        component: SectionMaster,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | SectionMaster'
        },
        isPreventedRoute: false
    },
    {
        path: "/teacher-master",
        name: "TeacherMaster",
        component: TeacherMaster,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | TeacherMaster'
        },
        isPreventedRoute: false
    },
    {
        path: "/viewEdit-teacher-master",
        name: "ViewEditTeacherMaster",
        component: ViewEditTeacherDetails,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | ViewEditTeacherDetails'
        },
        isPreventedRoute: false
    },
    {
        path: "/student-master",
        name: "StudentMaster",
        component: StudentMaster,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | StudentMaster'
        },
        isPreventedRoute: false
    },
    {
        path: "/viewEdit-student-master",
        name: "ViewEditStudentMaster",
        component: ViewEditStudentDetails,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | ViewEditStudentDetails'
        },
        isPreventedRoute: false
    },
    {
        path: "/house-master",
        name: "HouseMaster",
        component: HouseMaster,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | HouseMaster'
        },
        isPreventedRoute: false
    },
    {
        path: "/parent-master",
        name: "ParentMaster",
        component: ParentMaster,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | ParentMaster'
        },
        isPreventedRoute: false
    },
    {
        path: "/viewEdit-parent-master",
        name: "ViewEditParentMaster",
        component: ViewEditParentDetails,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | ViewEditParentMaster'
        },
        isPreventedRoute: false
    },
    {
        path: "/class-configuration",
        name: "ClassConfiguration",
        component: ClassConfiguration,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | ClassConfiguration'
        },
        isPreventedRoute: false
    },
    {
        path: "/id-card-template-configuration",
        name: "IdCardTemplateConfiguration",
        component: IdCardTemplateConfiguration,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | IdCardTemplateConfiguration'
        },
        isPreventedRoute: false
    },
    {
        path: "/id-card-generator",
        name: "IdCardGenerator",
        component: IdCardMaster,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | IdCardGenerator'
        },
        isPreventedRoute: false
    },
    {
        path: "/subject-master",
        name: "SubjectMaster",
        component: SubjectMaster,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | SubjectMaster'
        },
        isPreventedRoute: false
    },
    {
        path: "/shift-master",
        name: "ShiftMaster",
        component: ShiftMaster,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | ShiftMaster'
        },
        isPreventedRoute: false
    },
    {
        path: "/profile-configuration",
        name: "ProfileConfiguration",
        component: ProfileConfiguration,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | ProfileConfiguration'
        },
        isPreventedRoute: false
    },
    {
        path: "/academic-calendar-template",
        name: "AcademicCalendarTemplate",
        component: AcademicCalendarTemplate,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | AcademicCalendarTemplate'
        },
        isPreventedRoute: false
    },
    {
        path: "/time-table-configuration",
        name: "TimeTable",
        component: TimeTableConfiguration,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | TimeTableConfiguration'
        },
        isPreventedRoute: false
    },
    {
        path: "/edit-class-time-table",
        name: "EditClassTimeTable",
        component: EditClassTimeTable,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | EditClassTimeTable'
        },
        isPreventedRoute: false
    },
    {
        path: "/edit-teacher-time-table",
        name: "EditTeacherTimeTable",
        component: EditTeacherTimeTable,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | EditTeacherTimeTable'
        },
        isPreventedRoute: false
    },
    {
        path: "/leave-reason-master",
        name: "LeaveReasonMaster",
        component: LeaveReasonMaster,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | LeaveReasonMaster'
        },
        isPreventedRoute: false
    },
    {
        path: "/fee-master",
        name: "FeeMaster",
        component: FeeMaster,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | FeeMaster'
        },
        isPreventedRoute: false
    },
    {
        path: "/attendance",
        name: "Attendance",
        component: Attendance,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | Attendance'
        },
        isPreventedRoute: false
    },
    {
        path: "/taken-attendance",
        name: "TakeAttendance",
        component: TakeAttendance,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | Take Attendance'
        },
        isPreventedRoute: false
    },
    {
        path: "/term-master",
        name: "TermMaster",
        component: TermMaster,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | Term Master'
        },
        isPreventedRoute: false
    },
    {
        path: "/stop-master",
        name: "StopMaster",
        component: StopMaster,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | Stop Master'
        },
        isPreventedRoute: false
    },
    {
        path: "/grade-master",
        name: "GradeMaster",
        component: GradeMaster,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | Grade Master'
        },
        isPreventedRoute: false
    },
    {
        path: "/co-scholastic-master",
        name: "CoScholasticMaster",
        component: CoScholasticMaster,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | Co-Scholastic Master'
        },
        isPreventedRoute: false
    },
    {
        path: "/exam-master",
        name: "ExamMaster",
        component: ExamMaster,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | ExamMaster'
        },
        isPreventedRoute: false
    },
    {
        path: "/type-master",
        name: "TypeMaster",
        component: TypeMaster,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | TypeMaster'
        },
        isPreventedRoute: false
    },
    {
        path: "/co-scholastic-template",
        name: "CoScholasticTemplate",
        component: CoScholasticTemplateConfiguration,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | Co-Scholastic Template'
        },
        isPreventedRoute: false
    },
    {
        path: "/room-master",
        name: "RoomMaster",
        component: RoomMaster,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | RoomMaster'
        },
        isPreventedRoute: false
    },
    {
        path: "/classNo-master",
        name: "ClassNoMaster",
        component: ClassNoMaster,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | ClassNoMaster'
        },
        isPreventedRoute: false
    },
    {
        path: "/exam-schedule",
        name: "ExamSchedule",
        component: ExamSchedule,
        icon: "pi-calendar-plus",
        invisible: false,
        meta: {
            title: 'SCLERP | ExamSchedule'
        },
        isPreventedRoute: false
    },
    {
        path: "/general-configuration",
        name: "GeneralConfiguration",
        component: GeneralConfiguration,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | GenearalConfiguration'
        },
        isPreventedRoute: false
    },
    {
        path: "/examination",
        name: "Examation",
        component: Examination,
        icon: "pi pi-user",
        invisible: false,
        meta: {
            title: 'SCLERP | Examination'
        },
        isPreventedRoute: false
    },
    {
        path: "/examination-marks",
        name: "ExamationMarks",
        component: ExamMarksEntry,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | Examination Marks'
        },
        isPreventedRoute: false
    },
    {
        path: "/fee-structure",
        name: "FeeStructure",
        component: FeeStructureConfiguration,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | Fee Structure'
        },
        isPreventedRoute: false
    },
    {
        path: "/result-template",
        name: "ResultTemplate",
        component: ResultTemplateConfiguration,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | Result Template'
        },
        isPreventedRoute: false
    },
    {
        path: "/result",
        name: "Result",
        component: Result,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | Result'
        },
        isPreventedRoute: false
    },
    {
        path: "/result-generator",
        name: "ResultGenerator",
        component: ResultGenerator,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | ResultGenerator'
        },
        isPreventedRoute: false
    },
    {
        path: "/co-scholastic-grade-template",
        name: "CoScholasticGradeTemplate",
        component: CoScholasticGradeTemplateConfiguration,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | CoScholasticGradeTemplate'
        },
        isPreventedRoute: false
    },
    {
        path: "/co-scholastic-grading",
        name: "CoScholasticGrading",
        component: CoScholasticGrading,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | CoScholasticGrading'
        },
        isPreventedRoute: false
    },
    {
        path: "/co-scholastic-entry",
        name: "CoScholasticEntry",
        component: CoScholasticGradeEntry,
        icon: "",
        invisible: false,
        meta: {
            title: 'SCLERP | CoScholasticGradingEntry'
        },
        isPreventedRoute: false
    },
]

export default dashRoutes;