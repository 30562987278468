import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { FloatLabel } from 'primereact/floatlabel';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primeicons/primeicons.css';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import Toast from '../../Shared/Toast/Toast';
import { MultiSelect } from 'primereact/multiselect';
import { Checkbox } from 'primereact/checkbox';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';

const AddEditCoScholasticGradeTemplate = (props) => {
    const [securityUserID, setSecurityUserID] = useState("");
    const [titleName, setTitleName] = useState('');
    const [visible, setVisible] = useState(false);
    const [titleNameErr, setTitleNameErr] = useState(false);
    const [titleNameErrText, setTitleNameErrText] = useState('');
    const [classList, setClassList] = useState([]);
    const [className, setClassName] = useState([]);
    const [termList, setTermList] = useState([]);
    const [termName, setTermName] = useState([]);
    const [coScholasticList, setCoScholasticList] = useState([]);
    const [coScholasticName, setCoScholasticName] = useState([]);
    const [isClassTeacher, setIsClassTeacher] = useState(true);
    const [isSubjectTeacher, setIsSubjectTeacher] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [startDateErr, setStartDateErr] = useState(false);
    const [startDateErrText, setStartDateErrText] = useState('');
    const [endDate, setEndDate] = useState('');
    const [endDateErr, setEndDateErr] = useState(false);
    const [endDateErrText, setEndDateErrText] = useState('');


    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        GetClassList(loginData?._id);
        GetTermsList(loginData?._id);
        GetCoScholasticList(loginData?._id);
        if (props?.configId) {
            setTitleName(props?.masterData?.TemplateName);
            setClassName(props?.masterData?.ClassId ? props?.masterData?.ClassId.split(',') : []);
            setIsSubjectTeacher(props?.masterData?.IsSubjectTeacher);
            setIsClassTeacher(props?.masterData?.IsClassTeacher);
            setCoScholasticName(props?.masterData?.CoScholasticName ? props?.masterData?.CoScholasticName.split(',') : []);
            setTermName(props?.masterData?.TermName ? props?.masterData?.TermName.split(',') : []);
            setStartDate(props?.masterData?.StartDate ? new Date(props?.masterData?.StartDate) : null);
            setEndDate(props?.masterData?.EndDate ? new Date(props?.masterData?.EndDate) : null);
        }
    }, []);

    const GetClassList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.getClassList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setClassList(
                        Array.from(
                            new Map(
                                res?.data?.filter(x => x?.Status === 'Active')?.map((x) => [
                                    `${x?.ClassName}-${x?.MediumName}-${x?.Board}`,
                                    {
                                        ...x,
                                        value: x?.ClassId,
                                        label: `${x?.ClassName}-${x?.MediumName}-${x?.Board}`
                                    }
                                ])
                            ).values()
                        )
                    );

                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const GetCoScholasticList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.getCoScholasticList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setCoScholasticList(res?.data?.map(x => ({
                        label: `${x?.CoScholasticName}(${x?.Type})`,
                        value: x?._id
                    })));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const GetTermsList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.getTermList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setTermList(res?.data?.map(x => ({
                        label: x?.TermName,
                        value: x?._id,
                    })));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const getDateOnly = (date) => {
        return date ? new Date(date.getFullYear(), date.getMonth(), date.getDate()) : null;
    };
    
    const handleStartChange = (value) => {
        const fromDate = getDateOnly(value);
        const toDate = getDateOnly(endDate);

        if (toDate && fromDate > toDate) {
            setStartDateErr(true);
            setStartDateErrText("'Start' date cannot be later than 'End' date.");
        } else if (toDate && fromDate.getDate() === toDate.getDate()) {
            setStartDateErr(true);
            setStartDateErrText("'Start' date cannot be the same as 'End' date.");
        } else {
            setStartDateErr(false);
            setStartDateErrText('');
            setStartDate(value);
        }
    };

    const handleEndChange = (value) => {
        const fromDate = getDateOnly(startDate);
        const toDate = getDateOnly(value);

        if (fromDate && toDate < fromDate) {
            setEndDateErr(true);
            setEndDateErrText("'End' date cannot be earlier than 'Start' date.");
        } else if (fromDate && fromDate.getDate() === toDate.getDate()) {
            setEndDateErr(true);
            setEndDateErrText("'End' date cannot be the same as 'Start' date.");
        } else {
            setEndDateErr(false);
            setEndDateErrText('');
            setEndDate(value);
        }
    };

    const validate = () => {
        let formIsValid = true;
        if (CommonConfig.isEmpty(titleName)) {
            formIsValid = false;
            setTitleNameErr(true);
            setTitleNameErrText("Title Name is required");
        } else {
            setTitleNameErr(false);
            setTitleNameErrText("");
        }
        return formIsValid;
    };

    const onCancel = () => {
        props.onCancel();
        setTitleName('');
        setClassName([]);
        setEndDate('');
        setStartDate('');
    }

    const AddUpdateTemplate = async (e) => {
        if (validate(e)) {
            try {
                Loader.show();
                let data = {
                    id: props?.configId,
                    TemplateName: titleName,
                    ClassId: className ? className.length > 1 ? className.join(',') : className[0] : '',
                    CoScholasticName: coScholasticName ? coScholasticName.length > 1 ? coScholasticName.join(',') : coScholasticName[0] : '',
                    TermName: termName ? termName.length > 1 ? termName.join(',') : termName[0]: '',
                    IsClassTeacher: isClassTeacher,
                    IsSubjectTeacher: isSubjectTeacher,
                    StartDate: startDate ? moment(startDate).format("YYYY-MM-DD") : '',
                    EndDate : endDate ? moment(endDate).format("YYYY-MM-DD") : '',
                    CreatedBy: securityUserID,
                };
                await api
                    .post(APIConstant.path.AddUpdateCoScholasticGradeTemplate, data)
                    .then((response) => {
                        let res = response;
                        if (res.success) {
                            Toast.success({ message: res.message });
                            Loader.hide();
                            onCancel();
                        } else {
                            Toast.error({ message: res.message });
                            Loader.hide();
                            onCancel();
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        Loader.hide();
                        onCancel();
                    });
            } catch (err) {
                console.log(err);
                Toast.error({ message: err });
                Loader.hide();
                onCancel();
            }
        }
    };


    return (
        <div className="p-d-flex p-flex-column p-jc-between">
            <div className='p-grid'>
                <div className="p-col-12 p-md-6 p-pt-2">
                    <label htmlFor="titleName">Name</label>
                    <FloatLabel>
                        <InputText
                            className='p-w-100'
                            id="titleName"
                            value={titleName}
                            onChange={(e) => setTitleName(e.target.value)}
                        />
                    </FloatLabel>
                    <span className="error-msg" style={{ color: 'red' }}>
                        {titleNameErr ? titleNameErrText : null}
                    </span>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                    <div className="">
                        <label htmlFor="className">Class</label>
                        <MultiSelect
                            id="className"
                            value={className}
                            options={classList}
                            onChange={(e) => setClassName(e.value)}
                            placeholder="Select Class"
                            display="chip"
                            className='p-w-100'
                            filter
                        />
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                    <div className="">
                        <Checkbox
                            inputId="classTeacher"
                            name="isClassTeacher"
                            value="IsClassTeacher"
                            onChange={(e) => {
                                setIsClassTeacher(e.checked);
                            }}
                            checked={isClassTeacher}
                        />
                        <label htmlFor="classTeacher" className="ml-2"> Is Class Teacher</label>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                    <div className="">
                        <Checkbox
                            inputId="subjectTeacher"
                            name="isSubjectTeacher"
                            value="IsSubjectTeacher"
                            onChange={(e) => {
                                setIsSubjectTeacher(e.checked);
                            }}
                            checked={isSubjectTeacher}
                        />
                        <label htmlFor="subjectTeacher" className="ml-2"> Is Subject Teacher</label>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                    <div className="">
                        <label htmlFor="startDate">Start Date</label>
                        <div className="custom-inputs">
                            <Calendar
                                className='p-w-100'
                                id="startDate"
                                value={startDate}
                                onChange={(e) => handleStartChange(e.value)}
                                hourFormat="12"
                                dateFormat="dd/mm/yy"
                            />
                            <span className="error-msg" style={{ color: 'red' }}>
                                {startDateErr ? startDateErrText : null}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                    <div className="">
                        <label htmlFor="endDate">End Date</label>
                        <div className="custom-inputs">
                            <Calendar
                                className='p-w-100'
                                id="endDate"
                                value={endDate}
                                onChange={(e) => handleEndChange(e.value)}
                                hourFormat="12"
                                dateFormat="dd/mm/yy"
                            />
                            <span className="error-msg" style={{ color: 'red' }}>
                                {endDateErr ? endDateErrText : null}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                    <div className="">
                        <label htmlFor="termName">Term</label>
                        <MultiSelect
                            id="termName"
                            value={termName}
                            options={termList}
                            onChange={(e) => setTermName(e.value)}
                            placeholder="Select Term"
                            display="chip"
                            className='p-w-100'
                            filter
                        />
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                    <div className="">
                        <label htmlFor="coScholasticName">Co-Scholastic Name</label>
                        <MultiSelect
                            id="coScholasticName"
                            value={coScholasticName}
                            options={coScholasticList}
                            onChange={(e) => setCoScholasticName(e.value)}
                            placeholder="Select Grade"
                            display="chip"
                            className='p-w-100'
                            filter
                        />
                    </div>
                </div>
            </div>
            <div className="dialog-footer1 p-d-flex p-ai-center p-jc-end">
                <Button className='p-m-0 primary-btn1 p-m-1' label="Cancel" onClick={(e) => onCancel()} />
                <Button className='p-m-0 primary-btn' label="Save" onClick={(e) => AddUpdateTemplate()} />
            </div>
        </div >
    );
};

export default AddEditCoScholasticGradeTemplate;
