import React, { useState, useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { FilterMatchMode } from 'primereact/api';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import { Dialog } from 'primereact/dialog';
import CommonConfig from '../../../utils/constant';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import { Checkbox } from 'primereact/checkbox';
import moment from 'moment';
import Toast from '../../Shared/Toast/Toast';
import { InputText } from 'primereact/inputtext';
const ProfileConfiguration = () => {
    const [securityUserID, setSecurityUserID] = useState("");
    const [usersList, setUsersList] = useState([]);
    const [userId, setUserId] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [isAllowed, setIsAllowed] = useState(false);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [fromDateErr, setFromDateErr] = useState(false);
    const [fromDateErrText, setFromDateErrText] = useState('');
    const [toDate, setToDate] = useState('');
    const [toDateErr, setToDateErr] = useState(false);
    const [toDateErrText, setToDateErrText] = useState(false);
    const [ProfileUserId, setProfileUserId] = useState("");


    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        GetUsersList(loginData?._id);
    }, []);

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const GetUsersList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.getProfileConfigurationList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setUsersList(res.data.sort((a, b) => a?.Name?.toLowerCase().localeCompare(b?.Name?.toLowerCase())));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const actionBodyTemplateSrNo = (data, props) => {
        return props.rowIndex + 1;
    };

    const actionBodyTemplate = (data) => {
        return (
            <React.Fragment>
                <div className='p-d-flex p-ai-center'>
                    <Button icon="pi pi-pencil" className="e-sction-btn p-m-0" onClick={() => {
                        setUserId(data._id);
                        setProfileUserId(data.ProfileConfigId);
                        setFromDate(data?.FromDate ? new Date(data?.FromDate) : '');
                        setToDate(data?.ToDate ? new Date(data?.ToDate) : '');
                        setIsAllowed(data?.IsAllowed == 1 ? true : false);
                        setOpenModal(true);
                    }} />
                </div>
            </React.Fragment>
        );
    };

    const onCancel = () => {
        setOpenModal(false);
        setFromDate('');
        setFromDateErr(false);
        setFromDateErrText('');
        setToDate('');
        setToDateErr(false);
        setToDateErrText('');
        setIsAllowed(false);
        setUserId('');
        GetUsersList(securityUserID);
    }

    const AddUpdateConfiguration = async (e) => {
        e.preventDefault();
        try {
            let data = {
                id: ProfileUserId,
                UserId: userId,
                FromDate: moment(fromDate).format('YYYY-MM-DD'),
                ToDate: moment(toDate).format('YYYY-MM-DD'),
                IsAllowed: isAllowed == true ? 1 : 0,
                CreatedBy: securityUserID,
            };
            Loader.show();
            await api.post(APIConstant.path.addUpdateProfileConfiguration, data).then(async response => {
                let res = response;
                if (res?.success) {
                    Loader.hide();
                    onCancel();
                    Toast.success({ message: res?.message });
                } else {
                    Toast.error({ message: res?.message });
                    Loader.hide();
                }
            })
                .catch(() => {
                    Loader.hide();
                });
        } catch (err) {
            console.log(err);
            Loader.hide();
        }

    }

    const handleFromChange = (value) => {
        const endDate = toDate;
        const startDate = value;
        if (endDate && startDate > endDate) {
            setFromDateErr(true);
            setFromDateErrText("'From' time cannot be later than 'To' date.");
        } else if (endDate && startDate.getDate() === endDate.getDate()) {
            setFromDateErr(true);
            setFromDateErrText("'From' date cannot be the same as 'To' date.");
        } else {
            setFromDateErr(false);
            setFromDateErrText("");
        }
        setFromDate(value);
    };

    const handleToChange = (value) => {
        const startDate = fromDate;
        const endDate = value; 
        if (startDate && endDate < startDate) {
            setToDateErr(true);
            setToDateErrText("'To' time cannot be earlier than 'From' date.");
        }  else if (startDate && startDate.getDate() === endDate.getDate()) {
            setToDateErr(true);
            setToDateErrText("'To' date cannot be the same as 'From' date.");
        } else {
            setToDateErr(false);
            setToDateErrText("");
        }
        setToDate(value);
    };


    return <div className="wayment-owner-dashboard e-weighment-dashoard">
        <div className="heading-area p-d-flex p-ai-center p-jc-between">
            <div className="p-d-flex p-ai-center">
                <i className="icon-edit-profile"></i>
                <h2>Profile Configuration</h2>
            </div>
            <div className="p-d-flex p-ai-center p-jc-between">
                <div className="searchbar-area e-searchbar-area">
                    <i className="pi pi-search" />
                    <InputText
                        value={globalFilterValue}
                        onChange={onGlobalFilterChange}
                        placeholder="Global Search"
                    />
                </div>
            </div>
        </div>
        <hr />
        <div >

            <div >
                <DataTable className='p-mt-2' value={usersList} filters={filters} paginator rows={10}>
                    <Column body={actionBodyTemplateSrNo} header="Sr No."  style={{ Width: '15%'}} />
                    <Column field="Name" header="Name"  style={{ Width: '30%'}} />
                    <Column field="Phone" header="Phone"  style={{ Width: '30%'}} />
                    <Column field="UserType" header="Type"  style={{ Width: '30%'}} />
                    <Column body={actionBodyTemplate} header="Action" />
                </DataTable>
            </div>
            <Dialog
                header="Update Profile Configuration Access"
                className='dialog-popup'
                visible={openModal}
                onHide={() => setOpenModal(false)}
                draggable={false}
                style={{ width: "40vw" }}
                position="center"
            >
                <div className="p-d-flex p-flex-column p-jc-between">
                    <div className='p-grid'>

                        <div className="p-col-12 p-md-6 p-pt-2">
                            <div className="">
                                <label htmlFor="clasName">From Date </label>
                                <Calendar className='p-w-100' value={fromDate} onChange={(e) => handleFromChange(e.value)} dateFormat='dd/mm/yy' />
                                <span className="error-msg" style={{ color: 'red' }}>
                                    {fromDateErr ? fromDateErrText : null}
                                </span>
                            </div>
                        </div>
                        <div className="p-col-12 p-md-6 p-pt-2">
                            <div className="">
                                <label htmlFor="clasName">To Date </label>
                                <Calendar className='p-w-100' value={toDate} onChange={(e) => handleToChange(e.value)} dateFormat='dd/mm/yy' />
                                <span className="error-msg" style={{ color: 'red' }}>
                                    {toDateErr ? toDateErrText : null}
                                </span>
                            </div>
                        </div>
                        <div className="p-col-12 p-md-12 p-pt-2">
                            <div className="">
                                <Checkbox
                                    inputId="isAllowed"
                                    checked={isAllowed}
                                    onChange={(e) => setIsAllowed(e.checked)}
                                />
                                <label htmlFor="isAllowed" style={{ marginLeft: '8px' }}>Change Profile Allowed</label>
                            </div>
                        </div>
                    </div>
                    <div className='dialog-footer1 p-d-flex p-ai-center p-jc-end'>
                        <Button className='p-m-0 primary-btn1 p-m-1' onClick={(e) => onCancel(e)}> Cancel </Button>
                        <Button className='p-m-0 primary-btn p-m-1 color-white' label="Submit" severity="secondary" raised onClick={(e) => AddUpdateConfiguration(e)} />
                    </div>
                </div>

            </Dialog>
        </div>
    </div>
}

export default ProfileConfiguration;
