import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { FilterMatchMode } from 'primereact/api';
import { Dialog } from 'primereact/dialog';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import AddEditRoomMasterDialog from './AddUpdateRoomMaster';
import Toast from '../../Shared/Toast/Toast';
import DeleteConfirmation from '../../Dialogs/DeleteConfirmation';
import { InputSwitch } from 'primereact/inputswitch';

const RoomMaster = () => {
    const [roomList, setRoomList] = useState([]);
    const [masterData, setMasterData] = useState([]);
    const [roomId, setRoomId] = useState('');
    const [visible, setVisible] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        RoomNo: { value: null, matchMode: FilterMatchMode.CONTAINS },
        RoomAddress: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);
    const [isStatusConfirmation, setIsStatusConfirmation] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [securityUserID, setSecurityUserID] = useState("");
    const [rows, setRows] = useState(10);
    const [first, setFirst] = useState(0);
    const [visibleStudentList, setVisibleStudentList] = useState(false);
    const [masterStudentList, setMasterStudentList] = useState([]);
    const [visibleClassList, setVisibleClassList] = useState(false);
    const [masterClassList, setMasterClassList] = useState([]);
     const [updatedStatus, setUpdatedStatus] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                Loader.show();
                const loginData = CommonConfig.loginData();
                setSecurityUserID(loginData?._id);
                await GetRoomList(loginData?._id);
            } catch (error) {
                Loader.hide();
                console.error('Error in useEffect:', error);
            } finally {
                Loader.hide();
            }
        };
        fetchData();
    }, []);

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const onPageChange = (e) => {
        setFirst(e.first);
        setRows(e.rows);
    };

    const GetRoomList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.getRoomList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setRoomList(res.data);
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const UpdateRoomStatus = async () => {
        try {
            let data = {
                id: deleteId,
                Status: updatedStatus
            }
            await api.post(APIConstant.path.updateRoomDetailStatus, data).then(async response => {
                let res = response;
                if (res.success) {
                    Loader.hide();
                    Toast.success({ message: 'Room details status updated successfully' });
                    setDeleteId('');
                    setUpdatedStatus('');
                    GetRoomList(securityUserID);
                    setIsStatusConfirmation(false);
                }
            }).catch(err => {
                Loader.hide();
                setDeleteId('');
                setUpdatedStatus('');
                setIsStatusConfirmation(false);
                console.log(err);
            });
        } catch (err) {
            console.log(err);
            setDeleteId('');
            setUpdatedStatus('');
            setIsStatusConfirmation(false);
        }
    }

    const DeleteRoom = async () => {
        try {
            let data = {
                id: deleteId
            }
            await api.post(APIConstant.path.deleteRoomDetail, data).then(async response => {
                let res = response;
                if (res.success) {
                    Loader.hide();
                    Toast.success({ message: 'Room deleted successfully' });
                    setDeleteId('');
                    GetRoomList(securityUserID);
                    setIsDeleteConfirmation(false);
                }
            }).catch(err => {
                Loader.hide();
                setIsDeleteConfirmation(false);
                console.log(err);
            });
        } catch (err) {
            console.log(err);
            setIsDeleteConfirmation(false);
        }
    }

    const actionBodyTemplate = (rows) => {
        return (
            <React.Fragment>
                <div className='p-d-flex p-ai-center p-jc-end'>
                    <Button icon="pi pi-pencil" className="e-sction-btn p-m-1" onClick={() => {
                        setRoomId(rows._id)
                        setMasterData(rows);
                        setIsEdit(true)
                        setVisible(true)
                    }} />
                    <Button icon="pi pi-trash" className="e-sction-btn p-m-0" onClick={(e) => {
                        setIsDeleteConfirmation(true);
                        setDeleteId(rows._id);
                    }} />
                    <InputSwitch
                        checked={rows?.Status === 'Active' ? true : false}
                        onChange={(e) => {
                            toggleActiveStatus(rows._id, e.value);
                        }}
                        className="p-ml-2"
                    />
                </div>
            </React.Fragment>
        );
    };

    const toggleActiveStatus = (id, status) => {
        setDeleteId(id);
        setUpdatedStatus(status === true ? 'Active' : 'Inactive');
        setIsStatusConfirmation(true);
    };

    const renderFooter = () => (
        <div className="dialog-footer1 p-d-flex p-ai-center p-jc-end">
            <Button
                label="Cancel"
                className="primary-btn1"
                onClick={handleClose}
            />
            <Button
                label="Delete"
                className="primary-btn"
                onClick={(e) => DeleteRoom()}
            />
        </div>
    );

    const handleClose = () => {
        setIsDeleteConfirmation(false)
    };

    const renderStatusFooter = () => (
        <div className="dialog-footer1 p-d-flex p-ai-center p-jc-end">
            <Button
                label="Cancel"
                className="primary-btn1"
                onClick={handleCloseStatus}
            />
            <Button
                label="Update"
                className="primary-btn"
                onClick={(e) => UpdateRoomStatus()}
            />
        </div>
    );

    const handleCloseStatus = () => {
        setIsStatusConfirmation(false)
    };

    const onCancel = () => {
        setVisible(false);
        setRoomId('');
        setIsEdit(false);
        setMasterData([]);
        GetRoomList(securityUserID);
    }


    const actionBodyTemplateSrNo = (data, props) => {
        return props.rowIndex + 1;
    };

    return (
        <div className='wayment-owner-dashboard e-weighment-dashoard'>
            <div className="heading-area p-d-flex p-ai-center p-jc-between">
                <div className="p-d-flex p-ai-baseline">
                    <i className="pi pi-slack"></i>
                    <h2>Room Master</h2>
                </div>
                <div className="p-d-flex p-ai-center p-jc-between">
                    <div className="searchbar-area e-searchbar-area">
                        <i className="pi pi-search" />
                        <InputText
                            value={globalFilterValue}
                            onChange={onGlobalFilterChange}
                            placeholder="Global Search"
                        />
                    </div>
                    <Button icon="pi pi-plus" className="e-h-btn" onClick={() => setVisible(true)} />
                </div>
            </div>
            <hr className='p-m-0' />
            <DataTable
                value={roomList}
                paginator
                rows={rows}
                first={first}
                filters={filters}
                filterDisplay="menu"
                globalFilterFields={['RoomNo', 'RoomAddress', 'RoomEndTime']}
                responsiveLayout="scroll"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                rowsPerPageOptions={[5, 10, 20]}
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                onPage={onPageChange}
                className='p-mt-3 id-card-table'
            >
                <Column field="id" body={actionBodyTemplateSrNo} header="Sr No." style={{width: '10%'}}/>
                <Column
                    field="RoomNo"
                    header="Room No"
                    style={{width: '15%'}}
                />
                <Column
                    field="RoomAddress"
                    header="Room Address"
                    style={{width: '30%'}}
                />
                <Column
                    field="TotalCapacity"
                    header="TotalCapacity"
                    style={{width: '15%'}}
                />
                 <Column
                    field="BenchX2"
                    header="Bench (X2)"
                    style={{width: '15%'}}
                />
                <Column
                    field="BenchX3"
                    header="Bench (X3)"
                    style={{width: '15%'}}
                />

                <Column body={actionBodyTemplate} header="Action" headerStyle={{ display: 'flex', justifyContent: 'end'}}/>
            </DataTable>
            <Dialog
                header={roomId ? "Edit Room Master" : "Add Room Master"}
                className='dialog-popup'
                visible={visible}
                onHide={() => onCancel()}
                draggable={false}
                position="center"
            >
                <AddEditRoomMasterDialog masterData={masterData} isEdit={isEdit} ID={roomId} onCancel={onCancel} />

            </Dialog>
            <Dialog
                header="Teachers List"
                // className='dialog-popup'
                visible={visibleStudentList}
                onHide={() => {
                    setVisibleStudentList(false)
                    setMasterStudentList([]);
                }}
                draggable={false}
                position="top">

                <div className="p-col-12 p-card p-p-3">
                    <DataTable value={masterStudentList} paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rowsPerPageOptions={[10, 20, 50]}>
                        <Column body={actionBodyTemplateSrNo} header="Sr No." />
                        <Column field="Name" header="Teacher Name" />
                        <Column field="Email" header="Email" />
                        <Column field="Phone" header="Phone" />
                    </DataTable>
                </div>
            </Dialog>
            <Dialog
                header="Class List"
                visible={visibleClassList}
                onHide={() => {
                    setVisibleClassList(false)
                    setMasterClassList([]);
                }}
                draggable={false}
                position="top">

                <div className="p-col-12 p-card p-p-3">
                    <DataTable value={masterClassList} paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rowsPerPageOptions={[10, 20, 50]}>
                        <Column body={actionBodyTemplateSrNo} header="Sr No." />
                        <Column field="ClassName" header="Class Name" />
                        <Column field="Section" header="Section Name" />
                        <Column field="Board" header="Board Name" />
                        <Column field="Medium" header="Medium" />
                    </DataTable>
                </div>
            </Dialog>
            <Dialog
                visible={isDeleteConfirmation}
                onHide={handleClose}
                header={
                    <div className="p-d-flex p-jc-between p-ai-center">
                        <span>Delete Confirmation</span>
                    </div>
                }
                footer={renderFooter()}
                draggable={false}
                dismissableMask
                style={{ width: '450px' }}
            >
                <DeleteConfirmation />
            </Dialog>
            <Dialog
                visible={isStatusConfirmation}
                onHide={handleCloseStatus}
                header={
                    <div className="p-d-flex p-jc-between p-ai-center">
                        <span>Status Update</span>
                    </div>
                }
                footer={renderStatusFooter()}
                draggable={false}
                dismissableMask
                style={{ width: '450px' }}
            >
                <div>
                    <p>
                        Are you sure you want to update status?
                    </p>
                </div>
            </Dialog>
        </div>
    );
};

export default RoomMaster;
