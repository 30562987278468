import React from "react";
import { FileUpload } from "primereact/fileupload";
import CommonConfig from "../../utils/constant";
import placeHolder from '../../../src/assets/images/PlaceHolder.webp';

const UploadFile = ({ label,note, preview, original, uploadHandler, uploadRef, handleDelete }) => {
    return (
        <div>
            <label>{label}{note ? <span style={{ color: "red" }}>{note}</span> : ''}</label>
            <div className="p-d-flex p-flex-column p-jc-around">
                <img src={preview || original || placeHolder} alt="" className="upload-preview" style={{ width: '100px', height: '100px', borderRadius: '8px', objectFit: 'cover' }} />
                <div className='p-d-flex p-ai-center user-profile-btn p-mt-3'>
                    {!preview && <FileUpload ref={uploadRef} name='importFile' customUpload auto mode="basic" uploadHandler={uploadHandler} className="small-file-upload" accept="image/*" />
                    }
                    {(!CommonConfig.isEmpty(original) || !CommonConfig.isEmpty(preview)) && (
                        <div
                            className="error-msg-alert p-ml-2"
                            style={{
                                cursor: "pointer",
                            }}
                            onClick={() => handleDelete()}
                        >
                            <i className="pi pi-trash" style={{ fontSize: "16px", color: "red" }}></i>
                        </div>
                    )}
                </div>
            </div>
        </div >
    );
};

export default UploadFile;
