
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import React, { useState, useEffect } from 'react';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import Toast from '../../Shared/Toast/Toast';
import { FloatLabel } from 'primereact/floatlabel';

const AddClassNoMasterDialog = (props) => {
    const [securityUserID, setSecurityUserID] = useState("");
    const [classNoName, setClassNoName] = useState('');
    const [classNoNameErrText, setClassNoNameErrText] = useState('');
    const [classNoNameErr, setClassNoNameErr] = useState(false);

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        if (props.ID) {
            setClassNoName(props?.masterData?.ClassNo);
        }
    }, []);

    const onCancel = () => {
        props.onCancel();
        setClassNoName('');
    }

    const validate = () => {
        let formIsValid = true;
        if (CommonConfig.isEmpty(classNoName)) {
            formIsValid = false;
            setClassNoNameErr(true);
            setClassNoNameErrText("ClassNo Name is required");
        } else {
            setClassNoNameErr(false);
            setClassNoNameErrText("");
        }
        return formIsValid;
    };

    const AddClassNoMaster = async (e) => {
        if (validate(e)) {
            try {
                Loader.show();
                let data = {
                    id: props.ID,
                    ClassNo: classNoName,
                    CreatedBy: securityUserID,
                };

                await api
                    .post(APIConstant.path.AddUpdateClassNoMaster, data)
                    .then((response) => {
                        let res = response;
                        if (res.success) {
                            Toast.success({ message: res.message });
                            Loader.hide();
                            onCancel();
                        } else {
                            Toast.error({ message: res.message });
                            Loader.hide();
                            // onCancel();
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        Loader.hide();
                        onCancel();
                    });
            } catch (err) {
                console.log(err);
                Loader.hide();
                onCancel();
            }
        }
    };

    const handleChange = (e, type) => {
        const value = e.target.value;
        const alphanumericValue = value.replace(/[^a-zA-Z0-9\s!@#$%^&*]/g, '');
        const numericValue = value.replace(/[^0-9]/g, '');
        if (type === 'ClassNoName') {
            setClassNoName(alphanumericValue);
        } 
    };

    return (
        <div>
            <div className='p-grid'>
                <div className="p-col-12 p-md-12 p-pt-2">
                    <label htmlFor="clasName">Class No<span style={{ color: "red" }}>*</span></label>
                    <div className="">
                        <FloatLabel>
                            <InputText
                                className="p-mt-2 p-pl-2 p-w-100"
                                id="classNoName"
                                type="text"
                                maxLength={20}
                                placeholder="Class No"
                                value={classNoName}
                                autoFocus={true}
                                onChange={(e) => handleChange(e, 'ClassNoName')}
                            />
                        </FloatLabel>
                        <span className="error-msg" style={{ color: 'red' }}>
                            {classNoNameErr ? classNoNameErrText : null}
                        </span>
                    </div>
                </div>
            </div>
            <div className="dialog-footer1 p-d-flex p-ai-center p-jc-end">
                <Button className='primary-btn1' onClick={(e) => onCancel()}> cancel </Button>
                <Button className='primary-btn' label="Submit" severity="secondary" raised onClick={(e) => AddClassNoMaster()} />
            </div>
        </div>
    );
};

export default AddClassNoMasterDialog;
