
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import React, { useState, useEffect } from 'react';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import Toast from '../../Shared/Toast/Toast';
import { FloatLabel } from 'primereact/floatlabel';
import moment from 'moment';

const AddEditShiftMasterDialog = (props) => {
    const [securityUserID, setSecurityUserID] = useState("");
    const [shiftStart, setShiftStart] = useState(null);
    const [shiftEnd, setShiftEnd] = useState(null);
    const [shiftName, setShiftName] = useState('');
    const [shiftNameErrText, setShiftNameErrText] = useState('');
    const [shiftNameErr, setShiftNameErr] = useState(false);
    const [shiftStartErrText, setShiftStartErrText] = useState('');
    const [shiftStartErr, setShiftStartErr] = useState(false);
    const [shiftEndErrText, setShiftEndErrText] = useState('');
    const [shiftEndErr, setShiftEndErr] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                Loader.show();
                const loginData = CommonConfig.loginData();
                setSecurityUserID(loginData?._id);
                if (props.ID) {
                    setShiftName(props?.masterData?.ShiftName);
                    setShiftStart(props?.masterData?.ShiftStart ? new Date(props?.masterData?.ShiftStart) : null);
                    setShiftEnd(props?.masterData?.ShiftEnd ? new Date(props?.masterData?.ShiftEnd) : null);
                }
                Loader.hide();
            } catch (error) {
                Loader.hide();
                console.error('Error in useEffect:', error);
            }
        };
        fetchData();
    }, []);

    const onCancel = () => {
        props.onCancel();
    }

    const handleStartChange = (value) => {
        const endTime = shiftEnd;
        if (endTime && value > endTime) {
            setShiftStartErr(true);
            setShiftStartErrText("'Start' time cannot be later than 'End' time.");
        } else if (endTime && value.getTime() === endTime.getTime()) {
            setShiftStartErr(true);
            setShiftStartErrText("'Start' time cannot be the same as 'End' time.");
        } else {
            setShiftStartErr(false);
            setShiftStartErrText("");
        }
        setShiftStart(value);
    };

    const handleEndChange = (value) => {
        const startTime = shiftStart;
        if (startTime && value < startTime) {
            setShiftEndErr(true);
            setShiftEndErrText("'End' time cannot be earlier than 'From' time");
        } else if (startTime && value.getTime() === startTime.getTime()) {
            setShiftEndErr(true);
            setShiftEndErrText("'End' time cannot be the same as 'Start' time.");
        } else {
            setShiftEndErr(false);
            setShiftEndErrText("");
        }
        setShiftEnd(value);
    };

    const validate = () => {
        let formIsValid = true;
        if (CommonConfig.isEmpty(shiftName)) {
            formIsValid = false;
            setShiftNameErr(true);
            setShiftNameErrText("Shift Name is required");
        } else {
            setShiftNameErr(false);
            setShiftNameErrText("");
        }
        return formIsValid;
    };

    const AddEditShiftMaster = async (e) => {
        if (validate(e)) {
            try {
                Loader.show();
                let data = {
                    id: props.ID,
                    ShiftName: shiftName,
                    ShiftStart: moment(shiftStart),
                    ShiftEnd: moment(shiftEnd),
                    CreatedBy: securityUserID,
                };
                await api
                    .post(APIConstant.path.AddUpdateShiftMaster, data)
                    .then((response) => {
                        let res = response;
                        if (res.success) {
                            Toast.success({ message: res.message });
                            Loader.hide();
                            onCancel();
                        } else {
                            Toast.error({ message: res.message });
                            Loader.hide();
                            // onCancel();
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        Loader.hide();
                        onCancel();
                    });
            } catch (err) {
                console.log(err);
                Loader.hide();
                onCancel();
            }
        }
    };

    const handleChange = (e, type) => {
        const value = e.target.value;
        const alphanumericValue = value.replace(/[^a-zA-Z0-9\s]/g, '');
        if (type === 'ShiftName') {
            setShiftName(alphanumericValue);
        }
    };

    return (
        <div className="p-d-flex p-flex-column p-jc-between">
            <div className='p-grid'>
                <div className="p-col-12 p-md-6">
                    <div className="">
                        <label htmlFor="clasName">Shift Name<span style={{ color: "red" }}>*</span></label>
                        <FloatLabel>
                            <InputText
                                className="p-w-100"
                                id="className"
                                type="text"
                                placeholder="Shift Name"
                                maxLength={20}
                                value={shiftName}
                                autoFocus={true}
                                onChange={(e) => handleChange(e, 'ShiftName')}
                            />
                        </FloatLabel>
                        <span className="error-msg" style={{ color: 'red' }}>
                            {shiftNameErr ? shiftNameErrText : null}
                        </span>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                    <div className="">
                        <label htmlFor="shiftStart">Shift Start</label>
                        <Calendar value={shiftStart} onChange={(e) => handleStartChange(e.value)} hourFormat='12' timeOnly dateFormat='dd/mm/yy' />
                        <span className="error-msg" style={{ color: 'red' }}>
                            {shiftStartErr ? shiftStartErrText : null}
                        </span>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                    <div className="">
                        <label htmlFor="shiftEnd">Shift End</label>
                        <Calendar value={shiftEnd} onChange={(e) => handleEndChange(e.value)} hourFormat='12' timeOnly dateFormat='dd/mm/yy' />
                        <span className="error-msg" style={{ color: 'red' }}>
                            {shiftEndErr ? shiftEndErrText : null}
                        </span>
                    </div>
                </div>
            </div>
            <div className='dialog-footer1 p-d-flex p-ai-center p-jc-end'>
                <Button className='p-m-0 primary-btn1' onClick={() => onCancel()}> cancel </Button>
                <Button className='p-m-0 primary-btn p-m-1' label="Submit" severity="secondary" raised onClick={() => AddEditShiftMaster()} />
            </div>
        </div>
    );
};

export default AddEditShiftMasterDialog;