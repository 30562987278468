import React from 'react';
import { Button } from 'primereact/button';
import '../../../assets/css/teacherMaster.css';
import userImage from '../../../assets/icons/OBJECTS.svg';
import { useNavigate } from 'react-router-dom';
import { apiBase } from '../../../utils/config';
import CommonConfig from '../../../utils/constant';
import { InputSwitch } from 'primereact/inputswitch';

const StudentCard = ({ student, handleDelete, toggleActiveStatus }) => {
    const navigate = useNavigate();
    const isNoLogin = CommonConfig.isEmpty(student?.Phone);

    return (
        <div className="teacher-card p-mt-4">
            <img src={student?.ProfilePhotoUrl ? `${apiBase}/UserProfilePic/${student?.ProfilePhotoUrl}` : userImage} alt='' className="teacher-image" />
            <h4 className='p-text-capitalize p-m-0'>{student?.Name}</h4>
            <div className="p-d-flex p-ai-center p-jc-center p-mt-2">
                {CommonConfig.isEmpty(student?.Phone) ? <Button icon="pi pi-ban" className="e-sction-btn p-my-0 p-mx-0" /> : null}
                <Button icon="icon-view tailer-icon" className='e-btn p-my-0 p-mx-2' onClick={() => navigate('/viewEdit-student-master', { state: { userId: student?._id, isEdit: false } })} />
                <Button icon="pi pi-pencil" className='e-sction-btn p-my-0 p-mx-0' onClick={() => navigate('/viewEdit-student-master', { state: { userId: student?._id, isEdit: true } })} />
                <Button icon="pi pi-trash" className="e-sction-btn p-my-0 p-mr-0 p-ml-2" onClick={(e) => handleDelete(student?._id)} />
                <InputSwitch
                    checked={student?.Status === 'Active' ? true : false}
                    onChange={(e) => {
                        toggleActiveStatus(student._id, e.value);
                    }}
                    className="p-ml-2"
                />
            </div>
        </div>
    );
}

export default StudentCard;
