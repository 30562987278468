import React, { useState, useEffect, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { FloatLabel } from 'primereact/floatlabel';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primeicons/primeicons.css';
import '../../../assets/css/idCardTemplateConfig.css';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import Toast from '../../Shared/Toast/Toast';
import { apiBase } from '../../../utils/config';
import { Dialog } from 'primereact/dialog';
import DeleteConfirmation from '../../Dialogs/DeleteConfirmation';
import Result1 from '../../../assets/images/Result1.png';
import placeHolder from '../../../assets/images/PlaceHolder.webp';

const AddEditResultTemplate = (props) => {
    const [securityUserID, setSecurityUserID] = useState("");
    const schoolImgUploadRef = useRef(null);
    const sealImgUploadRef = useRef(null);
    const backgroundImgUploadRef = useRef(null);
    const signUploadRef = useRef(null);
    const [titleName, setTitleName] = useState('');
    const [titleNameErr, setTitleNameErr] = useState(false);
    const [titleNameErrText, setTitleNameErrText] = useState('');
    const [principalSignLogo, setPrincipalSignLogo] = useState(null);
    const [previewPrincipalSignLogo, setPreviewPrincipalSignLogo] = useState(null);
    const [schoolLogo, setSchoolLogo] = useState(null);
    const [previewSchoolLogo, setPreviewSchoolLogo] = useState(null);
    const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);
    const [bodyoptionsList, setBodyoptionsList] = useState([]);
    const [selectedBodyOptions, setSelectedBodyOptions] = useState([]);
    const [footerOptionsList, setFooterOptionsList] = useState([]);
    const [selectedFooterOptions, setSelectedFooterOptions] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState('/static/media/Result1.ccfa70caf758e10f3b11.png');
    const [editingIndex, setEditingIndex] = useState(null);
    const [newOptionValue, setNewOptionValue] = useState("");
    const [modelType, setModelType] = useState('');
    const [sealLogo, setSealLogo] = useState(null);
    const [previewSealLogo, setPreviewSealLogo] = useState(null);
    const [isMarksAllowed, setIsMarksAllowed] = useState(false);
    const [backGroundImage, setBackGroundImage] = useState(null);
    const [previewBackGroundImage, setPreviewBackGroundImage] = useState(null);

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        let bodyList = [
            { label: 'Class', value: 'Class' },
            { label: 'Section', value: 'Section' },
            { label: 'Board', value: 'Board' },
            { label: 'Medium', value: 'Medium' },
            { label: 'Academic Year', value: 'AcademicYear' },
            { label: 'First Name', value: 'FirstName' },
            { label: 'Surname', value: 'Surname' },
            { label: 'Full Name', value: 'FullName' },
            { label: 'Father Name', value: 'FName' },
            { label: 'Mother Name', value: 'MName' },
            { label: 'Date of Birth', value: 'DOB' },
            { label: 'Address', value: 'Address' },
            { label: 'Roll No', value: 'RollNo' },
            { label: 'GRNo', value: 'GRNo' }
        ];
        let footerList = [
            { label: 'Marks', value: 'Marks' },
            { label: 'Attendance', value: 'Attendance' },
            { label: 'Percentage', value: 'Percentage' },
            { label: 'Remarks', value: 'Remarks' },
            { label: 'Grade', value: 'Grade' },
            { label: 'Rank', value: 'Rank' },
            { label: 'School Address', value: 'SCLAddress' },
            { label: 'School Phone', value: 'SCLPhone' },
            { label: 'School Email', value: 'SCLEmail' },

        ];
        if (props?.configId) {
            setTitleName(props?.masterData?.TitleName);
            setSelectedTemplate(props?.masterData?.TemplateName ?? selectedTemplate);
            setSelectedBodyOptions(props?.masterData?.BodySection
                ? props?.masterData?.BodySection.map((item) => {
                    return item?.key;
                })
                : []);
            setBodyoptionsList(bodyList);
            setSelectedFooterOptions(props?.masterData?.FooterSection
                ? props?.masterData?.FooterSection.map((item) => {
                    return item?.key;
                })
                : []);
            setFooterOptionsList(footerList);
            setPrincipalSignLogo(props?.masterData?.PrincipalSignImage ? `${apiBase}/Documents/${props?.masterData?.PrincipalSignImage}` : null);
            setSchoolLogo(props?.masterData?.SchoolLogo ? `${apiBase}/Documents/${props?.masterData?.SchoolLogo}` : null);
            setSealLogo(props?.masterData?.SealImage ? `${apiBase}/Documents/${props?.masterData?.SealImage}` : null);
            setBackGroundImage(props?.masterData?.BackgroundImage ? `${apiBase}/Documents/${props?.masterData?.BackgroundImage}` : null);
        } else {
            setBodyoptionsList(bodyList);
            setFooterOptionsList(footerList);
        }
    }, []);

    const validate = () => {
        let formIsValid = true;
        if (CommonConfig.isEmpty(titleName)) {
            formIsValid = false;
            setTitleNameErr(true);
            setTitleNameErrText("Title Name is required");
        } else {
            setTitleNameErr(false);
            setTitleNameErrText("");
        }
        return formIsValid;
    };

    const onCancel = () => {
        props.onCancel();
    }

    const AddUpdateTemplate = async (e) => {
        if (validate(e)) {
            try {
                let bodySectionData = [];
                if (selectedBodyOptions) {
                    bodySectionData = selectedBodyOptions.map((x, idx) => {
                        if (idx === 1) {
                            return {
                                label: 'StudentProfilePic',
                                key: 'StudentProfilePic'
                            }
                        }
                        return {
                            label: bodyoptionsList.filter(y => y?.value === x)[0]?.label,
                            key: x
                        }
                    })
                }
                let footerSectionData = [];
                if (selectedFooterOptions) {
                    footerSectionData = selectedFooterOptions.map((x, idx) => {
                        return {
                            label: footerOptionsList.filter(y => y?.value === x)[0]?.label,
                            key: x
                        }
                    })
                }
                const formData = new FormData();
                formData.append("id", props?.masterData?._id ?? '');
                formData.append('TemplateName', selectedTemplate);
                formData.append("TitleName", titleName);
                formData.append("BodySection", JSON.stringify(bodySectionData));
                formData.append("FooterSection", JSON.stringify(footerSectionData));
                formData.append("IsMarksAllowed", JSON.stringify(isMarksAllowed));
                formData.append("CreatedBy", securityUserID);
                if (principalSignLogo && principalSignLogo.type) {
                    formData.append("PrincipalSignImage", principalSignLogo);
                } else if (CommonConfig.isEmpty(principalSignLogo)) {
                    formData.append("PrincipalSignImage", '');
                }
                if (schoolLogo && schoolLogo.type) {
                    formData.append("SchoolLogo", schoolLogo);
                } else if (CommonConfig.isEmpty(schoolLogo)) {
                    formData.append("SchoolLogo", '');
                }
                if (sealLogo && sealLogo.type) {
                    formData.append("SealImage", sealLogo);
                } else if (CommonConfig.isEmpty(sealLogo)) {
                    formData.append("SealImage", '');
                }
                if (backGroundImage && backGroundImage.type) {
                    formData.append("BackgroundImage", backGroundImage);
                } else if (CommonConfig.isEmpty(backGroundImage)) {
                    formData.append("BackgroundImage", '');
                }
                Loader.show();
                await api
                    .post(APIConstant.path.AddUpdateResultConfiguration, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then((response) => {
                        let res = response;
                        if (res.success) {
                            Toast.success({ message: res.message });
                            Loader.hide();
                            onCancel();
                        } else {
                            Toast.error({ message: res.message });
                            Loader.hide();
                            onCancel();
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        Toast.error({ message: err?.data?.message });
                        Loader.hide();
                        onCancel();
                    });
            } catch (err) {
                console.log(err);
                Toast.error({ message: err });
                Loader.hide();
                onCancel();
            }
        }
    };

    const handlePrincipalSignLogo = (e) => {
        const selectedFile = e.files[0];
        if (selectedFile) {
            const validFormats = ['image/jpeg', 'image/png', 'image/jpg', 'image/svg+xml'];
            if (validFormats.includes(selectedFile.type)) {
                const img = new Image();
                img.onload = () => {
                    // if (img.width === img.height) {
                        setPrincipalSignLogo(selectedFile);
                        const fileURL = URL.createObjectURL(selectedFile);
                        setPreviewPrincipalSignLogo(fileURL);
                    // } else {
                    //     if (signUploadRef.current) {
                    //         signUploadRef.current.clear();
                    //     }
                    //     Toast.error({ message: 'The image must have a 1:1 aspect ratio.' });
                    // }
                };
                img.onerror = () => {
                    if (signUploadRef.current) {
                        signUploadRef.current.clear();
                    }
                    Toast.error({ message: 'Error loading the image.' });
                };

                const reader = new FileReader();
                reader.onload = (event) => {
                    img.src = event.target.result;
                };
                reader.readAsDataURL(selectedFile);
            } else {
                if (signUploadRef.current) {
                    signUploadRef.current.clear();
                }
                Toast.error({ message: 'Invalid file format. Please select a JPEG, SVG or PNG image.' });
            }
        }
    };

    const handleSealLogo = (e) => {
        const selectedFile = e.files[0];
        if (selectedFile) {
            const validFormats = ['image/jpeg', 'image/png', 'image/jpg', 'image/svg+xml'];
            if (validFormats.includes(selectedFile.type)) {
                const img = new Image();
                img.onload = () => {
                    if (img.width === img.height) {
                        setSealLogo(selectedFile);
                        const fileURL = URL.createObjectURL(selectedFile);
                        setPreviewSealLogo(fileURL);
                    } else {
                        if (sealImgUploadRef.current) {
                            sealImgUploadRef.current.clear();
                        }
                        Toast.error({ message: 'The image must have a 1:1 aspect ratio.' });
                    }
                };
                img.onerror = () => {
                    if (sealImgUploadRef.current) {
                        sealImgUploadRef.current.clear();
                    }
                    Toast.error({ message: 'Error loading the image.' });
                };

                const reader = new FileReader();
                reader.onload = (event) => {
                    img.src = event.target.result;
                };
                reader.readAsDataURL(selectedFile);
            } else {
                if (sealImgUploadRef.current) {
                    sealImgUploadRef.current.clear();
                }
                Toast.error({ message: 'Invalid file format. Please select a JPEG, SVG or PNG image.' });
            }
        }
    };

    const handleSchoolLogo = (e) => {
        const selectedFile = e.files[0];
        if (selectedFile) {
            const validFormats = ['image/jpeg', 'image/png', 'image/jpg', 'image/svg+xml'];
            if (validFormats.includes(selectedFile.type)) {
                const img = new Image();
                img.onload = () => {
                    if (img.width == '1046' && img.height == '100') {
                        setSchoolLogo(selectedFile);
                        const fileURL = URL.createObjectURL(selectedFile);
                        setPreviewSchoolLogo(fileURL);
                    } else {
                        if (schoolImgUploadRef.current) {
                            schoolImgUploadRef.current.clear();
                        }
                        Toast.error({ message: 'The image must have a 10.46:1 aspect ratio.' });
                    }
                };
                img.onerror = () => {
                    if (schoolImgUploadRef.current) {
                        schoolImgUploadRef.current.clear();
                    }
                    Toast.error({ message: 'Error loading the image.' });
                };

                const reader = new FileReader();
                reader.onload = (event) => {
                    img.src = event.target.result;
                };
                reader.readAsDataURL(selectedFile);
            } else {
                if (schoolImgUploadRef.current) {
                    schoolImgUploadRef.current.clear();
                }
                Toast.error({ message: 'Invalid file format. Please select a JPEG, SVG or PNG image.' });
            }
        }
    };

    const handleBackgroundImage = (e) => {
        const selectedFile = e.files[0];
        if (selectedFile) {
            const validFormats = ['image/jpeg', 'image/png', 'image/jpg', 'image/svg+xml'];
            if (validFormats.includes(selectedFile.type)) {
                const img = new Image();
                img.onload = () => {
                    if (img.width === img.height) {
                        setBackGroundImage(selectedFile);
                        const fileURL = URL.createObjectURL(selectedFile);
                        setPreviewBackGroundImage(fileURL);
                    } else {
                        if (backgroundImgUploadRef.current) {
                            backgroundImgUploadRef.current.clear();
                        }
                        Toast.error({ message: 'The image must have a 1:1 aspect ratio.' });
                    }
                };
                img.onerror = () => {
                    if (backgroundImgUploadRef.current) {
                        backgroundImgUploadRef.current.clear();
                    }
                    Toast.error({ message: 'Error loading the image.' });
                };
                const reader = new FileReader();
                reader.onload = (event) => {
                    img.src = event.target.result;
                };
                reader.readAsDataURL(selectedFile);
            } else {
                if (backgroundImgUploadRef.current) {
                    backgroundImgUploadRef.current.clear();
                }
                Toast.error({ message: 'Invalid file format. Please select a JPEG, SVG or PNG image.' });
            }
        }
    };

    const renderFooter = () => (
        <div className="dialog-footer1 p-d-flex p-ai-center p-jc-end">
            <Button
                label="Cancel"
                className="primary-btn1"
                onClick={handleClose}
            />
            <Button
                label="Delete"
                className="primary-btn"
                onClick={() => {
                    handleClose();
                    if (modelType === 'Principal Logo') {
                        setPrincipalSignLogo("")
                        setPreviewPrincipalSignLogo();
                        if (signUploadRef.current) {
                            signUploadRef.current.clear();
                        }
                    } else if (modelType === 'Seal') {
                        setSealLogo("")
                        setPreviewSealLogo();
                        if (sealImgUploadRef.current) {
                            sealImgUploadRef.current.clear();
                        }
                    } else if (modelType === 'School Logo') {
                        setSchoolLogo("")
                        setPreviewSchoolLogo();
                        if (schoolImgUploadRef.current) {
                            schoolImgUploadRef.current.clear();
                        }
                    } else {
                        setBackGroundImage("")
                        setPreviewBackGroundImage();
                        if (backgroundImgUploadRef.current) {
                            backgroundImgUploadRef.current.clear();
                        }
                    }
                }}
            />
        </div>
    );

    const handleClose = () => {
        setIsDeleteConfirmation(false)
    };

    const startEditing = (index, currentValue) => {
        setEditingIndex(index);
        setNewOptionValue(currentValue);
    };

    const saveEdit = (index, type) => {
        if (type === 'Body') {
            setBodyoptionsList((prev) =>
                prev.map((option, i) =>
                    i === index ? { ...option, label: newOptionValue } : option
                )
            );
        } else {
            setFooterOptionsList((prev) =>
                prev.map((option, i) =>
                    i === index ? { ...option, label: newOptionValue } : option
                )
            );
        }
        setEditingIndex(null);
        setNewOptionValue("");
    };

    const cancelEdit = () => {
        setEditingIndex(null);
        setNewOptionValue("");
    };

    const handleCheckBodyboxChange = (e) => {
        const value = e.value;
        setSelectedBodyOptions((prevSelected) =>
            prevSelected.includes(value)
                ? prevSelected.filter((v) => v !== value)
                : [...prevSelected, value]
        );
    };

    const handleCheckFooterboxChange = (e) => {
        const value = e.value;
        setSelectedFooterOptions((prevSelected) =>
            prevSelected.includes(value)
                ? prevSelected.filter((v) => v !== value)
                : [...prevSelected, value]
        );
    };

    const handleImageClick = (image) => {
        if (selectedTemplate === image) {
            setSelectedTemplate('');
        } else {
            setSelectedTemplate(image);
        }
    };

    const renderFileUpload = (label, note, preview, original, uploadHandler, uploadRef) => (
        <div>
            <label>{label}<span style={{ color: "red" }}>{note}</span></label>
            <div className="p-d-flex p-flex-column p-jc-around">
                <img src={preview || original || placeHolder} alt="" className="upload-preview" style={{ width: '150px', height: '150px', borderRadius: '8px', objectFit: 'cover' }} />
                <div className='p-d-flex p-ai-center user-profile-btn p-mt-3'>
                    {!preview && <FileUpload ref={uploadRef} name='importFile' customUpload auto mode="basic" uploadHandler={uploadHandler} className="small-file-upload" accept="image/*" />
                    }
                    {(!CommonConfig.isEmpty(original) || !CommonConfig.isEmpty(preview)) && (
                        <div
                            className="error-msg-alert p-ml-2"
                            style={{
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                setIsDeleteConfirmation(true);
                                setModelType(label);
                            }}
                        >
                            <i className="pi pi-trash" style={{ fontSize: "16px", color: "red" }}></i>
                        </div>
                    )}
                </div>
            </div>
        </div >
    );

    const OptionItem = ({ option, index, editingIndex, selectedOptions, newOptionValue, handleCheckChange, startEditing, setNewOptionValue, saveEdit, cancelEdit, section }) => (
        <div className="p-d-flex p-ai-center" style={{ flexGrow: 1 }}>
            <Checkbox
                inputId={option.value}
                value={option.value}
                onChange={handleCheckChange}
                checked={selectedOptions.includes(option.value)}
                className="p-mr-1"
            />
            {editingIndex === index ? (
                <>
                    <InputText
                        value={newOptionValue || option.label}
                        onChange={(e) => setNewOptionValue(e.target.value)}
                        placeholder="Enter new value"
                        className="p-mr-2"
                        style={{ maxWidth: '150px' }}
                    />
                    <Button icon="pi pi-check" className="e-border-btn p-mr-2" onClick={() => saveEdit(index, section)} />
                    <Button icon="pi pi-times" className="e-border-btn" onClick={cancelEdit} />
                </>
            ) : (
                <>
                    <label htmlFor={option.value} className="p-mr-2">{option.label}</label>
                    <Button className="e-border-btn" icon="pi pi-pencil" onClick={() => startEditing(index, option.label)} />
                </>
            )}
        </div>
    );

    const OptionGrid = ({ optionsList, section, ...props }) => (
        <div className="p-grid">
            {optionsList.map((option, index) => (
                <OptionItem
                    key={option.value}
                    option={option}
                    index={index}
                    section={section}
                    {...props}
                />
            ))}
        </div>
    );

    return (
        <div className="p-d-flex p-flex-column p-jc-between">
            <div>
                <div className="image-selection-container">
                    {[Result1].map((image, index) => (
                        <div
                            key={index}
                            className={`image-item ${selectedTemplate === image ? 'selected' : ''}`}
                            onClick={() => handleImageClick(image)}
                            style={{
                                border: selectedTemplate === image ? '2px solid red' : '2px solid transparent',
                                cursor: 'pointer',
                                display: 'inline-block',
                                margin: '5px',
                            }}
                        >
                            <img
                                src={image}
                                alt={`Result${index + 1}`}
                                style={{ width: '200px', height: '300px' }}
                            />
                        </div>
                    ))}
                </div>
                <hr className='p-m-0' />
                <div className='p-grid'>
                    <div className="p-col-6 p-md-6 ">
                        <label htmlFor="titleName">Title Name</label>
                        <FloatLabel>
                            <InputText
                                className='p-mt-1 p-w-100'
                                id="titleName"
                                value={titleName}
                                onChange={(e) => setTitleName(e.target.value)}
                            />
                        </FloatLabel>
                        <span className="error-msg" style={{ color: 'red' }}>
                            {titleNameErr ? titleNameErrText : null}
                        </span>
                    </div>
                    <p className='p-mt-2'>
                        {renderFileUpload("School Logo", '(Select 10.46:1 ratio image)', previewSchoolLogo, schoolLogo, handleSchoolLogo, schoolImgUploadRef)}
                    </p>
                    <div className="">
                        <Checkbox
                            inputId="isMarksAllowed"
                            checked={isMarksAllowed}
                            onChange={(e) => setIsMarksAllowed(e.checked)}
                            className="p-mr-1"
                        />
                        <label htmlFor="isMarksAllowed">Marks lessthan 33% not added in overall?</label>
                    </div>
                </div>
                <div className="header-section p-ai-center">
                    <h2 className="p-m-0 p-pb-2">Body Section</h2>
                    <hr className='p-m-0' />
                    <div >
                        <OptionGrid
                            optionsList={bodyoptionsList}
                            section="Body"
                            editingIndex={editingIndex}
                            selectedOptions={selectedBodyOptions}
                            newOptionValue={newOptionValue}
                            handleCheckChange={handleCheckBodyboxChange}
                            startEditing={startEditing}
                            setNewOptionValue={setNewOptionValue}
                            saveEdit={saveEdit}
                            cancelEdit={cancelEdit}
                        />

                    </div>
                </div>

                <div className="header-section  p-ai-center">
                    <h2 className="p-m-0 p-d-flex p-pb-2">Footer Section</h2>
                    <hr className='p-m-0' />
                    <div className="p-grid" style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
                        gap: '5px'
                    }}>
                        <OptionGrid
                            optionsList={footerOptionsList}
                            section="Footer"
                            editingIndex={editingIndex}
                            selectedOptions={selectedFooterOptions}
                            newOptionValue={newOptionValue}
                            handleCheckChange={handleCheckFooterboxChange}
                            startEditing={startEditing}
                            setNewOptionValue={setNewOptionValue}
                            saveEdit={saveEdit}
                            cancelEdit={cancelEdit}
                        />
                    </div>
                    <div className="p-grid" style={{ gap: "10px" }}>
                        {renderFileUpload("Principal Logo", '', previewPrincipalSignLogo, principalSignLogo, handlePrincipalSignLogo, signUploadRef)}
                        {renderFileUpload("Seal", '(Select 1:1 ratio image)', previewSealLogo, sealLogo, handleSealLogo, sealImgUploadRef)}
                        {renderFileUpload("Background Image",'(Select 1:1 ratio image)', previewBackGroundImage, backGroundImage, handleBackgroundImage, backgroundImgUploadRef)}
                    </div>
                </div>
            </div>
            <div className="dialog-footer1 p-d-flex p-ai-center p-jc-end">
                <Button className='p-m-0 primary-btn1 p-m-1' label="Cancel" onClick={(e) => onCancel()} />
                <Button label="Save" icon="pi pi-check" className="p-m-0 btn-text-action-primary" onClick={(e) => AddUpdateTemplate()} />
            </div>
            <Dialog
                visible={isDeleteConfirmation}
                onHide={handleClose}
                header={
                    <div className="p-d-flex p-jc-between p-ai-center">
                        <span>Delete Confirmation</span>
                    </div>
                }
                footer={renderFooter()}
                draggable={false}
                dismissableMask
                style={{ width: '450px' }}
            >
                <DeleteConfirmation />
            </Dialog>
        </div >
    );
};

export default AddEditResultTemplate;
