import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useNavigate } from "react-router-dom";
import CommonConfig from "../../../utils/constant";
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import Toast from '../../Shared/Toast/Toast';

const ExamMarksEntry = (props) => {
    const [students, setStudents] = useState([]);
    const navigate = useNavigate();
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [securityUserID, setSecurityUserID] = useState("");

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        GetExamMarksList();
    }, []);

    const GetExamMarksList = async () => {
        try {
            let data = {
                ClassId: props?.location?.state?.classId,
                ExamTypeId: props?.location?.state?.examId,
                SubjectId: props?.location?.state?.subjectId,
                TermId: props?.location?.state?.termId
            }
            await api.post(APIConstant.path.GetExamMarksList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setStudents(res?.data);
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const AddExamMarksData = async (isGraft) => {
        try {
            const updatedStudents = students?.map((student) => (
                { ...student, IsGraft: isGraft })
            );
            await api.post(APIConstant.path.AddUpdateExamMarks, updatedStudents).then(async response => {
                let res = response;
                if (res.success) {
                    Toast.success({ message: res.message });
                    Loader.hide();
                    navigate('/examination')
                    // GetExamMarksList();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    };

    const onPageChange = (e) => {
        setFirst(e.first);
        setRows(e.rows);
    };

    const handleMarksChange = (e, id) => {
        const updatedStudents = students.map((student) => {
            if (student?.StudentId === id) {
                const enteredMarks = Number(e.target.value);
                const totalMarks = Number(student.TotalMarks);
    
                return {
                    ...student,
                    SecuredMarks: enteredMarks > totalMarks ? totalMarks : enteredMarks
                };
            }
            return student;
        });
        setStudents(updatedStudents);
    };

    const marksEditor = (rowData) => {
        return (
            <InputText
                value={rowData?.SecuredMarks}
                onChange={(e) => handleMarksChange(e, rowData?.StudentId)}
                style={{ width: "60px", textAlign: "center" }}
            />
        );
    };

    const srNoBodyTemplate = (data, props) => {
        return props?.rowIndex + 1;
    };

    return (
        <div className='wayment-owner-dashboard e-weighment-dashoard'>
            <div className="heading-area p-d-flex p-ai-center p-jc-between">
                <div className="p-d-flex p-ai-baseline">
                    <i className="pi pi-arrow-left" onClick={(e) => navigate('/examination')}></i>
                    <h2>{props?.location?.state?.className} / {props?.location?.state?.subjectName}</h2>
                </div>
            </div>
            <hr className='p-m-0' />
            <DataTable value={students} paginator className="p-mt-3"
                rows={rows}
                first={first}
                responsiveLayout="scroll"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                rowsPerPageOptions={[5, 10, 20]}
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                onPage={onPageChange}>
                <Column header="Sr No" body={srNoBodyTemplate} style={{ width: "10%" }} />
                <Column field="StudentName" header="Name" style={{ width: "25%" }} />
                <Column field="GrNo" header="GR No." style={{ width: "15%" }} />
                <Column field="RollNo" header="Roll No." style={{ width: "15%" }} />
                <Column field="TotalMarks" header="Total Marks" style={{ width: "15%" }} />
                <Column header="Secured Marks" body={marksEditor} style={{ width: "20%" }} />
            </DataTable>
            <div className="flex justify-end gap-3 mt-4">
                <Button label="Cancel" onClick={(e) => navigate('/examination')} className="p-button-outlined p-button-secondary" />
                <Button label="Save as Draft" className="p-button-secondary" onClick={(e) => AddExamMarksData(true)} />
                <Button label="Submit All" className="p-button-primary" onClick={(e) => AddExamMarksData(false)}/>
            </div>
        </div>
    );
};

export default ExamMarksEntry;
