import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { TabView, TabPanel } from "primereact/tabview";
import { FilterMatchMode } from 'primereact/api';
import { useNavigate } from "react-router-dom";
import CommonConfig from "../../../utils/constant";
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";


const Examination = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [activeTab, setActiveTab] = useState(0);
    const [securityUserID, setSecurityUserID] = useState("");
    const navigate = useNavigate();
    const [rows, setRows] = useState(10);
    const [first, setFirst] = useState(0);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [examScheduleOnGoingMarksList, setExamScheduleOnGoingMarksList] = useState([]);
    const [examScheduleCompletedMarksList, setExamScheduleCompletedMarksList] = useState([]);
    const onPageChange = (e) => {
        setFirst(e.first);
        setRows(e.rows);
    };
    const filteredExams = (activeTab === 1 ? examScheduleCompletedMarksList : examScheduleOnGoingMarksList).filter(exam =>
        exam?.Class?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
        exam?.Subject?.toLowerCase()?.includes(searchTerm?.toLowerCase()) || 
        exam?.ExamType?.toLowerCase()?.includes(searchTerm?.toLowerCase())
    );

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        GetExamScheduleList(loginData?._id, loginData?.CreatedBy);
    }, []);

    const GetExamScheduleList = async (id, schId) => {
        try {
            let data = {
                UserID: id,
                SchoolId: schId
            }
            Loader.show();
            await api.post(APIConstant.path.GetExamScheduleMarksList, data).then(async response => {
                let res = response;
                if (res.success) {
                    var onGoingList = res?.data.filter(x => x?.IsAllow === true);
                    var completeList = res?.data.filter(x => x?.IsAllow === false);
                    setExamScheduleOnGoingMarksList(onGoingList);
                    setExamScheduleCompletedMarksList(completeList);
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) {
            Loader.hide();
            console.log(err); }
    }

    const checkedTemplate = (rowData) => (
        <span style={{ color: "green" }}>{rowData?.Checked}</span>
    );

    const actionTemplate = (rowData) => (
        <Button label="Configure" disabled={!rowData?.IsAllow} onClick={(e) => navigate('/examination-marks', { state: { className: rowData?.ClassName, subjectName: rowData?.SubjectName, classId: rowData?.ClassId, examId: rowData?.ExamId, subjectId: rowData?.SubjectId, termId: rowData?.TermId}})} />
    );

    const srNoBodyTemplate = (data, props) => {
        return props?.rowIndex + 1;
    };

    return (
        <div className='wayment-owner-dashboard e-weighment-dashoard'>
            <div className="heading-area p-d-flex p-ai-center p-jc-between">
                <div className="p-d-flex p-ai-baseline">
                    <i className="pi pi-book"></i>
                    <h2>Examination</h2>
                </div>
                <div className="p-d-flex p-ai-center p-jc-between">
                    <div className="searchbar-area e-searchbar-area">
                        <i className="pi pi-search" />
                        <InputText
                            value={globalFilterValue}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            placeholder="Global Search"
                        />
                    </div>
                    <Button icon="pi pi-plus" className="e-h-btn" />
                </div>
            </div>
            <hr className='p-m-0' />
            <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
                <TabPanel header="Ongoing">
                    <DataTable value={filteredExams} paginator className='e-table-height'
                        rows={rows}
                        first={first}
                        responsiveLayout="scroll"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        rowsPerPageOptions={[5, 10, 20]}
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        onPage={onPageChange}>
                        <Column header="Sr No" body={srNoBodyTemplate}  style={{ width: "6%" }}/>
                        <Column field="ExamType" header="Exam Type"  style={{ width: "15%" }}/>
                        <Column field="ClassName" header="Class"  style={{ width: "15%" }}/>
                        <Column field="SubjectName" header="Subject"  style={{ width: "15%" }}/>
                        <Column field="TotalStudents" header="Total Students"  style={{ width: "10%" }}/>
                        <Column header="Checked" body={checkedTemplate}  style={{ width: "10%" }}/>
                        <Column header='Action' body={actionTemplate} style={{ width: "10%" }}/>
                    </DataTable>
                </TabPanel>
                <TabPanel header="Completed">
                <DataTable value={filteredExams} paginator className="e-table-height"
                        rows={rows}
                        first={first}
                        responsiveLayout="scroll"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        rowsPerPageOptions={[5, 10, 20]}
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        onPage={onPageChange}>
                        <Column header="Sr No" body={srNoBodyTemplate}  style={{ width: "6%" }}/>
                        <Column field="ExamType" header="Exam Type"  style={{ width: "15%" }}/>
                        <Column field="ClassName" header="Class"  style={{ width: "15%" }}/>
                        <Column field="SubjectName" header="Subject"  style={{ width: "15%" }}/>
                        <Column field="TotalStudents" header="Total Students"  style={{ width: "10%" }}/>
                        <Column header="Checked" body={checkedTemplate}  style={{ width: "10%" }}/>
                    </DataTable>
                </TabPanel>
            </TabView>
        </div>
    );
};

export default Examination;
