import React, { useState, useEffect } from 'react';
import Loader from "../Shared/Loader/Loader";
import APIConstant from "../../utils/pathConstants";
import api from "../../utils/apiClient";
import CommonConfig from '../../utils/constant';
import { TabView, TabPanel } from 'primereact/tabview';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";


const Attendance = () => {
    const [securityUserID, setSecurityUserID] = useState("");
    const [classList, setClassList] = useState([]);
    const [classWiseAttendanceList, setClassWiseAttendanceList] = useState([]);
    const [studentWiseAttendanceList, setStudentWiseAttendanceList] = useState([]);
    const [rows, setRows] = useState(10);
    const [first, setFirst] = useState(0);
    const [selectedClass, setSelectedClass] = useState(null);

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        GetClassList(loginData?._id);
    }, []);


    const GetClassList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            Loader.show();
            await api.post(APIConstant.path.getClassList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setClassList(res?.data?.filter(x => x?.Status === 'Active')?.map((x) => ({
                        value: x?._id,
                        label: `${x?.ClassName}-${x?.SectionFullName.charAt(0)}-${x?.MediumName}-${x?.Board}`
                    })));
                    if (res?.data) {
                        setSelectedClass(res?.data[0]?._id);
                        GetClassWiseAttendanceList(res?.data[0]?._id);
                        GetStudentWiseAttendanceList(res?.data[0]?._id);
                    }
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const GetClassWiseAttendanceList = async (id) => {
        try {
            let data = {
                ClassId: id,
            }
            Loader.show();
            await api.post(APIConstant.path.GetClassWiseAttendanceList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setClassWiseAttendanceList(res.data);
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const GetStudentWiseAttendanceList = async (id) => {
        try {
            let data = {
                ClassId: id,
            }
            Loader.show();
            await api.post(APIConstant.path.GetStudentWiseAttendanceList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setStudentWiseAttendanceList(res.data);
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const actionBodyTemplateSrNo = (data, props) => {
        return props.rowIndex + 1;
    };

    const onPageChange = (e) => {
        setFirst(e.first);
        setRows(e.rows);
    };

    function formatDate(dateString) {
        const date = new Date(dateString);
        const options = {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        };
        return date.toLocaleString('en-US', options).replace(',', '');
    }

    const attendanceDateBodyTemplate = (rows) => {
        return <div>{!CommonConfig.isEmpty(rows?.AttendanceDate) ? formatDate(rows?.AttendanceDate) : '--'}</div>
    };

    const attendanceBodyTemplate = (rows) => {
        return (
            <label htmlFor="attendance"
                style={{
                    color: rows?.AttendanceType === 'P' ? 'green' : rows?.AttendanceType === 'L' ? 'yellow' : 'red',
                    fontWeight: "bold",
                    cursor: "pointer",
                }}
            >
                {rows?.AttendanceType}
            </label>
        )
    };

    const handleClassChange = (value) => {
        setSelectedClass(value);
        GetClassWiseAttendanceList(value);
        GetStudentWiseAttendanceList(value)
    }

    return (
        <div className='wayment-owner-dashboard e-weighment-dashoard'>
            <div className="heading-area p-d-flex p-ai-center p-jc-between">
                <div className="p-d-flex p-ai-baseline">
                    <i className="pi pi-eye"></i>
                    <h2>View Attendance</h2>
                </div>
                <div className="p-grid p-align-center" style={{ gap: "10px" }}>
                    <div style={{ marginBottom: '10px', marginTop: '10px' }}>
                        <Dropdown
                            value={selectedClass}
                            options={classList}
                            onChange={(e) => handleClassChange(e.value)}
                            placeholder="Select Class"
                        />
                    </div>
                </div>
            </div>
            <hr className='p-m-0' />
            <TabView className=''>
                <TabPanel header="Class Wise Attendance">
                    <div>
                        <DataTable
                            value={classWiseAttendanceList}
                            paginator
                            rows={rows}
                            first={first}
                            filterDisplay="menu"
                            responsiveLayout="scroll"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            rowsPerPageOptions={[5, 10, 20]}
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            onPage={onPageChange}
                           className='e-table-height'
                        >
                            <Column field="id" body={actionBodyTemplateSrNo} header="Sr No." />
                            <Column
                                field="ClassName"
                                header="Class Name"
                            />
                            <Column
                                body={attendanceDateBodyTemplate}
                                header="Submitted On"
                            />
                            <Column
                                field='TakenBy'
                                header="Submitted By"
                            />
                            <Column
                                field='Attendance'
                                header="Attendance"
                            />
                        </DataTable>
                    </div>
                </TabPanel>
                <TabPanel header="Student Wise Attendance">
                    <div>
                        <DataTable
                            value={studentWiseAttendanceList}
                            paginator
                            rows={rows}
                            first={first}
                            filterDisplay="menu"
                            responsiveLayout="scroll"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            rowsPerPageOptions={[5, 10, 20]}
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            onPage={onPageChange}
                             className='e-table-height'
                        >
                            <Column field="RollNo" header="Roll No." />
                            <Column
                                field="ClassName"
                                header="Class Name"
                            />
                            <Column
                                field="StudentName"
                                header="Student Name"
                            />
                            <Column
                                body={attendanceDateBodyTemplate}
                                header="Submitted On"
                            />
                            <Column
                                field='TakenBy'
                                header="Submitted By"
                            />
                            <Column
                                body={attendanceBodyTemplate}
                                header="Attendance"
                            />
                        </DataTable>
                    </div>
                </TabPanel>
            </TabView>
        </div>
    );
};

export default Attendance;
