import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { FloatLabel } from 'primereact/floatlabel';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primeicons/primeicons.css';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import Toast from '../../Shared/Toast/Toast';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';

const AddUpdateExamSchedule = (props) => {
    const [securityUserID, setSecurityUserID] = useState("");
    const [titleName, setTitleName] = useState('');
    const [titleNameErr, setTitleNameErr] = useState(false);
    const [titleNameErrText, setTitleNameErrText] = useState('');
    const [examType, setExamType] = useState('');
    const [examTypeErr, setExamTypeErr] = useState(false);
    const [examTypeErrText, setExamTypeErrText] = useState('');
    const [classList, setClassList] = useState([]);
    const [subjectList, setSubjectList] = useState([]);
    const [examTypeList, setExamTypeList] = useState([]);
    const [className, setClassName] = useState('');
    const [classNameErr, setClassNameErr] = useState(false);
    const [classNameErrText, setClassNameErrText] = useState('');
    const [applyStartTimeToAll, setApplyStartTimeToAll] = useState(false);
    const [applyEndTimeToAll, setApplyEndTimeToAll] = useState(false);
    const [originalTimes, setOriginalTimes] = useState({});
    const [examStartDate, setExamStartDate] = useState(null);
    const [examEndDate, setExamEndDate] = useState(null);
    const [isClassTeacher, setIsClassTeacher] = useState(true);
    const [isSubjectTeacher, setIsSubjectTeacher] = useState(true);
    const [termList, setTermList] = useState([]);
    const [termName, setTermName] = useState('');

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        GetClassList(loginData?._id);
        GetTermsList(loginData?._id);
        if (props?.configId) {
            setTitleName(props?.masterData?.TitleName);
            setExamType(props?.masterData?.ExamType);
            setClassName(props?.masterData?.ClassId);
            setTermName(props?.masterData?.TermId);
            setExamStartDate(props?.masterData?.StartDate ? new Date(props?.masterData?.StartDate) : '');
            setExamEndDate(props?.masterData?.EndDate ? new Date(props?.masterData?.EndDate) : '');
            if (props?.masterData?.ClassId) {
                GetExamTypeList(props?.masterData?.ClassId);
                GetSubjectList(loginData?._id, props?.masterData?.ClassId);
            }
        }
        GetConfigurationData(loginData?._id);
    }, []);

    useEffect(() => {
        const originalData = {};
        subjectList.forEach((row) => {
            originalData[row._id] = { StartTime: row.StartTime, EndTime: row.EndTime };
        });
        setOriginalTimes(originalData);
    }, [applyStartTimeToAll, applyEndTimeToAll]);

    const applyToAll = (field, value) => {
        setSubjectList(subjectList.map((item, index) =>
            index === 0 ? item : { ...item, [field]: value }
        ));
    };

    const revertToOriginal = () => {
        setSubjectList(subjectList.map((item) => ({
            ...item,
            StartTime: originalTimes[item._id]?.StartTime || item.StartTime,
            EndTime: originalTimes[item._id]?.EndTime || item.EndTime,
        })));
    };

    const handleCheckboxChange = (field, value, setApplyState) => {
        setApplyState(value);
        if (value) {
            applyToAll(field, subjectList[0]?.[field]);
        } else {
            revertToOriginal();
        }
    };

    const GetClassList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.getClassList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setClassList(
                        Array.from(
                            new Map(
                                res?.data?.filter(x => x?.Status === 'Active')?.map((x) => [
                                    `${x?.ClassName}-${x?.MediumName}-${x?.Board}`,
                                    {
                                        ...x,
                                        value: x?.ClassId,
                                        label: `${x?.ClassName}-${x?.MediumName}-${x?.Board}`
                                    }
                                ])
                            ).values()
                        )
                    );
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const GetTermsList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.getTermList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setTermList(res?.data?.map(x => ({
                        label: x?.TermName,
                        value: x?._id,
                    })));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const GetSubjectList = async (id, classId) => {
        try {
            let data = {
                ClassId: classId,
                UserID: id
            }
            await api.post(APIConstant.path.getSubjectByClassId, data).then(async response => {
                let res = response;
                if (res.success) {
                    let existingSubjects = props?.masterData?.SubjectList || [];
                    let formattedExistingSubjects = existingSubjects?.map(x => ({
                        ...x,
                        StartDate: x?.StartDate ? new Date(x?.StartDate) : '',
                        StartTime: x?.StartTime ? new Date(x?.StartTime) : '',
                        EndTime: x?.EndTime ? new Date(x?.EndTime) : '',
                        isEditing: false
                    }));
                    let newSubjects = res?.data?.filter(subject =>
                        !existingSubjects.some(existing => existing?._id === subject?._id)
                    );
                    console.log('formated: ', formattedExistingSubjects);
                    console.log('shfsff: ', newSubjects);
                    
                    setSubjectList([...formattedExistingSubjects, ...newSubjects]);
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const GetExamTypeList = async (id) => {
        try {
            let data = {
                ClassId: id,
            }
            await api.post(APIConstant.path.GetResultTemplateListByClassId, data).then(async response => {
                let res = response;
                if (res.success) {
                    setExamTypeList(res.data.map((x) => ({
                        ...x,
                        value: x?._id,
                        label: `${x?.Name} (${x?.ShortName} - ${x?.Marks})`
                    })));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const GetConfigurationData = async (id) => {
        try {
            let data = {
                id: id,
            }
            await api.post(APIConstant.path.GetGeneralConfigurationDetail, data).then(async response => {
                let res = response;
                if (res.success) {
                    setIsClassTeacher(props?.masterData?.IsClassTeacher ? props?.masterData?.IsClassTeacher : res?.data?.IsClassTeacher);
                    setIsSubjectTeacher(props?.masterData?.IsSubjectTeacher ? props?.masterData?.IsSubjectTeacher : res?.data?.IsSubjectTeacher);
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const validate = () => {
        let formIsValid = true;
        if (CommonConfig.isEmpty(titleName)) {
            formIsValid = false;
            setTitleNameErr(true);
            setTitleNameErrText("Title Name is required");
        } else {
            setTitleNameErr(false);
            setTitleNameErrText("");
        }
        if (CommonConfig.isEmpty(className)) {
            formIsValid = false;
            setClassNameErr(true);
            setClassNameErrText("Class name is required");
        } else {
            setClassNameErr(false);
            setClassNameErrText("");
        }
        if (CommonConfig.isEmpty(examType)) {
            formIsValid = false;
            setExamTypeErr(true);
            setExamTypeErrText("Exam type is required");
        } else {
            setExamTypeErr(false);
            setExamTypeErrText("");
        }
        return formIsValid;
    };

    const onCancel = () => {
        props.onCancel();
        setTitleName('');
        setExamType('');
        setClassName('');
        setSubjectList([]);
        setExamType('');
        setExamTypeList([]); //
    }

    const AddUpdateTemplate = async (e) => {
        if (validate(e)) {
            try {
                Loader.show();
                var subData = subjectList.map(x => ({
                    SubjectId: x?._id,
                    StartDate: x?.StartDate,
                    StartTime: x?.StartTime,
                    EndTime: x?.EndTime,
                    Marks: x?.Marks
                }));
                let data = {
                    id: props?.configId,
                    TitleName: titleName,
                    ExamType: examType,
                    ClassId: className,
                    StartDate: examStartDate,
                    EndDate: examEndDate,
                    IsClassTeacher: isClassTeacher,
                    IsSubjectTeacher: isSubjectTeacher,
                    Subjects: subData,
                    TermId: termName,
                    CreatedBy: securityUserID,
                };
                await api
                    .post(APIConstant.path.AddUpdateExamSchedule, data)
                    .then((response) => {
                        let res = response;
                        if (res.success) {
                            Toast.success({ message: res.message });
                            Loader.hide();
                            onCancel();
                        } else {
                            Toast.error({ message: res.message });
                            Loader.hide();
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        Loader.hide();
                        onCancel();
                    });
            } catch (err) {
                console.log(err);
                Toast.error({ message: err });
                Loader.hide();
                onCancel();
            }
        }
    };

    const actionBodyTemplateSrNo = (rowData, { rowIndex }) => {
        return rowIndex + 1;
    };

    const actionsBodyTemplate = (rowData) => {
        return rowData.isEditing ? (
            <Button icon="pi pi-check" className="e-border-btn" onClick={() => toggleEdit(rowData)} />
        ) : (
            <Button icon="pi pi-pencil" className="e-btn" onClick={() => toggleEdit(rowData)} />
        );
    };

    const toggleEdit = (rowData) => {
        const updatedSubjects = subjectList.map(item =>
            item?._id === rowData?._id ? { ...item, isEditing: !item.isEditing } : item
        );
        setSubjectList(updatedSubjects);
    };

    const onChangeClass = async (classId) => {
        setClassName(classId);
        GetSubjectList(securityUserID, classId);
        GetExamTypeList(classId);
    };

    const onDateChange = (e, rowData, field) => {
        const updatedSubjects = subjectList.map(item =>
            item?._id === rowData?._id ? { ...item, [field]: e.value } : item
        );
        setSubjectList(updatedSubjects);
    };

    const onMarksChange = (e, rowData, field) => {
        const updatedSubjects = subjectList.map(item =>
            item?._id === rowData?._id ? { ...item, Marks: e.target.value } : item
        );
        setSubjectList(updatedSubjects);
    };

    const formatDate = (date) => {
        if (!date) return "";
        return new Date(date).toLocaleDateString('en-GB');
    };

    const formatTime = (date) => {
        if (!date) return "";
        return new Date(date).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
    };

    const startDateTemplate = (rowData) => {
        return rowData.isEditing ? (
            <Calendar
                value={rowData?.StartDate}
                onChange={(e) => onDateChange(e, rowData, "StartDate")}
                dateFormat="dd/mm/yy"
            // showIcon
            />
        ) : (
            <span>{formatDate(rowData?.StartDate)}</span>
        );
    };

    const startTimeTemplate = (rowData, index) => {
        return (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                {rowData.isEditing ? (
                    <Calendar
                        value={rowData?.StartTime}
                        onChange={(e) => onDateChange(e, rowData, "StartTime")}
                        showTime
                        hourFormat="12"
                        timeOnly
                    // showIcon
                    />
                ) : (
                    <span>{formatTime(rowData?.StartTime)}</span>
                )}
                {index === 0 && (
                    <input
                        type="checkbox"
                        onChange={(e) => {
                            if (e.target.checked) {
                                applyToAll("StartTime", rowData.StartTime);
                            }
                        }}
                    />
                )}
            </div>
        );
    };

    const endTimeTemplate = (rowData, index) => {
        return (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                {rowData.isEditing ? (
                    <Calendar
                        value={rowData?.EndTime}
                        onChange={(e) => onDateChange(e, rowData, "EndTime")}
                        showTime
                        hourFormat="12"
                        timeOnly
                    // showIcon
                    />
                ) : (
                    <span>{formatTime(rowData?.EndTime)}</span>
                )}
                {index === 0 && (
                    <input
                        type="checkbox"
                        onChange={(e) => {
                            if (e.target.checked) {
                                applyToAll("EndTime", rowData.EndTime);
                            }
                        }}
                    />
                )}
            </div>
        );
    };

    const dayTemplate = (rowData) => {
        if (!rowData?.StartDate) return "";

        const day = new Date(rowData?.StartDate).toLocaleDateString('en-US', { weekday: 'long' });
        return <span className="day-pill">{day}</span>;
    };

    const marksTemplate = (rowData) => {
        return rowData.isEditing ? (
            <InputText
                value={rowData.Marks}
                onChange={(e) => onMarksChange(e, rowData, "Marks")}
                maxLength={3}
            />
        ) : (
            <span>{rowData?.Marks}</span>
        );
    };


    return (
        <div className="p-d-flex p-flex-column p-jc-between">
            <div className='p-grid'>
                <div className="p-col-12 p-md-4 p-pt-2">
                    <label htmlFor="titleName">Exam Name</label>
                    <FloatLabel>
                        <InputText
                            className='p-w-100'
                            id="titleName"
                            value={titleName}
                            maxLength={20}
                            onChange={(e) => setTitleName(e.target.value)}
                        />
                    </FloatLabel>
                    <span className="error-msg" style={{ color: 'red' }}>
                        {titleNameErr ? titleNameErrText : null}
                    </span>
                </div>
                <div className="p-col-12 p-md-4 p-pt-2">
                    <div className="">
                        <label htmlFor="className">Class</label>
                        <Dropdown
                            id="className"
                            value={className}
                            options={classList}
                            onChange={(e) => onChangeClass(e.value)}
                            placeholder="Select Class"
                            display="chip"
                            className='p-w-100'
                            filter
                        />
                        <span className="error-msg" style={{ color: 'red' }}>
                            {classNameErr ? classNameErrText : null}
                        </span>
                    </div>
                </div>
                <div className="p-col-12 p-md-4 p-pt-2">
                    <div className="">
                        <label htmlFor="examType">Exam Type</label>
                        <Dropdown
                            id="examType"
                            value={examType}
                            options={examTypeList}
                            onChange={(e) => setExamType(e.value)}
                            placeholder="Select Exam Type"
                            display="chip"
                            className='p-w-100'
                            filter
                        />
                        <span className="error-msg" style={{ color: 'red' }}>
                            {examTypeErr ? examTypeErrText : null}
                        </span>
                    </div>
                </div>
                <div className="p-col-12 p-md-4 p-pt-2">
                    <div className="">
                        <Checkbox
                            inputId="classTeacher"
                            name="isClassTeacher"
                            value="IsClassTeacher"
                            onChange={(e) => {
                                setIsClassTeacher(e.checked);
                            }}
                            checked={isClassTeacher}
                        />
                        <label htmlFor="classTeacher" className="ml-2"> Is Class Teacher</label>
                    </div>
                </div>
                <div className="p-col-12 p-md-4 p-pt-2">
                    <div className="">
                        <Checkbox
                            inputId="subjectTeacher"
                            name="isSubjectTeacher"
                            value="IsSubjectTeacher"
                            onChange={(e) => {
                                setIsSubjectTeacher(e.checked);
                            }}
                            checked={isSubjectTeacher}
                        />
                        <label htmlFor="subjectTeacher" className="ml-2"> Is Subject Teacher</label>
                    </div>
                </div>
                <div className="p-col-12 p-md-4 p-pt-2"></div>
                <div className="p-col-12 p-md-3 p-pt-2">
                    <div className="custom-inputs">
                        <label htmlFor="startDate">Start Date</label>
                        <Calendar
                            className='p-w-100 p-mt-0'
                            id='startDate'
                            value={examStartDate}
                            onChange={(e) => setExamStartDate(e.value)}
                            dateFormat="dd/mm/yy"
                        // showIcon
                        />
                    </div>
                </div>
                <div className="p-col-12 p-md-3 p-pt-2">
                    <div className="custom-inputs">
                        <label htmlFor="endDate">End Date</label>
                        <Calendar
                            className='p-w-100 p-mt-0'
                            id='endDate'
                            value={examEndDate}
                            onChange={(e) => setExamEndDate(e.value)}
                            dateFormat="dd/mm/yy"
                        // showIcon
                        />
                    </div>
                </div>
                <div className="p-col-12 p-md-3 p-pt-2">
                    <div className="">
                        <label htmlFor="term">Terms</label>
                        <div className="custom-inputs">
                            <Dropdown
                                id="term"
                                value={termName}
                                options={termList}
                                onChange={(e) => setTermName(e.value)}
                                placeholder="Select Terms"
                                display="chip"
                                className='p-w-100'
                                filter
                            />
                        </div>
                    </div>
                </div>
                {/* <div className="p-col-12 p-md-3 p-pt-2">
                    <div className="">
                        <label htmlFor="term">Terms</label>
                        <div className="custom-inputs">
                            <Dropdown
                                id="term"
                                value={termName}
                                options={termList}
                                onChange={(e) => setTermName(e.value)}
                                placeholder="Select Terms"
                                display="chip"
                                className='p-w-100'
                                filter
                            />
                        </div>
                    </div>
                </div> */}
                <div className="p-col-12 p-md-12">
                    <DataTable
                        value={subjectList}
                        responsiveLayout="scroll"
                    >
                        <Column field="SrNo" body={actionBodyTemplateSrNo} header="Sr No." />
                        <Column field="SubjectName" header="Subject" />
                        <Column field="SubjectId" header="Subject Code" />
                        <Column field="StartDate" header="Date" body={startDateTemplate} />
                        <Column field="Day" header="Day" body={dayTemplate} />
                        <Column
                            header={
                                <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                    From Time
                                    <Checkbox
                                        onChange={(e) => handleCheckboxChange("StartTime", e.checked, setApplyStartTimeToAll)}
                                        checked={applyStartTimeToAll}
                                    />
                                </div>
                            }
                            body={startTimeTemplate}
                        />
                        <Column
                            header={
                                <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                    To Time
                                    <Checkbox
                                        onChange={(e) => handleCheckboxChange("EndTime", e.checked, setApplyEndTimeToAll)}
                                        checked={applyEndTimeToAll}
                                    />
                                </div>
                            }
                            body={endTimeTemplate}
                        />
                        <Column
                            header="Marks"
                            body={marksTemplate}
                        />
                        <Column header="Actions" body={actionsBodyTemplate} />
                    </DataTable>
                </div>

            </div>
            <div className="dialog-footer1 p-d-flex p-ai-center p-jc-end">
                <Button className='p-m-0 primary-btn1 p-m-1' label="Cancel" onClick={(e) => onCancel()} />
                <Button className='p-m-0 primary-btn' label="Save" onClick={(e) => AddUpdateTemplate()} />
            </div>
        </div >
    );
};

export default AddUpdateExamSchedule;
