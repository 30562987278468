import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { FloatLabel } from 'primereact/floatlabel';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primeicons/primeicons.css';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import { Dialog } from 'primereact/dialog';
import Toast from '../../Shared/Toast/Toast';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import TemplateValues from './TemplateValues';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';

const AddEditExamTemplate = (props) => {
    const [securityUserID, setSecurityUserID] = useState("");
    const [titleName, setTitleName] = useState('');
    const [visible, setVisible] = useState(false);
    const [titleNameErr, setTitleNameErr] = useState(false);
    const [titleNameErrText, setTitleNameErrText] = useState('');
    const [editingIndex, setEditingIndex] = useState(null);
    const [examTemplateValues, setExamTemplateValues] = useState([]);
    const [classList, setClassList] = useState([]);
    const [className, setClassName] = useState([]);
    const [classNameErr, setClassNameErr] = useState([]);
    const [classNameErrText, setClassNameErrText] = useState('');

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        GetClassList(loginData?._id);
        if (props?.configId) {
            setTitleName(props?.masterData?.TemplateName);
            setExamTemplateValues(props?.masterData?.TemplateValues);
            setClassName(props?.masterData?.ClassId ? props?.masterData?.ClassId.split(',') : []);
        }
    }, []);

    const GetClassList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.getClassList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setClassList(
                        Array.from(
                            new Map(
                                res?.data?.filter(x => x?.Status === 'Active')?.map((x) => [
                                    `${x?.ClassName}-${x?.MediumName}-${x?.Board}`,
                                    { 
                                        ...x, 
                                        value: x?.ClassId, 
                                        label: `${x?.ClassName}-${x?.MediumName}-${x?.Board}`
                                    }
                                ])
                            ).values()
                        )
                    );
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const validate = () => {
        let formIsValid = true;
        if (CommonConfig.isEmpty(titleName)) {
            formIsValid = false;
            setTitleNameErr(true);
            setTitleNameErrText("Title Name is required");
        } else {
            setTitleNameErr(false);
            setTitleNameErrText("");
        }
        if (!className.length) {
            formIsValid = false;
            setClassNameErr(true);
            setClassNameErrText("Select atleast one class");
        } else {
            setClassNameErr(false);
            setClassNameErrText("");
        }
        return formIsValid;
    };

    const onCancel = () => {
        props.onCancel();
        setTitleName('');
    }

    const onValueCancel = () => {
        setVisible(false);
    }

    const AddUpdateTemplate = async (e) => {
        if (validate(e)) {
            try {
                Loader.show();
                let data = {
                    id: props?.configId,
                    TemplateName: titleName,
                    ClassId: className ? className.join(',') : '',
                    TemplateValues: examTemplateValues,
                    CreatedBy: securityUserID,
                };
                await api
                    .post(APIConstant.path.AddUpdateResultTemplate, data)
                    .then((response) => {
                        let res = response;
                        if (res.success) {
                            Toast.success({ message: res.message });
                            Loader.hide();
                            onCancel();
                        } else {
                            Toast.error({ message: res.message });
                            Loader.hide();
                            // onCancel();
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        Loader.hide();
                        onCancel();
                    });
            } catch (err) {
                console.log(err);
                Toast.error({ message: err });
                Loader.hide();
                onCancel();
            }
        }
    };

    const actionBodyTemplateSrNo = (rowData, { rowIndex }) => {
        return rowData?.SrNo;
    };

    const valueBodyTemplate = (rowData, { rowIndex }) => {
        return rowData?.ExamType === 'Best Of' ? rowData?.BestOfValue : rowData?.TotalValueName;
    };

    const actionsBodyTemplate = (rowData, { rowIndex }) => (
        <div className="p-d-flex p-jc-start">
            <Button
                icon="pi pi-pencil"
                className="e-sction-btn p-m-0 p-m-2"
                onClick={() => openDialogForEdit(rowIndex)}
            />
            <Button icon="pi pi-trash" className="e-sction-btn p-m-0 p-m-2"
                onClick={() => removeTemplateValue(rowIndex)}
            />
        </div>
    );

    const openDialogForEdit = (index) => {
        setEditingIndex(index);
        setVisible(true);
    };

    const removeTemplateValue = (index) => {
        setExamTemplateValues(prevValues => prevValues.filter((_, i) => i !== index));
    };

    const handleSaveTemplateValue = (value) => {
        setExamTemplateValues(prevValues => {
            let updatedValues;
            if (editingIndex !== null) {
                updatedValues = [...prevValues];
                updatedValues[editingIndex] = { ...value, SrNo: prevValues[editingIndex].SrNo };
            } else {
                updatedValues = [
                    ...prevValues,
                    { ...value, SrNo: prevValues.length + 1 }
                ];
            }
            return updatedValues;
        });

        setEditingIndex(null);
        setVisible(false);
    };

    const handleRowReorder = (event) => {
        const updatedValues = event.value.map((item, index) => ({
            ...item,
            SrNo: index + 1
        }));
        setExamTemplateValues(updatedValues);
    };

    return (
        <div className="e-exam-master-table">
            <div className='p-grid'>
                <div className="p-col-12 p-md-4 p-pt-2">
                    <label htmlFor="titleName">Template Name<span style={{ color: "red" }}>*</span></label>
                    <FloatLabel>
                        <InputText
                            className='p-w-100 input-height'
                            id="titleName"
                            value={titleName}
                            onChange={(e) => setTitleName(e.target.value)}
                        />
                    </FloatLabel>
                    <span className="error-msg" style={{ color: 'red' }}>
                        {titleNameErr ? titleNameErrText : null}
                    </span>
                </div>
                <div className="p-col-12 p-md-4 p-pt-2">
                    <div className="">
                        <label htmlFor="className">Class<span style={{ color: "red" }}>*</span></label>
                        <MultiSelect
                            id="className"
                            value={className}
                            options={classList}
                            onChange={(e) => setClassName(e.value)}
                            placeholder="Select Class"
                            display="chip"
                            className='p-w-100 input-height'
                            filter
                        />
                        <span className="error-msg" style={{ color: 'red' }}>
                        {classNameErr ? classNameErrText : null}
                    </span>
                    </div>
                </div>
                <div className="p-col-12 p-md-1 p-mt-2">
                    <Button icon='pi pi-plus' className="e-h-btn" onClick={() => setVisible(true)} />
                </div>
                <div className='p-w-100 e-grade-master-table'>
                    <DataTable value={examTemplateValues} responsiveLayout="scroll" reorderableRows
                        onRowReorder={handleRowReorder}>
                        <Column rowReorder header="#" />
                        <Column field="SrNo" body={actionBodyTemplateSrNo} header="Sr No." />
                        <Column header="Exam Type" field="ExamType" />
                        <Column header="Name" field="Name" />
                        <Column header="Short Name" field="ShortName" />
                        <Column header="Marks" field="Marks" />
                        <Column header="IsOverAllAllow" field="IsOverAllAllow"/>
                        <Column header="Term" field="TermName" />
                        <Column header="Value" body={valueBodyTemplate} />
                        <Column header="Weightage" field='Weightage' />
                        <Column header="Actions" body={actionsBodyTemplate} />
                    </DataTable>
                </div>
            </div>
            <div className="dialog-footer1 p-d-flex p-ai-center p-jc-end">
                <Button className='p-m-0 primary-btn1 p-m-1' label="Cancel" onClick={(e) => onCancel()} />
                <Button className='p-m-0 primary-btn' label="Save" onClick={(e) => AddUpdateTemplate()} />
            </div>
            <Dialog
                header={"Template Value"}
                visible={visible}
                onHide={() => onValueCancel()}
                draggable={false}
                position="center"
                style={{ width: '48vw', height: '82vh'}}
            >
                <TemplateValues onCancel={onValueCancel} onSave={handleSaveTemplateValue}
                    editData={editingIndex !== null ? examTemplateValues[editingIndex] : null} bestOfList={examTemplateValues.length ? examTemplateValues.filter(x => x?.ExamType === 'Individual') : []} totalOfList={examTemplateValues} />
            </Dialog>
        </div >
    );
};

export default AddEditExamTemplate;
