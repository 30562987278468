import React from 'react';
import { Button } from 'primereact/button';
import '../../../assets/css/teacherMaster.css';
import userImage from '../../../assets/icons/OBJECTS.svg';
import { useNavigate } from 'react-router-dom';
import { apiBase } from '../../../utils/config';
import { InputSwitch } from 'primereact/inputswitch';

const ParentCard = ({ parent, handleDelete, toggleActiveStatus}) => {
    const navigate = useNavigate();
    return (
        <div className="teacher-card p-mt-4">
            <img src={parent?.ProfilePhotoUrl ? `${apiBase}/UserProfilePic/${parent?.ProfilePhotoUrl}` : userImage} alt='' className="teacher-image" />
            <h4 className='p-m-0'>{parent?.Name}</h4>
            <p className='p-m-0'>{parent?.Status}</p>
            <div className="p-d-flex p-ai-center p-jc-center">
                <Button icon="icon-view tailer-icon" className='e-btn p-m-0' onClick={() => navigate('/viewEdit-parent-master', { state: { userId: parent?._id, isEdit: false } })} />
                <Button icon="pi pi-pencil" className='e-sction-btn p-ml-2 p-mr-0' onClick={() => navigate('/viewEdit-parent-master', { state: { userId: parent?._id, isEdit: true } })} />
                <Button icon="pi pi-trash" className="e-sction-btn p-mr-1 p-ml-2" onClick={(e) => handleDelete(parent?._id)} />
                <InputSwitch
                    checked={parent?.Status === 'Active' ? true : false}
                    onChange={(e) => {
                        toggleActiveStatus(parent._id, e.value);
                    }}
                    className="p-ml-1"
                />
            </div>
        </div>
    );
}

export default ParentCard;
