import React from 'react';
import { apiBase } from '../../utils/config';
import UserImage from '../../assets/icons/OBJECTS.svg';
import moment from 'moment';

const ResultCardPDF = React.forwardRef((props, ref) => {
    const { templateConfiguration, pdfData } = props;
    const pdfBlocks = [];
    pdfData?.map((x, idx) => {
        pdfBlocks.push(
            <div className='reault-main' ref={ref} key={idx} id={`result-${idx}`}>
                <div className='student-result'>
            <header>
                {/* <div className='school-logo'></div> */}
                {/* <div className='p-d-flex flex-column p-ai-center'> */}
                <div style={{ width: '100%'}}>
                    <img
                        src={templateConfiguration?.SchoolLogo ? `${apiBase}/Documents/${templateConfiguration?.SchoolLogo}` :  UserImage}
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = UserImage;
                        }}
                    />
                </div>
                {/* <div className='school-logo'></div> */}
            </header>
            <section class="report-info">
                <div className='report-box'>
                    <p className='report-text'>report card</p>
                </div>
                <div className='class-box'>
                    <p className='class-text'>class : <span>{templateConfiguration?.BodySection?.filter(x => x?.key === 'Class').length && x?.Class} - {templateConfiguration?.BodySection?.filter(x => x?.key === 'Section').length && x?.Section}</span></p>
                </div>
                {templateConfiguration?.BodySection?.filter(x => x?.key === 'AcademicYear').length && <div className='class-box'>
                    <p className='ay-text'>academic year : <span>{x?.AcademicYear ?? '-'}</span></p>
                </div>}
                <div className='student-details'>
                    <div className='student-texts'>
                        <div className='text-box-par'>
                            {templateConfiguration?.BodySection?.filter(x => x?.key === 'FullName').length &&
                                <p>student's name :</p>}
                            {templateConfiguration?.BodySection?.filter(x => x?.key === 'FullName').length &&
                                <p>father's name :</p>}
                            {templateConfiguration?.BodySection?.filter(x => x?.key === 'FullName').length && <p>date of birth :</p>}
                            {templateConfiguration?.BodySection?.filter(x => x?.key === 'FullName').length && <p>address :</p>}
                        </div>
                        <div className='text-box-cha'>
                            {templateConfiguration?.BodySection?.filter(x => x?.key === 'FullName').length && <p> {x?.Name ?? '-'}</p>}
                            {templateConfiguration?.BodySection?.filter(x => x?.key === 'FName').length && <p>{x?.FName ?? '-'}</p>}
                            {templateConfiguration?.BodySection?.filter(x => x?.key === 'DOB').length && <p>{moment(x?.DOB).format("DD-MM-YYYY")}</p>}
                            {templateConfiguration?.BodySection?.filter(x => x?.key === 'Address').length && <p>{x?.Address ?? '-'}</p>}
                        </div>
                    </div>
                    <div className='student-numbers'>
                        <div className='text-par'>
                            {templateConfiguration?.BodySection?.filter(x => x?.key === 'RollNo').length && <p className=''>roll no</p>}
                            {templateConfiguration?.BodySection?.filter(x => x?.key === 'GRNo').length && <p className=''>g. r. no.</p>}
                        </div>
                        <div className='text-cha'>
                            {templateConfiguration?.BodySection?.filter(x => x?.key === 'RollNo').length && <p>{x?.RollNo ?? '-'}</p>}
                            {templateConfiguration?.BodySection?.filter(x => x?.key === 'GRNo').length && <p>{x?.GRNo ?? '-'}</p>}
                        </div>
                    </div>
                </div>
            </section>
            <section class="result-table footer-table">
                <table>
                    <thead>
                        <tr>
                            <th className='yello'>Scholastic Area</th>
                            {x?.TermData.map((term, index) => (
                                <>
                                    <th key={index} className={index % 2 === 0 ? 'light-peach' : 'light-bluish'} colSpan="5">
                                        {`${term.TermName} (${term?.Marks} Marks)`}
                                    </th>
                                </>
                            ))}
                            <th colspan="2" className='p-p-0 light-yello'>Overall
                                <tr>
                                    <th className='t1-t2 light-yello'>T1 + T2</th>
                                </tr>
                            </th>
                        </tr>
                        <tr>
                            <th className='yello'>Subject</th>
                            {x?.TermData.map((term, index) => (
                                Object.keys(x?.MarksData?.[0]?.[term.TermName] || {}).map(testType => (
                                    <th className={index % 4 === 0 ? 'light-peach' : 'light-bluish'}>{testType}</th>
                                ))
                            ))}
                            <th className='light-yello'>
                                Grand Total ({x?.MarksData?.[0]?.GrandTotalType || 0})
                            </th>
                            <th className='light-yello'>Grade</th>
                        </tr>
                    </thead>
                    <tbody>
                        {x?.MarksData.map(subject => (
                            <tr key={subject?.Subject}>
                                <td>{subject?.Subject}</td>
                                {x?.TermData.map(term => (
                                    Object.keys(subject?.[term.TermName] || {}).map(testType => (
                                        <td className='purple'>{subject?.[term.TermName]?.[testType] == 0 ? '-' : subject?.[term.TermName]?.[testType] ?? '-'}</td>
                                    ))
                                ))}
                                <td>{subject?.GrandTotal == 0 ? '-' : subject?.GrandTotal ?? '-'}</td>
                                <td>{subject?.Grade ?? '-'}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div className="footer-row">
                    <div className="column-one">
                        <p className="title">{x?.GradeData?.GradeTemplateName ?? '-'}</p>
                    </div>
                    <div className="grades-container">
                        {x?.GradeData?.GradeTemplateValues?.map((grade) => (
                            <div
                                key={grade._id}
                                className="grade-box"
                                style={{ backgroundColor: `#${grade.HexCode}` }}
                            >
                                <p>
                                    {grade?.GradeName} ({grade?.PercentageFrom} - {grade?.PercentageTo})
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <div className='co-main-box'>
                <div className='co-left'>
                    <div className='grad-points'>
                        <p>co scholastic ares</p>
                        <p>(3 point grading scale a,b,c)</p>
                    </div>
                    <div className='co-acti'>
                        <div className='text-par'>
                            <p className='p-text-center'>activities</p>
                            <p className='text'>love for prayer</p>
                            <p className='text'>cleanliness</p>
                            <p className='text'>participants in activities</p>
                        </div>
                        <div className='text-cha'>
                            <p className=''>t 1</p>
                            <p className='text'>b</p>
                            <p className='text'>b</p>
                            <p className='text'>a</p>
                        </div>
                        <div className='text-cha'>
                            <p className=''>t 2</p>
                            <p className='text'>a</p>
                            <p className='text'>a</p>
                            <p className='text'>b</p>
                        </div>
                    </div>
                </div>
                <div className='co-left'>
                    <div className='grad-points'>
                        <p>co scholastic ares</p>
                        <p>(3 point grading scale a,b,c)</p>
                    </div>
                    <div className='co-acti'>
                        <div className='text-par'>
                            <p className='p-text-center'>elements</p>
                            <p className='text'>school social services</p>
                            <p className='text'>polite, well mannered</p>
                            <p className='text'>discipline</p>
                        </div>
                        <div className='text-cha'>
                            <p className=''>t 1</p>
                            <p className='text'>a</p>
                            <p className='text'>a</p>
                            <p className='text'>a</p>
                        </div>
                        <div className='text-cha'>
                            <p className=''>t 2</p>
                            <p className='text'>a</p>
                            <p className='text'>a</p>
                            <p className='text'>a</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='overall-main'>
                <p className='overall-text'>overall</p>
                <div className='overall-cha'>
                    {templateConfiguration?.FooterSection?.filter(x => x?.key === 'Marks').length &&
                        <div className='overall-numbers border-left'>
                            <div className='text-par'>
                                <p className=''>marks</p>
                            </div>
                            <div className='text-cha'>
                                <p className=''>{x?.TotalMarks ?? 0}</p>
                            </div>
                        </div>}
                    {templateConfiguration?.FooterSection?.filter(x => x?.key === 'Percentage').length &&
                        <div className='overall-numbers'>
                            <div className='text-par'>
                                <p className=''>percentage</p>
                            </div>
                            <div className='text-cha'>
                                <p className=''>{x?.Percentage}</p>
                            </div>
                        </div>}
                    {templateConfiguration?.FooterSection?.filter(x => x?.key === 'Grade').length &&
                        <div className='overall-numbers'>
                            <div className='text-par'>
                                <p className=''>grade</p>
                            </div>
                            <div className='text-cha'>
                                <p className=''>{x?.Grade ?? '-'}</p>
                            </div>
                        </div>}
                    {templateConfiguration?.FooterSection?.filter(x => x?.key === 'Rank').length &&
                        <div className='overall-numbers border-right'>
                            <div className='text-par'>
                                <p className=''>rank</p>
                            </div>
                            <div className='text-cha'>
                                <p className=''>{x?.Rank ?? '-'}</p>
                            </div>
                        </div>}
                </div>
                <div className='overall-cha'>
                    {templateConfiguration?.FooterSection?.filter(x => x?.key === 'Attendance').length &&
                        <div className='overall-numbers border-left p-m-0'>
                            <div className='text-par'>
                                <p className=''>attendance</p>
                            </div>
                            <div className='text-cha'>
                                <p className=''>{x?.Attendance ?? '-'}</p>
                            </div>
                        </div>}
                    {/* {templateConfiguration?.FooterSection?.filter(x => x?.key === 'Remark').length && */}
                    <div className='overall-numbers border-right p-m-0'>
                        <div className='text-par'>
                            <p className=''>remarks</p>
                        </div>
                        <div className='text-cha'>
                            <p className=''>{x?.Remarks ?? '-'}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='sign-main'>
                <div className='sign-cha'>
                    <img
                        src={templateConfiguration?.SealImage ? `${apiBase}/Documents/${templateConfiguration?.SealImage}` : UserImage}
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = UserImage;
                        }}
                        style={{ width: '200px', height: '200px', objectFit: 'contain', marginRight: '10px' }}
                    />
                    <p>{moment().format("DD-MM-YYYY")}</p>
                </div>
                <hr />
                <div className='sign-cha'>
                    <img
                        src={x?.ClassTeacherProfileUrl ? `${apiBase}/UserProfilePic/${x?.ClassTeacherProfileUrl}` : UserImage}
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = UserImage;
                        }}
                        style={{ width: '200px', height: '200px', objectFit: 'contain', marginRight: '10px' }}
                    />
                    <p>class teacher</p>
                </div>
                <hr />
                <div className='sign-cha'>
                    <img
                        src={templateConfiguration?.PrincipalSignImage ? `${apiBase}/Documents/${templateConfiguration?.PrincipalSignImage}` : UserImage}
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = UserImage;
                        }}
                        style={{ width: '200px', height: '200px', objectFit: 'contain', marginRight: '10px' }}
                    />
                    <p>principal</p>
                </div>
            </div>        </div>
        <p className='footer-text'>
            {templateConfiguration?.FooterSection?.filter(x => x?.key === 'SCLAddress').length && x?.SchoolAddress}, {templateConfiguration?.FooterSection?.filter(x => x?.key === 'SCLPhone').length && x?.SchoolPhone},
            {templateConfiguration?.FooterSection?.filter(x => x?.key === 'SCLEmail').length && x?.SchoolEmail}
        </p>
            </div>
        )
    });
    return pdfBlocks;
});

export default ResultCardPDF;
