
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import React, { useState, useEffect } from 'react';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import Toast from '../../Shared/Toast/Toast';
import { FloatLabel } from 'primereact/floatlabel';
import { RadioButton } from 'primereact/radiobutton';

const AddEditCoScholasticMasterDialog = (props) => {
    const [coScholasticName, setCoScholasticName] = useState('');
    const [securityUserID, setSecurityUserID] = useState("");
    const [coScholasticNameErrText, setCoScholasticNameErrText] = useState('');
    const [coScholasticNameErr, setCoScholasticNameErr] = useState(false);
    const [coScholasticType, setCoScholasticType] = useState('Activity');

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        if (props.ID) {
            setCoScholasticName(props?.masterData?.CoScholasticName);
            setCoScholasticType(props?.masterData?.Type)
        }
    }, []);

    const onCancel = () => {
        props.onCancel();
        setCoScholasticName('');
        setCoScholasticType('');
    }

    const validate = () => {
        let formIsValid = true;
        if (CommonConfig.isEmpty(coScholasticName)) {
            formIsValid = false;
            setCoScholasticNameErr(true);
            setCoScholasticNameErrText("CoScholastic Name is required");
        } else {
            setCoScholasticNameErr(false);
            setCoScholasticNameErrText("");
        }
        return formIsValid;
    };

    const AddCoScholasticMaster = async (e) => {
        if (validate(e)) {
            try {
                Loader.show();
                let data = {
                    id: props.ID,
                    CoScholasticName: coScholasticName,
                    Type: coScholasticType,
                    CreatedBy: securityUserID,
                };
                await api
                    .post(APIConstant.path.AddUpdateCoScholasticMaster, data).then((response) => {
                        let res = response;
                        if (res.success) {
                            Toast.success({ message: res.message });
                            Loader.hide();
                            onCancel();
                        } else {
                            Toast.error({ message: res.message });
                            Loader.hide();
                            // onCancel();
                        }
                    })
                    .catch((err) => {
                        Toast.error({ message: err?.data?.message });
                        Loader.hide();
                        onCancel();
                    });
            } catch (err) {
                console.log(err);
                Loader.hide();
                onCancel();
            }
        }
    };

    const handleChange = (e, type) => {
        const value = e.target.value;
        const alphanumericValue = value.replace(/[^a-zA-Z0-9\s]/g, '');
        setCoScholasticName(alphanumericValue);
    };

    return (
        <div className="p-d-flex p-flex-column p-jc-between">
            <div className='p-grid'>
                <div className="p-col-5">
                    <div className="">
                        <RadioButton
                            inputId="Activity"
                            name="coScholasticType"
                            value="Activity"
                            checked={coScholasticType === 'Activity'}
                            onChange={(e) => setCoScholasticType(e.value)}
                        />
                        <label htmlFor="Activity"> Activity</label>
                    </div>
                </div>
                <div className="p-col-5">
                    <div className="">
                        <RadioButton
                            inputId="Elements"
                            name="coScholasticType"
                            value="Elements"
                            checked={coScholasticType === 'Elements'}
                            onChange={(e) => setCoScholasticType(e.value)}
                        />
                        <label htmlFor="Elements"> Elements</label>
                    </div>
                </div>
                <div className="p-col-12 p-md-12 p-pt-2">
                    <div className="">
                        <label className='' htmlFor="coScholasticName">Co-Scholastic Name<span style={{ color: "red" }}>*</span></label>
                        <FloatLabel>
                            <InputText
                                className="p-mt-2 p-w-100"
                                id="coScholasticName"
                                type="text"
                                maxLength={150}
                                placeholder="CoScholastic Name"
                                value={coScholasticName}
                                autoFocus={true}
                                onChange={(e) => handleChange(e)}
                            />
                        </FloatLabel>
                        <span className="error-msg" style={{ color: 'red' }}>
                            {coScholasticNameErr ? coScholasticNameErrText : null}
                        </span>
                    </div>
                </div>
            </div>
            <div className='dialog-footer1 p-d-flex p-ai-center p-jc-end'>
                <Button className='p-m-0 primary-btn1 p-m-1' onClick={(e) => onCancel()}> Cancel </Button>
                <Button className='p-m-0 primary-btn' label="Submit" severity="secondary" raised onClick={(e) => AddCoScholasticMaster()} />
            </div>
        </div>
    );
};

export default AddEditCoScholasticMasterDialog;
