import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { TabView, TabPanel } from "primereact/tabview";
import { FilterMatchMode } from 'primereact/api';
import { useNavigate } from "react-router-dom";
import CommonConfig from "../../../utils/constant";
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import moment from "moment";


const CoScholasticGrading = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [activeTab, setActiveTab] = useState(0);
    const [securityUserID, setSecurityUserID] = useState("");
    const navigate = useNavigate();
    const [rows, setRows] = useState(10);
    const [first, setFirst] = useState(0);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [coScholasticOnGoingGradesList, setCoScholasticOnGoingGradesList] = useState([]);
    const [coScholasticCompletedGradesList, setCoScholasticCompletedGradesList] = useState([]);
    const onPageChange = (e) => {
        setFirst(e.first);
        setRows(e.rows);
    };
    const filteredExams = (activeTab === 1 ? coScholasticCompletedGradesList : coScholasticOnGoingGradesList).filter(exam =>
        exam?.ClassName?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
        exam?.TermName?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
        exam?.CoScholasticName?.toLowerCase()?.includes(searchTerm?.toLowerCase())
    );

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        GetCoScholasticGradeList(loginData?._id, loginData?.CreatedBy);
    }, []);

    const dateBodyTemplate = (rows, type) => {
        return (
            <div>{moment(rows[type]).format("DD-MM-YYYY")}</div>
        );
    };

    const GetCoScholasticGradeList = async (id, schId) => {
        try {
            let data = {
                UserID: id,
                SchoolId: schId
            }
            Loader.show();
            await api.post(APIConstant.path.GetCoScholasticGradesList, data).then(async response => {
                let res = response;
                if (res.success) {
                    var onGoingList = res?.data;
                    var completeList = res?.data;
                    setCoScholasticOnGoingGradesList(onGoingList);
                    // setCoScholasticCompletedGradesList(completeList);
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) {
            Loader.hide();
            console.log(err);
        }
    }

    const actionTemplate = (rowData) => (
        <Button label="Configure" onClick={(e) => navigate('/co-scholastic-entry', { state: { className: rowData?.ClassName, classId: rowData?.ClassId, coScholasticId: rowData?.CoScholasticId } })} />
    );

    const srNoBodyTemplate = (data, props) => {
        return props?.rowIndex + 1;
    };

    return (
        <div className='wayment-owner-dashboard e-weighment-dashoard'>
            <div className="heading-area p-d-flex p-ai-center p-jc-between">
                <div className="p-d-flex p-ai-baseline">
                    <i className="pi pi-book"></i>
                    <h2>Co-Scholastic Grading</h2>
                </div>
                <div className="p-d-flex p-ai-center p-jc-between">
                    <div className="searchbar-area e-searchbar-area">
                        <i className="pi pi-search" />
                        <InputText
                            value={globalFilterValue}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            placeholder="Global Search"
                        />
                    </div>
                    <Button icon="pi pi-plus" className="e-h-btn" />
                </div>
            </div>
            <hr className='p-m-0' />
            <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
                <TabPanel header="Ongoing">
                    <DataTable value={filteredExams} paginator className='e-table-height'
                        rows={rows}
                        first={first}
                        responsiveLayout="scroll"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        rowsPerPageOptions={[5, 10, 20]}
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        onPage={onPageChange}>
                        <Column header="Sr No" body={srNoBodyTemplate} style={{ width: "6%" }} />
                        <Column field="ClassName" header="Class" />
                        <Column field="TermName" header="Term Name" />
                        <Column field="CoScholasticName" header="Co-Scholastic Name" />
                        <Column field="StartDate" header="StartDate" body={(rowData) => dateBodyTemplate(rowData,'StartDate')} />
                        <Column field="EndDate" header="End Name" body={(rowData) => dateBodyTemplate(rowData,'EndDate')} />
                        <Column header='Action' body={actionTemplate} />
                    </DataTable>
                </TabPanel>
                <TabPanel header="Completed">
                    <DataTable value={filteredExams} paginator className="e-table-height"
                        rows={rows}
                        first={first}
                        responsiveLayout="scroll"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        rowsPerPageOptions={[5, 10, 20]}
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        onPage={onPageChange}>
                        <Column header="Sr No" body={srNoBodyTemplate} style={{ width: "6%" }} />
                        <Column field="ClassName" header="Class" />
                        <Column field="TermName" header="Term Name" />
                        <Column field="CoScholasticName" header="Co-Scholastic Name" />
                        <Column header='Action' body={actionTemplate} />
                    </DataTable>
                </TabPanel>
            </TabView>
        </div>
    );
};

export default CoScholasticGrading;
