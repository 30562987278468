import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { FilterMatchMode } from 'primereact/api';
import { Dialog } from 'primereact/dialog';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import AddEditExamMasterDialog from './AddEditExamMaster';
import moment from 'moment';
import Toast from '../../Shared/Toast/Toast';
import DeleteConfirmation from '../../Dialogs/DeleteConfirmation';


const ExamTemplateConfiguration = () => {
    const [examTemplate, setExamTemplateList] = useState([]);
    const [masterData, setMasterData] = useState([]);
    const [visible, setVisible] = useState(false);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        TemplateName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [securityUserID, setSecurityUserID] = useState("");
    const [rows, setRows] = useState(10);
    const [first, setFirst] = useState(0);
    const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);
    const [deleteId, setDeleteId] = useState('');

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        GetExamTemplateList(loginData?._id);
    }, []);

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const onPageChange = (e) => {
        setFirst(e.first);
        setRows(e.rows);
    };

    const GetExamTemplateList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.GetResultTemplateList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setExamTemplateList(res.data);
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const actionBodyTemplate = (rows) => {
        return (
            <React.Fragment>
                <div className='p-d-flex p-ai-center p-jc-end'>
                    <Button icon="pi pi-pencil" className="e-sction-btn p-my-0 p-m-0" onClick={() => {
                        setMasterData(rows);
                        setVisible(true)
                    }} />
                    <Button icon="pi pi-trash" className="e-sction-btn p-my-0 p-mr-0 p-ml-2" onClick={(e) => {
                   setIsDeleteConfirmation(true);
                   setDeleteId(rows._id);
                }}/>
                </div>
            </React.Fragment>

        );
    };

    const lastUpdatedBodyTemplate = (rows) => {
        return (
            <div>{moment(rows?.UpdatedOn).format("DD-MM-YYYY HH:mm")}</div>
        );
    };

    const onCancel = () => {
        setVisible(false);
        setMasterData([]);
        GetExamTemplateList(securityUserID);
    }

    const actionBodyTemplateSrNo = (data, props) => {
        return props.rowIndex + 1;
    };
    
    const renderFooter = () => (
        <div className="dialog-footer1 p-d-flex p-ai-center p-jc-end">
            <Button
                label="Cancel"
                className="primary-btn1"
                onClick={handleClose}
            />
            <Button
                label="Delete"
                className="primary-btn"
                onClick={(e) => UpdateStatus()}
            />
        </div>
    );

    const handleClose = () => {
        setIsDeleteConfirmation(false)
    };

    const UpdateStatus = async () => {
        try {
            let data = {
                id: deleteId,
                Status: 'Inactive'
            }
            await api.post(APIConstant.path.UpdateResultTemplateStatus, data).then(async response => {
                let res = response;
                if (res.success) {
                    Loader.hide();
                    Toast.success({ message: 'Template deleted successfully' });
                    GetExamTemplateList(securityUserID);
                }
                handleClose();
            }).catch(err => {
                Loader.hide();
                handleClose();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    return (
        <div className='wayment-owner-dashboard e-weighment-dashoard'>
            <div className="heading-area p-d-flex p-ai-center p-jc-between">
                <div className="p-d-flex p-ai-baseline">
                    <i className="pi pi-file"></i>
                    <h2>Exam Template</h2>
                </div>
                <div className="p-d-flex p-ai-center p-jc-between">
                    <div className="searchbar-area e-searchbar-area">
                        <i className="pi pi-search" />
                        <InputText
                            
                            value={globalFilterValue}
                            onChange={onGlobalFilterChange}
                            placeholder="Global Search"
                        />
                    </div>
                    <Button icon="pi pi-plus" className="e-h-btn" onClick={() => setVisible(true)} />
                </div>
            </div>
            <hr className='p-m-0' />
            <DataTable
                value={examTemplate}
                paginator
                rows={rows}
                first={first}
                filters={filters}
                filterDisplay="menu"
                responsiveLayout="scroll"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                rowsPerPageOptions={[5, 10, 20]}
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                onPage={onPageChange}
                className='p-mt-3 id-card-table'
            >
                <Column field="id" body={actionBodyTemplateSrNo} header="Sr No." />
                <Column
                    field="TemplateName"
                    header="Title"
                />
                <Column
                    field="ClassName"
                    header="Class Name"
                />
                <Column
                    body={lastUpdatedBodyTemplate}
                    header="Last Updated On"
                />
                <Column body={actionBodyTemplate} header="Action" headerStyle={{ display: 'flex', justifyContent: 'end' }} />
            </DataTable>

            <Dialog
                header={masterData?._id ? 'Edit Exam Template' : 'Add Exam Template'}
                // className='dialog-popup'
                visible={visible}
                onHide={() => onCancel()}
                draggable={false}
                position="center"
                style={{ width: '90%', height: '90%' }}
            >
                <AddEditExamMasterDialog onCancel={onCancel} configId={masterData?._id} masterData={masterData} />

            </Dialog>
            <Dialog
                visible={isDeleteConfirmation}
                onHide={handleClose}
                header={
                    <div className="p-d-flex p-jc-between p-ai-center">
                        <span>Delete Confirmation</span>
                    </div>
                }
                footer={renderFooter()}
                draggable={false}
                dismissableMask
                style={{ width: '450px' }}
            >
                <DeleteConfirmation />
            </Dialog>
        </div>
    );
};

export default ExamTemplateConfiguration;
