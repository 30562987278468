import React, { useState, useEffect } from 'react';
import { FilterMatchMode } from 'primereact/api';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import ClassTimeTableCard from './ClassTimeTableCard';
import { TabView, TabPanel } from 'primereact/tabview';
import TeacherTimeTableCard from './TeacherTimeTableCard';


const TimeTableConfiguration = () => {
    const [securityUserID, setSecurityUserID] = useState("");
    const [classList, setClassList] = useState([]);
    const [teachersList, setTeachersList] = useState([]);

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        GetClassList(loginData?._id);
        GetTeachersList(loginData?._id);
    }, []);


    const GetClassList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            Loader.show();
            await api.post(APIConstant.path.getClassList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setClassList(res?.data?.filter(x => x?.Status === 'Active')?.map((x) => ({
                        ...x,
                        Class: `${x?.ClassName}-${x?.SectionFullName.charAt(0)}-${x?.MediumName}-${x?.Board}`
                    })));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const GetTeachersList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            Loader.show();
            await api.post(APIConstant.path.getTeacherDetailList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setTeachersList(res.data);
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) {
            Loader.hide();
            console.log(err);
        }
    }

    return (
        <div className='wayment-owner-dashboard e-weighment-dashoard'>
            <div className="heading-area p-d-flex p-ai-center p-jc-between">
                <div className="p-d-flex p-ai-baseline">
                    <i className="pi pi-clock"></i>
                    <h2>Time Table</h2>
                </div>
            </div>
            <hr className='p-m-0' />
            <TabView className='p-mt-3'>
                <TabPanel header="Class">
                    <div>
                        {
                            classList.map(x => (
                                <ClassTimeTableCard key={x?._id} ClassId={x?._id} ClassName={x?.Class} />
                            ))
                        }
                    </div>
                </TabPanel>
                <TabPanel header="Teacher">
                    <div>
                        {
                            teachersList.map(x => (
                                <TeacherTimeTableCard key={x?._id} TeacherId={x?._id} TeacherName={x?.Name} />
                            ))
                        }
                    </div>
                </TabPanel>
            </TabView>
        </div>
    );
};

export default TimeTableConfiguration;
