import React, { useState, useEffect, useCallback } from 'react';
import { Dropdown } from 'primereact/dropdown';
import APIConstant from "../../utils/pathConstants";
import api from "../../utils/apiClient";
import CommonConfig from '../../utils/constant';
import moment from 'moment';

const AcademicYearSelector = (props) => {

    const [userId, setUserId] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const [financialYears, setFinancialYears] = useState([]);

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setUserId(loginData?._id);
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1;
        const startYear = currentMonth >= 4 ? currentYear : currentYear - 1;
        const endYear = startYear + 1;
        const financialYear = `${startYear}-${endYear}`;

        const years = [];
        for (let i = 0; i < 7; i++) {
            const year = startYear - i;
            years.push({ label: `${year}-${year + 1}`, value: `${year}-${year + 1}` });
        }
        getFinancialYear(loginData?._id);

    }, []);

    const loadCurrentFinancialYear = async (id) => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1;
        const startYear = currentMonth >= 4 ? currentYear : currentYear - 1;
        const endYear = startYear + 1;
        const financialYear = `${startYear}-${endYear}`;

        setSelectedYear(financialYear);
        // await updateFinancialYear(id, financialYear);
    }

    const handleYearChange = useCallback(async (e) => {
        setSelectedYear(e.value);
        await updateFinancialYear(userId, e.value);
    }, [])

    const getFinancialYear = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.getAcademicCalanderTemplateList, data).then(async response => {
                let res = response;
                if (res.success) {
                    if (res?.data) {
                        setFinancialYears(res?.data?.map(x => ({
                            label: `${moment(x?.StartDate).format("YYYY")}-${moment(x?.EndDate).format("YYYY")}`,
                            value: `${moment(x?.StartDate).format("YYYY")}-${moment(x?.EndDate).format("YYYY")}`
                        })));
                        loadCurrentFinancialYear(userId);
                    }
                } else {
                    await loadCurrentFinancialYear(userId);
                }
            }).catch(err => {
                console.log(err);
            });
        } catch (error) {
            console.log("Error get Financial Year: ", error);
        }
    }

    const updateFinancialYear = async (userId, year) => {
        try {
            const payloads = {
                UserID: userId,
                FinancialYear: year
            }
            await api.post(APIConstant.path.updateFinancialYear, payloads).then(async (res) => {
                if (res.success) {
                    await getFinancialYear(userId);
                }
                setTimeout(() => {
                    window.location.reload();
                }, 2000)
            }).catch(err => {
                console.log("Error Update Financial Year: ", err);
            });
        } catch (error) {
            console.log("Error Update Financial Year: ", error);

        }
    }

    return (
        <div className="custom-inputs">
            <Dropdown
                className="p-w-100 p-mt-1"
                name='selectedYear'
                inputId='selectedYear'
                value={selectedYear}
                options={financialYears}
                onChange={handleYearChange}
                placeholder="Select Financial Year"
            />
        </div>
    );
};

export default AcademicYearSelector;